import React, { useState } from "react";
import {
  TextField,
  Chip,
  Stack,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/system";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import MuiText from "./MuiText";
import StyledTextField from "./StyledTextField";
import { TeamsService } from "../../services/TeamsService";
import LinearProgress from "@mui/material/LinearProgress";

export default function MemberManager({
  members,
  setMembers,
  roundRobinLocations,
  setRoundRobinLocation,
  spaceId,
  label = "Add Members",
  header = "Add Users",
  error = false,
  handleRoundRObinMeetingLoc,
}) {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const searchMembers = async (query) => {
    setLoading(true);
    setTimeout(async () => {
      const res = await TeamsService.getTeamUsersbyName(query, spaceId);
      setOptions(res);
      console.log(res);

      // if (query) {
      //   const results = ["User 1", "User 2"].filter((user) =>
      //     user.toLowerCase().includes(query.toLowerCase())
      //   );
      //   setOptions(
      //     results.length > 0 ? results : [`Invite this User: ${query}`]
      //   );
      // } else {
      //   setOptions([]);
      // }
      setLoading(false);
    }, 500);
  };

  const handleAddMember = (newMember) => {
    handleRoundRObinMeetingLoc(newMember.Id, {
      id: 1,
      value: "zoom",
      name: "Zoom",
      icon: "",
    });
    setMembers([...members, newMember]);
    setInputValue("");
    setOptions([]);
  };

  const handleRemoveMember = (memberToRemove) => {
    setMembers(members.filter((member) => member !== memberToRemove));
    setRoundRobinLocation(delete roundRobinLocations[memberToRemove.Id]);
  };



  return (
    <>
      <MuiText
        icon={PersonAddIcon}
        text={header}
        textColor="#808080"
        mt={1}
        ml={0}
      />
      <Stack spacing={2}>
        <StyledTextField
          label={label}
          value={inputValue}
          error={error}
          onChange={(event) => {
            const newValue = event.target.value;

            setInputValue(newValue);
            if (newValue) {
              searchMembers(newValue);
            } else {
              setOptions([]);
            }
          }}
        />

        {loading ? (
          // <CircularProgress />
          <LinearProgress color="secondary" />
        ) : (
          inputValue && (
            <List>
              {options && options.map((option, index) => (
                <ListItem
                  key={index}
                  button
                  onClick={() => {
                    // if (option.startsWith("Invite this User:")) {
                    //   handleInviteUser(
                    //     option.replace("Invite User:", "")
                    //   );
                    // } else {
                    handleAddMember(option);
                    // }
                  }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <PersonIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${option.FirstName} ${option.LastName}`}
                  />
                </ListItem>
              ))}
            </List>
          )
        )}

        {members && (
          <Stack direction="row" spacing={1} flexWrap="wrap">
            {members.map((member, index) => (
              <Chip
                key={index}
                label={member.FirstName}
                onDelete={() => handleRemoveMember(member)}
                avatar={
                  <Avatar>
                    <PersonIcon />
                  </Avatar>
                }
              />
            ))}
          </Stack>
        )}
      </Stack>
    </>
  );
}
