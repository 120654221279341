import React, { useEffect, useState } from "react";
import MUISubHeader from "../UIComponents/MUISubHeader";
import { Box, Grid, Typography } from "@mui/material";
import MuiText from "../UIComponents/MuiText";
import ButtonDropDown from "../UIComponents/ButtonDropDown";
import TextOnly from "../UIComponents/TextOnly";
import SyncIcon from "@mui/icons-material/Sync";
import ChecklistIcon from "@mui/icons-material/Checklist";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import StoreIcon from "@mui/icons-material/Store";
import LinkIcon from "@mui/icons-material/Link";
import IntegrationService from "../../services/IntegrationService";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import withLoader from "../Loader/withLoader";
import { InfoWrapper } from "../../utils/InfoWrapper";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import CustomModal from "../UIComponents/CustomModal";
import GoBack from "../UIComponents/GoBack";

const ConnectGoogle = () => {
  const location = useLocation();
  const [userAccounts, setUserAccounts] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [refreshAccounts, setrefreshAccounts] = useState(false);
  const dispatch = useDispatch();
  const { user } = InfoWrapper();
 const navigate = useNavigate();

  const ConnectUserAcc = async (token) => {
    let res = await withLoader(dispatch, () =>
      IntegrationService.ConnectGoogleAcc(token, user.uid)
    );
    if (res.Info) {
        navigate("/Integrations/Google/Connect");
    } else {
        navigate("/Integrations/Google/Connect");
    }
  };

  const GetConectedAcc = async () => {
    let res = await withLoader(dispatch, () =>
      IntegrationService.GetUserConnectedAppDetails(user.uid, "google")
    );
    if (res.Info) {
      // Parse the JSON string into an actual array
      const accountsArray = JSON.parse(res.Info);
      setUserAccounts(accountsArray);
    } else {
      setUserAccounts([]); // Optional: set to an empty array if there's no info
    }
  };

  useEffect(() => {
    GetConectedAcc();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const extractedCode = params.get("code");
    if (extractedCode) {
      // setCode(extractedCode);
      ConnectUserAcc(extractedCode);
      // You can handle the code here (e.g., send it to your backend)
    }
  }, [location.search]);

  async function onClick() {
    console.log("=========Google OAuth CLICKED===========");
    let res = await IntegrationService.ConnectAppInfo("google");

    if (res.Info) {
      const clientId = res.Info.clientId;
      const redirectUri = encodeURIComponent(res.Info.redirectUri);
      const scopes = [
        "https://www.googleapis.com/auth/calendar",
        "https://www.googleapis.com/auth/calendar.events", // Manage calendar events (optional)
        "openid", // To retrieve the user's basic profile information
        "profile", // To access the user's profile information
        "email", // To access the user's email
      ];

      const scope = encodeURIComponent(scopes.join(" "));
      const responseType = "code";

      const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&access_type=offline`;

      window.location.href = googleAuthUrl;
    }
  }

  async function handleAccountDelete() {
    let res = await withLoader(dispatch, () =>
      IntegrationService.DeleteIntegratedApps(
        user.uid,
        selectedAccount?.Id,
        "google"
      )
    );
    if (res.Info) {
      setShowDeleteModal(false);
      setSelectedAccount();
      GetConectedAcc();
    }
  }
  function toggleDeleteModal(account) {
    setSelectedAccount(account);
    setShowDeleteModal(true);
  }

  const GoBackHeader = ({ header, navLink = "/Integrations" }) => (
    <Box display="flex" alignItems="center">
      <GoBack iconFont="medium" onClick={() => navigate(navLink)} />
      <Box ml={1} mt={"4px"} display="flex" alignItems="center">
        <MUISubHeader text={header} />
      </Box>
    </Box>
  );

  return (
    <>
      <Box
        sx={{
          padding: "3rem",
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
          backgroundColor: "#ffffff",
          marginBottom: "2rem",
          overflowY: "auto",
          margin: 5,
        }}
      >
        <Grid container spacing={3}>
          <GoBackHeader header={"Integrations"} />
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" color="primary" gutterBottom>
              <SyncIcon
                sx={{
                  fontSize: "1.5rem",
                  verticalAlign: "middle",
                  marginRight: "0.5rem",
                }}
              />
              Connect Google
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Create Google Meetings when an event is scheduled.
            </Typography>

            <Typography variant="h6" color="primary" gutterBottom mt={2}>
              <ChecklistIcon
                sx={{
                  fontSize: "1.5rem",
                  verticalAlign: "middle",
                  marginRight: "0.5rem",
                }}
              />
              Requirements
            </Typography>
            <Typography variant="body1" color="textSecondary">
              <AccountCircleIcon
                sx={{
                  fontSize: "1.2rem",
                  verticalAlign: "middle",
                  marginRight: "0.3rem",
                }}
              />
              Google Account
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              <StoreIcon
                sx={{
                  fontSize: "1.2rem",
                  verticalAlign: "middle",
                  marginRight: "0.3rem",
                }}
              />
              Pre-approve Synclead in the Google Marketplace
            </Typography>

            {/* Connect Button */}
            <Box mt={4}>
              <ButtonDropDown
                text="Connect Google"
                onClick={onClick}
                startIcon={<LinkIcon />}
                sx={{
                  backgroundColor: "#1976d2",
                  color: "#ffffff",
                  fontSize: "1rem",
                  padding: "0.75rem 1.5rem",
                  borderRadius: "4px",
                  "&:hover": {
                    backgroundColor: "#1565c0",
                  },
                  marginTop: "1rem",
                }}
              />
            </Box>
          </Grid>

          {/* Right Side: Connected Accounts */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" color="primary" gutterBottom>
              Connected Accounts
            </Typography>
            {userAccounts.length === 0 && (
              <TextOnly text={"No Account Connected!"} />
            )}
            {userAccounts.length > 0 &&
              userAccounts.map((account, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "1.5rem",
                    border: "1px solid #1976d2",
                    borderRadius: "8px",
                    backgroundColor: "#ffffff",
                    marginBottom: "1rem",
                    justifyContent: "space-between", // Ensures spacing between content and delete button
                  }}
                >
                  <Box>
                    <MUISubHeader
                      text={`${account.FirstName} ${account.LastName}`}
                      textSize={16}
                    />
                    <Typography variant="body2" color="textSecondary">
                      {account.Email}
                    </Typography>
                  </Box>
                  <IconButton
                    onClick={() => toggleDeleteModal(account)}
                    sx={{ color: "#d32f2f" }} // optional color styling for the delete button
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
          </Grid>
        </Grid>
      </Box>
      <CustomModal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onSave={handleAccountDelete}
        btnSaveText={"Delete"}
        // BtnSaveEndIcon={<DeleteIcon />}
        headerLabel={"Delete Google Account"}
        // headerIcon={GroupAddIcon}
        content={
          <Box>
            <TextOnly
              text={"Are you sure you want to delete this Google Account ?"}
            />
          </Box>
        }
      />
    </>
  );
};

export default ConnectGoogle;
