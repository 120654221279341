import { useState } from "react";
import { Box, Divider, Switch, IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import MUISubHeader from "../UIComponents/MUISubHeader";
import TextOnly from "../UIComponents/TextOnly";
import InfoIcon from "@mui/icons-material/Info";
import SelectInput from "../UIComponents/SelectInput";

const initialQuestionaire = [
  {
    id: "1",
    q: "Please share anything that will help prepare for our meeting.",
    AnswerType: "Text",
  },
  {
    id: "2",
    q: "Please share anything that will help prepare for our meeting.",
    AnswerType: "Radio",
  },
];

const SortableItem = ({ id, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      display={"flex"}
      alignItems="center"
      justifyContent="space-between"
      p={2}
      mb={1}
      bgcolor={"#f9f9f9"}
      borderRadius={1}
    >
      {children}
    </Box>
  );
};

const EventBookingOptions = ({header}) => {
  const [questionarie, setQuestionaire] = useState(initialQuestionaire);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(TouchSensor)
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = questionarie.findIndex((item) => item.id === active.id);
      const newIndex = questionarie.findIndex((item) => item.id === over.id);

      setQuestionaire((items) => arrayMove(items, oldIndex, newIndex));
    }
  };
  const [selectedRedirect, setSelectedRedirect] = useState("");
  return (
    <Box>
      {/* <GoBackHeader header={"Booking Options"} navigate={"Booking"} /> */}
      {header}
      <Divider sx={{ mr: 1, ml: 1, mt: 3, mb: 2 }} />
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={questionarie.map((item) => item.id)}
          strategy={verticalListSortingStrategy}
        >
          <Box
            display={"flex"}
            flexDirection="column"
            margin={1}
            // border={"1px solid #ccc"}
          >
            {questionarie.map((x) => (
              <SortableItem key={x.id} id={x.id}>
                <Box display="flex" flexDirection="column">
                  <Box>
                    <TextOnly text={`Q${x.id}: `} />
                    <TextOnly text={x.q} />
                  </Box>
                  <Box fontSize="small" color="gray" mt={1}>
                    {x.AnswerType}
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  <IconButton>
                    <EditIcon />
                  </IconButton>
                  <Switch />
                </Box>
              </SortableItem>
            ))}
          </Box>
        </SortableContext>
      </DndContext>
      <Box ml={2}>
        <MUISubHeader text="Collect Payment" />
        <Box onClick={console.log("Collect Payment Invoked")}>
          <TextOnly text={"+ Collect Payment"} />
        </Box>
      </Box>
      <Box ml={2} mt={2}>
        <MUISubHeader text="Confirmation page" />
        <Box display={"flex"} mt={2}>
          <TextOnly text={"After Booking *"} />
          <Tooltip title="Click for Information" sx={{ ml: 1 }}>
            <InfoIcon />
          </Tooltip>
        </Box>
        <Box onClick={console.log("Collect Payment Invoked")} mt={2}>
          <SelectInput
            label="Redirect to Url"
            value={selectedRedirect}
            onChange={(e) =>  setSelectedRedirect(e.target.value)}
            options={[
              { value: "30", label: "Redirect To Confirmation Page" },
              { value: "60", label: "Redirect to External Site" },
            ]}
            fullWidth
          />
        </Box>
      </Box>
    </Box>
  );
};

export default EventBookingOptions;
