import React, { useEffect, useState } from "react";
import { Table, Avatar, Input, Button, Typography, Tooltip, Space } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { TeamsService } from "../../services/TeamsService";
import ErrorComponent from "../Messages/ErrorComponent";
import UserListWithSearch from "../Team/UserListWithSearch";
import zIndex from "@mui/material/styles/zIndex";

const Teams = ({ spaceId, handleEdit }) => {
  const [teams, setTeams] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [errorMsg, setErrorMsg] = useState();
  const [showError, setShowError] = useState(false);
  const [searchText, setSearchText] = useState(""); // For Team Name search filter
  const [expandedRowKeys, setExpandedRowKeys] = useState([]); // Track expanded rows

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await TeamsService.GetTeams(spaceId);

        if (res.Error) {
          setErrorMsg(res.Error);
          setShowError(true);
        } else if (res.Info) {
          const parsedTeams = JSON.parse(res.Info);
          console.log("========= parsedTeams =====", parsedTeams);
          setTeams(parsedTeams);
          setTeamMembers(parseParticipants(parsedTeams.ParticipantInfo));
        }
      } catch (error) {
        setErrorMsg("Failed to load teams.");
        setShowError(true);
      }
    };

    fetchData();
  }, [spaceId]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredTeams = teams.filter((team) =>
    team.TeamName.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleExpand = (expanded, record) => {
    const keys = expanded
      ? [...expandedRowKeys, record.TeamId]
      : expandedRowKeys.filter((id) => id !== record.TeamId);
    console.log("==== keys ======", keys);
    setExpandedRowKeys(keys);
  };

  const columns = [
    {
      title: "Team Name",
      dataIndex: "TeamName",
      key: "teamName",
      filterDropdown: () => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Team Name"
            value={searchText}
            onChange={handleSearch}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      render: (text, record) => (
        <Space>
          <Tooltip title={text}>
            <Avatar src={record.Avatar} size={"large"}>
              {!record.Avatar && text.charAt(0).toUpperCase()}
            </Avatar>
          </Tooltip>
          <Typography.Text>{text}</Typography.Text>
        </Space>
      ),
    },
    {
      title: "Team URL",
      dataIndex: "TeamURL",
      key: "teamUrl",
      render: (url) => (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      ),
    },
    {
      title: "Participants",
      key: "participants",
      render: (text, record) => {
        const maxAvatarsToShow = 3; // Limit the number of avatars shown inline
        const avatars = record.ParticipantInfo.slice(0, maxAvatarsToShow).map(
          (participant) => (
            <Avatar
              key={participant.ParticipantId}
              src={participant.Avatar}
              style={{ marginRight: 8, backgroundColor: participant.BgColor }}
            >
              {!participant.Avatar &&
                participant.FirstName.charAt(0).toUpperCase()}
            </Avatar>
          )
        );

        return (
          <Space>
            {avatars}

            {record.ParticipantInfo.length > maxAvatarsToShow && (
              <Tooltip title="Click to expand and see more participants">
                <Typography.Text>
                  +{record.ParticipantInfo.length - maxAvatarsToShow} more
                </Typography.Text>
              </Tooltip>
            )}
          </Space>
        );
      },
    },
    {
      title: "Admin",
      dataIndex: "Admins",
      key: "admin",
      render: (admins) => (admins ? admins.join(", ") : "No Admins"),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record.TeamId)}
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.TeamId)}
          />
        </Space>
      ),
    },
  ];

  const parseParticipants = (participants) => {
    console.log("===== Inside parseparticipant ====", participants);
    if (!participants) return []; // Return empty array if participants string is empty or null

    // Split participants by comma
    return participants.split(",").map((p) => {
      const [id, firstName, lastName, avatarOrLetter, bgColor] = p.split("___");

      // Return an object with parsed user information
      return {
        id,
        firstName,
        lastName,
        avatar: avatarOrLetter.length > 1 ? avatarOrLetter : null, // Avatar if length > 1
        letter: avatarOrLetter.length === 1 ? avatarOrLetter : null, // Initial letter if single character
        bgcolor: bgColor,
      };
    });
  };
  // Expanded row content
  const expandedRowRender = (record) => {
    const avatars = record.ParticipantInfo.slice(0, 100).map((participant) => (
      <Tooltip title={`${participant.FirstName} ${participant.LastName} `} sx={{zIndex:20}}>
        <Avatar
          key={participant.ParticipantId}
          src={participant.Avatar}
          style={{ marginRight: 8, backgroundColor: participant.BgColor }}
        >
          {!participant.Avatar && participant.FirstName.charAt(0).toUpperCase()}
        </Avatar>
      </Tooltip>
    ));

    return <Space>{avatars}</Space>; // Return the avatars inside Space
  };
  // const expandedRowRender = (record) => (
  //   <Space>
  //     <Avatar
  //             key={teamMembers.ParticipantId}
  //             src={teamMembers.Avatar}
  //             style={{ marginRight: 8, backgroundColor:teamMembers.BgColor}}
  //           >
  //              {teamMembers.Avatar?.length === 1 && teamMembers.Avatar}
  //           </Avatar>
  //   </Space>
  // );

  // const handleEdit = (id) => {
  //   setEditTeamId(id);
  //   setShowEditTeam(true);
  // }
  const handleDelete = (id) => {
    console.log("===== Invoked handleDelete ===== ", id);
  };

  return (
    <>
      {showError && <ErrorComponent message={errorMsg} />}
      <Table
        columns={columns}
        dataSource={filteredTeams}
        rowKey="TeamId"
        expandable={{
          expandedRowRender, // Content to show when row is expanded
          expandedRowKeys, // Keeps track of expanded rows
          onExpand: handleExpand, // Handle row expansion
          expandIconColumnIndex: 3, // Move expand icon to Participants column
        }}
        pagination={{ pageSize: 5 }}
        style={{ backgroundColor: "#fff" }}
      />
    </>
  );
};

export default Teams;
