import React from 'react'

function Settings() {
  return (
    <div className='Settings' style={{marginLeft:10}}>
      
      {/* <Tabs defaultActiveKey="1" onChange={handleTabChange} style={{marginRight:2}}>
      <TabPane tab="All" key="1">
        <All/>
      </TabPane>
      <TabPane tab="Sent" key="2">
        <Sent/>
      </TabPane>
      <TabPane tab="Opened" key="3">
        <Opened/>
      </TabPane>
      <TabPane tab="Clicks" key="4">
        <Clicks/>
      </TabPane>
      <TabPane tab="Conversations" key="5">
        <Conversations/>
      </TabPane>
      <TabPane tab="Meeting" key="6">
        <Meetings/>
      </TabPane>
      <TabPane tab="Unsubscribe" key="7">
       <Unsubscribe/>
      </TabPane>
    </Tabs> */}
    
    </div>
  )
}

export default Settings
