import React, { useState } from "react";
import {
  Box,
  Typography,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import dayjs from "dayjs";
import PreviewCalendar from "./PreviewCalendar";
import TextOnly from "../UIComponents/TextOnly";

const EventPreview = ({ username, eventName, duration, location }) => {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1400));

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isSmallScreen ? "column" : "row",
        gap: 3,
        padding: "1.5rem",
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        justifyContent: "center", // Center the content horizontally
      }}
    >
      {/* Event Details Section */}
      <Box
        sx={{
          width: isSmallScreen ? "100%" : "30%",
          mb: isSmallScreen ? 2 : 0,
        }}
      >
        <Box
          sx={{
            mb: 2,
            textAlign: isSmallScreen ? "center" : "left",
          }}
        >
          <TextOnly text={eventName} color={"#1976d2"} />
          <TextOnly text={username} />
        </Box>

        {/* Duration and Location */}
        <Box
          display="flex"
          flexDirection={isSmallScreen ? "row" : "column"}
          justifyContent={"center"}
          alignItems={isSmallScreen ? "center" : "flex-start"}
          mb={2}
        >
          {/* Duration */}
          <Box display="flex" alignItems="center" mb={isSmallScreen ? 0 : 1}>
            <AccessTimeIcon sx={{ fontSize: 20, mr: 1 }} />
            <Typography variant="body1">
              {duration ? `${duration} Minutes` : "Duration"}
            </Typography>
          </Box>

          {/* Location */}
          <Box display="flex" alignItems="center" ml={isSmallScreen ? 2 : 0}>
            <LocationOnIcon sx={{ fontSize: 20, mr: 1 }} />
            <Typography variant="body1">
              {location ? location : "Add Location to be shown here!"}
            </Typography>
          </Box>
        </Box>

        {isSmallScreen && <Divider sx={{ mb: 2 }} />}
      </Box>

      {/* Calendar Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          flexGrow: 1,
          flexDirection: "column", // Allow vertical expansion for time slots
        }}
      >
        <PreviewCalendar
          selectedDate={selectedDate}
          onDateChange={handleDateChange}
          isPreview={true}
        />
      </Box>
    </Box>
  );
};

export default EventPreview;


// import React, { useState } from "react";
// import {
//   Box,
//   Typography,
//   Divider,
//   useMediaQuery,
//   useTheme,
// } from "@mui/material";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
// import dayjs from "dayjs";
// import PreviewCalendar from "./PreviewCalendar";
// import TextOnly from "../UIComponents/TextOnly";

// const EventPreview = ({ username, eventName, duration, location }) => {
//   const [selectedDate, setSelectedDate] = useState(dayjs());
//   const theme = useTheme();
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down(1400));

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//   };

//   return (
//     <Box
//       sx={{
//         display: "flex",
//         flexDirection: isSmallScreen ? "column" : "row",
//         gap: 3,
//         padding: "1.5rem",
//         backgroundColor: "#ffffff",
//         borderRadius: "8px",
//         boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//       }}
//     >
//       {/* Event Details Section */}
//       <Box
//         sx={{
//           width: isSmallScreen ? "100%" : "30%",
//           mb: isSmallScreen ? 2 : 0,
//         }}
//       >
//         <Box
//           sx={{
//             mb: 2,
//             textAlign: isSmallScreen ? "center" : "left", // Align center or left
//           }}
//         >
//           <TextOnly text={eventName} color={"#1976d2"} />
//           <TextOnly text={username} />
//         </Box>

//         {/* Duration and Location (Single row on small screens) */}
//         <Box
//           display="flex"
//           flexDirection={isSmallScreen ? "row" : "column"}
//           justifyContent={"center"}
//           alignItems={isSmallScreen ? "center" : "flex-start"}
//           mb={2}
//         >
//           {/* Duration */}
//           <Box display="flex" alignItems="center" mb={isSmallScreen ? 0 : 1}>
//             <AccessTimeIcon sx={{ fontSize: 20, mr: 1 }} />
//             <Typography variant="body1">
//               {duration ? `${duration} Minutes` : "Duration"}
//             </Typography>
//           </Box>

//           {/* Location */}
//           <Box display="flex" alignItems="center" ml={isSmallScreen ? 2 : 0}>
//             <LocationOnIcon sx={{ fontSize: 20, mr: 1 }} />
//             <Typography variant="body1">
//               {location ? location : "Add Location to be shown here!"}
//             </Typography>
//           </Box>
//         </Box>

//         {isSmallScreen && <Divider sx={{ mb: 2 }} />}
//       </Box>

//       {/* Calendar Section */}
//       <Box
//         sx={{ flexGrow:1}}
//         justifyContent={"center"}        
//         alignItems={"center"}
//       >
//         <PreviewCalendar
//           selectedDate={selectedDate}
//           onDateChange={handleDateChange}
//         />
//       </Box>
//     </Box>
//   );
// };

// export default EventPreview;

// import React, { useState, useEffect } from "react";
// import {
//   Grid,
//   Box,
//   Typography,
//   Button,
//   TextField,
//   Divider,
//   useMediaQuery,
//   useTheme,
// } from "@mui/material";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
// import dayjs from "dayjs";
// import PreviewCalendar from "./PreviewCalendar";
// import TextOnly from "../UIComponents/TextOnly";

// const EventPreview = ({ username, eventName, duration, location }) => {
//   const [selectedDate, setSelectedDate] = useState(dayjs());
//   const theme = useTheme();
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down(1400));

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//   };

//   return (
//     <Grid
//       container
//       spacing={3}
//       sx={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row" }}
//     >

//       {/* Event Details Section */}
//       <Grid item xs={12} md={12}>
//         <Box
//           sx={{
//             padding: "1.5rem",
//             backgroundColor: "#ffffff",
//             borderRadius: "8px",
//             boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//             // display: "flex",
//             // flexDirection: "column",
//           }}
//         >
//           <Grid container>
//             <Grid item xs={12} md={3} spacing={2}>
//               <Box sx={{ mb: 2 }}>
//                 {isSmallScreen && (
//                   <>
//                     <Box
//                       sx={{
//                         color: "#1976d2",
//                         fontWeight: "bold",
//                         fontSize: "18px",
//                       }}
//                       alignContent={"center"}
//                       alignItems={"center"}
//                       justifyItems={"center"}
//                     >
//                       {eventName}
//                     </Box>
//                     <Box sx={{ fontSize: "16px", color: "#808080" }}>
//                       {username}
//                     </Box>
//                     <Box display="flex" flexDirection="center" mb={2}>
//                       <AccessTimeIcon
//                         sx={{ fontSize: 20, marginRight: "8px" }}
//                       />
//                       <Typography variant="body1">
//                         {duration ? `${duration} Minutes` : "Duration"}
//                       </Typography>
//                       <LocationOnIcon
//                         sx={{ fontSize: 20, marginRight: "8px" }}
//                       />
//                       <Typography variant="body1">
//                         {location
//                           ? location
//                           : `Add Location to be shown here!.`}
//                       </Typography>
//                     </Box>
//                   </>
//                 )}
//                 {!isSmallScreen && (
//                   <>
//                     <Box>
//                       <TextOnly text={eventName} color={"#1976d2"} />
//                     </Box>
//                     <Box>
//                       <TextOnly text={username} />
//                     </Box>
//                     <Box display="flex" flexDirection="center" mb={1}>
//                       <AccessTimeIcon
//                         sx={{ fontSize: 20, marginRight: "8px" }}
//                       />
//                       <Typography variant="body1">
//                         {duration ? `${duration} Minutes` : "Duration"}
//                       </Typography>
//                     </Box>
//                     <Box display="flex" flexDirection="center" mb={2}>
//                       <LocationOnIcon
//                         sx={{ fontSize: 20, marginRight: "8px" }}
//                       />
//                       <Typography variant="body1">
//                         {location
//                           ? location
//                           : `Add Location to be shown here!.`}
//                       </Typography>
//                     </Box>
//                   </>
//                 )}
//               </Box>
//               {/* Render the details at the top if small screen */}
//               {isSmallScreen && (
//                 <>
//                   <Divider sx={{ mb: 2 }} />
//                 </>
//               )}
//             </Grid>
//             <Grid item>
//               <PreviewCalendar />
//             </Grid>
//           </Grid>
//         </Box>
//       </Grid>
//     </Grid>
//   );
// };

// export default EventPreview;

// {/* Calendar Section */}
// {/* <Grid item xs={12} md={6}>
//   <Box
//     sx={{
//       padding: "1.5rem",
//       backgroundColor: "#ffffff",
//       borderRadius: "8px",
//       boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//     }}
//   >
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <StaticDatePicker
//         value={selectedDate}
//         onChange={handleDateChange}
//         disablePast
//         renderInput={(params) => <TextField {...params} fullWidth />}
//         sx={{
//           "& .MuiPickersDay-root": {
//             "&.Mui-selected": {
//               backgroundColor: "#1976d2",
//               color: "#ffffff",
//             },
//           },
//         }}
//       />
//     </LocalizationProvider>
//   </Box>
// </Grid> */}

//  {/* Username */}
//  <Typography variant="h6" gutterBottom>
//  {username ? username : "Your Name"}
// </Typography>

// {/* Event Name */}
// <Typography variant="h5" gutterBottom>
//  {eventName ? eventName : "Event Name"}
// </Typography>

// {/* Duration */}
// <Box display="flex" flexDirection="center" mb={2}>
//  <AccessTimeIcon sx={{ fontSize: 20, marginRight: "8px" }} />
//  <Typography variant="body1">
//    {duration ? `${duration} Minutes` : "Duration"}
//  </Typography>
// </Box>

// {/* Location */}
// <Box display="flex" flexDirection="center" mb={2}>
//  <LocationOnIcon sx={{ fontSize: 20, marginRight: "8px" }} />
//  <Typography variant="body1">
//    {location
//      ? `${location}: Meeting Details Provided upon Booking`
//      : "Meeting Medium"}
//  </Typography>
// </Box>
