import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  InputAdornment,
  IconButton,
  FormControl,
  Divider,
  Chip,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import StyledTextField from "../components/UIComponents/StyledTextField";
import GoogleAuthButton from "../components/UIComponents/GoogleAuthButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import withLoader from "../components/Loader/withLoader";
import { useDispatch } from "react-redux";
import  { AuthService } from "../services/LoginService";
import ErrorComponent from "../components/Messages/ErrorComponent";
import BtnIcons from "../components/UIComponents/BtnIcons";
import MUISubHeader from "../components/UIComponents/MUISubHeader";

const JoinTeam = () => {
  const [searchParams] = useSearchParams();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([""]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const validateUrl = async () => {
      let res = await withLoader(dispatch, () =>
        AuthService.isInviteUrlExpired(searchParams.get("token"))
      );
      if (res.Error) {
        setShowError(true);
        setErrorMessage([res.Error]);
      }
    };
    validateUrl();
  }, [dispatch, searchParams]);

  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    newpassword: "",
    confirmnewpassword: "",
  });

  // State for error messages
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    newpassword: false,
    confirmnewpassword: false,
  });
  const [errorsMsg, setErrorsMsg] = useState({
    firstName: "",
    lastName: "",
    email: "",
    newpassword: "",
    confirmnewpassword: "",
  });

  const [showNewPassword, setShowNewPassword] = useState(false);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update form values
    setFormValues({
      ...formValues,
      [name]: value,
    });

    // Clear error state when user types
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
    setErrorsMsg((prevMsg) => ({
      ...prevMsg,
      [name]: "",
    }));
  };

  async function handleSubmit() {
    setLoading(true);
    let valid = true;

    // Validate First Name
    if (formValues.firstName === "") {
      setErrors((prevErrors) => ({ ...prevErrors, firstName: true }));
      setErrorsMsg((prevMsg) => ({ ...prevMsg, firstName: "Required!" }));
      valid = false;
    }

    // Validate Last Name
    if (formValues.lastName === "") {
      setErrors((prevErrors) => ({ ...prevErrors, lastName: true }));
      setErrorsMsg((prevMsg) => ({ ...prevMsg, lastName: "Required!" }));
      valid = false;
    }

    // Validate Email
    if (formValues.email === "") {
      setErrors((prevErrors) => ({ ...prevErrors, email: true }));
      setErrorsMsg((prevMsg) => ({ ...prevMsg, email: "Required!" }));
      valid = false;
    } else if (!validateEmail(formValues.email)) {
      setErrors((prevErrors) => ({ ...prevErrors, email: true }));
      setErrorsMsg((prevMsg) => ({
        ...prevMsg,
        email: "Invalid email format!",
      }));
      valid = false;
    }

    // Validate Password
    if (formValues.newpassword === "") {
      setErrors((prevErrors) => ({ ...prevErrors, newpassword: true }));
      setErrorsMsg((prevMsg) => ({ ...prevMsg, newpassword: "Required!" }));
      valid = false;
    }

    // Validate Confirm Password
    if (formValues.confirmnewpassword === "") {
      setErrors((prevErrors) => ({ ...prevErrors, confirmnewpassword: true }));
      setErrorsMsg((prevMsg) => ({
        ...prevMsg,
        confirmnewpassword: "Required!",
      }));
      valid = false;
    } else if (formValues.newpassword !== formValues.confirmnewpassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newpassword: true,
        confirmnewpassword: true,
      }));
      setErrorsMsg((prevMsg) => ({
        ...prevMsg,
        confirmnewpassword: "Passwords do not match!",
      }));
      valid = false;
    }
    if (!valid) {
      setLoading(false);
    }
    if (valid) {
      // Proceed with form submission logic
      let token = searchParams.get("token") ? searchParams.get("token") : "";
      console.log(token, formValues);
      const res = await withLoader(dispatch, () =>
        AuthService.CreateUser(formValues, token)
      );
      if (res.Error) {
        setErrorMessage([res.Error]);
        setShowError(true);
        setLoading(false);

      } else if (res.Login) {
        setLoading(false);
        navigate("/login");
      }
    }
  }

  return (
    <>
      <Container maxWidth="sm">
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            mb={2}
          >
            <Grid item>
              <MUISubHeader text="Register" />
            </Grid>

            <Grid item mt={2}>
              {showError && (
                <ErrorComponent
                  errorMessages={errorMessage}
                  setErrorMessage={setErrorMessage}
                  show={showError}
                  setShow={setShowError}
                />
              )}
            </Grid>
          </Grid>

          <FormControl>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  required
                  fullWidth
                  id="firstName"
                  name="firstName"
                  label="First Name"
                  value={formValues.firstName}
                  onChange={handleChange}
                  error={errors.firstName}
                  helperText={errorsMsg.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  required
                  fullWidth
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  value={formValues.lastName}
                  onChange={handleChange}
                  error={errors.lastName}
                  helperText={errorsMsg.lastName}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  required
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  value={formValues.email}
                  onChange={handleChange}
                  error={errors.email}
                  helperText={errorsMsg.email}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  required
                  fullWidth
                  id="newpassword"
                  name="newpassword"
                  label="Password"
                  type={showNewPassword ? "text" : "password"}
                  value={formValues.newpassword}
                  onChange={handleChange}
                  error={errors.newpassword}
                  helperText={errorsMsg.newpassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowNewPassword}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  required
                  fullWidth
                  id="confirmnewpassword"
                  name="confirmnewpassword"
                  label="Confirm Password"
                  type={showNewPassword ? "text" : "password"}
                  value={formValues.confirmnewpassword}
                  onChange={handleChange}
                  error={errors.confirmnewpassword}
                  helperText={errorsMsg.confirmnewpassword}
                />
              </Grid>
            </Grid>
            {/* <Button
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              sx={{ mt: 3, mb: 2 }}
            >
              Register
            </Button> */}
            <Grid mt={3}>
              <BtnIcons
                variant="contained"
                onClick={handleSubmit}
                btnLabel="Register"
                loading={loading}
                setLoading={setLoading}
                isIconButton={true}
              />
            </Grid>
          </FormControl>
        </Box>
        <Divider>
          <Chip label="or" />
        </Divider>
      </Container>
      <Container maxWidth="sm">
        <Grid
          sm={4}
          md={2}
          sx={{
            marginTop: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <GoogleAuthButton
            isSignIn={false}
            crypto={searchParams.get("token")}
          />
        </Grid>
      </Container>
    </>
  );
};

export default JoinTeam;

// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   Button,
//   TextField,
//   Typography,
//   Container,
//   Grid,
//   InputAdornment,
//   IconButton,
//   FormControl,
//   Divider,
//   Chip,
// } from "@mui/material";
// import { Google as GoogleIcon } from "@mui/icons-material";
// import MUISubHeader from "../components/UIComponents/MUISubHeader";
// import GoogleButton from "react-google-button";
// import StyledTextField from "../components/UIComponents/StyledTextField";
// import { Visibility, VisibilityOff } from "@mui/icons-material";
// import GoogleAuthButton from "../components/UIComponents/GoogleAuthButton";
// import { useSearchParams } from "react-router-dom";
// import withLoader from "../components/Loader/withLoader";
// import { useDispatch } from "react-redux";
// import { AuthService } from "../services/LoginService";
// import ErrorComponent from "../components/Messages/ErrorComponent";

// const JoinTeam = () => {
//   // const { user } = InfoWrapper();
//   const [searchParams, setSearchParams] = useSearchParams();
//   const [showError, setShowError] = useState(false);
//   const [errorMessage, setErrorMessage] = useState("");
//   console.log(searchParams.get("token"));
//   const dispatch = useDispatch();
//   useEffect(() => {
//     const validateUrl = async () => {
//       let res = await withLoader(dispatch, () =>
//         AuthService.isInviteUrlExpired(searchParams.get("token"))
//       );
//       if (res.Error) {
//         setShowError(true);
//         setErrorMessage(res.Error);
//       }
//     };
//     validateUrl();
//   }, []);

//   const [formValues, setFormValues] = useState({
//     uid: null,
//     companyname: "",
//     profession: "",
//     file: null,
//     description: "",
//     firstName: "",
//     lastName: "",
//     phoneNumber: "",
//     userName: null,
//     email: "",
//     password: "",
//     newpassword: "",
//     confirmnewpassword: "",
//   });
//   // State for error messages
//   const [errors, setErrors] = useState({
//     companyname: false,
//     profession: false,
//     file: false,
//     description: false,
//     firstName: false,
//     lastName: false,
//     phoneNumber: false,
//     userName: false,
//     email: false,
//     password: false,
//     newpassword: false,
//     confirmnewpassword: false,
//   });
//   const [errorsMsg, setErrorsMsg] = useState({
//     firstName: "",
//     lastName: "",
//     email: "",
//     password: "",
//     newpassword: "",
//     confirmnewpassword: "",
//   });
//   const [showPassword, setShowPassword] = useState(false);
//   const [showNewPassword, setShowNewPassword] = useState(false);
//   const handleClickShowPassword = () => setShowPassword(!showPassword);
//   const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
//   const [uniquePassword, setUniquePassword] = useState("");

//   const handleChange = (e) => {
//     const { name, value } = e.target;

//     // Update form values
//     setFormValues({
//       ...formValues,
//       [name]: value,
//     });

//     // Validate and update error state
//     setErrors({
//       ...errors,

//       // [name]:
//       //   name == "companyname" || name == "profession" || name == "description"
//       //     ? validateCompanyDetails(name, value)
//       //     : validate(name, value),
//     });
//     if (name) {
//       // setErrorMsg([
//       //   "Required:First Name,Last Name,User Name, Email",
//       //   "If You dont Intend To Update Password and Company Details leave them blank",
//       // ]);
//     }
//   };

//   function handleSubmit() {

//     if (formValues.firstName === "") {
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         firstName: true,
//       }));
//       setErrorsMsg((prevMsg) => ({
//         ...prevMsg,
//         firstName: "Required!",
//       }));
//     }else{
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         firstName: false,
//       }));
//       setErrorsMsg((prevMsg) => ({
//         ...prevMsg,
//         firstName: "",
//       }));
//     }

//     if (formValues.lastName === "") {
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         lastName: true,
//       }));
//       setErrorsMsg((prevMsg) => ({
//         ...prevMsg,
//         lastName: "Required!",
//       }));
//     }

//     if (formValues.email === "") {
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         email: true,
//       }));
//       setErrorsMsg((prevMsg) => ({
//         ...prevMsg,
//         email: "Required!",
//       }));
//     }

//     if (formValues.newpassword === "") {
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         newpassword: true,
//       }));
//       setErrorsMsg((prevMsg) => ({
//         ...prevMsg,
//         newpassword: "Required!",
//       }));
//     }

//     if (formValues.confirmnewpassword === "") {
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         confirmnewpassword: true,
//       }));
//       setErrorsMsg((prevMsg) => ({
//         ...prevMsg,
//         confirmnewpassword: "Required!",
//       }));
//     }

//     if (formValues.newpassword !== formValues.confirmnewpassword) {
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         newpassword: true,
//         confirmnewpassword: true,
//       }));
//       setErrorsMsg((prevMsg) => ({
//         ...prevMsg,
//         confirmnewpassword: "Password Mismatch!",
//       }));
//     }
//   }
//   return (
//     <>
//       <Container maxWidth="sm">
//         <Box
//           sx={{
//             marginTop: 4,
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//           }}
//         >
//           <MUISubHeader text="Register" />
//           {showError && (
//             // <Typography
//             //   variant="body2"
//             //   color="error"
//             //   sx={{ marginTop: 2, marginBottom: 2 }}
//             // >
//             //   {errorMessage}
//             // </Typography>
//             <ErrorComponent
//               errorMessages={errorMessage}
//               setErrorMessage={setErrorMessage}
//               show={showError}
//               setShow={setShowError}
//             />
//           )}
//           <FormControl>
//             <Grid container spacing={2}>
//               <Grid item xs={12} sm={6}>
//                 <StyledTextField
//                   required
//                   fullWidth
//                   id="firstName"
//                   name="firstName"
//                   label="First Name"
//                   inputStyle="box"
//                   labelStyle="floating"
//                   placeholder="Enter User ID"
//                   value={formValues.firstName}
//                   onChange={handleChange}
//                   error={errors.firstName}
//                   helperText={errorsMsg.firstName}
//                   sx={{ borderColor: errors.firstName ? "red" : "initial" }}
//                 />
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <StyledTextField
//                   required
//                   fullWidth
//                   id="lastName"
//                   name="lastName"
//                   label="Last Name"
//                   inputStyle="box"
//                   labelStyle="floating"
//                   placeholder="Enter Last Name"
//                   value={formValues.lastName}
//                   onChange={handleChange}
//                   error={errors.lastName}
//                   helperText={errorsMsg.lastName }
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <StyledTextField
//                   required
//                   fullWidth
//                   id="email"
//                   name="email"
//                   label="Email"
//                   inputStyle="box"
//                   labelStyle="floating"
//                   placeholder="Enter Email"
//                   value={formValues.email}
//                   onChange={handleChange}
//                   error={errors.email}
//                   helperText={errorsMsg.email}
//                 />
//               </Grid>

//               <Grid item xs={12}>
//                 <StyledTextField
//                   required
//                   fullWidth
//                   id="newpassword"
//                   name="newpassword"
//                   label="Password"
//                   type={showNewPassword ? "text" : "password"}
//                   inputStyle="box"
//                   labelStyle="floating"
//                   placeholder=" Password"
//                   value={formValues.newpassword}
//                   onChange={handleChange}
//                   error={errors.newpassword}
//                   helperText={errors.newpassword ? uniquePassword : ""}
//                   InputProps={{
//                     endAdornment: (
//                       <InputAdornment position="end">
//                         <IconButton
//                           aria-label="toggle new password visibility"
//                           onClick={handleClickShowNewPassword}
//                           edge="end"
//                           style={{ color: "#808080" }}
//                         >
//                           {showNewPassword ? <VisibilityOff /> : <Visibility />}
//                         </IconButton>
//                       </InputAdornment>
//                     ),
//                   }}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <StyledTextField
//                   required
//                   fullWidth
//                   id="confirmnewpassword"
//                   name="confirmnewpassword"
//                   label="Confirm Password"
//                   type={showNewPassword ? "text" : "password"}
//                   inputStyle="box"
//                   labelStyle="floating"
//                   placeholder="Confirm Password"
//                   value={formValues.confirmnewpassword}
//                   onChange={handleChange}
//                   error={errors.confirmnewpassword}
//                   helperText={errorsMsg.confirmnewpassword}
//                 />
//               </Grid>
//             </Grid>
//             <Button
//               type="submit"
//               fullWidth
//               variant="contained"
//               onClick={handleSubmit}
//               sx={{
//                 mt: 3,
//                 mb: 2,
//                 backgroundColor: "#1976d2",
//                 "&:hover": {
//                   backgroundColor: "#1565c0",
//                 },
//               }}
//             >
//               Register
//             </Button>
//             {/* <GoogleButton
//               type="light" // can be light or dark
//               label="Sign Up with Google"
//               onClick={() => {
//                 console.log("Google button clicked");
//               }}
//               style={{ width: "100%" }}
//             /> */}
//           </FormControl>
//         </Box>
//         <Divider>
//           <Chip label="or" size="medium" sx={{}} />
//         </Divider>
//       </Container>
//       <Container maxWidth="sm">
//         <Grid
//           sm={4}
//           md={2}
//           sx={{
//             marginTop: 4,
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//           }}
//         >
//           <GoogleAuthButton
//             isSignIn={false}
//             crypto={searchParams.get("token")}
//           />
//         </Grid>
//       </Container>
//     </>
//   );
// };

// export default JoinTeam;
