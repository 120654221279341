// import React from "react";
// import "./App.css";
// import SidePanel from "./components/SidePanel/SidePanel";
// import EventSidePanel from "./components/SidePanel/EventSidePanel";
// import {
//   BrowserRouter as Router,
//   Routes,
//   Route,
//   useLocation,
// } from "react-router-dom";
// import Analytics from "./pages/Analytics";
// import ClientCalendar from "./pages/ClientCalendar";
// import Settings from "./pages/Settings";
// import Login from "./pages/Login";
// import DashBoard from "./pages/DashBoard";
// import SignUp from "./pages/SignUp";
// import UserDetails from "./components/Settings/UserDetails";
// import { useDispatch, useSelector } from "react-redux";
// import { selectUser } from "./features/user/userSlice";
// import SyncApps from "./components/calendar/SyncApps";
// import Logout from "./pages/Logout";
// import Chatbot from "./pages/Chatbot";
// import Availability from "./pages/Availability";
// import { selectLoader } from "./features/user/LoaderSlice";
// import AppLoader from "./components/Loader/AppLoader";
// import { hideLoader } from "./features/user/LoaderSlice";
// import Events from "./pages/Events";
// import CreateEvent from "./pages/CreateEvent";
// import JoinTeam from "./pages/JoinTeam";
// import UpgradePlan from "./pages/UpgradePlan";
// import Subscribe from "./pages/Subscribe";

// function App() {
//   const user = useSelector(selectUser);
//   const loader = useSelector(selectLoader);

//   return !user ? (
//     <Login />
//   ) : (
//     <Router>
//       <AppContent loader={loader} />
//     </Router>
//   );
// }

// function AppContent({ loader }) {
//   const location = useLocation(); // Use useLocation inside the Router context

//   const checkUrl = () => {
//     if (location.search.includes("code=")) {
//       const codeIndex = location.search.indexOf("code=");
//       if (codeIndex !== -1) {
//         const codeStartIndex = codeIndex + 5;
//         const nextAmpersandIndex = location.search.indexOf("&", codeStartIndex);
//         const code =
//           nextAmpersandIndex !== -1
//             ? location.search.substring(codeStartIndex, nextAmpersandIndex)
//             : location.search.substring(codeStartIndex);
//         return <SyncApps code={code} />;
//       }
//     }
//     return <DashBoard />;
//   };

//   const Panel = () => {
//     let currentPath = location.pathname.toLowerCase();
//     // if (currentPath.includes("eventdetails")) {
//     //   return <EventSidePanel />;
//     // } else if (currentPath.includes("token")) {
//     //   return null;
//     // } else {
//     //   <SidePanel />;
//     // }
//     //return location.pathname.toLowerCase().includes("eventdetails") ? (
//     return currentPath.includes("eventdetails") ? (
//       <EventSidePanel />
//     ) : (currentPath.includes("jointeam") ? null :
//       <SidePanel />
//     );
//   };

//   return (
//     <div className="app-container">
//       {loader && <AppLoader show={loader} />}
//       <div className="sidebar">{<Panel />}</div>
//       <div className="main-content">
//         <Routes>
//           <Route path="/" element={checkUrl()} />
//           <Route path="/analytics" element={<Analytics />} />
//           <Route path="/calendar" element={<ClientCalendar />} />
//           <Route path="/availability" element={<Availability />} />
//           <Route path="/settings" element={<Settings />} />
//           <Route path="/logout" element={<Logout />} />
//           <Route path="/signup" element={<SignUp />} />
//           <Route path="/settings/SyncApps" element={<SyncApps />} />
//           <Route path="/Settings/UserDetails" element={<UserDetails />} />
//           <Route path="/login" element={<Login />} />
//           <Route path="/events" element={<Events />} />
//           <Route path="/createevent" element={<CreateEvent />} />
//           <Route path="/JoinTeam" element={<JoinTeam />} />
//           <Route path="/UpgradePlan" element={<UpgradePlan />} />
//           <Route path="/Subscribe" element={<Subscribe />} />
//         </Routes>
//       </div>
//     </div>
//   );
// }

// export default App;

import React from "react";
import "./App.css";
import SidePanel from "./components/SidePanel/SidePanel";
import EventSidePanel from "./components/SidePanel/EventSidePanel";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Analytics from "./pages/Analytics";
import ClientCalendar from "./pages/ClientCalendar";
import Settings from "./pages/Settings";
import Login from "./pages/Login";
import DashBoard from "./pages/DashBoard";
import SignUp from "./pages/SignUp";
import UserDetails from "./components/Settings/UserDetails";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "./features/user/userSlice";
import Logout from "./pages/Logout";
import Chatbot from "./pages/Chatbot";
import Availability from "./pages/Availability";
import { selectLoader } from "./features/user/LoaderSlice";
import AppLoader from "./components/Loader/AppLoader";
import { hideLoader } from "./features/user/LoaderSlice";
import Events from "./pages/Events";
import CreateEvent from "./pages/CreateEvent";
import JoinTeam from "./pages/JoinTeam";
import UpgradePlan from "./pages/UpgradePlan";
import Subscribe from "./pages/Subscribe";
import BookEvent from "./pages/BookEvent";
import Zoom from "./pages/Zoom";
import EventDetails from "./pages/EventDetails";
import SyncApps from "./pages/SyncApps";
import ConnectGoogleMeet from "./components/Integrations/ConnectGoogleMeet";
import ConnectTeams from "./components/Integrations/ConnectTeams";
import ConnectWebEx from "./components/Integrations/ConnectWebEx";
import ConnectOutlook from "./components/Integrations/ConnectOutlook";
import ConnectGoogle from "./components/Integrations/ConnectGoogle";
import Register from "./pages/Register";

function App() {
  const user = useSelector(selectUser);

  return (
    <Router>{!user ? <UnauthenticatedApp /> : <AuthenticatedApp />}</Router>
  );
}

function UnauthenticatedApp() {
  return (
    <Routes>
      <Route path="/jointeam" element={<JoinTeam />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/Register" element={<Register />} />
      <Route path="*" element={<Login />} />
    </Routes>
  );
}

function AuthenticatedApp() {
  const loader = useSelector(selectLoader);
  const location = useLocation();

  const checkUrl = () => {
    if (location.search.includes("code=")) {
      const codeIndex = location.search.indexOf("code=");
      if (codeIndex !== -1) {
        const codeStartIndex = codeIndex + 5;
        const nextAmpersandIndex = location.search.indexOf("&", codeStartIndex);
        const code =
          nextAmpersandIndex !== -1
            ? location.search.substring(codeStartIndex, nextAmpersandIndex)
            : location.search.substring(codeStartIndex);
        return <SyncApps code={code} />;
      }
    }
    return <DashBoard />;
  };

  // const Panel = () => {
  //   let currentPath = location.pathname.toLowerCase();
  //   return currentPath.includes("eventdetails") ? (
  //     <EventSidePanel />
  //   ) : currentPath.includes("jointeam") ? null : (
  //     <SidePanel />
  //   );
  // };
  const Panel = () => {
    let currentPath = location.pathname.toLowerCase();
    console.log("========================>>>1")
    if (currentPath.includes("eventdetails") || currentPath.includes("createevent")) {
      // console.log("========================>>>2")
      // return <div className="sidebar"><EventSidePanel /></div>;
      return null;
    } else if (currentPath.includes("jointeam")) {
      console.log("========================>>>3")
      return null;
    } else {
      console.log("========================>>>4")
      return <div className="sidebar"><SidePanel /></div>;
    }
  };
  const sider = () => {
    const isTwoSegments = /^\/[^/]+\/[^/]+$/.test(location.pathname);

    console.log("========= Location =========", isTwoSegments);
    if (isTwoSegments) {
      console.log("========= if =========");
      return;
    } else {
      console.log("========= else =========");
      return <Panel />;
    }
  };
  return (
    <div className="app-container">
      {loader && <AppLoader show={loader} />}
      {sider()}
      <div className="main-content">
        <Routes>
          <Route path="/" element={checkUrl()} />
          {/* <Route path="/BookeEvent" element={<BookEvent />} /> */}
          <Route path="/:a/:b" element={<BookEvent />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/availability" element={<Availability />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/Integrations" element={<SyncApps />} />
          <Route path="/settings/userdetails" element={<UserDetails />} />
          <Route path="/login" element={<Login />} />
          <Route path="/events" element={<Events />} />
          <Route path="/EventDetails/:id" element={<EventDetails />} />
          <Route path="/createevent" element={<CreateEvent />} />
          <Route path="/jointeam" element={<JoinTeam />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/upgradeplan" element={<UpgradePlan />} />
          <Route path="/subscribe" element={<Subscribe />} />
          <Route path="/Integrations/Zoom/Connect" element={<Zoom />} />
          <Route path="/Integrations/GoogleMeet/Connect" element={<ConnectGoogleMeet />} />
          <Route path="/Integrations/Teams/Connect" element={<ConnectTeams />} />
          <Route path="/Integrations/WebEx/Connect" element={<ConnectWebEx />} /> 
          <Route path="/Integrations/Outlook/Connect" element={<ConnectOutlook />} /> 
          <Route path="/Integrations/Google/Connect" element={<ConnectGoogle />} /> 
          <Route path="*" element={<DashBoard />} />
          {/* <Route path="/signup" element={<SignUp />} /> */}
          {/* <Route path="/calendar" element={<ClientCalendar />} /> */}

        </Routes>
      </div>
    </div>
  );
}

export default App;
