import React from "react";
import Typography from "antd/es/typography/Typography";
import { useTheme } from "@mui/material";

const TextOnly = ({ text, fontSize, color,fontWeight }) => {
  const theme = useTheme();

  return (
    <Typography
      style={{
        fontSize: fontSize || theme.palette.common.fontSize, // Default to theme's fontSize
        color: color || theme.palette.font.appTextColor, // Default to theme's text color
        fontFamily: theme.palette.font.customFont, // Use custom font from theme
        fontWeight:fontWeight || theme.palette.font.customFontTextWeight
      }}
    >
      {text}
    </Typography>
  );
};

export default TextOnly;
