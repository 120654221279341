// import { Box, Collapse } from "@mui/material";
// import React, { useState } from "react";
// import TextOnly from "./TextOnly";

// const ClickableRow = ({ content, text, currentVal, customIcons }) => {
//   const [expanded, setExpanded] = useState(false);

//   const handleToggle = () => {
//     setExpanded(!expanded);
//   };

//   return (
//     <Box>
//       <Box
//         onClick={!customIcons ? handleToggle : null}
//         display="flex"
//         padding={2}
//         borderRadius={2}
//         sx={{ backgroundColor: "#F9F9F9", cursor: "pointer" }}
//         justifyContent="space-between"
//       >
//         <Box ml={1}>
//           <TextOnly text={text} />
//         </Box>
//         <Box mr={currentVal ? 3 : 0}>
//           {!customIcons && <TextOnly text={currentVal} color={"#0069FF"} />}
//           {customIcons && <Box onClick={handleToggle}>{customIcons}</Box>}
//         </Box>
//       </Box>
//       <Collapse in={expanded} timeout="auto" unmountOnExit>
//         <Box padding={1} sx={{ backgroundColor: "#F9F9F9" }}>
//           {content}
//         </Box>
//       </Collapse>
//     </Box>
//   );
// };

// export default ClickableRow;
// const ClickableRow = ({
//   content,
//   text,
//   currentVal,
//   editIcon,
//   customIcons,
//   expanded,
//   onToggle,
// }) => {
//   return (
//     <Box>
//       <Box
//         onClick={!customIcons ? onToggle : null}
//         display="flex"
//         padding={2}
//         borderRadius={2}
//         sx={{ backgroundColor: "#F9F9F9", cursor: "pointer" }}
//         justifyContent="space-between"
//       >
//         <Box ml={1}>
//           <TextOnly text={text} />
//         </Box>
//         <Box mr={currentVal ? 3 : 0}>
//           {!customIcons && <TextOnly text={currentVal} color={"#0069FF"} />}
//           {customIcons && (
//             <Box ml={"auto"}>
//               {customIcons}
//               {editIcon && <Box onClick={onToggle}>{<EditIcon />}</Box>}
//             </Box>
//           )}
//         </Box>
//       </Box>
//       <Collapse in={expanded} timeout="auto" unmountOnExit>
//         <Box padding={1} sx={{ backgroundColor: "#F9F9F9" }}>
//           {content}
//         </Box>
//       </Collapse>
//     </Box>
//   );
// };
import { Box, Collapse } from "@mui/material";
import React from "react";
import TextOnly from "./TextOnly";
import EditIcon from "@mui/icons-material/Edit";



const ClickableRow = ({
  content,
  text,
  currentVal,
  editIcon,
  customIcons,
  expanded,
  onToggle,
}) => {
  return (
    <Box>
      <Box
        onClick={!customIcons ? onToggle : null}
        display="flex"
        padding={2}
        borderRadius={2}
        sx={{ backgroundColor: "#F9F9F9", cursor: "pointer" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box ml={1}>
          <TextOnly text={text} />
        </Box>
        <Box display="flex" alignItems="center" ml="auto">
          {!customIcons && (
            <TextOnly text={currentVal} color={"#0069FF"} />
          )}
          <Box display="flex" alignItems="center" ml={2}>
            {customIcons && customIcons}
            {editIcon && (
              <Box 
              onClick={onToggle}
              // onClick={(e) => {
              //   //e.stopPropagation(); // Prevent row toggle when clicking the edit icon
              //   onToggle;
              // }}
              >
                <EditIcon />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box padding={1} sx={{ backgroundColor: "#F9F9F9" }}>
          {content}
        </Box>
      </Collapse>
    </Box>
  );
};

export default ClickableRow;
