// import React, { useRef, useState } from "react";
// import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import Modal from "@mui/material/Modal";
// import TextField from "@mui/material/TextField";
// import FormatBoldIcon from "@mui/icons-material/FormatBold";
// import FormatItalicIcon from "@mui/icons-material/FormatItalic";
// import LinkIcon from "@mui/icons-material/Link";
// import UndoIcon from "@mui/icons-material/Undo";
// import RedoIcon from "@mui/icons-material/Redo";
// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";
// import DynamicMenu from "./DynamicMenu";
// import { BorderColor } from "@mui/icons-material";

// const variableOptions = [
//   { label: "My Name", value: "{my_name}" },
//   { label: "Invitee Full Name", value: "{invitee_full_name}" },
//   { label: "Invitee First Name", value: "{invitee_first_name}" },
//   { label: "Invitee Last Name", value: "{invitee_last_name}" },
//   { label: "Invitee Email", value: "{invitee_email}" },
//   { label: "Invitee UUID", value: "{invitee_uuid}" },
//   { label: "Event Name", value: "{event_name}" },
//   { label: "Event Date", value: "{event_date}" },
//   { label: "Event Time", value: "{event_time}" },
//   { label: "Event Description", value: "{event_description}" },
//   { label: "Location", value: "{location}" },
//   { label: "Questions and Answers", value: "{questions_and_answers}" },
//   { label: "Answer 1", value: "{answer_1}" },
// ];

// const TextAreaWithUndoRedo = () => {
//   const editorRef = useRef(null);
//   const [isFocused, setIsFocused] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [linkText, setLinkText] = useState("");
//   const [linkUrl, setLinkUrl] = useState("");
//   const secondaryColor = "#1976d2";
//   let savedSelection = null; // To save the current selection

//   const formatText = (command) => {
//     document.execCommand(command, false, null);
//   };

//   const openLinkModal = () => {
//     // Save the current selection before opening the modal
//     if (window.getSelection) {
//       const selection = window.getSelection();
//       if (selection.rangeCount > 0) {
//         savedSelection = selection.getRangeAt(0);
//       }
//     }
//     const selection = window.getSelection();
//     const selectedText = selection.toString();
//     setLinkText(selectedText); // Set the selected text in the modal
//     setIsModalOpen(true); // Open the modal
//   };

//   const attachLink = () => {
//     closeLinkModal();
//     if (!linkUrl) return;

//     const editor = editorRef.current;
//     editor.focus(); // Ensure editor is focused

//     const selection = window.getSelection();
//     if (!selection || selection.rangeCount === 0) return;
//     const range = selection.getRangeAt(0);
//     range.deleteContents(); // Clear the current selection

//     // Create an anchor element
//     const linkElement = document.createElement("a");
//     linkElement.href = linkUrl;
//     linkElement.textContent = linkText || linkUrl;
//     linkElement.target = "_blank";

//     // Insert the new link element into the range
//     range.insertNode(linkElement);

//     // Move the cursor after the inserted link
//     range.setStartAfter(linkElement);
//     range.setEndAfter(linkElement);
//     selection.removeAllRanges();
//     selection.addRange(range);

//     // Fallback using execCommand if direct insertion fails
//     if (!linkElement.parentNode) {
//       document.execCommand("createLink", false, linkUrl);
//     }

//     closeLinkModal(); // Close the modal after adding the link
//   };

//   const closeLinkModal = () => {
//     setIsModalOpen(false);
//     setLinkText("");
//     setLinkUrl("");

//     // Restore focus to the editor
//     if (editorRef.current) {
//       editorRef.current.focus();
//     }
//   };

//   const insertVariable = (variable) => {
//     if (variable && editorRef.current) {
//       const editor = editorRef.current;
//       editor.focus();

//       const selection = window.getSelection();
//       if (!selection) return;

//       // Check if there is a range, otherwise create one
//       if (selection.rangeCount === 0) {
//         const range = document.createRange();
//         range.setStart(editor, 0);
//         range.collapse(true);
//         selection.addRange(range);
//       }

//       const range = selection.getRangeAt(0);
//       range.deleteContents(); // Remove any selected content

//       // Insert the text node with the variable
//       const textNode = document.createTextNode(variable);
//       range.insertNode(textNode);

//       // Move the cursor to the end of the inserted text
//       range.setStartAfter(textNode);
//       range.setEndAfter(textNode);
//       selection.removeAllRanges();
//       selection.addRange(range);
//     }
//   };

//   return (
//     <Box
//       sx={{
//         backgroundColor: "#ffffff",
//         border: isFocused ? `2px solid ${secondaryColor}` : "1px solid #ccc",
//         borderRadius: 1,
//         transition: "0.1s",
//       }}
//     >
//       <Box
//         className="toolbar"
//         sx={{
//           display: "flex",
//           alignItems: "center",
//           gap: "3px",
//           padding: "10px",
//           marginBottom: "1px",
//           backgroundColor: "white",
//           border: "none",
//           zIndex: 10,
//         }}
//       >
//         <Box ml={1} color={"#808080"} onClick={() => formatText("bold")}>
//           <FormatBoldIcon />
//         </Box>
//         <Box ml={1} color={"#808080"} onClick={() => formatText("italic")}>
//           <FormatItalicIcon />
//         </Box>
//         <Box ml={3} color={secondaryColor} onClick={openLinkModal}>
//           <LinkIcon />
//         </Box>
//         <Box
//           ml={3}
//           color={"#808080"}
//           onClick={() => document.execCommand("undo")}
//         >
//           <UndoIcon />
//         </Box>
//         <Box
//           ml={1}
//           color={"#808080"}
//           onClick={() => document.execCommand("redo")}
//         >
//           <RedoIcon />
//         </Box>
//         <Box ml={5}>
//           {/* <DynamicMenu
//             buttonLabel="+ Variables"
//             menuItems={variableOptions}
//             onMenuItemClick={(item) => insertVariable(item.value)}
//             menuHeight="200px"
//           /> */}
//           <Select
//             displayEmpty
//             value=""
//             variant="standard"
//             onChange={(e) => insertVariable(e.target.value)}
//             disableUnderline={true}

//           >
//             <MenuItem value="" disabled >
//                Variables
//             </MenuItem>
//             {variableOptions.map((variable) => (
//               <MenuItem key={variable.value} value={variable.value}>
//                 {variable.label}
//               </MenuItem>
//             ))}
//           </Select>
//         </Box>
//       </Box>
//       <Box
//         contentEditable
//         ref={editorRef}
//         className="editor"
//         sx={{
//           height: "200px",
//           width: "100%",
//           padding: "12px 20px",
//           margin: "8px 0",
//           boxSizing: "border-box",
//           border: "none",
//           outline: "none",
//         }}
//         onFocus={() => setIsFocused(true)}
//         onBlur={() => setIsFocused(false)}
//       ></Box>
//       <Modal
//         open={isModalOpen}
//         onClose={closeLinkModal}
//         sx={{ ariaHidden: false }}
//       >
//         <Box
//           sx={{
//             position: "absolute",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             width: 300,
//             bgcolor: "background.paper",
//             borderRadius: 1,
//             boxShadow: 24,
//             p: 4,
//           }}
//         >
//           <h2>Attach Link</h2>
//           <TextField
//             label="Link Text"
//             fullWidth
//             variant="outlined"
//             value={linkText}
//             onChange={(e) => setLinkText(e.target.value)}
//             sx={{ mb: 2 }}
//           />
//           <TextField
//             label="URL"
//             fullWidth
//             variant="outlined"
//             value={linkUrl}
//             onChange={(e) => setLinkUrl(e.target.value)}
//           />
//           <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
//             <Button onClick={attachLink} variant="contained" color="primary">
//               Attach
//             </Button>
//             <Button onClick={closeLinkModal} sx={{ ml: 2 }}>
//               Cancel
//             </Button>
//           </Box>
//         </Box>
//       </Modal>
//     </Box>
//   );
// };

// export default TextAreaWithUndoRedo;

import React, { useRef, useState } from "react";
import { Box, Button, Modal, Select, MenuItem, TextField } from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import LinkIcon from "@mui/icons-material/Link";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";

const variableOptions = [
  { label: "My Name", value: "{my_name}" },
  { label: "Invitee Full Name", value: "{invitee_full_name}" },
  { label: "Invitee First Name", value: "{invitee_first_name}" },
  { label: "Invitee Last Name", value: "{invitee_last_name}" },
  { label: "Invitee Email", value: "{invitee_email}" },
  { label: "Invitee UUID", value: "{invitee_uuid}" },
  { label: "Event Name", value: "{event_name}" },
  { label: "Event Date", value: "{event_date}" },
  { label: "Event Time", value: "{event_time}" },
  { label: "Event Description", value: "{event_description}" },
  { label: "Location", value: "{location}" },
  { label: "Questions and Answers", value: "{questions_and_answers}" },
  { label: "Answer 1", value: "{answer_1}" },
];

const TextAreaWithUndoRedo = ({ showLink = true, showVariables = true }) => {
  const editorRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [linkText, setLinkText] = useState("");
  const [linkUrl, setLinkUrl] = useState("");
  const [contentHistory, setContentHistory] = useState([]);
  const [currentContentIndex, setCurrentContentIndex] = useState(1);
  const secondaryColor = "#1976d2";
  let savedSelection = null; // To save the current selection

  const updateContentHistory = (newContent) => {
    const updatedHistory = contentHistory.slice(0, currentContentIndex + 1);
    updatedHistory.push(newContent);
    setContentHistory(updatedHistory);
    setCurrentContentIndex(updatedHistory.length - 1);
  };

  // const formatText = (command) => {
  //   // document.execCommand(command, false, null);
  //   // const editorContent = editorRef.current.innerHTML;
  //   // updateContentHistory(editorContent);
  //   const selection = window.getSelection();
  //   if (!selection.rangeCount) return;
  //   const range = selection.getRangeAt(0);
  //   const formatedText = document.createElement(command === "bold" ? "strong" : "em");
  //   range.surroundContents(formatedText);
  // };

  const formatText = (command) => {
    const selection = window.getSelection();
    const editor = editorRef.current;
  
    if (!selection || !editor) return;
    if (selection.rangeCount === 0 || selection.isCollapsed) {
      // If no text is selected, toggle formatting at the cursor position
      const range = selection.getRangeAt(0);
      const parentNode = range.startContainer.parentNode;
      var tag = (command === "bold" ? "STRONG" : "EM");
      const isAlreadyFormatted =range.startContainer.parentNode.nodeName === (tag);

        // parentNode.nodeName === (command === "bold" ? "STRONG" : "EM");
  
      if (isAlreadyFormatted) {

        // If already formatted, unwrap the element
        const formattedNode = parentNode;
        while (formattedNode.firstChild) {
          formattedNode.parentNode.insertBefore(formattedNode.firstChild, formattedNode);
        }
        formattedNode.parentNode.removeChild(formattedNode);
  
        // Move the cursor to the end of the unwrapped text node
        const newRange = document.createRange();
        newRange.setStart(range.startContainer, range.startOffset);
        newRange.collapse(true);
        selection.removeAllRanges();
        selection.addRange(newRange);
      } else {
        // Apply the formatting tag
        const formatElement = document.createElement(command === "bold" ? "strong" : "em");
        formatElement.appendChild(document.createTextNode("\u200B")); // Zero-width space for cursor
        range.insertNode(formatElement);
        
  
        // Move the cursor inside the formatting element
        const newRange = document.createRange();
        newRange.setStart(formatElement.firstChild, 1);
        newRange.collapse(true);
        selection.removeAllRanges();
        selection.addRange(newRange);
      }
    } else {
      // If there is selected text, wrap the selection with the formatting tag
      const range = selection.getRangeAt(0);
      const wrapperElement = document.createElement(command === "bold" ? "strong" : "em");
      var tag = (command === "bold" ? "STRONG" : "EM");
      // const isAlreadyFormatted =range.startContainer.innerHTML.startsWith(tag);
      const isAlreadyFormatted =range.startContainer.parentNode.nodeName === (tag);
      //const isAlreadyFormatted = range.startContainer.parentNode.nodeName === (command === "bold" ? "STRONG" : "EM");
      const parentNode = range.startContainer.parentNode;
      if (isAlreadyFormatted) {
        // If the selected text is already formatted, unwrap it
        const formattedNode = parentNode;
        while (formattedNode.firstChild) {
          formattedNode.parentNode.insertBefore(formattedNode.firstChild, formattedNode);
        }
        formattedNode.parentNode.removeChild(formattedNode);
        const newRange = document.createRange();
        newRange.setStart(range.startContainer, range.startOffset);
        newRange.collapse(true);
        selection.removeAllRanges();
        selection.addRange(newRange);

        // const formattedNode = range.startContainer.parentNode;
        // const textContent = document.createTextNode(formattedNode.textContent);
        // formattedNode.parentNode.replaceChild(textContent, formattedNode);
        // selection.removeAllRanges();
        // const newRange = document.createRange();
        // newRange.selectNodeContents(textContent);
        // selection.addRange(newRange);
      } else {
        // Apply formatting to the selected text
        try {
          range.surroundContents(wrapperElement);
        } catch (error) {
          console.error("Error applying formatting:", error);
        }
      }
    }
  
    // Update content history after applying the formatting
    updateContentHistory(editor.innerHTML);
  };
  

  const openLinkModal = () => {
    if (window.getSelection) {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        savedSelection = selection.getRangeAt(0);
      }
    }
    const selection = window.getSelection();
    const selectedText = selection.toString();
    setLinkText(selectedText);
    setIsModalOpen(true);
  };

  const attachLink = () => {
    closeLinkModal();
    if (!linkUrl) return;

    const editor = editorRef.current;
    editor.focus();

    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return;
    const range = selection.getRangeAt(0);
    range.deleteContents();

    const linkElement = document.createElement("a");
    linkElement.href = linkUrl;
    linkElement.textContent = linkText || linkUrl;
    linkElement.target = "_blank";

    range.insertNode(linkElement);
    range.setStartAfter(linkElement);
    range.setEndAfter(linkElement);
    selection.removeAllRanges();
    selection.addRange(range);

    // Update content history after inserting the link
    updateContentHistory(editor.innerHTML);
    closeLinkModal();
  };

  const closeLinkModal = () => {
    setIsModalOpen(false);
    setLinkText("");
    setLinkUrl("");
    if (editorRef.current) {
      editorRef.current.focus();
    }
  };

  const insertVariable = (variable) => {
    if (variable && editorRef.current) {
      const editor = editorRef.current;
      editor.focus();

      const selection = window.getSelection();
      if (!selection) return;

      if (selection.rangeCount === 0) {
        const range = document.createRange();
        range.setStart(editor, 0);
        range.collapse(true);
        selection.addRange(range);
      }

      const range = selection.getRangeAt(0);
      range.deleteContents();

      const textNode = document.createTextNode(variable);
      range.insertNode(textNode);

      range.setStartAfter(textNode);
      range.setEndAfter(textNode);
      selection.removeAllRanges();
      selection.addRange(range);

      // Update content history after inserting the variable
      updateContentHistory(editor.innerHTML);
    }
  };

  const undo = () => {
    if (currentContentIndex > 0) {
      setCurrentContentIndex(currentContentIndex - 1);
      editorRef.current.innerHTML = contentHistory[currentContentIndex - 1];
    }
  };

  const redo = () => {
    if (currentContentIndex < contentHistory.length - 1) {
      setCurrentContentIndex(currentContentIndex + 1);
      editorRef.current.innerHTML = contentHistory[currentContentIndex + 1];
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        border: isFocused ? `2px solid ${secondaryColor}` : "1px solid #ccc",
        borderRadius: 1,
        transition: "0.1s",
      }}
    >
      <Box
        className="toolbar"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "3px",
          padding: "10px",
          marginBottom: "1px",
          backgroundColor: "white",
          border: "none",
          zIndex: 10,
        }}
      >
        <Box ml={1} color={"#808080"} onClick={() => formatText("bold")}>
          <FormatBoldIcon />
        </Box>
        <Box ml={1} color={"#808080"} onClick={() => formatText("italic")}>
          <FormatItalicIcon />
        </Box>
        {showLink && (
          <Box ml={3} color={secondaryColor} onClick={openLinkModal}>
            <LinkIcon />
          </Box>
        )}
        <Box ml={3} color={"#808080"} onClick={undo}>
          <UndoIcon />
        </Box>
        <Box ml={1} color={"#808080"} onClick={redo}>
          <RedoIcon />
        </Box>
        {showVariables && (
          <Box ml={5}>
            <Select
              displayEmpty
              value=""
              variant="standard"
              onChange={(e) => insertVariable(e.target.value)}
              disableUnderline={true}
            >
              <MenuItem value="" disabled>
                Variables
              </MenuItem>
              {/* Replace this with your actual variable options */}
              {variableOptions.map((variable) => (
                <MenuItem key={variable.value} value={variable.value}>
                  {variable.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
      </Box>
      <Box
        contentEditable
        ref={editorRef}
        className="editor"
        sx={{
          height: "200px",
          width: "100%",
          padding: "12px 20px",
          margin: "8px 0",
          boxSizing: "border-box",
          border: "none",
          outline: "none",
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      ></Box>
      <Modal
        open={isModalOpen}
        onClose={closeLinkModal}
        sx={{ ariaHidden: false }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: 24,
            p: 4,
          }}
        >
          <h2>Attach Link</h2>
          <TextField
            label="Link Text"
            fullWidth
            variant="outlined"
            value={linkText}
            onChange={(e) => setLinkText(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="URL"
            fullWidth
            variant="outlined"
            value={linkUrl}
            onChange={(e) => setLinkUrl(e.target.value)}
          />
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={attachLink} variant="contained" color="primary">
              Attach
            </Button>
            <Button onClick={closeLinkModal} sx={{ ml: 2 }}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
export default TextAreaWithUndoRedo;
