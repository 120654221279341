import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Grid,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/system";
import LockIcon from "@mui/icons-material/Lock";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import MuiText from "../UIComponents/MuiText";
import MuiHeader from "../UIComponents/MuiHeader";
import ErrorComponent from "../Messages/ErrorComponent";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/user/userSlice";
import { UserDetailsService } from "../../services/UserDetailsService";
import { AuthService } from "../../services/LoginService";
import GetOtp from "./GetOtp";
import { withLoader } from "../Loader/withLoader";

const StyledContainer = styled(Container)({
  marginTop: "1rem",
  //   padding: '2rem',
  background: "#f9f9f9",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  overflowY: "auto",
  height: "calc(100vh - 4rem)", // Full height minus some margin
  fontFamily:
    'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"', // Applying font family to the container
});
const HeaderContainer = styled(Container)({
  height: "6vh",
  fontFamily:
    'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"', // Applying font family to the container
});
const StyledTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    background: "#fff",
    fontSize: "0.77rem", // Font size 0.875rem (14px)
    fontFamily:
      'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"', // Applying font family to the input
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#3f51b5", // Border color when focused
  },
  "& .MuiInputLabel-root": {
    fontFamily:
      'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"', // Applying font family to the input
    fontWeight: 500,
    fontSize: "0.8rem",
    color: "#B0B0B0", // Professional shade of gray for label
  },
  "&:hover .MuiOutlinedInput-root:not(.Mui-focused) .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "#3f51b5", // Maintain border color on hover as when focused
    },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#3f51b5", // Color when label is focused
  },
});

const UserDetails = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  //==========Validations=============
  //Company New Password
  const [errorCompanyname, setErrorcompanyname] = useState(false);
  const [errorFirstName, setErrorFirstName] = useState(false);
  const [errorLastName, setErrorLastName] = useState(false);

  const [formValues, setFormValues] = useState({
    uid: user.uid,
    companyname: "",
    profession: "",
    file: null,
    description: "",
    firstName: user.firstName,
    lastName: user.lastName,
    phoneNumber: "",
    userName: user.userName,
    email: user.email,
    password: "",
    newpassword: "",
    confirmnewpassword: "",
  });
  // State for error messages
  const [errors, setErrors] = useState({
    companyname: false,
    profession: false,
    file: false,
    description: false,
    firstName: false,
    lastName: false,
    phoneNumber: false,
    userName: false,
    email: false,
    password: false,
    newpassword: false,
    confirmnewpassword: false,
  });

  const [otp, setOtp] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState(false);
  const [uniquePassword, setUniquePassword] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState([]);
  const [customMsg, setCustomMsg] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [passUpdate, setPassUpdated] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleClickShowConfirmNewPassword = () =>
    setShowConfirmNewPassword(!showConfirmNewPassword);
  //handle file input
  const handleFileChange = (event) => {
    setFormValues((formValues) => ({
      ...formValues,
      file: event.target.files[0],
    }));
  };
  const passwordUnique = (value) => {
    if (value === formValues.password) {
      setUniquePassword("Password Should not match Old Password");
    } else {
      setUniquePassword("");
    }
  };

  const validateCompanyDetails = (name, value) => {
    const { companyname, profession, file, description } = formValues;
    var isAnyCompanyDetailFilled = false;
    //  !companyname && !profession && !file && !description;
    if (companyname) {
      if (!profession && !description) {
        isAnyCompanyDetailFilled = true;
      }
    }
    if (profession) {
      if (!companyname && !description) {
        isAnyCompanyDetailFilled = true;
      }
    }
    if (description) {
      if (!companyname && !profession) {
        isAnyCompanyDetailFilled = true;
      }
    }

    // if (
    //   isAnyCompanyDetailFilled &&
    //   (name === "companyname" ||
    //     name === "profession" ||
    //     name === "file" ||
    //    name === "description" )
    // ) {
    //   return false;
    // }
    // If any field is filled, ensure all fields are filled
    if (isAnyCompanyDetailFilled) {
      switch (name) {
        case "companyname":
        case "profession":
        case "file":
        case "description":
          return value === null || value.trim() === "";
        default:
          return false;
      }
    }

    return false;
  };
  const validate = (name, value) => {
    // Check if all three fields are empty
    const { password, newpassword, confirmnewpassword } = formValues;
    const areAllPasswordFieldsEmpty =
      !password && !newpassword && !confirmnewpassword;

    if (
      areAllPasswordFieldsEmpty &&
      (name === "password" ||
        name === "newpassword" ||
        name === "confirmnewpassword")
    ) {
      return false;
    }

    switch (name) {
      case "firstName":
      case "lastName":
      case "userName":
        return value.trim() === "";
      case "phoneNumber":
        // Phone number must start with a "+" followed by digits
        return !/^\+\d+$/.test(value);
      case "email":
        // More robust email validation regex
        return !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      case "password":
        return !/(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}/.test(
          value
        );
      case "newpassword":
        // Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character
        passwordUnique(value);
        let isValidNewPassword =
          !/(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}/.test(
            value
          );
        return isValidNewPassword || value === formValues.password;
      case "confirmnewpassword":
        return value !== formValues.newpassword; // Must match new password
        case "username":
          let isvalidUsername = /^[a-zA-Z0-9]+$/.test(value);
          return  !isvalidUsername;
      default:
        return false;
    }
  };

  // Handle input change and validation
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update form values
    setFormValues({
      ...formValues,
      [name]: value,
    });

    // Validate and update error state
    setErrors({
      ...errors,

      [name]:
        name == "companyname" || name == "profession" || name == "description"
          ? validateCompanyDetails(name, value)
          : validate(name, value),
    });
    if (name) {
      setErrorMsg([
        "Required:First Name,Last Name,User Name, Email",
        "If You dont Intend To Update Password and Company Details leave them blank",
      ]);
    }
  };
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    // const newErrors = {
      // firstName: validate("firstName", formValues.firstName),
      // lastName: validate("lastName", formValues.lastName),
      // userName: validate("username", formValues.userName),
      // email: validate("email", formValues.email),
      // password: validate("password", formValues.password),
      // newpassword: validate("newpassword", formValues.newpassword),
      // confirmnewpassword: validate(
      //   "confirmnewpassword",
      //   formValues.confirmnewpassword
      // ),
      // companyname: validateCompanyDetails(
      //   "companyname",
      //   formValues.companyname
      // ),
      // profession: validateCompanyDetails("profession", formValues.profession),
      // file: validateCompanyDetails("file", formValues.file),
      // description: validateCompanyDetails(
      //   "description",
      //   formValues.description
      // ),
    // };

      errors.firstName = validate("firstName", formValues.firstName);
      errors.lastName = validate("lastName", formValues.lastName);
      errors.userName = validate("username", formValues.userName);
      errors.email = validate("email", formValues.email);
      errors.password =  validate("password", formValues.password);
      errors.newpassword = validate("newpassword", formValues.newpassword);
      errors.confirmnewpassword = validate(
        "confirmnewpassword",
        formValues.confirmnewpassword
      );
      errors.companyname = validateCompanyDetails(
        "companyname",
        formValues.companyname
      );
      errors.profession = validateCompanyDetails("profession", formValues.profession);
      errors.file = validateCompanyDetails("file", formValues.file);
      errors.description = validateCompanyDetails(
        "description",
        formValues.description
      );
    // Check if there are any errors
    const hasErrors = Object.values(errors).some((error) => error);
    // const hasErrors = Object.values(newErrors).some((error) => error);
    if (hasErrors) {
      console.log("Validation failed");
     
      setShowError(true);
      return; // Prevent form submission
    }
    setShowError(false);
    // Proceed with form submission logic
    console.log(formValues);
    
    const { userName, password, newpassword, confirmnewpassword } = formValues;

    if (userName) {
      // let exist = await AuthService.ValidateUserName(user.email, userName);
      let exist = await withLoader(dispatch,()=>AuthService.ValidateUserName(user.email, userName));
      if (exist) {
        setShowError(true);
        setCustomMsg(["User Name Exists!"]);
        return;
      } else {
        setShowError(false);
        setCustomMsg([""]);
        UserDetailsService.saveSettings(formValues);
      }
    }
    if (password && newpassword && confirmnewpassword) {
      //Validate api
      var authRes = await withLoader(dispatch,()=>AuthService.ValidatePassword(user.email, password));
      console.log(authRes);
      if (authRes) {
        console.log("==============>IsAuthenticated Invoked", user);
        //Generate OTP
        var otp = await withLoader(dispatch,()=>AuthService.GenerateUpdatePasswordOTP(user.uid));
        if (otp) {
          setOtp(true);
          setShowError(false);
        }
        // setOtpErrorMessage(false);
      } else {
        // setOtpErrorMessage(true);
        setOtp(false);
        setShowError(true);
        setCustomMsg(["Incorrect Password!"]);
        return;
      }
    }
  };

  const handleOTP = (val) => {
    console.log("==============OTP=================", val);
  };

  // useEffect(() => {
  //   var res = AuthService.UpdatePassword(user.uid, formValues.newpassword);
  // }, otpVerified);
  useEffect(() => {
    //  Reset all  fields of passsword
  }, passUpdate);
  return (
    <>
      <MuiHeader
        text={"Settings"}
        onSave={handleSubmit}
        showSaveButton={true}
      />
      
      <StyledContainer maxWidth={false}>
      {showError && (
        // <ErrorComponent
        //   errorMessages={errorMsg}
        //   show={showError}
        // />
        <ErrorComponent
          errorMessages={errorMsg
            // customMsg
            //   ? customMsg
            //   : [
            //       "Validation Failed!",
            //       "Required:FirstName,LastName,Phone,Email",
            //       "If you dont intend to update Password & Company Details leave as it is!",
            //     ]
          }
          show={showError}
        />
      )}
    
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{
                fontFamily:
                  'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
              }}
            >
              Company Details
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                padding: "1.5rem",
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#ffffff",
                marginBottom: "2rem",
                overflowY: "auto",
                height: "80%",
                maxWidth: "88%",
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                  <StyledTextField
                    fullWidth
                    id="companyname"
                    name="companyname"
                    label="Company Name"
                    inputStyle="box"
                    labelStyle="floating"
                    placeholder="Enter User ID"
                    value={formValues.companyname}
                    onChange={handleChange}
                    error={errors.companyname}
                    helperText={
                      errors.companyname ? "Company Name Required!" : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <StyledTextField
                    fullWidth
                    id="profession"
                    name="profession"
                    label="Profession"
                    inputStyle="box"
                    labelStyle="floating"
                    placeholder="Enter Profession"
                    value={formValues.profession}
                    onChange={handleChange}
                    error={errors.profession}
                    helperText={errors.profession ? "Profession Required!" : ""}
                  />
                </Grid>
                
                <MuiText
                  icon={AddAPhotoIcon}
                  text="Company Logo"
                  textColor="#808080"
                />
                <Grid item xs={12} md={6} lg={6}>
                  <StyledTextField
                    fullWidth
                    id="file"
                    name="file"
                    type="file"
                    // label="Logo"
                    inputStyle="box"
                    labelStyle="floating"
                    placeholder="Upload File"
                    // value={formValues.file}
                    onChange={handleFileChange}
                    error={errors.file}
                    helperText={errors.file ? "Logo Required!" : ""}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <StyledTextField
                    fullWidth
                    multiline
                    rows={4}
                    id="description"
                    name="description"
                    label="Description"
                    inputStyle="box"
                    labelStyle="floating"
                    placeholder="Enter Description"
                    value={formValues.description}
                    onChange={handleChange}
                    error={errors.description}
                    helperText={
                      errors.description ? "Description Required!" : ""
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{
                fontFamily:
                  'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
              }}
            >
              Personal Details
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                width: "100%",
                padding: "1.5rem",
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#ffffff",
                marginBottom: "2rem",
                overflowY: "auto",
                height: "80%",
                maxWidth: "88%",
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                  <StyledTextField
                    required
                    fullWidth
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    inputStyle="box"
                    labelStyle="floating"
                    placeholder="Enter User ID"
                    value={formValues.firstName}
                    onChange={handleChange}
                    error={errors.firstName}
                    helperText={
                      errors.firstName ? "First Name must not be empty" : ""
                    }
                    sx={{ borderColor: errors.firstName ? "red" : "initial" }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <StyledTextField
                    required
                    fullWidth
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    inputStyle="box"
                    labelStyle="floating"
                    placeholder="Enter Last Name"
                    value={formValues.lastName}
                    onChange={handleChange}
                    error={errors.lastName}
                    helperText={
                      errors.lastName ? "Last Name Must Not be empty" : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <StyledTextField
                    required
                    fullWidth
                    id="userName"
                    name="userName"
                    label="User Name"
                    inputStyle="box"
                    labelStyle="floating"
                    placeholder="Enter User Name"
                    value={formValues.userName}
                    onChange={handleChange}
                    error={errors.userName}
                    helperText={
                      errors.userName ? "User Name should Contain Letter & Digits!" : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <StyledTextField
                    required
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    inputStyle="box"
                    labelStyle="floating"
                    placeholder="Enter Email"
                    value={formValues.email}
                    onChange={handleChange}
                    error={errors.email}
                    helperText={errors.email ? "Must be an valid Email" : ""}
                  />
                </Grid>
                <MuiText
                  icon={LockIcon}
                  text={otp ? "Enter OTP" : "Change Password"}
                  textColor={"#808080"}
                />
                {otp && (
                  <GetOtp
                    id={formValues.uid}
                    newPassword={formValues.newpassword}
                    type={"1"}
                    setPassUpdated={setPassUpdated}
                    setShowOtp={setOtp}
                    errors={errors}
                  />
                )}
                {!otp && (
                  <>
                    {passUpdate && (
                      <ErrorComponent errorMessages={["PasswordUpdated"]} />
                    )}
                    <Grid item xs={12} md={12} lg={12}>
                      <StyledTextField
                        required
                        fullWidth
                        id="password"
                        name="password"
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        inputStyle="box"
                        labelStyle="floating"
                        placeholder="Enter Previous Password"
                        value={formValues.password}
                        onChange={handleChange}
                        error={errors.password}
                        helperText={
                          errors.password
                            ? "Pasword must Contains Capital Letter,Special Characters & Numbers"
                            : ""
                        }
                        autoComplete="new-password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                                style={{ color: "#808080" }}
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <StyledTextField
                        required
                        fullWidth
                        id="newpassword"
                        name="newpassword"
                        label="New Password"
                        type={showNewPassword ? "text" : "password"}
                        inputStyle="box"
                        labelStyle="floating"
                        placeholder="Enter New Password"
                        value={formValues.newpassword}
                        onChange={handleChange}
                        error={errors.newpassword}
                        helperText={errors.newpassword ? uniquePassword : ""}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle new password visibility"
                                onClick={handleClickShowNewPassword}
                                edge="end"
                                style={{ color: "#808080" }}
                              >
                                {showNewPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <StyledTextField
                        required
                        fullWidth
                        id="confirmnewpassword"
                        name="confirmnewpassword"
                        label="Confirm New Password"
                        type={showNewPassword ? "text" : "password"}
                        inputStyle="box"
                        labelStyle="floating"
                        placeholder="Confirm New Password"
                        value={formValues.confirmnewpassword}
                        onChange={handleChange}
                        error={errors.confirmnewpassword}
                        helperText={
                          errors.confirmnewpassword
                            ? "New Password and Confirm Password Must Macth!!"
                            : ""
                        }
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </StyledContainer>
    </>
  );
};

export default UserDetails;
