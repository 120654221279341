import React, { useState } from "react";
import { Box, Typography, Button,  Grid, RadioGroup, FormControlLabel, Radio, Divider } from "@mui/material";
import MUISubHeader from "../components/UIComponents/MUISubHeader";
import PaymentForm from "../components/UIComponents/PaymentForm";
import { useLocation } from "react-router-dom";

const Subscribe = () => {
  const location= useLocation();
  const plan = location.state?.plan;
  const [seats, setSeats] = useState(1);
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [cardDetails, setCardDetails] = useState({
    cardNumber: "",
    expiry: "",
    cvv: "",
  });
  const [errors, setErrors] = useState({});

  const handleSeatChange = (type) => {
    setSeats((prev) => (type === "increase" ? prev + 1 : prev > 1 ? prev - 1 : prev));
  };

  // const handleInputChange = (e) => {
  //   setCardDetails({
  //     ...cardDetails,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const validateForm = () => {
    let valid = true;
    let errors = {};

    // Basic Credit Card Number Validation (16 digits)
    const cardNumberPattern = /^\d{16}$/;
    if (!cardNumberPattern.test(cardDetails.cardNumber)) {
      errors.cardNumber = "Card number must be 16 digits.";
      valid = false;
    }

    // Basic Expiry Date Validation (MM/YY format)
    const expiryPattern = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;
    if (!expiryPattern.test(cardDetails.expiry)) {
      errors.expiry = "Expiry date must be in MM/YY format.";
      valid = false;
    }

    // Basic CVV Validation (3 digits)
    const cvvPattern = /^\d{3}$/;
    if (!cvvPattern.test(cardDetails.cvv)) {
      errors.cvv = "CVV must be 3 digits.";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  // const handlePurchase = () => {
  //   if (validateForm()) {
  //     console.log("Purchasing:", plan, seats, billingCycle, cardDetails);
  //     // Proceed with the payment process
  //   }
  // };

  return (
    <Box sx={{ padding: "20px" }}>
      

      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Box sx={{ padding: "20px", border: "1px solid #ddd", borderRadius: "8px" }}>
          
            <MUISubHeader text="1. Number Of Seats" textSize={18}/>
            <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
              <Button onClick={() => handleSeatChange("decrease")}>-</Button>
              <Typography sx={{ mx: 2 }}>{seats}</Typography>
              <Button onClick={() => handleSeatChange("increase")}>+</Button>
            </Box>

           
            <MUISubHeader text="2. Billing Cycle" textSize={18}/>
            <RadioGroup
              row
              value={billingCycle}
              onChange={(e) => setBillingCycle(e.target.value)}
              sx={{ mt: 2 }}
            >
              <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
              <FormControlLabel value="yearly" control={<Radio />} label="Yearly" />
            </RadioGroup>

            {/* Step 3: Payment Details */}
           <MUISubHeader text="3. Payment Details" textSize={18}/>
            <Box sx={{ display: "flex", alignItems: "flex-start", mt: 2, gap: 2 }}>
              <PaymentForm billingCycle={billingCycle} numberOfSeats={seats} plan={plan}/>
            </Box>
          </Box>
        </Grid>

        {/* Order Summary */}
        <Grid item xs={12} md={4}>
          <Box sx={{ padding: "20px", border: "1px solid #ddd", borderRadius: "8px", backgroundColor: "#e3f2fd" }}>
            <Typography variant="h6">Order Summary</Typography>
            <Divider sx={{ my: 2 }} />
            <Typography>Subscription Type: {plan}</Typography>
            <Typography>
              Billing Cycle: {billingCycle.charAt(0).toUpperCase() + billingCycle.slice(1)}
            </Typography>
            <Typography>Seats: {seats}</Typography>
            <Typography>
              Total: ${seats * (billingCycle === "monthly" ? 20 : 200)} ({billingCycle})
            </Typography>

            <Divider sx={{ my: 2 }} />
            <Typography variant="body1">Total Due Today: ${seats * (billingCycle === "monthly" ? 20 : 200)}</Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              You will be charged as per the {billingCycle} billing cycle.
            </Typography>

            {/* <Button
              variant="contained"
              color="primary"
              sx={{ mt: 4, width: "100%" }}
              onClick={handlePurchase}
            >
              Purchase
            </Button> */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Subscribe;

              {/* <Box sx={{ padding: "20px", border: "1px solid #ddd", borderRadius: "8px", backgroundColor: "#e3f2fd" }}> */}