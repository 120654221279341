import React, { useState, useEffect, useRef } from "react";
import { useTheme, Box, Typography } from "@mui/material";
import "../../styles/ButtonDropDown.css"; // Import the CSS file

const ButtonDropDown = ({
  text = "Action",
  dropdownItems = [{ option: "Option 1", value: "vdsf1" }], // Default options as objects
  buttonWidth = "auto", // Dynamic width, default to auto
  buttonHeight = "auto", // Dynamic height, default to auto
  dropdownWidth = "160px",
  padding = "20px",
  onClick,
  isDropDown = false,
}) => {
  const theme = useTheme();
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null); // Reference to the dropdown element

  // Toggle dropdown on button click
  const handleButtonClick = () => {
    setShowDropdown((prev) => !prev);
  };

  const handleClick = () => {
    onClick();
  };
  // Close dropdown if clicked outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    // Add event listener to detect outside clicks
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box
      className="button_cont"
      align="center"
      sx={{ position: "relative", display: "inline-block" }}
    >
      <Box
        className="example_d"
        onClick={isDropDown ? handleButtonClick : handleClick}
        sx={{
          cursor: "pointer",
          color: theme.palette.primary.main,
          textDecoration: "none",
          backgroundColor: "#ffffff",
          padding: padding || "0.75rem 1.5rem",
          border: `4px solid ${theme.palette.primary.main}`,
          borderRadius: "50px", // Initial capsule shape
          display: "inline-block",
          width: buttonWidth || "auto",
          height: buttonHeight || "auto",
          transition: "all 0.3s ease",
          fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
          fontSize: "1rem", // Increased font size
          fontWeight: "500", // Slightly bolder for emphasis
          "&:hover": {
            color: "#494949",
            backgroundColor: "#f9f9f9",
            borderRadius: "6px", // Transition to square shape
            borderColor: "#494949",
          },
        }}
      >
        {text}
      </Box>

      {showDropdown && (
        <Box
          ref={dropdownRef}
          sx={{
            position: "absolute",
            top: "100%",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#ffffff",
            boxShadow: theme.shadows[2],
            borderRadius: "6px",
            padding: "10px",
            marginTop: "8px",
            zIndex: 5,
            minWidth:
              buttonWidth === "auto" ? "200px" : dropdownWidth || "auto",
          }}
        >
          {dropdownItems &&
            dropdownItems.map((item, index) => (
              <Typography
                key={index}
                sx={{
                  fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                  fontSize: "0.9rem",
                  fontWeight: "400",
                  color: theme.palette.grey[700],
                  padding: "8px 12px",
                  transition: "background-color 0.3s ease",
                  "&:hover": {
                    backgroundColor: theme.palette.grey[100],
                  },
                }}
                onClick={() => onClick(item.value)}
              >
                {item.name}
              </Typography>
            ))}
        </Box>
      )}
    </Box>
  );
};

export default ButtonDropDown;

// <Box
//   className="button_cont"
//   align="center"
//   sx={{ position: "relative", display: "inline-block" }}
// >
//   <Box
//     className="example_d" // Styled as a button
//     onClick={isDropDown ? handleButtonClick : handleClick} // Toggle dropdown on click
//     sx={{
//       cursor: "pointer",
//       color: "#20bf6b",
//       // textTransform: "uppercase",
//       textDecoration:"none",
//       backgroundColor: "#ffffff",
//       padding: padding,
//       border: "4px solid #20bf6b",
//       borderRadius: "6px",
//       display: "inline-block",
//       width: buttonWidth,
//       height: buttonHeight,
//       transition: "all 0.3s ease",
//       "&:hover": {
//         color: "#494949 !important",
//         borderRadius: "50px",
//         borderColor: "#494949 !important",
//         transition: "all 0.3s ease 0s",
//       },
//     }}
//   >
//     {text}
//   </Box>

//   {showDropdown && (
//     <Box
//       ref={dropdownRef}
//       sx={{
//         position: "absolute",
//         top: "100%",
//         left: "50%",
//         transform: "translateX(-50%)",
//         backgroundColor: "#ffffff",
//         boxShadow: theme.shadows[2],
//         borderRadius: "6px",
//         padding: "10px",
//         marginTop: "8px",
//         zIndex: 5,
//         minWidth: buttonWidth === "auto" ? "200px" : dropdownWidth
//       }}
//     >
//       {dropdownItems && dropdownItems.map((item, index) => (
//         <Typography
//           key={index}
//           sx={{
//             color: theme.palette.grey[700], // Use grey color from theme
//             padding: "8px 12px",
//             transition: "background-color 0.3s ease",
//             "&:hover": {
//               backgroundColor: theme.palette.grey[100]
//             },
//           }}
//           onClick={() => onClick(item.value)} // Pass the value to the onClick handler
//         >
//           {item.name} {/* Display the option text */}
//         </Typography>
//       ))}
//     </Box>
//   )}
// </Box>
