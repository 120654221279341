import React, { useEffect, useState } from "react";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
// import '../../../node_modules/antd/dist/antd.css';
import "../../styles/MultiDatePicker.css"; // Assuming you have a styles.css for custom styles
import zIndex from "@mui/material/styles/zIndex";

const { RangePicker } = DatePicker;

const MultiDatePicker = ({
  selectedDates,
  setSelectedDates,
  handleSelectedDateRange,
}) => {
  useEffect(() => {
    console.log("dates", selectedDates);
  });

  const disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };

  const onChange = (dates) => {
    handleSelectedDateRange
      ? handleSelectedDateRange(dates)
      : setSelectedDates(dates);
  };

  const dayjsSelectedDates =
    selectedDates &&
    selectedDates.length === 2 &&
    dayjs(selectedDates[1]).isAfter(dayjs(), "day") // Check if the end date is after today
      ? [dayjs(selectedDates[0]), dayjs(selectedDates[1])]
      : [];

  return (
    <Space direction="vertical" size={12}>
      <RangePicker
        disabledDate={disabledDate}
        onChange={onChange}
        value={dayjsSelectedDates}
        dropdownClassName="custom-range-picker-dropdown"
      />
    </Space>
  );
};

export default MultiDatePicker;
