import { login, logout } from "../features/user/userSlice";
import { AdduserDetails } from "../features/user/userDetailsSlice";
const baseUrl = process.env.REACT_APP_BASEURL;

export const AuthService = {
  Login: async (username, password, dispatch) => {
    try {
      console.log("Inside AuthService Login: <params>", username, password);

      const data = JSON.stringify({
        UserName: username,
        Password: password,
      });
      console.log("JsonObject", data);
      const apiUrl = `${baseUrl}Logins/Customer`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: data,
      });
      const responseObject = await response.json();
      const isAuthenticated =
        responseObject["Authentication"]["IsAuthenticated"];
      const isLocked = responseObject["Authentication"]["IsLocked"];
      const accessToken = responseObject["Authentication"]["AccessToken"];
      const timerExpire = responseObject["Authentication"]["TimerExpire"];
      const userId = responseObject["Authentication"]["LoginsId"];
      const firstName = responseObject["Authentication"]["FirstName"];
      const lastName = responseObject["Authentication"]["LastName"];
      const uniqueName = responseObject["Authentication"]["UserName"];

      console.log("Response Data:", responseObject);
      if (isAuthenticated) {
        const data = JSON.stringify({
          LoginsId: userId,
        });

        const apiUrl = `${baseUrl}GetUserDetails`;
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            AppName: "DbLocal",
          },
          body: data,
        });
        const userdetails = await response.json();
        let result = userdetails.Info;
        const UserType = result.SpaceType;
        const SpaceId = result.Space_ID;
        const Expiry = result.PaymentExpiryDate;
        const Active = result.isActive;

        debugger;
        dispatch(
          AdduserDetails({
            UserType: UserType,
            SpaceId: SpaceId,
            Expiry: Expiry,
            Active: Active
          })
        );
        dispatch(
          login({
            email: username,
            uid: userId,
            accessToken: accessToken,
            isLocked: isLocked,
            timerExpire: timerExpire,
            firstName: firstName,
            lastName: lastName,
            userName: uniqueName,
          })
        );
      }

      return isAuthenticated;
    } catch (error) {
      console.error("Error SaveMsCalendar:", error);
      throw error;
    }
  },
  GetUserDetails: async (userId) => {
    try {
      const data = JSON.stringify({
        LoginsId: userId,
      });

      const apiUrl = `${baseUrl}GetUserDetails`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: data,
      });
      const userdetails = await response.json();

      return userdetails;
    } catch (error) {
      console.error("Error Create User:", error);
      throw error;
    }
  },
  CreateUser: async (formValues, token) => {
    try {
      console.log("Inside AuthService CreateUser: <params>", formValues, token);

      const data = JSON.stringify({
        FirstName: formValues.firstName,
        LastName: formValues.lastName,
        Email: formValues.email,
        Password: formValues.newpassword,
        InviteToken: token,
      });
      console.log("formData", data);

      const apiUrl = `${baseUrl}Logins/Create`;
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: data,
      });
      const responseObject = await response.json();

      return responseObject;
    } catch (error) {
      console.error("Error Create User:", error);
      throw error;
    }
  },
  ValidatePassword: async (email, password) => {
    try {
      const apiUrl = `${baseUrl}isValidCustomer`;
      console.log("Inside ValidatePassword Login: <params>", email, password);

      const data = JSON.stringify({
        Email: email,
        Password: password,
      });
      console.log("JsonObject", data);
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: data,
      });
      const responseObject = await response.json();
      const isAuthenticated = responseObject["Authentication"];
      console.log("IsAuthenticated:", isAuthenticated);

      // if (isAuthenticated) {
      //   console.log("==============>IsAuthenticated Invoked");
      //   setOtp(true);
      //   setOtpErrorMessage(false);
      //   setShowError(false);
      // } else {
      //   setOtpErrorMessage(true);
      //   setOtp(false);
      //   setShowError(true)
      // }
      return isAuthenticated;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  ValidateUserName: async (email, username) => {
    try {
      const apiUrl = `${baseUrl}ValidateUserName`;
      console.log("Inside ValidateUserName: <params>", username, email);

      const data = JSON.stringify({
        UserName: username,
        Email: email,
      });
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: data,
      });
      const responseObject = await response.json();
      const exist = responseObject["Exist"];
      console.log("exist:", exist);

      return exist;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  GenerateUpdatePasswordOTP: async (id) => {
    try {
      const apiUrl = `${baseUrl}GenerateUpdatePasswordOTP`;
      console.log("Inside GenerateUpdatePasswordOTP: <params>", id);

      const data = JSON.stringify({
        LoginsId: id,
      });
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: data,
      });
      const responseObject = await response.json();
      const otp = responseObject["Otp"];
      console.log("OTp:", otp);

      return otp;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  VerifyOtp: async (uid, Otp, type) => {
    try {
      const apiUrl = `${baseUrl}VerifyOtp`;
      console.log("Inside ValidateOtp: <params>", uid, Otp, type);

      const data = JSON.stringify({
        LoginsId: uid,
        Otp: Otp,
        Type: type,
      });
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: data,
      });
      const responseObject = await response.json();
      const res = responseObject["Otp"];
      console.log("OTp:", res);

      return res;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  UpdatePassword: async (uid, password, Otp) => {
    try {
      const apiUrl = `${baseUrl}UpdatePassword`;
      console.log("Inside UpdatePassword: <params>", uid, Otp, password);

      const data = JSON.stringify({
        LoginsId: uid,
        OTP: Otp,
        Password: password,
      });
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: data,
      });
      const responseObject = await response.json();
      const res = responseObject["Info"];
      console.log("Info:", res);

      return res;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  GoogleSignUp: async (data, dispatch, token = null) => {
    try {
      const apiUrl = `${baseUrl}GoogleAuthuntication`;
      console.log("Inside GoogleSignUp", data);
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: JSON.stringify({
          Code: data.code,
          Encrypted: token,
        }),
      });
      let res = await response.json();
      dispatch(logout());
      dispatch(
        login({
          email: res["Email"],
          uid: res["LoginsId"],
          accessToken: res["AccessToken"],
          isLockedres: ["IsLocked"],
          timerExpire: "",
          firstName: res["FirstName"],
          lastName: res["LastName"],
          userName: res["UserName"],
        })
      );
      return res;
    } catch (error) {
      console.error("Error fetching Token from GoogleAuth:", error);
      throw error;
    }
  },
  GoogleSignIn: async (data, invitedToken = null) => {
    try {
      const apiUrl = `${baseUrl}GoogleAuthuntication`;
      console.log("Inside GoogleSignIn", data);
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: JSON.stringify({
          Code: data.code,
          Encrypted: invitedToken,
        }),
      });
      return await response.json();
    } catch (error) {
      console.error("Error fetching Token from GoogleAuth:", error);
      throw error;
    }
  },
  isInviteUrlExpired: async (data) => {
    try {
      const apiUrl = `${baseUrl}ValidateInviteUrl`;
      console.log("Inside isInviteUrlExpired", data);
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: JSON.stringify({
          Encrypted: data,
        }),
      });
      return await response.json();
    } catch (error) {
      console.error("Error fetching Token from GoogleAuth:", error);
      throw error;
    }
  },
};

export default { AuthService };
//Below is the code working with firebase
// export const SignIn = ({ email, password,dispatch }) => {
//   try{
//     if(email){
//       signInWithEmailAndPassword(auth, email, password)
//     .then((userCredential) => {
//       // Signed in
//       const user = userCredential.user;
//       console.log(userCredential.user);
//       dispatch(
//         login({
//           email: userCredential.user.email,
//           uid: userCredential.user.uid,
//           displayName: userCredential.user.displayName,
//           photoURL: userCredential.user.photoURL,
//         })
//       );
//     })
//     .catch((error) => {
//       const errorCode = error.code;
//       const errorMessage = error.message;
//       console.error(errorMessage); // Log the error message
//     });
//     }
//   }catch(error){
//         console.log('LoginService SignIn err: ',error)
//     }
// };

// export const SignOut = ({dispatch}) =>{
//   dispatch(
//     logout()
//   );
// }
//End Firebase auth
// export const Register = ({email,password,fullName,profilePicUrl}) =>{
//     const dispatch = useDispatch();
//     if(!email,!password,!fullName){
//         return alert('Set Values');
//     }

//     createUserWithEmailAndPassword(auth, email, password).then((userCredential) => {
//       //Update profile Pic If needed
//     userCredential.user.updateProfile({
//     displayName:fullName,
//     photoURL:profilePicUrl
//     }).then(() => {
//     dispatch(login({
//           email:userCredential.user.email,
//           uid: userCredential.user.uid,
//           displayName:fullName,
//           photoURL:profilePicUrl
//     }))
//     })

//       // Signed up
//       const user = userCredential.user;
//       console.log('User Registered!!!',user)
//       // ...
//     })
//     .catch((error) => {
//       const errorCode = error.code;
//       const errorMessage = error.message;
//       // ..
//     });
// };
