// import React, { useEffect, useState } from "react";
// import { Box, Typography } from "@mui/material";
// import { styled } from "@mui/system";

// const ErrorBox = styled(Box)(({ visible }) => ({
//   backgroundColor: "#f8d7da",
//   color: "#721c24",
//   padding: "10px",
//   borderRadius: "4px",
//   border: "1px solid #f5c6cb",
//   marginBottom:  "10px" ,
//   opacity: visible ? 1 : 0,
//   transform: visible ? "translateY(0px)" : "translateY(-10px)",
//   transition: "opacity 0.8s ease-in-out, transform 0.8s ease-in-out",
// }));

// const ErrorComponent = ({ errorMessages, show,setErrorMsesage }) => {
//   const [visible, setVisible] = useState(false);
//     useEffect(() => {
//     if (show) {
//       setVisible(true);
//             const timer = setTimeout(() => setVisible(false), 10000); // Hide after 10 seconds
//       return () => clearTimeout(timer);
//     } else {
//       const timer = setTimeout(() => setVisible(false), 800); // Match transition duration
//       return () => clearTimeout(timer);
//     }
//   }, [show]);

//   return (
//     errorMessages.length > 0 && (
//       <ErrorBox visible={visible}>
//         {errorMessages.map((message, index) => (
//           <Typography key={index} variant="body2">
//             {message}
//           </Typography>
//         ))}
//       </ErrorBox>
//     )
//   );
// };

// export default ErrorComponent;
import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

const ErrorBox = styled(Box)(({ visible, isInfo = false }) => ({
  backgroundColor: "#f8d7da",
  color: "#721c24",
  padding: "10px",
  borderRadius: "4px",
  border: "1px solid #f5c6cb",
  marginBottom: "10px",
  opacity: visible ? 1 : 0,
  transform: visible ? "translateY(0px)" : "translateY(-10px)",
  transition: "opacity 0.8s ease-in-out, transform 0.8s ease-in-out",
}));

const SuccessBox = styled(Box)(({ visible }) => ({
  backgroundColor: "#d4edda", // Light green background for success
  color: "#155724", // Dark green text
  padding: "10px",
  borderRadius: "4px",
  border: "1px solid #c3e6cb", // Light green border
  marginBottom: "10px",
  opacity: visible ? 1 : 0,
  transform: visible ? "translateY(0px)" : "translateY(-10px)",
  transition: "opacity 0.8s ease-in-out, transform 0.8s ease-in-out",
}));

export const ErrorComponent = ({
  errorMessages,
  show,
  setShow,
  setErrorMessage,
  isSuccess = false,
  mt = 0,
}) => {
  const [visible, setVisible] = useState(false);
  const [render, setRender] = useState(false);

  useEffect(() => {
    if (show) {
      setRender(true); // Start rendering the box

      setTimeout(() => setVisible(true), 10); // Trigger animation after render
      const timer = setTimeout(() => {
        setVisible(false);
        setTimeout(() => setRender(false), 800); // Remove from DOM after transition ends
        setTimeout(() => setShow(false), 900); // Remove from DOM after transition ends
      }, 10000); // Hide after 10 seconds
      return () => clearTimeout(timer);
    } else {
      setVisible(false); // Trigger hide animation
      const timer = setTimeout(() => setRender(false), 800); // Remove from DOM after transition
      return () => clearTimeout(timer);
    }
  }, [show]);

  return (
    <>
      {!isSuccess && render && (
        <ErrorBox mt={mt} visible={visible}>
          {Array.isArray(errorMessages) ? (
            errorMessages.map((message, index) => (
              <Typography key={index} variant="body2">
                {message}
              </Typography>
            ))
          ) : (
            <Typography variant="body2">{errorMessages}</Typography>
          )}
        </ErrorBox>
      )}
      {isSuccess && render && (
        <SuccessBox mt={mt} visible={visible}>
          {errorMessages.map((message, index) => (
            <Typography key={index} variant="body2">
              {message}
            </Typography>
          ))}
        </SuccessBox>
      )}
    </>
  );
};

export default ErrorComponent;
