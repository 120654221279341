import React, { useEffect, useState } from "react";
import { Grid, TextField } from "@mui/material";
import { styled } from "@mui/system";
import LoginService, { AuthService } from "../../services/LoginService";
import { findNonSerializableValue } from "@reduxjs/toolkit";
import ErrorComponent from "../Messages/ErrorComponent";

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    background: "#fff",
    fontSize: "0.77rem", // Font size 0.875rem (14px)
    fontFamily:
      'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"', // Applying font family to the input
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#3f51b5", // Border color when focused
  },
  "& .MuiInputLabel-root": {
    fontFamily:
      'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"', // Applying font family to the input
    fontWeight: 500,
    font: "0.5rem",
    color: "#808080", // Professional shade of gray for label
  },
  "&:hover .MuiOutlinedInput-root:not(.Mui-focused) .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "#3f51b5", // Maintain border color on hover as when focused
    },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#3f51b5", // Color when label is focused
  },
});

export const GetOtp = ({ handleChange, errors, id,newPassword, type,setPassUpdated,setShowOtp }) => {

  const [uotp, setOtp] = useState(Array(6).fill(""));
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrormsg] = useState([""]);

  const handleOtpChange =  async (element, index) => {
    console.log(uotp);
    if (isNaN(element.value)) return;
    const newOtp = [...uotp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    // Focus next input field
    if (element.value && index < 5) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
    if (newOtp.length === 6 && !newOtp.includes("")) {
      console.log("Validate Otp");
      var finalizeOtp = newOtp.toString().replaceAll(",", "");
      var otp =await AuthService.VerifyOtp(id, finalizeOtp, type);
      if (otp) {
        //proceed to Update password
        var res =await AuthService.UpdatePassword(id, newPassword, finalizeOtp);      
        setShowOtp(false);
        setPassUpdated(true);
      } else {
        setShowError(true);
        setErrormsg(["Invalid OTP!"]);
      }
    }
    // Pass OTP value to handleChange
    // handleChange({
    //   target: {
    //     name: "otp",
    //     value: newOtp.join(""),
    //   },
    // });
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && !uotp[index] && index > 0) {
      document.getElementById(`otp-${index - 1}`).focus();
    }
  };

  return (
    <Grid container spacing={1} paddingTop={5} justifyContent="center">
      {showError && (
        <ErrorComponent errorMessages={errorMsg} show={showError} />
      )}
      {uotp.map((data, index) => (
        <Grid item key={index} xs="auto">
          <StyledTextField
            inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
            id={`otp-${index}`}
            name={`otp-${index}`}
            value={data}
            onChange={(e) => handleOtpChange(e.target, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            error={errors.otp}
            helperText={index === 0 && errors.otp ? "OTP Incorrect!" : ""}
            variant="outlined"
            size="small"
            style={{ width: "3rem" }} // Adjust width for a better appearance
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default GetOtp;
