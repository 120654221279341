import React from "react";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material";

const MUISubHeader = ({ text = "", textSize,textColor ,fontWeight = 500}) => {
  const theme = useTheme();
  textSize = textSize ? textSize : theme.palette.font.customFontSize;
  textColor = textColor ? textColor : theme.palette.font.appTextColor;
  return (
    <Typography
      variant="h4"
      gutterBottom
      sx={{
        fontWeight:fontWeight,
        fontSize: textSize,
        color: textColor,
        fontFamily:
          'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
      }}
    >
      {text} 
    </Typography>
  );
};

export default MUISubHeader;
