// import React, { useEffect, useState } from "react";
// import Modal from "@mui/material/Modal";
// import { Box, Button, Grid } from "@mui/material";
// import BtnIcons from "./BtnIcons";
// import PersonAdd from "@mui/icons-material/PersonAdd";
// import MuiText from "./MuiText";
// const CustomModal = ({
//   open,
//   onClose,
//   content,
//   onSave,
//   onCancel,
//   btnSaveText,
//   btnCancelText,
//   bgColor,
//   footerMarginTop,
//   reset = true,
//   showHeader = true,
//   headerLabel = "",
//   headerIcon = { PersonAdd },
// }) => {
//   const [loading, setLoading] = useState(false);
//   useEffect(() => {
//     if (!reset) {
//       setLoading(false);
//     }
//   }, [reset]);
//   const modalStyle = {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     width: 400,
//     bgcolor: "background.paper",
//     border: "1px solid #808080",
//     borderRadius: 3,
//     boxShadow: 24,
//     p: 4,
//     transition: "all 0.9s ease 2s",
//   };

//   return (
//     <Modal
//       open={open}
//       onClose={onClose}
//       aria-labelledby="modal-modal-title"
//       aria-describedby="modal-modal-description"
//     >
//       <Box sx={modalStyle}>
//         {showHeader && (
//           <MuiText
//             icon={headerIcon}
//             text={headerLabel}
//             textColor="#808080"
//             fontSize={"1.3rem"}
//             mt={1}
//             ml={0}
//           />
//         )}
//         {content}
//         <Grid
//           container
//           spacing={1}
//           justifyContent="flex-end"
//           sx={{ marginTop: footerMarginTop ? footerMarginTop : 2 }}
//         >
//           <Grid item>
//             <Button
//               variant="contained"
//               color="secondary"
//               onClick={onClose}
//               sx={{ padding: "8px 16px", textTransform: "none" }}
//             >
//               {btnCancelText ? btnCancelText : "Cancel"}
//             </Button>
//           </Grid>
//           <Grid item>
//             {/* <Button
//               variant="contained"
//               onClick={() => onSave()}
//               fullWidth
//               sx={{ padding: "8px 16px", textTransform: "none" }}
//             >
//               {btnSaveText ? btnSaveText : "Save & Close"}
//             </Button> */}
//             <BtnIcons
//               onClick={() => onSave()}
//               loading={loading}
//               setLoading={setLoading}
//             />
//           </Grid>
//         </Grid>
//       </Box>
//     </Modal>
//   );
// };

// export default CustomModal;
import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box, Button, FormControl, Grid } from "@mui/material";
import BtnIcons from "./BtnIcons";
import PersonAdd from "@mui/icons-material/PersonAdd";
import MuiText from "./MuiText";
import ErrorComponent from "../Messages/ErrorComponent";

const CustomModal = ({
  open,
  onClose,
  content,
  onSave,
  btnSaveText = "Button",
  BtnSaveStartIcon,
  BtnSaveEndIcon,
  btnCancelText,
  bgColor,
  footerMarginTop,
  reset = true,
  showHeader = true,
  headerLabel = "",
  headerIcon,
  errorMsg = "",
  setErrorMsg,
  showError = false,
  setShowError,
  width=400,
  height
}) => {
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    setLoading(true);
    try {
      await onSave();
    } finally {
      setLoading(false);
    }
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width,
    bgcolor: "background.paper",
    border: "1px solid #808080",
    borderRadius: 3,
    boxShadow: 24,
    p: 4,
    pt: 2, // Add padding at the top
    transition: "all 0.9s ease 2s",
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        {showHeader && (
          <MuiText
            icon={headerIcon}
            text={headerLabel}
            textColor="#808080"
            fontSize={"1.3rem"}
            mt={1}
            ml={0}
          />
        )}
        <ErrorComponent
          errorMessages={errorMsg}
          setErrorMsesage={setErrorMsg}
          show={showError}
          setShow={setShowError}
        />
        <FormControl required>
          {content}
          <Grid
            container
            spacing={0} // Adds some spacing between buttons
            justifyContent="flex-end"
            flexDirection="row"
            sx={{ marginTop: footerMarginTop ? footerMarginTop : 2 }}
          >
            <Grid item xs="auto">
              {" "}
              {/* Auto width to fit the button */}
              <BtnIcons
                btnLabel="Cancel"
                variant="contained"
                onClick={onClose}
              />
            </Grid>
            <Grid item xs="auto">
              {" "}
              {/* Auto width to fit the button */}
              <BtnIcons
                onClick={onClick}
                loading={loading}
                setLoading={setLoading}
                btnLabel={btnSaveText}
                startIcon={BtnSaveStartIcon}
                endIcon={BtnSaveEndIcon}
                isIconButton={true}
              />
            </Grid>
          </Grid>
        </FormControl>
        {/* {content} */}
      </Box>
    </Modal>
  );
};

export default CustomModal;
