import React, { useState } from "react";
import { Box, TextField, Zoom, Button, Grid } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { useTheme, useMediaQuery } from "@mui/material";
import TextOnly from "../UIComponents/TextOnly";

const PreviewCalendar = ({
  disablePast = true,
  calendarWidth = "400px",
  calendarHeight = "500px",
  calendarScale = 1.2,
  isPreview=false
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedDate, setSelectedDate] = useState(null);
  const [slotsVisible, setSlotsVisible] = useState(false);
  const [bookedSlot, setBookedSlot] = useState(null);

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//     setSlotsVisible(true);
//     setBookedSlot(null);
//   };
//   const handleSlotClick = (slot) => {
//     setBookedSlot(slot);
//   };

  const handleBookClick = (slot) => {
    console.log(`Slot ${slot} booked!`);
  };

const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedSlot(null); // Clear slot selection on date change
  };

  const handleSlotClick = (slot) => {
    setSelectedSlot(slot);
  };


  const [selectedSlot, setSelectedSlot] = useState(null);

  //   const renderTimeSlots = () => {
  //     const dummySlots = [
  //       "10:00 AM",
  //       "11:30 AM",
  //       "1:00 PM",
  //       "3:30 PM",
  //       "5:00 PM",
  //     ];
  //     return dummySlots.map((slot, index) => (
  //       <Zoom
  //         in={slotsVisible}
  //         style={{ transitionDelay: `${index * 150}ms` }}
  //         key={index}
  //       >
  //         <Box
  //           sx={{
  //             display: "flex",
  //             alignItems: "center",
  //             gap: 1,
  //             width: "100%",
  //             justifyContent: isSmallScreen ? "center" : "flex-start",
  //           }}
  //         >
  //           <Button
  //             variant="outlined"
  //             onClick={() => handleSlotClick(slot)}
  //             sx={{
  //               margin: "10px 0",
  //               width: "150px",
  //               borderColor:"rgb(0, 105, 255)",
  //               backgroundColor: bookedSlot === slot ? "#1976d2" : "#ffffff",
  //               color: bookedSlot === slot ? "#ffffff" : "#1976d2",
  //               "&:hover": {
  //                 // backgroundColor: bookedSlot === slot ? "#1976d2" : "#f0f0f0",
  //                 border:"2px solid rgb(0, 105, 255)"

  //               },
  //             }}
  //           >
  //             {slot}
  //           </Button>
  //           {bookedSlot === slot && (
  //             <Button
  //               variant="contained"
  //               onClick={() => handleBookClick(slot)}
  //               sx={{
  //                 backgroundColor: "#4caf50",
  //                 color: "#ffffff",
  //                 "&:hover": {
  //                   backgroundColor: "#388e3c",
  //                 },
  //               }}
  //             >
  //               Book
  //             </Button>
  //           )}
  //         </Box>
  //       </Zoom>
  //     ));
  //   };

  const renderTimeSlots = () => {
    const dummySlots = [
      "10:00 AM",
      "11:30 AM",
      "1:00 PM",
      "3:30 PM",
      "5:00 PM",
    ];
  
    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
        {dummySlots.map((slot, index) => (
          <Box key={index} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Box
              sx={{
                padding: "12px",
                borderRadius: "8px",
                border: selectedSlot === slot ? "2px solid #1976d2" : "1px solid #ddd",
                backgroundColor: selectedSlot === slot ? "#e3f2fd" : "#fff",
                transition: "all 0.3s ease",
                width: isSmallScreen ? "100%" : "300px",
                cursor: "pointer",
                textAlign: "center",
                "&:hover": {
                  borderColor: "#1976d2",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                },
              }}
              onClick={() => handleSlotClick(slot)}
            >
              <TextOnly text={slot} color={selectedSlot === slot ? "#1976d2" : "#000"} />
            </Box>
  
            {selectedSlot === slot && (
              <Button
                variant="contained"
                disabled={isPreview}
                sx={{
                  backgroundColor: "#4caf50",
                  color: "#ffffff",
                  padding: "6px 12px",
                  borderRadius: "20px",
                  fontWeight: 500,
                  fontSize: "0.875rem",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    backgroundColor: "#388e3c",
                    boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
                  },
                }}
                onClick={() => handleBookClick(slot)}
              >
                Book
              </Button>
            )}
          </Box>
        ))}
      </Box>
    );
  };
  
  

  return (
    <>
      <Box display={"flex"} flexDirection={isSmallScreen ? "column" : "row"} >
        <Box sm={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              value={selectedDate}
              onChange={handleDateChange}
              disablePast={disablePast}
              renderInput={(params) => <TextField {...params} fullWidth />}
              slotProps={{
                actionBar: { actions: [] },
                toolbar: { hidden: true },
                calendarHeader: { hidden: true },
                textField: { fullWidth: true },
              }}
              sx={{
                backgroundColor: "#ffffff",
                width: calendarWidth,
                height: calendarHeight,
                transform: `scale(${calendarScale})`,
                transformOrigin: "top left",
                "& .css-gnwvcc-MuiTypography-root": {
                  color: "#1976d2",
                  fontWeight: 500,
                  fontSize: 22,
                  fontFamily: "Inter, sans-serif",
                },
                "& .MuiPickersDay-root": {
                  "&.Mui-selected": {
                    backgroundColor: "#1976d2",
                    fontWeight: 500,
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Box>
        {selectedDate && (
          <Box sm={12} md={6} ml={10}>
            <TextOnly
              text={format(selectedDate.toDate(), "MMMM d, yyyy")}
              color={"#1976d2"}
            />
            {renderTimeSlots()}
          </Box>
        )}
      </Box>
    </>
  );
};

export default PreviewCalendar;

// import React, { useState } from "react";
// import { Box, TextField, Zoom, Button, Divider, Grid } from "@mui/material";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
// import { format } from "date-fns";
// import { useTheme, useMediaQuery } from "@mui/material";
// import TextOnly from "../UIComponents/TextOnly";

// const PreviewCalendar = ({
//   disablePast = true,
//   calendarWidth = "400px",
//   calendarHeight = "500px",
//   calendarScale = 1.2,
// }) => {
//   const theme = useTheme();
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
//   const [selectedDate, setSelectedDate] = useState(null);
//   const [slotsVisible, setSlotsVisible] = useState(false);
//   const [bookedSlot, setBookedSlot] = useState(null);
//   const [eventName, setEventName] = useState("Meeting Event");
//   const [meetingLocation, setMeetingLocation] = useState("Online");

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//     setSlotsVisible(true);
//     setBookedSlot(null);
//   };

//   const renderTimeSlots = () => {
//     const dummySlots = [
//       "10:00 AM",
//       "11:30 AM",
//       "1:00 PM",
//       "3:30 PM",
//       "5:00 PM",
//     ];
//     return dummySlots.map((slot, index) => (
//       <Zoom
//         in={slotsVisible}
//         style={{ transitionDelay: `${index * 100}ms` }}
//         key={index}
//       >
//         <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
//           <Button
//             variant="outlined"
//             onClick={() => handleSlotClick(slot)}
//             sx={{
//               margin: "10px 0",
//               width: "150px",
//               backgroundColor: bookedSlot === slot ? "#1976d2" : "#ffffff",
//               color: bookedSlot === slot ? "#ffffff" : "#1976d2",
//               "&:hover": {
//                 backgroundColor: bookedSlot === slot ? "#1976d2" : "#f0f0f0",
//               },
//             }}
//           >
//             {slot}
//           </Button>
//           {bookedSlot === slot && (
//             <Button
//               variant="contained"
//               onClick={() => handleBookClick(slot)}
//               sx={{
//                 backgroundColor: "#4caf50",
//                 color: "#ffffff",
//                 "&:hover": {
//                   backgroundColor: "#388e3c",
//                 },
//               }}
//             >
//               Book
//             </Button>
//           )}
//         </Box>
//       </Zoom>
//     ));
//   };

//   const handleSlotClick = (slot) => {
//     setBookedSlot(slot);
//   };

//   const handleBookClick = (slot) => {
//     console.log(`Slot ${slot} booked!`);
//   };

//   return (
//     // <Box sx={{ padding: 2 }}>
//     <Grid container spacing={1}>
//       <Grid item xs={12} md={12}>
//         <LocalizationProvider dateAdapter={AdapterDayjs}>
//           <StaticDatePicker
//             value={selectedDate}
//             onChange={handleDateChange}
//             disablePast={disablePast}
//             renderInput={(params) => <TextField {...params} fullWidth />}
//             slotProps={{
//               actionBar: { actions: [] },
//               toolbar: { hidden: true },
//               calendarHeader: { hidden: true },
//               textField: { fullWidth: true },
//             }}
//             sx={{
//               backgroundColor: "#ffffff",
//               width: calendarWidth,
//               height: calendarHeight,
//               transform: `scale(${calendarScale})`,
//               transformOrigin: "top left",
//               "& .css-gnwvcc-MuiTypography-root": {
//                 color: "#1976d2",
//                 fontWeight: 500,
//                 fontSize: 22,
//                 fontFamily: "Inter, sans-serif",
//               },
//               "& .MuiPickersDay-root": {
//                 "&.Mui-selected": {
//                   backgroundColor: "#1976d2",
//                   fontWeight: 500,
//                 },
//               },
//             }}
//           />
//         </LocalizationProvider>
//         {selectedDate && (
//           <>
//             <Box
//               className="scrollbar"
//               id="style-1"
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 alignItems: "center",
//                 maxHeight: "300px",
//                 overflowY: "auto",
//                 // paddingRight: "10px",
//                 width: "100%",
//               }}
//             >
//               <TextOnly
//                 text={format(selectedDate.toDate(), "MMMM d, yyyy")}
//                 color={"#1976d2"}
//               />
//               {renderTimeSlots()}
//             </Box>
//           </>
//         )}
//       </Grid>

//       {/* Time Slots */}
//       {/* <Grid item xs={12} md={3}>

//       </Grid> */}
//     </Grid>
//     // </Box>
//   );
// };

// export default PreviewCalendar;

// import React,{useState} from "react";
// import { Box, TextField, Zoom,Button } from "@mui/material";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
// import { format } from "date-fns";
// import "../../styles/Scrollbar.css";
// import MuiText from "../UIComponents/MuiText";
// const PreviewCalendar = ({
// //   selectedDate,
// //   handleDateChange,
//   disablePast = true,
//   calendarWidth = "400px",
//   calendarHeight = "500px",
//   calendarScale = 1.2,
// }) => {
//     const [selectedDate, setSelectedDate] = useState(null);
//   const [slotsVisible, setSlotsVisible] = useState(false);
//   const [bookedSlot, setBookedSlot] = useState(null);
//   const [username, setUsername] = useState();
//   const [eventName, setEventName] = useState();
//   const [meetingLocation, setMeetingLocation] = useState();

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//     setSlotsVisible(true);
//     setBookedSlot(null);
//   };

//   const renderTimeSlots = () => {
//     const dummySlots = [
//       "10:00 AM",
//       "11:30 AM",
//       "1:00 PM",
//       "3:30 PM",
//       "11:30 AM",
//       "1:00 PM",
//       "3:30 PM",
//     ];
//     return dummySlots.map((slot, index) => (
//       <Zoom
//         in={slotsVisible}
//         style={{ transitionDelay: `${index * 100}ms` }}
//         key={index}
//       >
//         <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
//           <Button
//             variant="outlined"
//             onClick={() => handleSlotClick(slot)}
//             sx={{
//               margin: "10px 0",
//               width: "150px",
//               backgroundColor: bookedSlot === slot ? "#1976d2" : "#ffffff",
//               color: bookedSlot === slot ? "#ffffff" : "#1976d2",
//               "&:hover": {
//                 backgroundColor: bookedSlot === slot ? "#1976d2" : "#f0f0f0",
//               },
//             }}
//           >
//             {slot}
//           </Button>
//           {bookedSlot === slot && (
//             <Button
//               variant="contained"
//               onClick={() => handleBookClick(slot)}
//               sx={{
//                 backgroundColor: "#4caf50", // Green for the book button
//                 color: "#ffffff",
//                 "&:hover": {
//                   backgroundColor: "#388e3c", // Darker green on hover
//                 },
//               }}
//             >
//               Book
//             </Button>
//           )}
//         </Box>
//       </Zoom>
//     ));
//   };

//   const handleSlotClick = (slot) => {
//     setBookedSlot(slot);
//   };

//   const handleBookClick = (slot) => {
//     console.log(`Slot ${slot} booked!`);
//     // Add your booking logic here
//   };
//   return (
//     <>
//       <Box sx={{ justifyContent: "flex-start" }}>
//         <LocalizationProvider dateAdapter={AdapterDayjs}>
//           <StaticDatePicker
//             value={selectedDate}
//             onChange={handleDateChange}
//             disablePast={disablePast}
//             renderInput={(params) => <TextField {...params} fullWidth />}
//             slotProps={{
//               actionBar: { actions: [] },
//               toolbar: { hidden: true }, // Hide the top toolbar
//               calendarHeader: { hidden: true }, // Hide the calendar header
//               textField: { fullWidth: true },
//             }}
//             sx={{
//               backgroundColor: "#ffffff",
//               width: calendarWidth, // Adjust width dynamically
//               height: calendarHeight, // Adjust height dynamically
//               transform: `scale(${calendarScale})`, // Scale the entire calendar
//               transformOrigin: "top left",
//               "& .css-gnwvcc-MuiTypography-root": {
//                 color: "#1976d2",
//                 fontWeight: 500,
//                 fontSize: 22,
//                 fontFamily:
//                   'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
//               },
//               "& .css-3jvy96-MuiTypography-root-MuiDatePickerToolbar-title": {
//                 color: "#808080",
//                 fontWeight: 500,
//                 fontSize: 22,
//               },
//               "& .MuiPickersCalendarHeader-label": { color: "#3788d8" },
//               "& .MuiPickersDay-root": {
//                 "&.Mui-selected": {
//                   backgroundColor: "#1976d2",
//                   fontWeight: 500,
//                 },
//               },
//             }}
//           />
//         </LocalizationProvider>
//       </Box>
//       {selectedDate && (
//         <Box >
//           <MuiText
//             text={`Available Slots for ${format(
//               selectedDate.toDate(),
//               "MMMM d, yyyy"
//             )}`}
//             sx={{ marginBottom: "10px", color: "#1976d2" }}
//           />
//           <div className="scrollbar" id="style-1">
//             <Box
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 alignItems: "center",
//                 maxHeight: "300px", // Set a fixed height for the scrollable area
//                 overflowY: "auto", // Enable vertical scrolling
//                 paddingRight: "10px", // Optional: Add padding to avoid scrollbar overlap
//                 width: "100%", // Adjust width if needed

//                 // // Custom scrollbar styles
//                 // "&::-webkit-scrollbar": {
//                 //   width: "8px", // Width of the scrollbar
//                 //   background: "transparent", // No background for the scrollbar track
//                 // },
//                 // "&::-webkit-scrollbar-thumb": {
//                 //   backgroundColor: "#808080", // Cool gray color for the scrollbar thumb
//                 //   borderRadius: "4px", // Slightly rounded edges for the thumb
//                 // },
//                 // "&::-webkit-scrollbar-thumb:hover": {
//                 //   backgroundColor: "#606060", // Darker shade when hovering over the thumb
//                 // },
//                 // scrollbarWidth: "thin", // For Firefox
//                 // scrollbarColor: "#808080 transparent", // Thumb color and track color for Firefox
//               }}
//             >
//               {renderTimeSlots()}
//             </Box>
//           </div>
//         </Box>
//       )}
//     </>
//   );
// };

// export default PreviewCalendar;
