
import React from "react";
import { TextField } from "@mui/material";


const StyledTextField = ({
  required = false,
  fullWidth = false,
  id = "id",
  name = "name",
  label = "label",
  inputStyle = "box",
  labelStyle = "floating",
  placeholder = "Placehholder",
  type = "text",
  value,
  setValue,
  onChange,
  error = false,
  helperText = "",
  InputProps,
  marginTop,
}) => {
  function handleChange(e) {
    
    onChange ? onChange(e) : setValue(e.target.value);
  }
  return (
    <>
      <TextField
        key={id}
        required={required}
        fullWidth={fullWidth}
        id={id}
        name={name}
        type={type}
        label={label}
        inputStyle={inputStyle}
        labelStyle={labelStyle}
        placeholder={placeholder}
        value={value ? value : ''}
        onChange={handleChange}
        error={error}
        helperText={helperText}
        InputProps={InputProps}
        sx={{
          borderColor: error ? "red" : "initial",
          "& .MuiInputBase-root": {
            background: "#fff",
            fontSize: "0.77rem", // Font size 0.875rem (14px)
            fontFamily:
              'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"', // Applying font family to the input
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#3f51b5", // Border color when focused
            },
          "& .MuiInputLabel-root": {
            fontFamily:
              'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"', // Applying font family to the input
            fontWeight: 500,
            fontSize: "0.8rem",
            color: "#B0B0B0", // Professional shade of gray for label
          },
          "&:hover .MuiOutlinedInput-root:not(.Mui-focused) .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#3f51b5", // Maintain border color on hover as when focused
            },
          "& .MuiInputLabel-root.Mui-focused": {
            color: "#3f51b5", // Color when label is focused
          },
        }}
      />
    </>
  );
};

export default StyledTextField;


// import React from "react";
// import { TextField } from "@mui/material";
// import { styled } from "@mui/system";
// import { UploadFile } from "@mui/icons-material";
// import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
// import MuiText from "./MuiText";

// const StyledTextField = ({
//   required = false,
//   fullWidth = false,
//   id = "id",
//   name = "name",
//   label = "label",
//   inputStyle = "box",
//   labelStyle = "floating",
//   placeholder = "Placehholder",
//   value,
//   setValue,
//   onChange,
//   error = "",
//   helperText = "",
//   uploadFile = false,
//   uploadfileHeaderText="",
//   marginTop,
// }) => {
//   const CustomInput = styled(TextField)({
//     "& .MuiInputBase-root": {
//       background: "#fff",
//       fontSize: "0.77rem", // Font size 0.875rem (14px)
//       fontFamily:
//         'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"', // Applying font family to the input
//     },
//     "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
//       borderColor: "#3f51b5", // Border color when focused
//     },
//     "& .MuiInputLabel-root": {
//       fontFamily:
//         'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"', // Applying font family to the input
//       fontWeight: 500,
//       fontSize: "0.8rem",
//       color: "#B0B0B0", // Professional shade of gray for label
//     },
//     "&:hover .MuiOutlinedInput-root:not(.Mui-focused) .MuiOutlinedInput-notchedOutline":
//       {
//         borderColor: "#3f51b5", // Maintain border color on hover as when focused
//       },
//     "& .MuiInputLabel-root.Mui-focused": {
//       color: "#3f51b5", // Color when label is focused
//     },
//   });
//   const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];

//     if (file && file.size > MAX_FILE_SIZE) {
//       alert("File size exceeds the maximum limit of 2MB.");
//       // Reset the input if the file is too large
//       event.target.value = null;
//       return;
//     }

//     // Continue with the file handling logic if the size is okay
//     onChange(event);
//   };
//   const handleInput = (e) =>{
//     setValue(e.target.value);
//   }
//   return (
//     <>
//       {!uploadFile && (
//         <CustomInput
//           required={required}
//           fullWidth={fullWidth}
//           id={id}
//           name={name}
//           label={label}
//           inputStyle={inputStyle}
//           labelStyle={labelStyle}
//           placeholder={placeholder}
//           value={value}
//           onChange={handleInput}
//           error={error}
//           helperText={helperText}
//           sx={{ borderColor: { error } ? "red" : "initial" }}
//         />
//       )}
//       {uploadFile && (
//         <>
//           <MuiText
//             icon={AddAPhotoIcon}
//             text={uploadfileHeaderText ? uploadfileHeaderText : "Add File"}
//             textColor="#808080"
//             mt={0}
//             ml={0}
//           />
//           <CustomInput
//             required={required}
//             fullWidth={fullWidth}
//             id={id}
//             name={name}
//             type="file"
//             // label={label}
//             inputStyle={inputStyle}
//             labelStyle={labelStyle}
//             placeholder={placeholder}
//             // value={value}
//             onChange={handleFileChange}
//             error={error}
//             helperText={helperText}
//             sx={{
//               borderColor: { error } ? "red" : "initial",
//               marginTop: { marginTop } ? marginTop : 0,
//             }}
//           />
//         </>
//       )}
//     </>
//   );
// };

// export default StyledTextField;