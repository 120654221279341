// import { Box } from "@mui/material";
// import React from "react";
// import TextOnly from "./TextOnly";
// import { Radio } from "antd";

// const CustomRadioButton = ({
//   text,
//   fontSize,
//   fontWeight = 400,
//   fontColor = "#36454F",
//   setState,
//   mb = 1,
//   mt = 0,
//   ml = 1,
//   textml=1
// }) => {
//   return (
//     <Box display="flex" alignItems="center" mb={mb} mt={mt} ml={ml}>
//       <Radio />
//       <Box ml={textml}>
//         {text && (
//           <TextOnly text={text} fontWeight={fontWeight} color={fontColor} />
//         )}
//       </Box>
//     </Box>
//   );
// };

// export default CustomRadioButton;
import { Box } from "@mui/material";
import React from "react";
import TextOnly from "./TextOnly";
import { Radio } from "antd";

const CustomRadioButton = ({
  text,
  content,
  fontSize,
  fontWeight = 400,
  fontColor = "#36454F",
  checked,
  onChange,
  mb = 1,
  mt = 0,
  ml = 1,
  textml = 1,
}) => {
  return (
    <Box display="flex" alignItems="center" mb={mb} mt={mt} ml={ml}>
      <Radio checked={checked} onChange={onChange} />
      <Box ml={textml}>
        {text && (
          <TextOnly text={text} fontWeight={fontWeight} color={fontColor} />
        )}
      </Box>
    </Box>
  );
};

export default CustomRadioButton;
