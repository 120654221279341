import React, { useEffect, useState } from "react";
import {
  Avatar,
  Typography,
  Box,
  Grid,
  useTheme,
  useMediaQuery,
  Tooltip,
  TextField,
  styled,
  FormControl,
} from "@mui/material";

import MuiHeader from "../components/UIComponents/MuiHeader";
import EventCard from "../components/Dashboard/EventCard";
import GoogleIcon from "@mui/icons-material/Google";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import "../styles/Dashboard.css";
import CustomSelect from "../components/UIComponents/CustomSelect";
import { useNavigate } from "react-router-dom";
import withLoader from "../components/Loader/withLoader";
import { InfoWrapper } from "../utils/InfoWrapper";
import { useDispatch } from "react-redux";
import { EventService } from "../services/EventService";
import Teams from "../components/Dashboard/Teams";
import Users from "../components/Dashboard/Users";
import ButtonDropDown from "../components/UIComponents/ButtonDropDown";
import InputText from "../components/UIComponents/InputText";
import CustomModal from "../components/UIComponents/CustomModal";
import MemberManager from "../components/UIComponents/MemberManager";
import StyledTextField from "../components/UIComponents/StyledTextField";
import MUISubHeader from "../components/UIComponents/MUISubHeader";
import MuiText from "../components/UIComponents/MuiText";
import FileUpload from "../components/UIComponents/FileUpload";
import { TeamsService } from "../services/TeamsService";
import InviteMembers from "../components/Dashboard/InviteMembers";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SaveIcon from "@mui/icons-material/Save";
import { Edit as EditIcon } from "@mui/icons-material";
import InputFileUpload from "../components/UIComponents/InputFileUpload";
import ErrorComponent from "../components/Messages/ErrorComponent";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ModalEditTeams from "../components/ModalsBody/ModalEditTeams";

const DashBoard = () => {
  const { user } = InfoWrapper();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [url, setUrl] = useState(process.env.REACT_APP_BASEURL + user.userName);
  const [filterEvent, setFilterEvent] = useState([
    { id: 1, name: "All", value: "all" },
  ]);
  const [calendarTypes, setCalendarTypes] = useState([
    { id: 1, name: "My Events", value: "myevent" },
  ]);
  const [createOptions, setCreateOptions] = useState();

  const [selectedValues, setSelectedValues] = useState(1);
  const [showInfo, setSelectedInfo] = useState("myevent");
  const [selectedFilterevent, setSelectedFilterevent] = useState(1);
  const [userEvents, setEvents] = useState();
  const [spacetype, setSpacetype] = useState();
  const [copySuccess, setCopySuccess] = useState(false);
  const [createTeam, setCreateTeam] = useState(false);
  const [createUser, setCreateUser] = useState(false);
  const [showEditTeam, setShowEditTeam] = useState(false);
  const [editTeamId, setEditTeamId] = useState();
  const [editTeamError, setEditteamError] = useState(false);

  const [teamName, setTeamName] = useState();
  const [teamUrl, setTeamUrl] = useState();
  const [avatar, setAvatar] = useState(null);
  const [uploadedfile, setUploadedFile] = useState(null);
  const [members, setMembers] = useState([]);
  const [showMemberSuccess, setShowMemberSuccess] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [showinvteUserError, setShowinvteUserError] = useState(false);
  const [invteUserErrorMessage, setInvteUserErrorMessage] = useState("");

  const [errors, setErrors] = useState({
    teamName: false,
    teamUrl: false,
    avatar: false,
    participants: false,
  });

  const handleCopy = () => {
    navigator.clipboard.writeText(url).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 3000); // Reset the tooltip text after 2 seconds
    });
  };
  const handleEventCopy = (val) => {
    navigator.clipboard.writeText(val).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 3000); // Reset the tooltip text after 2 seconds
    });
  };
  const handleClick = (eId) => {
    console.log(`Sync ${eId} clicked`);
    navigate(`/EventDetails/${eId}`);
    // Add your event card logic here
  };

  useEffect(() => {
    if (selectedFilterevent !== "") {
      const fetchData = async () => {
        let res = await withLoader(dispatch, () =>
          EventService.GetEvents(user.uid, selectedFilterevent)
        );
        if (res) {
          const events = res.events;
          setEvents(events);
        }
      };
      fetchData();
    }
  }, [selectedFilterevent]);

  useEffect(() => {
    const fetchData = async () => {
      let res = await withLoader(dispatch, () =>
        EventService.GetAllInfo(user.uid)
      );
      if (res) {
        let event = res.events;
        let calendarTypes = res.calendarTypes;
        let filterEvents = res.filterEvents;
        let createOptions = res.createOptions;
        console.log(
          "----Dashboard Info----------",
          event,
          calendarTypes,
          filterEvents,
          createOptions
        );
        setEvents(event);
        setCalendarTypes(calendarTypes);
        setFilterEvent(filterEvents);
        setCreateOptions(createOptions);
        setSpacetype(res.spacetype);
      }
    };
    fetchData();
  }, []);

  const handleEventChange = (e) => {
    console.log(e.target.value);
    setSelectedFilterevent(e.target.value);
    // setSelectedValues((prevValues) => ({
    //   ...prevValues,
    //   [key]: event.target.value,
    // }));
  };

  const handleTeamClose = () => {
    setCreateTeam(false);
    setMembers([]);
    setTeamName();
    setTeamUrl();
    setAvatar(null);
  };
  const handleTeamEditClose = () => {
    setShowEditTeam(false);
    setEditTeamId();
  };
  const handleTeamEditOpen = (Id) => {
    console.log("===== Invoked =========", Id);
    setShowEditTeam(true);
    setEditTeamId(Id);
  };

  const handleUserModalClose = () => {
    setInvteUserErrorMessage("");
    setShowinvteUserError(false);
    setCreateUser(false);
    setMembers([]);
  };
  const handleInfoReturn = (e) => {
    // console.log(e.value);
    setSelectedValues(e.id);
    // let item = spacetype;
    if (e.value === "myevent" || e.value === "default") {
      setSelectedInfo("myevent");
    }
    if (e.value === "createteam") {
      setCreateTeam(true);
    }
    if (e.value.toLowerCase() === "users") {
      setSelectedInfo("user");
      //navigate("/UpgradePlan");
    }
    if (e.value.toLowerCase() === "teams") {
      setSelectedInfo("teams");
      //navigate("/UpgradePlan");
    }
    if (e.value === "upgradeplan") {
      navigate("/UpgradePlan");
    }
  };

  const handleCreate = (item) => {
    console.log("---- Invoked Handle Create -----", item);
    switch (item) {
      case "newevent":
        //handleCreateEvent();
        navigate("/Events", { state: { spaceId: spacetype } });
        break;
      case "newteam":
        setCreateTeam(true);
        break;
      case "newuser":
        setCreateUser(true);
        break;
    }
  };

  const HeaderAction = () => {
    return (
      <ButtonDropDown
        text={"Create"}
        dropdownItems={createOptions}
        onClick={handleCreate}
        buttonWidth="80px"
        buttonHeight="3vh"
        padding="10px"
        isDropDown={true}
      />
    );
  };

  const CreateTeam = async () => {
    console.log("Create Team APi", teamMembers);
    const data = {
      spacetype,
      user,
      teamName,
      teamUrl,
      uploadedfile,
      teamMembers,
    };
    let res = await withLoader(dispatch, () => TeamsService.CreateTeam(data));
    if (res.Success) {
      //Team Created Suceeesfully
      setCreateTeam(false);
      navigate("/Team");
    } else if (res.Error) {
    }
  };
  const InviteUser = async () => {
    console.log("Create User APi");
    if (!members.length) {
      setInvteUserErrorMessage(["Plese Add Users to Invite!"]);
      setShowinvteUserError(true);
    } else {
      let res = await withLoader(dispatch, () =>
        TeamsService.InviteTeamUsers(user, members, spacetype)
      );
      if (res.Error) {
        setShowinvteUserError(true);
        setInvteUserErrorMessage([res.Error]);
      } else {
        setMembers([]);
        setShowMemberSuccess(true);
      }
    }
  };
  const EditTeam = async () => {
    console.log("Edit Team", teamMembers);
    const data = {
      spacetype,
      user,
      teamName,
      teamUrl,
      uploadedfile,
      teamMembers,
    };
    let res = await withLoader(dispatch, () => TeamsService.CreateTeam(data));
    if (res.Success) {
      //Team Created Suceeesfully
      setCreateTeam(false);
      navigate("/Team");
    } else if (res.Error) {
    }
  };

  // const handleTeamName = (e) => {
  //   setTeamName(e.target.value);
  // };

  // const UploadFileInput = styled(TextField)({
  //   "& .MuiInputBase-root": {
  //     background: "#fff",
  //     fontSize: "0.77rem", // Font size 0.875rem (14px)
  //     fontFamily:
  //       'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"', // Applying font family to the input
  //   },
  //   "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
  //     borderColor: "#3f51b5", // Border color when focused
  //   },
  //   "& .MuiInputLabel-root": {
  //     fontFamily:
  //       'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"', // Applying font family to the input
  //     fontWeight: 500,
  //     fontSize: "0.8rem",
  //     color: "#B0B0B0", // Professional shade of gray for label
  //   },
  //   "&:hover .MuiOutlinedInput-root:not(.Mui-focused) .MuiOutlinedInput-notchedOutline":
  //     {
  //       borderColor: "#3f51b5", // Maintain border color on hover as when focused
  //     },
  //   "& .MuiInputLabel-root.Mui-focused": {
  //     color: "#3f51b5", // Color when label is focused
  //   },
  // });

  // const handleEditTeamModal = ({ tid }) => {
  //   // setShowEditTeam(!showEditTeam);
  // };

  return (
    <>
      <MuiHeader text={"Dashboard"} cusButton={<HeaderAction />} />

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={isMobile ? "column" : "row"}
        mt={2}
        mb={2}
      >
        <Box display="flex" alignItems="center" mb={isMobile ? 2 : 0}>
          <Avatar sx={{ bgcolor: "#87d068" }}>U</Avatar>
          <Tooltip title={copySuccess ? "Copied!" : "Click to copy"} arrow>
            <Typography
              variant="body1"
              component="a"
              href={url}
              target="_blank"
              sx={{
                ml: 2,
                cursor: "pointer",
                textDecoration: "none",
                fontFamily: theme.palette.common.font,
                fontWeight: 500,
                fontSize: 14,
                letterSpacing: 0.2,
                color: "rgb(0,105,255)",
              }}
              onClick={(e) => {
                e.preventDefault(); // Prevent opening the link on click
                handleCopy();
              }}
            >
              {url}
            </Typography>
          </Tooltip>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={isMobile ? "center" : "flex-start"}
        flexDirection={isMobile ? "column" : "row"}
        gap={2}
        mt={3}
      >
        <CustomSelect
          label="Info"
          handleDropdownChange={handleInfoReturn}
          selectedValue={selectedValues}
          options={calendarTypes}
          sx={{ width: isMobile ? "100%" : "auto" }}
        />

        <CustomSelect
          label="Event Types"
          handleDropdownChange={handleEventChange}
          selectedValue={selectedFilterevent}
          options={filterEvent}
          sx={{ width: isMobile ? "100%" : "auto" }}
        />
      </Box>
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        {/* {selectedValues && selectedValues === "default" && ( */}
        <Grid container spacing={3}>
          {showInfo === "myevent" &&
            userEvents &&
            userEvents.map((event, index) => (
              <Grid item xs={12} sm={6} md={3} key={event.Id || index}>
                <EventCard
                  icon={GoogleIcon}
                  title={event.EventName || "Event"}
                  description={
                    event.EventDescription || "No description available"
                  }
                  meetingType={event.Location || "Meeting"}
                  color={event.Rgb || "#4285F4"}
                  onClick={() => handleClick(event.Id)}
                  copyEventUrl={() =>handleEventCopy(event.EventName)}
                />
              </Grid>
            ))}
        </Grid>
        {/* )} */}
        {showInfo && (
          <Grid>
            {showInfo === "user" && <Users spaceId={spacetype} />}
            {showInfo === "teams" && (
              <Teams spaceId={spacetype} handleEdit={handleTeamEditOpen} />
            )}
            {showInfo === "all" && (
              <>
                <Teams spaceId={spacetype} />
                <Users spaceId={spacetype} />
              </>
            )}
          </Grid>
        )}
      </Box>
      <CustomModal
        open={createTeam}
        onClose={handleTeamClose}
        onSave={CreateTeam}
        btnSaveText={"Create"}
        BtnSaveEndIcon={<GroupAddIcon />}
        headerLabel={"Create Team"}
        headerIcon={GroupAddIcon}
        content={
          <FormControl required>
            <Box>
              <Grid container display={"flex"} mt={2} spacing={2}>
                <Grid sm={6} md={12} item>
                  {/* <InputText fullWidth={true} label={"Team Name"} required={true}/> */}
                  <StyledTextField
                    fullWidth={true}
                    label={"Team Name"}
                    required={true}
                    value={teamName}
                    setValue={setTeamName}
                    // onChange={handleTeamName}
                  />
                </Grid>
                <Grid sm={6} md={12} item rowSpacing={1}>
                  <MuiText
                    text={user.userName + "/"}
                    fontSize={12}
                    mt={0}
                    ml={0}
                  />
                  <StyledTextField
                    fullWidth={true}
                    label={"Team URL"}
                    required={true}
                    value={teamUrl}
                    setValue={setTeamUrl}
                  />
                </Grid>
                <Grid sm={6} md={12} rowSpacing={1} item>
                  <MuiText
                    icon={AddAPhotoIcon}
                    text={"Add Avatar"}
                    textColor="#808080"
                    mt={0}
                    ml={0}
                  />
                  {/* <FileUpload setUploadedFile={setUploadedFile} /> */}
                  <InputFileUpload setUploadedFile={setUploadedFile} />
                </Grid>

                <Grid sm={6} md={12} item>
                  <MemberManager
                    members={teamMembers}
                    setMembers={setTeamMembers}
                    spaceId={spacetype}
                  />
                </Grid>
              </Grid>
            </Box>
          </FormControl>
        }
      />

      <CustomModal
        open={createUser}
        onClose={handleUserModalClose}
        onSave={InviteUser}
        reset={!showinvteUserError}
        footerMarginTop={9}
        btnSaveText={"Invite "}
        BtnSaveEndIcon={<PersonAddIcon />}
        showHeader={true}
        headerLabel={"Invite Users"}
        headerIcon={PersonAddIcon}
        showError={showinvteUserError}
        setShowError={setShowinvteUserError}
        errorMsg={invteUserErrorMessage}
        setErrorMsg={setInvteUserErrorMessage}
        content={
          <>
            <Box>
              <Grid container display={"flex"} mt={2}>
                <InviteMembers
                  members={members}
                  setMembers={setMembers}
                  showMemberSuccess={showMemberSuccess}
                  setShowMemberSuccess={setShowMemberSuccess}
                />
              </Grid>
            </Box>
          </>
        }
      />

      <CustomModal
        open={showEditTeam}
        onClose={handleTeamEditClose}
        onSave={EditTeam}
        reset={!editTeamError}
        footerMarginTop={9}
        btnSaveText={"Save "}
        BtnSaveEndIcon={<SaveIcon />}
        showHeader={true}
        headerLabel={"Edit Team"}
        headerIcon={EditIcon}
        showError={showinvteUserError}
        setShowError={setShowinvteUserError}
        errorMsg={invteUserErrorMessage}
        setErrorMsg={setInvteUserErrorMessage}
        width={700}
        content={
          <>
            <Box>
              <Grid container display={"flex"}>
                <ModalEditTeams
                  data={{ spaceId: spacetype, TeamId: editTeamId }}
                />
              </Grid>
            </Box>
          </>
        }
      />
      {/* <Modal
        open={createTeam}
        onClose={handleTeamClose}
        aria-labelledby="event-modal-title"
        aria-describedby="event-modal-description"
      >
        <Box>
          <Grid container display={"flex"}>
            <InputText />
            <InputText />
            <InputText />
            <InputText />
          </Grid>
        </Box>
      </Modal> */}
    </>
  );
};

export default DashBoard;
