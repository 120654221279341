import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import customLoader from "../../Icons/customLoader.gif";
import { CircularProgress } from '@mui/material';

const AppLoader = ({ show }) => {
  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backdropFilter: 'blur(2px)', // Adds the blur effect
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      open={show}
    >
      <img src={customLoader} alt="Loading..." style={{ width: '100px', height: '100px' }} />
    </Backdrop>
  );
};

export default AppLoader;

// import React from 'react';
// import Backdrop from '@mui/material/Backdrop';
// import CircularProgress from '@mui/material/CircularProgress';

// const AppLoader = ({show}) => {
//   // const loader = useSelector(selectLoader);show

//   return (
//     <Backdrop
//       sx={{
//         color: '#fff',
//         zIndex: (theme) => theme.zIndex.drawer + 1,
//         backdropFilter: 'blur(2px)', // Adds the blur effect
//       }}
//       open={show}
//     >
//       <CircularProgress color="inherit" />
//     </Backdrop>
//   );
// };

// export default AppLoader;

