import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Modal,
  useTheme,
  Box,
  Slide,
  Button,
  Grid,
  Typography,
  TextField,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import {
  Dashboard as DashboardIcon,
  CalendarToday as CalendarTodayIcon,
  BarChart as BarChartIcon,
  Warning as WarningIcon,
  EditCalendar as EditCalendarIcon,
  Menu as MenuIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { styled } from "@mui/system";
import Booking from "./Booking";
import Scheduling from "./Scheduling";
import Alerts from "./Alerts";
import Participants from "./Participants";
import EventDetails from "./EventDetails";
import StyledTextField from "../UIComponents/StyledTextField";
import CustomSelect from "../UIComponents/CustomSelect";
import TextOnly from "../UIComponents/TextOnly";
import MUISubHeader from "../UIComponents/MUISubHeader";
import MeetingOptions from "../CreateEvent/MeetingOptions";
import IntegrationService from "../../services/IntegrationService";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { InfoWrapper } from "../../utils/InfoWrapper";
import { MuiPhone } from "../UIComponents/MuiPhone";
import InputText from "../UIComponents/InputText";
import EventPreview from "../Events/EventPreview";
const drawerWidth = 375;

const CustomDrawer = styled(Drawer)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const EventSidePanel = () => {
  const { user } = InfoWrapper();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [eventName, setEventName] = useState("");
  const [eventDurationId, setEventDurationId] = useState(1);
  const [eventUrl, setEventUrl] = useState("");
  const [selectedDuration, setSelectedDuration] = useState("");
  const [phoneModal, setPhoneModal] = useState(false);
  const [inPersonModal, setInPersonModal] = useState(false);
  const [physicalLocation, setPhysicalLocation] = useState("");
  const [phone, setPhone] = useState("");
  const [ownerWillCall, setOwnerWillCall] = useState(true);
  const [location, setLocation] = useState("");
  const [showlocationError, setShowLocationError] = useState(false);
  const [showlocationErrorMsg, setShowLocationErrorMsg] = useState(false);
  const [urlConnectApp, setUrlConnectApp] = useState("");

  const handlePhone = (val) => setPhone(val);
  const options = [
    { id: 1, value: "30", name: "30 min" },
    { id: 2, value: "60", name: "60 min" },
    { id: 3, value: "90", name: "90 min" },
  ];

  function ConnectApp(val) {
    console.log(`ConnectApp =>${val}`);
    switch (val.toLowerCase()) {
      case "zoom":
        setUrlConnectApp("/Integrations/Zoom/Connect");
      case "teams":
        setUrlConnectApp("/Integrations/Teams/Connect");
      case "google":
        setUrlConnectApp("/Integrations/Google/Connect");
    }
  }

  const handlephoneMOpen = () => {
    setPhoneModal(true);
  };
  const handlephoneClose = () => {
    setPhoneModal(false);
    setOwnerWillCall(true);
  };
  const handleinpersonMOpen = () => {
    setInPersonModal(true);
  };
  const handleinpersonSave = () => {
    setLocation("In-Person Meeting");
    setInPersonModal(false);
  };
  const handleinpersonMClose = () => {
    setInPersonModal(false);
    setPhysicalLocation("");
  };
  const handleLocation = async (val, name) => {
    //check if it is a valid location that is attached
    let res = await IntegrationService.IsUserAppConnected(user.uid, name);
    if (!res.Info) {
      setShowLocationError(true);
      setShowLocationErrorMsg(`${val} is not Connected`);
      ConnectApp(val);
    } else {
      setShowLocationError(false);
      setShowLocationErrorMsg("");
    }
    setLocation(name);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleItemClick = (item) => {
    setActiveItem(item);
    setDrawerOpen(true);
  };

  const handleBackClick = () => {
    setDrawerOpen(false);
  };

  const handleSelectChange = (event) => {
    setSelectedDuration(event.value);
    setEventDurationId(event.id);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "1px solid #808080",
    borderRadius: 3,
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Grid
        container
        height={"100vh"}
        // minWidth={"30vw"}
        // maxWidth={"30vw"}
        spacing={1}
        
      >
        <Grid item sm={6} md={4} sx={{
         
        }}>
          <Box
            sx={{
              padding: "1.5rem",
              borderRight: "1px solid #e0e0e0",
              // borderRadius: "8px",
              // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#ffffff",
              marginBottom: "2rem",
              overflowY: "auto",
              height: "100%",
              maxWidth: "100%",
            }}
          >
          <MUISubHeader text="Event Details" />
          <Grid display="flex" flexDirection={"column"} gap={1}>
            <Grid item sm={12} md={9}>
              <StyledTextField
                label="Name"
                placeholder="Event Name"
                value={eventName}
                setValue={setEventName}
                fullWidth
              />
            </Grid>

            {/* <Grid item sm={12} md={9}>
                  <Grid mb={2}>
                    <MUISubHeader text={`/${eventUrl}`} textSize={12} />
                  </Grid>
                  <StyledTextField
                    label="Url"
                    placeholder="Event Url"
                    value={eventUrl}
                    setValue={setEventUrl}
                    fullWidth
                  />
                </Grid> */}

            <Grid item sm={12} md={9} mt={2}>
              <CustomSelect
                label="Duration"
                handleDropdownChange={handleSelectChange}
                selectedValue={eventDurationId}
                options={options}
                width={"100%"}
                height={50}
                fullWidth={true}
              />
            </Grid>
            <MUISubHeader text="Location" textSize={17} />
            {!location && (
              <MeetingOptions
                handlephoneMOpen={handlephoneMOpen}
                handleLocation={handleLocation}
                handleinpersonMOpen={handleinpersonMOpen}
                locationSeleted={location}
              />
            )}
            {location && (
              <Grid sm={12} md={9}>
                {/* <InputText value={location} editable={false} fullWidth/> */}
                <TextField
                  value={location}
                  id="standard-search"
                  label="Location"
                  type="search"
                  fullWidth
                  onChange={(e) => setLocation(e.target.value)}
                  onTouchCancel={() => setShowLocationError("")}
                />
                {showlocationError && (
                  <>
                    <TextOnly
                      text={showlocationErrorMsg}
                      color={theme.palette.common.errorColor}
                      fontWeight={400}
                    />
                    <Typography
                      variant="body1"
                      component="a"
                      href={urlConnectApp}
                      target="_blank"
                      sx={{
                        cursor: "pointer",
                        textDecoration: "none",
                        fontFamily: theme.palette.common.font,
                        fontWeight: 500,
                        fontSize: 14,
                        letterSpacing: 0.2,
                        color: "rgb(0,105,255)",
                      }}
                      // onClick={(e) => {
                      //   e.preventDefault(); // Prevent opening the link on click
                      //   handleCopy();
                      // }}
                    >
                      {"Connect"}
                    </Typography>
                  </>
                )}
              </Grid>
            )}
          </Grid>
          </Box>
        </Grid>
        <Grid item sm={6} md={8}>
          <EventPreview
            eventName={"ev"}
            location={"loc"}
            duration={"30"}
            username={"Moiz Farooq"}
          />
        </Grid>
      </Grid>

      {phoneModal && (
        <Modal
          open={phoneModal}
          onClose={handlephoneClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="admin"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  onClick={() => setOwnerWillCall(true)}
                  value="admin"
                  control={<Radio />}
                  label="I will call my Invitee"
                />
                <FormControlLabel
                  onClick={() => setOwnerWillCall(false)}
                  value="invitee"
                  control={<Radio />}
                  label="Invitee Should Call me"
                />
              </RadioGroup>
            </FormControl>
            {!ownerWillCall && (
              <Box mt={2}>
                <MuiPhone onChange={handlePhone} value={phone} />
              </Box>
            )}
            <Grid
              container
              spacing={1}
              justifyContent="flex-end"
              sx={{ marginTop: 2 }}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handlephoneClose}
                  sx={{ padding: "8px 16px", textTransform: "none" }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={handlephoneClose}
                  fullWidth
                  sx={{ padding: "8px 16px", textTransform: "none" }}
                >
                  Save & Close
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      )}

      {inPersonModal && (
        <Modal
          open={inPersonModal}
          onClose={handleinpersonMClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <MUISubHeader text="Enter Location:" textSize={17} />
            <InputText
              value={physicalLocation}
              onChange={(e) => setPhysicalLocation(e.target.value)}
              editable={false}
              required={true}
              fullWidth
            />
            <Grid
              container
              spacing={1}
              justifyContent="flex-end"
              sx={{ marginTop: 2 }}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleinpersonMClose}
                  sx={{ padding: "8px 16px", textTransform: "none" }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={handleinpersonSave}
                  sx={{ padding: "8px 16px", textTransform: "none" }}
                  fullWidth
                >
                  Save & Close
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default EventSidePanel;

{
  /* <Grid item  xs={12} md={6}>          
          <MUISubHeader text="Event"/>
          <Box
            sx={{
              width: "100%",
              padding: "1.5rem",
              // border: "1px solid #e0e0e0",
              borderRadius: "8px",
              // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#ffffff",
              marginBottom: "2rem",
              overflowY: "auto",
              height: "100%",
              maxWidth: "88%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Grid display="flex" flexDirection="column">
              {eventName && <MUISubHeader text={eventName} textSize={20} />}
              {!eventName && <MUISubHeader text={"Your Event Name "} />}
              <Grid
                item
                sm={12}
                container
                alignItems="center"
                spacing={1}
                mt={1}
              >
                <Grid item>
                  <AccessTimeIcon sx={{ fontSize: 15 }} />
                </Grid>
                <Grid item>
                  {!selectedDuration && (
                    <TextOnly text={"Duration"} fontSize={15} />
                  )}
                  <TextOnly text={selectedDuration} fontSize={15} />
                </Grid>
              </Grid>
               <Grid item sm={12} container alignItems="center" spacing={1}>
                <Grid item>
                  <LocationOnIcon sx={{ fontSize: 15 }} />
                </Grid>
                <Grid item>
                  {!location && (
                    <TextOnly text={"Meeting Medium"} fontSize={15} />
                  )}
                  {location && (
                    <TextOnly
                      text={
                        location + ": Meeting Details Provided upon Booking"
                      }
                      fontSize={15}
                    />
                  )}
                </Grid>
              </Grid> 
            </Grid>
          </Box>
        </Grid> */
}

///Older COde
// import React, { useState } from "react";
// import {
//   Drawer,
//   List,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
//   IconButton,
//   useTheme,
//   Box,
//   Slide,
//   Button,
//   Grid,
//   Typography,
//   TextField
// } from "@mui/material";
// import {
//   Dashboard as DashboardIcon,
//   CalendarToday as CalendarTodayIcon,
//   BarChart as BarChartIcon,
//   Warning as WarningIcon,
//   EditCalendar as EditCalendarIcon,
//   Menu as MenuIcon,
//   ArrowBack as ArrowBackIcon,
// } from "@mui/icons-material";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import { styled } from "@mui/system";
// import Booking from "./Booking";
// import Scheduling from "./Scheduling";
// import Alerts from "./Alerts";
// import Participants from "./Participants";
// import EventDetails from "./EventDetails";
// import StyledTextField from "../UIComponents/StyledTextField";
// import CustomSelect from "../UIComponents/CustomSelect";
// import TextOnly from "../UIComponents/TextOnly";
// import MUISubHeader from "../UIComponents/MUISubHeader";
// import MeetingOptions from "../CreateEvent/MeetingOptions";
// import IntegrationService from "../../services/IntegrationService";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
// import { InfoWrapper } from "../../utils/InfoWrapper";
// const drawerWidth = 375;

// const CustomDrawer = styled(Drawer)(({ theme }) => ({
//   [theme.breakpoints.down("sm")]: {
//     display: "none",
//   },
// }));

// const CustomListItem = styled(ListItem)(({ theme }) => ({
//   "&.active": {
//     background: "linear-gradient(90deg, #6dd5fa, #2980b9)",
//     color: theme.palette.common.white,
//     transition: "background 1.5s ease",
//     "& .MuiListItemIcon-root": {
//       color: theme.palette.common.white,
//     },
//     "& .MuiListItemText-root": {
//       color: theme.palette.common.white,
//     },
//   },
//   "&:hover": {
//     background: "linear-gradient(90deg, #21618c, #5499c7)",
//     color: theme.palette.common.white,
//     transition: "background 1.5s ease",
//     "& .MuiListItemIcon-root": {
//       color: theme.palette.common.white,
//     },
//     "& .MuiListItemText-root": {
//       color: theme.palette.common.white,
//     },
//   },
// }));

// const EventSidePanel = () => {
//   const { user } = InfoWrapper();
//   const theme = useTheme();
//   const [mobileOpen, setMobileOpen] = useState(false);
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [activeItem, setActiveItem] = useState(null);
//   const [eventName, setEventName] = useState("");
//   const [eventDurationId, setEventDurationId] = useState(1);
//   const [eventUrl, setEventUrl] = useState("");
//   const [selectedDuration, setSelectedDuration] = useState("");
//   const [phoneModal, setPhoneModal] = useState(false);
//   const [inPersonModal, setInPersonModal] = useState(false);
//   const [physicalLocation, setPhysicalLocation] = useState("");
//   const [phone, setPhone] = useState("");
//   const [ownerWillCall, setOwnerWillCall] = useState(true);
//   const [location, setLocation] = useState("");
//   const [showlocationError, setShowLocationError] = useState(false);
//   const [showlocationErrorMsg, setShowLocationErrorMsg] = useState(false);
//   const [urlConnectApp, setUrlConnectApp] = useState("");
//   const options = [
//     { id: 1, value: "30", name: "30 min" },
//     { id: 2, value: "60", name: "60 min" },
//     { id: 3, value: "90", name: "90 min" },
//   ];

//   function ConnectApp(val) {
//     console.log(`ConnectApp =>${val}` )
//     switch (val.toLowerCase() ) {
//       case "zoom":
//         setUrlConnectApp("/Integrations/Zoom/Connect");
//       case "teams":
//         setUrlConnectApp("/Integrations/Teams/Connect");
//       case "google":
//         setUrlConnectApp("/Integrations/Google/Connect");
//     }
//   }

//   const handlephoneMOpen = () => {
//     setPhoneModal(true);
//   };
//   const handlephoneClose = () => {
//     setPhoneModal(false);
//     setOwnerWillCall(true);
//   };
//   const handleinpersonMOpen = () => {
//     setInPersonModal(true);
//   };
//   const handleinpersonSave = () => {
//     setLocation("In-Person Meeting");
//     setInPersonModal(false);
//   };
//   const handleinpersonMClose = () => {
//     setInPersonModal(false);
//     setPhysicalLocation("");
//   };
//   const handleLocation = async (val, name) => {
//     //check if it is a valid location that is attached
//     let res = await IntegrationService.IsUserAppConnected(user.uid, name);
//     if (!res.Info) {
//       setShowLocationError(true);
//       setShowLocationErrorMsg(`${val} is not Connected`);
//       ConnectApp(val)
//     } else {
//       setShowLocationError(false);
//       setShowLocationErrorMsg("");
//     }
//     setLocation(name);
//   };

//   const handleDrawerToggle = () => {
//     setMobileOpen(!mobileOpen);
//   };

//   const handleItemClick = (item) => {
//     setActiveItem(item);
//     setDrawerOpen(true);
//   };

//   const handleBackClick = () => {
//     setDrawerOpen(false);
//   };

//   const handleSelectChange = (event) => {
//     setSelectedDuration(event.value);
//     setEventDurationId(event.id);
//   };

//   const drawer = (
//     <List>
//       <CustomListItem button onClick={() => handleItemClick("Event Details")}>
//         <ListItemIcon>
//           <DashboardIcon />
//         </ListItemIcon>
//         <ListItemText primary="Event Details" />
//       </CustomListItem>

//       <CustomListItem button onClick={() => handleItemClick("Participants")}>
//         <ListItemIcon>
//           <CalendarTodayIcon />
//         </ListItemIcon>
//         <ListItemText primary="Participants" />
//       </CustomListItem>

//       <CustomListItem button onClick={() => handleItemClick("Scheduling")}>
//         <ListItemIcon>
//           <BarChartIcon />
//         </ListItemIcon>
//         <ListItemText primary="Scheduling" />
//       </CustomListItem>

//       <CustomListItem button onClick={() => handleItemClick("Alerts")}>
//         <ListItemIcon>
//           <WarningIcon />
//         </ListItemIcon>
//         <ListItemText primary="Alerts" />
//       </CustomListItem>

//       <CustomListItem button onClick={() => handleItemClick("Booking")}>
//         <ListItemIcon>
//           <EditCalendarIcon />
//         </ListItemIcon>
//         <ListItemText primary="Booking" />
//       </CustomListItem>
//     </List>
//   );
//   const Panel = () => {
//     switch (activeItem) {
//       case "Event Details":
//         return <EventDetails />;
//       case "Participants":
//         return <Participants />;
//       case "Scheduling":
//         return <Scheduling />;
//       case "Alerts":
//         return <Alerts />;
//       case "Booking":
//         return <Booking />;

//       default:
//         return null;
//     }
//   };

//   return (
//     // <Box
//     //   sx={{
//     //     display: "flex",
//     //     flexDirection:"column",
//     //     height: "100vh",
//     //     position: "relative",
//     //     boxSizing: "border-box",
//     //     boxShadow: 1, // Elevation (Shadow)
//     //     borderRight: "1px solid #808080", // Border right with the color #808080
//     //   }}
//     // >
//     //   <EventDetails />
//     //   <Box
//     //     sx={{
//     //       display: "flex",
//     //       flexDirection:"row",
//     //       justifyContent:"space-between",
//     //       pb:2
//     //     }}
//     //   >
//     //     <Button onClick={handleBackClick} variant="outlined">Cancel</Button>
//     //     <Button onClick={handleBackClick} variant="contained" color="primary" >Save & Close</Button>
//     //   </Box>
//     // </Box>
//     <>
//       <Grid container spacing={1}>
//         <Grid item xs={12} md={6}>

//           <Box
//             sx={{
//               padding: "1.5rem",
//               borderRight: "1px solid #e0e0e0",
//               // borderRadius: "8px",
//               // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//               backgroundColor: "#ffffff",
//               marginBottom: "2rem",
//               overflowY: "auto",
//               height: "100%",
//               maxWidth: "100%",
//             }}
//           >
//           <MUISubHeader text="Event Details"/>
//             <Grid display="flex" flexDirection={"column"} gap={1}>
//               <Grid item sm={12} md={9}>
//                 <StyledTextField
//                   label="Name"
//                   placeholder="Event Name"
//                   value={eventName}
//                   setValue={setEventName}
//                   fullWidth
//                 />
//               </Grid>

//               {/* <Grid item sm={12} md={9}>
//                   <Grid mb={2}>
//                     <MUISubHeader text={`/${eventUrl}`} textSize={12} />
//                   </Grid>
//                   <StyledTextField
//                     label="Url"
//                     placeholder="Event Url"
//                     value={eventUrl}
//                     setValue={setEventUrl}
//                     fullWidth
//                   />
//                 </Grid> */}

//               <Grid item sm={12} md={9} mt={2}>
//                 <CustomSelect
//                   label="Duration"
//                   handleDropdownChange={handleSelectChange}
//                   selectedValue={eventDurationId}
//                   options={options}
//                   width={"100%"}
//                   height={50}
//                   fullWidth={true}
//                 />
//               </Grid>
//               <MUISubHeader text="Location" textSize={17} />
//               {!location && (
//                 <MeetingOptions
//                   handlephoneMOpen={handlephoneMOpen}
//                   handleLocation={handleLocation}
//                   handleinpersonMOpen={handleinpersonMOpen}
//                   locationSeleted={location}
//                 />
//               )}
//               {location && (
//                 <Grid sm={12} md={9}>
//                   {/* <InputText value={location} editable={false} fullWidth/> */}
//                   <TextField
//                     value={location}
//                     id="standard-search"
//                     label="Location"
//                     type="search"
//                     fullWidth
//                     onChange={(e) => setLocation(e.target.value)}
//                     onTouchCancel={() => setShowLocationError("")}
//                   />
//                   {showlocationError && (
//                     <>
//                       <TextOnly
//                         text={showlocationErrorMsg}
//                         color={theme.palette.common.errorColor}
//                         fontWeight={400}
//                       />
//                       <Typography
//                         variant="body1"
//                         component="a"
//                         href={urlConnectApp}
//                         target="_blank"
//                         sx={{
//                           cursor: "pointer",
//                           textDecoration: "none",
//                           fontFamily: theme.palette.common.font,
//                           fontWeight: 500,
//                           fontSize: 14,
//                           letterSpacing: 0.2,
//                           color: "rgb(0,105,255)",
//                         }}
//                         // onClick={(e) => {
//                         //   e.preventDefault(); // Prevent opening the link on click
//                         //   handleCopy();
//                         // }}
//                       >
//                         {"Connect"}
//                       </Typography>
//                     </>
//                   )}
//                 </Grid>
//               )}
//             </Grid>
//           </Box>
//         </Grid>
//         <Grid item  xs={12} md={6}>
//           <MUISubHeader text="Event"/>
//           <Box
//             sx={{
//               width: "100%",
//               padding: "1.5rem",
//               // border: "1px solid #e0e0e0",
//               borderRadius: "8px",
//               // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//               backgroundColor: "#ffffff",
//               marginBottom: "2rem",
//               overflowY: "auto",
//               height: "100%",
//               maxWidth: "88%",
//               display: "flex",
//               flexDirection: "column",
//               justifyContent: "space-between",
//             }}
//           >
//             <Grid display="flex" flexDirection="column">
//               {eventName && <MUISubHeader text={eventName} textSize={20} />}
//               {!eventName && <MUISubHeader text={"Your Event Name "} />}
//               <Grid
//                 item
//                 sm={12}
//                 container
//                 alignItems="center"
//                 spacing={1}
//                 mt={1}
//               >
//                 <Grid item>
//                   <AccessTimeIcon sx={{ fontSize: 15 }} />
//                 </Grid>
//                 <Grid item>
//                   {!selectedDuration && (
//                     <TextOnly text={"Duration"} fontSize={15} />
//                   )}
//                   <TextOnly text={selectedDuration} fontSize={15} />
//                 </Grid>
//               </Grid>
//               <Grid item sm={12} container alignItems="center" spacing={1}>
//                 <Grid item>
//                   <LocationOnIcon sx={{ fontSize: 15 }} />
//                 </Grid>
//                 <Grid item>
//                   {!location && (
//                     <TextOnly text={"Meeting Medium"} fontSize={15} />
//                   )}
//                   {location && (
//                     <TextOnly
//                       text={
//                         location + ": Meeting Details Provided upon Booking"
//                       }
//                       fontSize={15}
//                     />
//                   )}
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Box>
//         </Grid>
//       </Grid>
//     </>
//   );
// };

// export default EventSidePanel;

// // import React, { useState } from "react";
// // import {
// //   Drawer,
// //   List,
// //   ListItem,
// //   ListItemIcon,
// //   ListItemText,
// //   IconButton,
// //   useTheme,
// //   Box,
// //   Slide,
// // } from "@mui/material";
// // import {
// //   Dashboard as DashboardIcon,
// //   CalendarToday as CalendarTodayIcon,
// //   BarChart as BarChartIcon,
// //   Warning as WarningIcon,
// //   EditCalendar as EditCalendarIcon,
// //   Menu as MenuIcon,
// //   ArrowBack as ArrowBackIcon,
// // } from "@mui/icons-material";
// // import { styled } from "@mui/system";
// // import Booking from "./Booking";
// // import Scheduling from "./Scheduling";
// // import Alerts from "./Alerts";
// // import Participants from "./Participants";
// // import EventDetails from "./EventDetails";

// // const drawerWidth = 375;

// // const CustomDrawer = styled(Drawer)(({ theme }) => ({
// //   [theme.breakpoints.down("sm")]: {
// //     display: "none",
// //   },
// // }));

// // const CustomListItem = styled(ListItem)(({ theme }) => ({
// //   "&.active": {
// //     background: "linear-gradient(90deg, #6dd5fa, #2980b9)",
// //     color: theme.palette.common.white,
// //     transition: "background 1.5s ease",
// //     "& .MuiListItemIcon-root": {
// //       color: theme.palette.common.white,
// //     },
// //     "& .MuiListItemText-root": {
// //       color: theme.palette.common.white,
// //     },
// //   },
// //   "&:hover": {
// //     background: "linear-gradient(90deg, #21618c, #5499c7)",
// //     color: theme.palette.common.white,
// //     transition: "background 1.5s ease",
// //     "& .MuiListItemIcon-root": {
// //       color: theme.palette.common.white,
// //     },
// //     "& .MuiListItemText-root": {
// //       color: theme.palette.common.white,
// //     },
// //   },
// // }));

// // const EventSidePanel = () => {
// //   const theme = useTheme();
// //   const [mobileOpen, setMobileOpen] = useState(false);
// //   const [drawerOpen, setDrawerOpen] = useState(false);
// //   const [activeItem, setActiveItem] = useState(null);

// //   const handleDrawerToggle = () => {
// //     setMobileOpen(!mobileOpen);
// //   };

// //   const handleItemClick = (item) => {
// //     setActiveItem(item);
// //     setDrawerOpen(true);
// //   };

// //   const handleBackClick = () => {
// //     setDrawerOpen(false);
// //   };

// //   const drawer = (
// //     <List>
// //       <CustomListItem button onClick={() => handleItemClick("Event Details")}>
// //         <ListItemIcon>
// //           <DashboardIcon />
// //         </ListItemIcon>
// //         <ListItemText primary="Event Details" />
// //       </CustomListItem>

// //       <CustomListItem button onClick={() => handleItemClick("Participants")}>
// //         <ListItemIcon>
// //           <CalendarTodayIcon />
// //         </ListItemIcon>
// //         <ListItemText primary="Participants" />
// //       </CustomListItem>

// //       <CustomListItem button onClick={() => handleItemClick("Scheduling")}>
// //         <ListItemIcon>
// //           <BarChartIcon />
// //         </ListItemIcon>
// //         <ListItemText primary="Scheduling" />
// //       </CustomListItem>

// //       <CustomListItem button onClick={() => handleItemClick("Alerts")}>
// //         <ListItemIcon>
// //           <WarningIcon />
// //         </ListItemIcon>
// //         <ListItemText primary="Alerts" />
// //       </CustomListItem>

// //       <CustomListItem button onClick={() => handleItemClick("Booking")}>
// //         <ListItemIcon>
// //           <EditCalendarIcon />
// //         </ListItemIcon>
// //         <ListItemText primary="Booking" />
// //       </CustomListItem>
// //     </List>
// //   );
// //   const Panel = () => {
// //     switch (activeItem) {
// //       case "Event Details":
// //         return <EventDetails />;
// //       case "Participants":
// //         return <Participants />;
// //       case "Scheduling":
// //         return <Scheduling />;
// //       case "Alerts":
// //         return <Alerts />;
// //       case "Booking":
// //         return <Booking />;

// //       default:
// //         return null;
// //     }
// //   };

// //   return (
// //     <div style={{ display: "flex", height: "100vh", position: "relative" }}>
// //       <IconButton
// //         color="inherit"
// //         aria-label="open drawer"
// //         edge="start"
// //         onClick={handleDrawerToggle}
// //         style={{ display: mobileOpen ? "none" : "block" }}
// //       >
// //         <MenuIcon />
// //       </IconButton>
// //       <CustomDrawer
// //         variant="permanent"
// //         sx={{
// //           width: drawerWidth,
// //           flexShrink: 0,
// //           [`& .MuiDrawer-paper`]: {
// //             width: drawerWidth,
// //             boxSizing: "border-box",
// //             background: "linear-gradient(90deg, #1a237e, #3949ab)",
// //             height: "100vh",
// //           },
// //         }}
// //       >
// //         {drawer}
// //       </CustomDrawer>
// //       <Drawer
// //         variant="temporary"
// //         open={mobileOpen}
// //         onClose={handleDrawerToggle}
// //         ModalProps={{
// //           keepMounted: true,
// //         }}
// //         sx={{
// //           display: { xs: "block", sm: "none" },
// //           [`& .MuiDrawer-paper`]: {
// //             boxSizing: "border-box",
// //             width: drawerWidth,
// //             background: "linear-gradient(90deg, #1a237e, #3949ab)",
// //             height: "100vh",
// //           },
// //         }}
// //       >
// //         {drawer}
// //       </Drawer>
// //       <Slide direction="right" in={drawerOpen} mountOnEnter unmountOnExit>
// //         <Box
// //           sx={{
// //             position: "absolute",
// //             top: 0,
// //             left: 0, // Make the slider start from the left (overlapping the side panel)
// //             width: drawerWidth,
// //             backgroundColor: theme.palette.common.white,
// //             padding: theme.spacing(2),
// //             boxShadow: theme.shadows[4],
// //             zIndex: theme.zIndex.drawer + 1, // Ensure the slider appears above the side panel
// //             height: "100vh",
// //             overflowY: "auto",
// //           }}
// //         >
// //           <IconButton onClick={handleBackClick}>
// //             <ArrowBackIcon />
// //           </IconButton>
// //           {/* Render your detailed content here based on `activeItem` */}
// //           {/* <div>
// //             <h2>{activeItem}</h2>
// //             <p>
// //               Detailed content for {activeItem} goes here. You can customize
// //               this part as needed.
// //             </p>
// //           </div> */}
// //           <Panel/>
// //         </Box>
// //       </Slide>
// //     </div>
// //   );
// // };

// // export default EventSidePanel;
