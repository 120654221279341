import React, { useEffect, useState, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { GoogleCalendarService } from "../services/CalendarService";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../features/user/userSlice";
import MuiHeader from "../components/UIComponents/MuiHeader";
import { Box, Modal, Typography, Paper, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";
import { withLoader } from "../components/Loader/withLoader";
const CalendarContainer = styled(Box)({
  marginTop: 5,
  marginRight: 10,
  fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
  '& .fc-header-toolbar': {
    backgroundColor: '#3949ab', // Blue background for headers
    color: '#ffffff', // White text color for headers
    borderRadius: '8px', // Rounded corners for the header toolbar
    padding: '10px', // Padding around the header toolbar
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow for header toolbar
    '& .fc-toolbar-title': {
      color: '#ffffff', // White text color for title
      fontSize: '1.5rem', // Larger font size for the title
      fontWeight: 'bold', // Bold font weight for the title
      textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)', // Subtle text shadow for title
    },
    '& .fc-button': {
      backgroundColor: '#3949ab', // Blue background for buttons
      borderColor: '#3949ab', // Blue border for buttons
      color: '#ffffff', // White text color for buttons
      borderRadius: '4px', // Rounded corners for buttons
      padding: '5px 10px', // Padding inside buttons
      margin: '0 5px', // Margin between buttons
      transition: 'background-color 0.3s, transform 0.3s', // Smooth transition for hover effects
      '&:hover': {
        backgroundColor: '#303f9f', // Darker blue on hover
        borderColor: '#303f9f', // Darker blue border on hover
        transform: 'scale(1.05)', // Slightly enlarge button on hover
      },
    },
    '& .fc-button-primary': {
      backgroundColor: '#3949ab', // Blue background for primary buttons
      borderColor: '#3949ab', // Blue border for primary buttons
      color: '#ffffff', // White text color for primary buttons
      borderRadius: '4px', // Rounded corners for primary buttons
      padding: '5px 10px', // Padding inside primary buttons
      margin: '0 5px', // Margin between primary buttons
      transition: 'background-color 0.3s, transform 0.3s', // Smooth transition for hover effects
      '&:hover': {
        backgroundColor: '#303f9f', // Darker blue on hover
        borderColor: '#303f9f', // Darker blue border on hover
        transform: 'scale(1.05)', // Slightly enlarge button on hover
      },
    },
  },
  '& .fc-view-harness': {
    color: '#808080', // Gray text color for events
    '& .fc-event': {
      backgroundColor: '#e0f7fa', // Light cyan background for events
      color: '#808080', // Dark cyan text color for events
      borderRadius: '4px', // Rounded corners for events
      padding: '5px', // Padding inside events
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle shadow for events
      transition: 'background-color 0.1s, transform 0.1s', // Smooth transition for hover effects
      '&:hover': {
        backgroundColor: '#b2ebf2', // Slightly darker cyan on hover
        transform: 'scale(1)', // Slightly enlarge event on hover
      },
    },
    '& .fc-event-selected': {
      borderRadius: '0px', // No rounded corners for selected events
      backgroundColor: '#b2ebf2', // Slightly darker cyan for selected events
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Slightly darker shadow for selected events
    },
  },
  '& .fc-daygrid-event-dot': {
    backgroundColor: '#3949ab', // Blue background for event dots
    borderColor: '#3949ab', // Blue border for event dots
  },
  '& .fc-today': {
    backgroundColor: '#e3f2fd', // Light blue background for today's date
  },
});



const ClientCalendar = () => {
  const user = useSelector(selectUser);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [open, setOpen] = useState(false);
  const calendarRef = useRef(null);
  const dispatch = useDispatch();

  const handleOpen = (event) => {
    setSelectedEvent(event);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchRes = async () => {
      const calendarApi = calendarRef.current.getApi();
      const view = calendarApi.view;
      const start = view.activeStart;
      const end = view.activeEnd;
      var res = await withLoader(dispatch, async () =>
        GoogleCalendarService.fetchEvents(user.uid, start, end)
      );
      setEvents(res);
    };
    fetchRes();
  }, [user.uid]);

  const handleDatesSet = async (dateInfo) => {
    const start = dateInfo.start;
    const end = dateInfo.end;
    var res = await withLoader(dispatch, async () =>
      GoogleCalendarService.fetchEvents(user.uid, start, end)
    );
    console.log("==========Response===========", res);
    return res;
  };

  const renderEventContent = (eventInfo) => {
    return (
      <div onClick={() => handleOpen(eventInfo.event)}>
        <b>{eventInfo.timeText}</b>
      </div>
    );
  };

  return (
    <>
      {/* <MuiHeader text={"Calendar"} /> */}
      <CalendarContainer>
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin]}
          initialView="dayGridMonth"
          headerToolbar={{
            left: "prev,next,today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          events={events}
          eventContent={renderEventContent} // Custom rendering function
          eventTimeFormat={{
            hour: "2-digit",
            minute: "2-digit",
            meridiem: false,
          }}
          datesSet={handleDatesSet} // Called when the date range changes
        />
      </CalendarContainer>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="event-modal-title"
        aria-describedby="event-modal-description"
      >
        <Paper style={{ margin: 'auto', marginTop: '15%', padding: '20px', maxWidth: '500px' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography id="event-modal-title" variant="h6">
              {selectedEvent?.title}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography id="event-modal-description">
            {selectedEvent?.extendedProps?.description}
          </Typography>
          <Typography>
            Start: {new Date(selectedEvent?.start).toLocaleString()}
          </Typography>
          <Typography>
            End: {new Date(selectedEvent?.end).toLocaleString()}
          </Typography>
          {selectedEvent?.url && (
            <Typography>
              <a href={selectedEvent.url} target="_blank" rel="noopener noreferrer">
                Join Meeting
              </a>
            </Typography>
          )}
        </Paper>
      </Modal>
    </>
  );
};

export default ClientCalendar;

// import React, { useEffect, useState, useRef } from "react";
// import FullCalendar from "@fullcalendar/react";
// import dayGridPlugin from "@fullcalendar/daygrid";
// import timeGridPlugin from "@fullcalendar/timegrid";
// import { GoogleCalendarService } from "../services/CalendarService";
// import { useDispatch, useSelector } from "react-redux";
// import { selectUser } from "../features/user/userSlice";
// import MuiHeader from "../components/UIComponents/MuiHeader";
// import { Box } from "@mui/material";
// import { styled } from "@mui/system";
// import { withLoader } from "../components/Loader/withLoader";
// const CalendarContainer = styled(Box)({
//   marginTop:5
//   // margin: '1rem',
//   // padding: '1rem',
//   // borderRadius: '8px',
//   // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//   // backgroundColor: '#ffffff',
//   // height: '80vh',
//   // '& .fc-button': {
//   //   backgroundColor: '#3f51b5', // Secondary color of the button
//   //   color: '#ffffff',
//   //   '&:hover': {
//   //     backgroundColor: '#303f9f', // Darker shade on hover
//   //   },
//   // },
//   // '& .fc-button-primary': {
//   //   backgroundColor: '#3f51b5', // Primary button color
//   //   color: '#ffffff',
//   //   '&:hover': {
//   //     backgroundColor: '#303f9f', // Darker shade on hover
//   //   },
//   // },
//   // '& .fc-button-primary:not(.fc-state-active)': {
//   //   backgroundColor: '#3f51b5', // Primary button color
//   //   color: '#ffffff',
//   // },
// });

// const ClientCalendar = () => {
//   const user = useSelector(selectUser);
//   const [events, setEvents] = useState([]);
//   const calendarRef = useRef(null);
//   const dispatch = useDispatch();

//   useEffect(() => {
//     const fetchRes = async () => {
//       // if (calendarRef.current) {
//       const calendarApi = calendarRef.current.getApi();
//       const view = calendarApi.view;
//       const start = view.activeStart;
//       const end = view.activeEnd;
//       var res = await withLoader(dispatch, async () =>
//         GoogleCalendarService.fetchEvents(user.uid, start, end)
//       );
//       setEvents(res);
//       // }
//     };
//     fetchRes();
//   }, [user.uid]);


//   const handleDatesSet = async (dateInfo) => {
//     const start = dateInfo.start;
//     const end = dateInfo.end;
//     var res = await withLoader(dispatch, async () =>
//       GoogleCalendarService.fetchEvents(user.uid, start, end)
//     );
//     console.log("==========Response===========", res);

//     // if(res.length > 0){
//     //   setEvents(res);
//     // }
//     return res;
//   };

//   // const getEvents = async (dateInfo) => {
//   //   const start = dateInfo.start;
//   //   const end = dateInfo.end;
//   //   var res = await withLoader(dispatch,async () => GoogleCalendarService.fetchEvents(user.uid,start, end));
//   //   console.log('==========Response===========',res)

//   //   // if(res.length > 0){
//   //   //   setEvents(res);
//   //   // }
//   //   return res;
//   // };

//   const renderEventContent = (eventInfo) => {
//     console.log('**************************',eventInfo)
//     return (
//       <>
//         <a href={eventInfo.event.url} target="_blank" rel="noopener noreferrer">
//           <b> {eventInfo.event.title} - {eventInfo.timeText} </b>          
//         </a>
//         {/* <p>{eventInfo.event.extendedProps.description}</p>
//         {eventInfo.event.url && (
//           <a href={eventInfo.event.url} target="_blank" rel="noopener noreferrer">Join Meeting</a>
//         )} */}
//       </>
//     );
//   };

//   return (
//     <>
//       <MuiHeader text={"Calendar"} />
//       <CalendarContainer>
//         <FullCalendar
//           ref={calendarRef}
//           plugins={[dayGridPlugin, timeGridPlugin]}
//           initialView="dayGridMonth"
//           headerToolbar={{
//             left: "prev,next,today",
//             center: "title",
//             right: "dayGridMonth,timeGridWeek,timeGridDay",
//           }}
//           events={events}
//           // events={[
//           //   {
//           //     title: 'Test Event',
//           //     start: '2024-07-29T01:55:25',
//           //     end: '2024-07-29T02:55:25',
//           //     url: null,
//           //     description: 'This is a test event'
//           //   }
//           // ]}
//           eventContent={renderEventContent} // Custom rendering function
//           eventTimeFormat={{
//             hour: "2-digit",
//             minute: "2-digit",
//             meridiem: false,
//           }}
//           // viewDidMount={handleViewDidMount} // Called when the view is initialized
//           // datesSet={handleDatesSet} // Called when the date range changes
//         />
//       </CalendarContainer>
//     </>
//   );
// };

// export default ClientCalendar;

// import React, { useEffect, useState } from 'react';
// import FullCalendar from '@fullcalendar/react';
// import dayGridPlugin from '@fullcalendar/daygrid';
// import timeGridPlugin from '@fullcalendar/timegrid';
// import { GoogleCalendarService } from '../services/CalendarService';
// import { useSelector } from 'react-redux';
// import { selectUser } from '../features/user/userSlice';
// import MuiHeader from '../components/UIComponents/MuiHeader';
// import { Box, Grid } from '@mui/material';
// import { styled } from '@mui/system';

// const CalendarContainer = styled(Box)({
//   margin: '1rem',
//   padding: '1rem',
//   borderRadius: '8px',
//   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//   backgroundColor: '#ffffff',
//   height: '80vh',
//   '& .fc-button': {
//     backgroundColor: '#3f51b5', // Secondary color of the button
//     color: '#ffffff',
//     '&:hover': {
//       backgroundColor: '#303f9f', // Darker shade on hover
//     },
//   },
//   '& .fc-button-primary': {
//     backgroundColor: '#3f51b5', // Primary button color
//     color: '#ffffff',
//     '&:hover': {
//       backgroundColor: '#303f9f', // Darker shade on hover
//     },
//   },
//   '& .fc-button-primary:not(.fc-state-active)': {
//     backgroundColor: '#3f51b5', // Primary button color
//     color: '#ffffff',
//   },
// });

// const ClientCalendar = () => {
//   const user = useSelector(selectUser);
//   const [events, setEvents] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       if (user.uid) {
//         try {
//           const res = await GoogleCalendarService.fetchEvents(user.uid);
//           console.log('Fetched events:', res);
//           if (res && res.Successfull) {
//             setEvents(res.Successfull);  // Set events to the Successfull array
//           }
//         } catch (error) {
//           console.error('Error fetching events:', error);
//         }
//       }
//     };
//     fetchData();
//   }, [user.uid]);

//   const renderEventContent = (eventInfo) => {
//     return (
//       <>
//         <b>{eventInfo.timeText}</b>
//         <i>{eventInfo.event.title}</i>
//         <p>{eventInfo.event.extendedProps.description}</p>
//         <a href={eventInfo.event.url} target="_blank" rel="noopener noreferrer">Join Meeting</a>
//       </>
//     );
//   };

//   return (
//     <>
//       <MuiHeader text={"Calendar"} />
//       <CalendarContainer>
//         <FullCalendar
//           plugins={[dayGridPlugin, timeGridPlugin]}
//           initialView="dayGridMonth"
//           headerToolbar={{
//             left: 'prev,next today',
//             center: 'title',
//             right: 'dayGridMonth,timeGridWeek,timeGridDay'
//           }}
//           events={events}
//           eventContent={renderEventContent} // Custom rendering function
//           eventTimeFormat={{
//             hour: '2-digit',
//             minute: '2-digit',
//             meridiem: false
//           }}
//         />
//       </CalendarContainer>
//     </>
//   );
// };

// export default ClientCalendar;
