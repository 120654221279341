import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardActionArea,
} from "@mui/material";
// import GoogleIcon from "@mui/icons-material/Google";
// import MicrosoftIcon from "@mui/icons-material/Microsoft";
// import ExchangeIcon from "@mui/icons-material/Business";
// import OutlookIcon from "@mui/icons-material/Email";
// import ICloudIcon from "@mui/icons-material/Cloud";
// import { CalendarIcon } from "@mui/x-date-pickers";
import { InfoWrapper } from "../utils/InfoWrapper";
import { CalendarDataService } from "../services/CalendarService";
import withLoader from "../components/Loader/withLoader";
import { useDispatch } from "react-redux";
import ResponsiveTabs from "../components/UIComponents/ResponsiveTabs";
import IntegrationService from "../services/IntegrationService";
import { useNavigate } from "react-router-dom";

// const SyncCard = ({ icon, title, description, color, onClick }) => (
//   <Card
//     elevation={4}
//     sx={{
//       // borderRadius: "8px",
//       boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
//       // transition: "transform 0.2s ease",
//       width: "190px",
//       height: "190px",
//       display: "flex",
//       flexDirection: "column",
//       alignItems: "center",
//       justifyContent: "center",
//       margin: 1,
//       "&:hover": {
//         // transform: "scale(1)",
//         backgroundColor: "#ffffff",
//         boxShadow: "0 2px 30px rgba(0, 0, 0, 0.1)",
//         borderColor: "#e0e0e0", // Very light border color
//         borderWidth: "0.1rem", // Minimal border width
//         borderStyle: "solid", // Solid border style
//         border: "0.1rem solid #e0e0e0", // Combine all properties into one line
//       },
//     }}
//   >
//     <CardActionArea
//       onClick={onClick}
//       sx={{
//         "&:hover": {
//           background: "#ffffff", //"linear-gradient(90deg, #6dd5fa, #2980b9)",
//           // transform: "scale(1.1)",
//           // textShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
//         },
//       }}
//     >
//       <CardContent sx={{ textAlign: "center" }}>
//         {/* <Icon style={{ color, fontSize: 48 }} /> */}
//         <img src={icon} />
//         <Typography variant="h6" component="div">
//           {title}
//         </Typography>
//         <Typography variant="body2" color="textSecondary">
//           {description}
//         </Typography>
//       </CardContent>
//     </CardActionArea>
//   </Card>
// );
const SyncCard = ({ icon, title, description, color, onClick }) => (
  <Card
    elevation={6}
    sx={{
      borderRadius: "12px",
      width: "200px",
      height: "220px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      margin: 2,
      background: "linear-gradient(135deg, #f7f7f7, #ffffff)",
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
      boxShadow: "0 6px 24px rgba(0, 0, 0, 0.15)",
      "&:hover": {
        transform: "translateY(-5px)",
        backgroundColor: "#ffffff",
        boxShadow: "0 8px 32px rgba(0, 0, 0, 0.2)",
        borderColor: "#d1d1d1",
        borderStyle: "solid",
        borderWidth: "1px",
      },
    }}
  >
    <CardActionArea
      onClick={onClick}
      sx={{
        borderRadius: "inherit",
        padding: "10px",
        "&:hover": {
          backgroundColor: "#ffffff",
        },
      }}
    >
      <CardContent sx={{ textAlign: "center" }}>
        <img src={icon} alt={`${title} icon`} style={{ height: 60, marginBottom: "16px" }} />
        <Typography
          variant="h6"
          component="div"
          sx={{
            fontWeight: "bold",
            fontSize: "1.1rem",
            color: color,
            marginBottom: "8px",
            textTransform: "capitalize",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            fontSize: "0.9rem",
            color: "#6c757d",
            lineHeight: "1.4",
          }}
        >
          {description}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
);

const SyncApps = () => {
  const dispatch = useDispatch();
  const [cal, setCal] = useState();
  const [tabs, setTabs] = useState([]);
  const [integrations, setIntegrations] = useState();
  const [filterdIntegration, setFilteredIntegrations] = useState([]);
  const { user } = InfoWrapper();
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);

    setFilteredIntegrations(
      integrations.filter(
        (x) => x.TabName === tabs.filter((x) => x.Id === newValue)[0]?.TabName
      )
    );
  };
  const navigate = useNavigate();
  const handleClick = (name) => {
    console.log(`Icon Name: ${name}`);
    switch (name.toLowerCase()) {
      case "zoom":
        navigate("/Integrations/Zoom/Connect");
        break;
      case "google calendar": 
        navigate("/Integrations/Google/Connect");
        break;
        case "google meet": 
        navigate("/Integrations/Google/Connect");
        break;
      case "exchange calendar":
        navigate("/Integrations/Exchange/Connect");
        break;
      case "outlook calendar":
        navigate("/Integrations/Outlook/Connect");
        break;
        case "microsoft teams":
        navigate("/Integrations/Outlook/Connect");
        break;
      case "icloud":
        navigate("/Integrations/ICloud/Connect");
        break;
      default:
        console.warn("No matching case for:", name);
    }    
    // Add your sync logic here if needed
  };

  useEffect(() => {
    const GetSyncedCalendars = async () => {
      let res = await withLoader(dispatch, () =>
        CalendarDataService.GetSyncedCalendars(user.uid)
      );
      setCal(res);
    };

    GetSyncedCalendars();
  }, []);

  useEffect(() => {
    console.log("===T===", tabs);
    console.log("===INT===", integrations);
  });

  useEffect(() => {
    if (!user || !user.uid) return;
    const getintegration = async () => {
      let res = await withLoader(dispatch, () =>
        IntegrationService.Get(user.uid)
      );
      if (res.Tabs) {
        setTabs(res.Tabs);
        setIntegrations(res.Integrations);
      }
    };
    getintegration();
  }, []);

  // const getIconComponent = (provider) => {
  //   switch (provider.toLowerCase()) {
  //     case "google":
  //       return GoogleIcon;
  //     case "microsoft":
  //       return MicrosoftIcon;
  //     case "exchange":
  //       return ExchangeIcon;
  //     case "outlook":
  //       return OutlookIcon;
  //     case "icloud":
  //       return ICloudIcon;
  //     default:
  //       return CalendarIcon; // A default icon in case provider is unknown
  //   }
  // };
  // const handleRemoveCalendar = async (calendarId) => {
  //   try {
  //     await withLoader(dispatch, () =>
  //       CalendarDataService.RemoveSyncedCalendar(user.uid, calendarId)
  //     );
  //     setCal(cal.filter((calendar) => calendar.id !== calendarId)); // Update the state after removal
  //   } catch (error) {
  //     console.error("Failed to remove calendar", error);
  //   }
  // };

  const IconForCard = (val) => {
    switch (val.toLowerCase()) {
      case "API and webhooks":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      case "SyncLead Android app":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      case "SyncLead for Chrome":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      case "SyncLead for Edge":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      case "SyncLead for Firefox":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      case "SyncLead for Intercom":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      case "SyncLead for Outlook":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      case "SyncLead iPhone app":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      case "zoom":
        return "https://img.icons8.com/?size=100&id=7csVZvHoQrLW&format=png&color=000000"; // Replace with actual icon
      case "google meet":
        return "https://img.icons8.com/?size=100&id=pE97I4t7Il9M&format=png&color=000000"; // Replace with actual icon
      case "microsoft teams":
        return "https://img.icons8.com/?size=100&id=68803&format=png&color=000000"; // Replace with actual icon
      case "webex":
        return "https://img.icons8.com/?size=100&id=995Ah2Szp29l&format=png&color=000000"; // Replace with actual icon
      case "google calendar":
        return "https://img.icons8.com/?size=100&id=WKF3bm1munsk&format=png&color=000000"; // Replace with actual icon
      case "exchange calendar":
        return "https://img.icons8.com/?size=100&id=13676&format=png&color=000000"; // Replace with actual icon
      case "outlook calendar":
        return "https://img.icons8.com/?size=100&id=WnHyYA2ecNqL&format=png&color=000000"; // Replace with actual icon
      case "icloud calendar":
        return "https://img.icons8.com/?size=100&id=3WEIrmIQdAvs&format=png&color=000000"; // Replace with actual icon
      case "HubSpot":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      case "Salesforce":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      case "Microsoft Dynamics 365":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      case "Mailchimp":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      case "Marketo":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      case "Pardot":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      case "Gmail for Workflows":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      case "Outlook for Workflows":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      case "Google Analytics":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      case "Meta Pixel":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      case "Squarespace":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      case "Wix":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      case "WordPress":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      case "Stripe":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      case "PayPal":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      case "Duo":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      case "Okta":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      case "OneLogin":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      case "Ping Identity":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      case "Microsoft Azure":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      case "Zapier":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      case "Power Automate":
        return "https://www.flaticon.com/free-icons/microsoft"; // Replace with actual icon
      default:
        return null;
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={2}
          sx={{
            borderRight: "1px solid #ddd", // Optional: For visual separation
            height: "100vh", // Ensure full height for tabs
            overflowY: "auto", // Allow scrolling for long tab lists
          }}
        >
          {tabs.length > 0 && (
            <ResponsiveTabs
              tabs={tabs}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              handleTabChange={handleTabChange}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start", // Align cards to the left
          }}
        >
          {integrations &&
            integrations
              .filter((x) => x.TabId === selectedTab)
              .map((key, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={index}
                  sx={{ padding: 2 }}
                >
                  <SyncCard
                    icon={IconForCard(key.Name)}
                    title={key.Name}
                    description={key.Description}
                    color="#4285F4"
                    onClick={() => handleClick(key.Name)}
                  />
                </Grid>
              ))}
        </Grid>
      </Grid>
    </>
  );
};

export default SyncApps;
