import React, { useEffect, useState } from "react";
import StyledTextField from "../UIComponents/StyledTextField";
import {
  Grid,
  Box,
  FormControl,
  IconButton,
  Avatar,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import InputFileUpload from "../UIComponents/InputFileUpload";
import MuiText from "../UIComponents/MuiText";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import DeleteIcon from "@mui/icons-material/Delete";
import { TeamsService } from "../../services/TeamsService";
import { InfoWrapper } from "../../utils/InfoWrapper";
import UserListWithSearch from "../Team/UserListWithSearch";

const ModalEditTeams = ({ data }) => {
  const { user } = InfoWrapper();
  const [spacetype, setSpacetype] = useState();
  const [teamId, setTeamId] = useState();
  const [teamName, setTeamName] = useState();
  const [teamUrl, setTeamUrl] = useState();
  const [avatar, setAvatar] = useState(null);
  const [uploadedfile, setUploadedFile] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let res = await TeamsService.getTeamsbyTeamId(data);
      if (res.Info) {
        let result = res.Info[0];
        setTeamId(result.TeamId);
        setTeamName(result.TeamName);
        setTeamUrl(result.TeamUrl);
        setAvatar(result.AvatarUrl);
        setTeamMembers(parseParticipants(result.Participants));
      }
    };
    fetchData();
  }, [data]);

  // Parse Participants into usable data
  const parseParticipants = (participants) => {
    console.log('===== Inside parseparticipant ====',participants);
    if (!participants) return []; // Return empty array if participants string is empty or null

    // Split participants by comma
    return participants.split(",").map((p) => {
      const [id, firstName, lastName, avatarOrLetter,bgColor] = p.split("___");

      // Return an object with parsed user information
      return {
        id,
        firstName,
        lastName,
        avatar: avatarOrLetter.length > 1 ? avatarOrLetter : null, // Avatar if length > 1
        letter: avatarOrLetter.length === 1 ? avatarOrLetter : null, // Initial letter if single character
        bgcolor:bgColor
      };
    });
  };

  const handleDeleteMember = (memberId) => {
    setSelectedMembers(selectedMembers.filter((m) => m.id !== memberId));
  };

  return (
    <FormControl required>
      <Box>
        <Grid container display="flex" mt={2} spacing={2}>
          {/* First Box: Team Name, Team URL, Avatar */}
          <Grid container item xs={12} md={6} spacing={2}>
            <Grid item xs={12}>
              <StyledTextField
                fullWidth
                label={"Team Name"}
                required
                value={teamName}
                setValue={setTeamName}
              />
            </Grid>
            <Grid item xs={12}>
              <MuiText text={user.userName + "/"} fontSize={12} mt={0} ml={0} />
              <StyledTextField
                fullWidth
                label={"Team URL"}
                required
                value={teamUrl}
                setValue={setTeamUrl}
              />
            </Grid>
            <Grid item xs={12}>
              <MuiText
                icon={AddAPhotoIcon}
                text={"Add Avatar"}
                textColor="#808080"
                mt={0}
                ml={0}
              />
              <InputFileUpload setUploadedFile={setUploadedFile} />
            </Grid>
          </Grid>

          {/* Second Box: Add Users */}
          <Grid container item xs={12} md={6} spacing={2}>
            <Grid item xs={12}>
              {teamMembers.length > 0 && <UserListWithSearch
                participants={teamMembers}
                setParticipants={setTeamMembers}
              />}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </FormControl>
  );
};

export default ModalEditTeams;
