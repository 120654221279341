import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { purple } from "@mui/material/colors";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  width:90,
  backgroundColor: purple[500],
  textTransform:"none",
  "&:hover": {
    backgroundColor: purple[700],
  },
}));

export default function BtnCancel({ text = "Cancel", onClick }) {
  return (
      <ColorButton variant="contained" onClick={onClick}>
        {text}
      </ColorButton>
  );
}
