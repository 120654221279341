import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Grid,
  FormGroup,
  Checkbox,
  FormControlLabel,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import MuiHeader from "../components/UIComponents/MuiHeader";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../features/user/userSlice";
import { withLoader } from "../components/Loader/withLoader";
import {
  DateTimePicker,
  TimePicker,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { red } from "@mui/material/colors";
import {
  AvailabilityService,
  UnAvailabilityService,
} from "../services/AvailabilityService";
import ErrorComponent from "../components/Messages/ErrorComponent";
import Alert from "@mui/material/Alert";
import MultiDatePicker from "../components/UIComponents/MultiDatePicker";
import UnavailabilityInfo from "../components/Availability/UnavailabilityInfo";

const StyledContainer = styled(Container)({
  //   marginTop: '2rem',
  //   padding: '2rem',
  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    fontFamily:
      'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"', // Applying font family to the input
    fontWeight: 500,
    font: "0.5rem",
    color: "#808080", // Professional shade of gray for label
  },

  background: "#f9f9f9",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  overflowY: "auto",
  height: "calc(100vh - 4rem)", // Full height minus some margin
  fontFamily:
    'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"', // Applying font family to the container
});
const HeaderContainer = styled(Container)({
  height: "6vh",
  fontFamily:
    'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"', // Applying font family to the container
});

const Availability = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const theme = useTheme();
  const [workingDays, setWorkingDays] = useState([
    { day: "monday", selected: false, startTime: "", endTime: "" },
    { day: "tuesday", selected: false, startTime: "", endTime: "" },
    { day: "wednesday", selected: false, startTime: "", endTime: "" },
    { day: "thursday", selected: false, startTime: "", endTime: "" },
    { day: "friday", selected: false, startTime: "", endTime: "" },
    { day: "saturday", selected: false, startTime: "", endTime: "" },
    { day: "sunday", selected: false, startTime: "", endTime: "" },
  ]);

  const [UnavailabilityStartTime, setUnavailabilityStartTime] = useState(null);
  const [UnavailabilityEndTime, setUnavailabilityEndTime] = useState(null);
  const [leavesStartDate, setLeavesStartDate] = useState(null);
  const [leavesEndDate, setLeavesEndDate] = useState(null);
  const [invalidDay, setInvalidDay] = useState(false);
  const [msgInvalidDateTime, setMsgInvalidDateTime] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [workOffDate, setWorkOffDate] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [leaveData, setLeaveData] = useState([]);
  const [showTime, setShowTIme] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const themeGray = theme.palette.common.grey;

  const handleWorkingDaysChange = (index) => (event) => {
    console.log(workingDays);
    const updatedWorkingDays = [...workingDays];
    updatedWorkingDays[index].selected = event.target.checked;
    setWorkingDays(updatedWorkingDays);
  };
  const showUnavailabilitySection = async (val) => {

    console.log(startTime, endTime);
    if (val === "time") {
      console.log("time");
      await withLoader(dispatch, async () => {
        await UnAvailabilityService.SaveWorkOffTime(
          user.uid,
          workOffDate,
          UnavailabilityStartTime,
          UnavailabilityEndTime
        );
      });
      setShowTIme(false);
      setShowDate(false);
    }
    if (val === "leaves") {
      console.log("leaves");
      await withLoader(dispatch, async () => {
        await UnAvailabilityService.SaveLeaves(
          user.uid,
          selectedDates
        );
      });
      setShowTIme(false);
      setShowDate(false);
    }
    setRefreshGrid(!refreshGrid);
  };

  useEffect(() => {
    console.log("============== Invoked GEt ===============");

    const fetchData = async () => {
      console.log("============== Invoked GET ===============");
      await withLoader(dispatch, async () => {
        await AvailabilityService.Get(user.uid, setWorkingDays);
      });
      await withLoader(dispatch, async () => {
        await UnAvailabilityService.Get(user.uid, setLeaveData);
      });
    };

    fetchData();
    // var res = withLoader(dispatch,() => AvailabilityService.Get(user.uid, setWorkingDays));

    // const hardcodedData = [
    //   { day: "monday", selected: true, startTime: "09:00", endTime: "17:00" },
    //   { day: "tuesday", selected: true, startTime: "09:00", endTime: "17:00" },
    //   { day: "wednesday", selected: false, startTime: "", endTime: "" },
    //   { day: "thursday", selected: true, startTime: "09:00", endTime: "17:00" },
    //   { day: "friday", selected: true, startTime: "09:00", endTime: "17:00" },
    //   { day: "saturday", selected: false, startTime: "", endTime: "" },
    //   { day: "sunday", selected: false, startTime: "", endTime: "" },
    // ];

    // setWorkingDays(hardcodedData);
  }, [refreshGrid]);

  useEffect(() => {
    if (!UnavailabilityStartTime || !UnavailabilityEndTime) {
      setInvalidDay(false);
      setMsgInvalidDateTime("");
    } else {
      const sameDay =
        UnavailabilityStartTime.year() === UnavailabilityEndTime.year() &&
        UnavailabilityStartTime.month() === UnavailabilityEndTime.month() &&
        UnavailabilityStartTime.date() === UnavailabilityEndTime.date();

      const startTimeGreaterThanEndTime = UnavailabilityEndTime.isAfter(
        UnavailabilityStartTime
      );

      if (!sameDay) {
        setInvalidDay(true);
        setMsgInvalidDateTime(
          "Date must be Same.Start Time must be greater than End Time! "
        );
        return;
      } else {
        setInvalidDay(false);
        setMsgInvalidDateTime("");
      }
      if (!startTimeGreaterThanEndTime) {
        setInvalidDay(true);
        setMsgInvalidDateTime("Start Time must be greater than End Time! ");
      } else {
        setInvalidDay(false);
        setMsgInvalidDateTime("");
      }
    }
  }, [UnavailabilityStartTime, UnavailabilityEndTime]);

  const handleUnavailabilityTime = (key, val) => {
    console.log(key, val);

    if (key == "start") {
      setUnavailabilityStartTime(val);
    } else if (key == "end") {
      setUnavailabilityEndTime(val);
    } else if (key === "date") {
      setWorkOffDate(val);
    }
  };

  useEffect(() => {
    //   if (!UnavailabilityStartTime || !UnavailabilityEndTime) {
    //     setInvalidDay(false);
    //     setMsgInvalidDateTime('');
    //   } else {
    //     const sameDay =
    //       UnavailabilityStartTime.year() === UnavailabilityEndTime.year() &&
    //       UnavailabilityStartTime.month() === UnavailabilityEndTime.month() &&
    //       UnavailabilityStartTime.date() === UnavailabilityEndTime.date();
    //     const startTimeGreaterThanEndTime = UnavailabilityStartTime.isAfter(UnavailabilityEndTime);
    //     if (!sameDay) {
    //       setInvalidDay(true);
    //       setMsgInvalidDateTime('Date must be Same.Start Time must be greater than End Time! ');
    //       return;
    //     } else {
    //       setInvalidDay(false);
    //       setMsgInvalidDateTime('');
    //     }
    //     if (!startTimeGreaterThanEndTime) {
    //       setInvalidDay(true);
    //       setMsgInvalidDateTime('Start Time must be greater than End Time! ');
    //     } else {
    //       setInvalidDay(false);
    //       setMsgInvalidDateTime('');
    //     }
    //   }
  }, [leavesStartDate, leavesEndDate]);

  const handleLeaves = (key, val) => {
    console.log(key, val);

    if (key == "start") {
      setLeavesStartDate(val);
    } else if (key == "end") {
      setLeavesEndDate(val);
    }
  };

  const handleWorkingHoursChange = (index, field) => (event) => {
    const updatedWorkingDays = [...workingDays];
    updatedWorkingDays[index][field] = event.target.value;
    setWorkingDays(updatedWorkingDays);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission here
    //AvailabilityService.Save(user.uid, workingDays);
    var res = await withLoader(dispatch, () =>
      AvailabilityService.Save(user.uid, workingDays)
    );
  };

  const handleUaTime = () => {
    setShowTIme(!showTime);
    setShowDate(false);
  };
  const handleUAdate = () => {
    setShowDate(!showDate);
    setShowTIme(false);
  };

  return (
    <>
      <MuiHeader
        text={"Availability"}
        onSave={handleSubmit}
        showSaveButton={true}
      />
      <StyledContainer maxWidth={false}>
        {/* <Typography variant="h8" component="h1" gutterBottom>
            Settings
        </Typography> */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            {/* <Paper elevation={7} sx={{ p: 3, height: '100%' }}> */}
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{
                fontFamily:
                  'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
              }}
            >
              Working Hours
            </Typography>
            <Box
              sx={{
                padding: "1.5rem",
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#ffffff",
                marginBottom: "2rem",
                overflowY: "auto",
                height: "80%",
                maxWidth: "88%",
              }}
            >
              <FormGroup>
                {workingDays.map((day, index) => (
                  <Grid
                    key={day.day}
                    container
                    alignItems="center"
                    spacing={1}
                    sx={{ mt: 2 }}
                  >
                    <Grid item xs={12} md={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={day.selected}
                            onChange={handleWorkingDaysChange(index)}
                          />
                        }
                        label={
                          day.day.charAt(0).toUpperCase() + day.day.slice(1)
                        }
                        sx={{
                          fontSize: theme.palette.font.lightText,
                          fontFamily:
                            'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                          letterSpacing: theme.palette.font.letterSpacing,
                          color: theme.palette.font.appTextColor,
                        }}
                      />
                      {/* <MuiText text={day.day.charAt(0).toUpperCase() + day.day.slice(1)}/> */}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        type="time"
                        label="Start Time"
                        value={day.startTime}
                        onChange={handleWorkingHoursChange(index, "startTime")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={!day.selected}
                        sx={{ color: "#808080" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        type="time"
                        label="End Time"
                        value={day.endTime}
                        onChange={handleWorkingHoursChange(index, "endTime")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={!day.selected}
                      />
                    </Grid>
                  </Grid>
                ))}
              </FormGroup>
            </Box>
            {/* </Paper> */}
          </Grid>
          <Grid item xs={12} md={6}>
            {/* <Paper elevation={7} sx={{ p: 3, height: '100%' }}> */}
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{
                fontFamily:
                  'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
              }}
            >
              Unavailability
            </Typography>
            <Box
              sx={{
                width: "100%",
                padding: "1.5rem",
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#ffffff",
                marginBottom: "2rem",
                overflowY: "auto",
                height: "80%",
                maxWidth: "88%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {/* <MUISubHeader text="Manage Unavailability" /> */}
              {/* {!showTime && !showDate && ( */}
              <>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => handleUaTime()}
                >
                  Add Unavailable Time
                </Button>
                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => handleUAdate()}
                  >
                    Add Unavailability Dates
                  </Button>
                </Box>
                {showTime && (
                  <>
                    {invalidDay && (
                      <Alert severity="error" sx={{ mb: 2 }}>
                        {msgInvalidDateTime}
                      </Alert>
                    )}
                    <Grid item sx={{ mt: 3 }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Grid>
                            <DatePicker
                              label="Work Off Date"
                              value={workOffDate}
                              onChange={(time) =>
                                handleUnavailabilityTime("date", time)
                              }
                              renderInput={(props) => (
                                <TextField {...props} fullWidth />
                              )}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{
                                color: themeGray,
                                "&:hover ": { borderColor: "red" },
                                ".MuiOutlinedInput-input": { color: themeGray },
                              }}
                            />
                          </Grid>
                          <Grid>
                            <TimePicker
                              label="Start Time"
                              value={UnavailabilityStartTime}
                              onChange={(time) =>
                                handleUnavailabilityTime("start", time)
                              }
                              renderInput={(props) => (
                                <TextField {...props} fullWidth />
                              )}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{
                                color: themeGray,
                                "&:hover": { borderColor: "red" },
                                ".MuiOutlinedInput-input": {
                                  color: themeGray,
                                  fontWeight: 500,
                                },
                                mt: 2,
                              }}
                            />
                          </Grid>
                          <Grid>
                            <TimePicker
                              label="End Time"
                              value={UnavailabilityEndTime}
                              onChange={(time) =>
                                handleUnavailabilityTime("end", time)
                              }
                              renderInput={(props) => (
                                <TextField {...props} fullWidth />
                              )}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{
                                color: themeGray,
                                "&:hover": { borderColor: "red" },
                                ".MuiOutlinedInput-input": {
                                  color: themeGray,
                                  fontWeight: 500,
                                },
                                mt: 2,
                              }}
                            />
                          </Grid>
                        </LocalizationProvider>
                      </LocalizationProvider>
                    </Grid>
                  </>
                )}

                {showDate && (
                  <>
                    {invalidDay && (
                      <Alert severity="error" sx={{ mb: 2 }}>
                        {msgInvalidDateTime}
                      </Alert>
                    )}
                    <Grid item xs={12} md={12} sx={{ mt: 3 }}>
                      <MultiDatePicker
                        selectedDates={selectedDates}
                        setSelectedDates={setSelectedDates}
                      />
                    </Grid>
                  </>
                )}
                {!showTime && !showDate && (
                  <>
                    <Grid item xs={12} md={12} sx={{ mt: 3 }}>
                      <UnavailabilityInfo leaveData={leaveData} />
                    </Grid>
                  </>
                )}
                {(showTime || showDate) && (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    value={showTime ? "time" : "leaves"}
                    onClick={(e) => showUnavailabilitySection(e.target.value)}
                    sx={{ mt: "auto" }}
                  >
                    OK
                  </Button>
                )}
              </>
              {/* )} */}
            </Box>
          </Grid>
        </Grid>
      </StyledContainer>
    </>
  );
};

export default Availability;

// <Grid item xs={12} md={12} sx={{ mt: 3 }}>
// <LocalizationProvider dateAdapter={AdapterDayjs}>
//   <DatePicker
//     label="Start Date"
//     value={leavesStartDate}
//     onChange={(time) =>
//       handleLeaves("start", time)
//     }
//     renderInput={(props) => (
//       <TextField {...props} fullWidth />
//     )}
//     InputLabelProps={{
//       shrink: true,
//     }}
//     sx={{
//       color: themeGray,
//       "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
//         {
//           borderColor: "#1890ff",
//         },
//       "& .MuiOutlinedInput-input": { color: themeGray },
//     }}
//   />
// </LocalizationProvider>
// </Grid>
// <Grid item xs={12} md={12} sx={{ mt: 3 }}>
// <LocalizationProvider dateAdapter={AdapterDayjs}>
//   <DatePicker
//     label="End Date"
//     value={leavesEndDate}
//     onChange={(time) =>
//       handleLeaves("end", time)
//     }
//     renderInput={(props) => (
//       <TextField {...props} fullWidth />
//     )}
//     InputLabelProps={{
//       shrink: true,
//     }}
//     sx={{
//       color: themeGray,
//       "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
//         {
//           borderColor: "#1890ff",
//         },
//       "& .MuiOutlinedInput-input": { color: themeGray },
//     }}
//   />
// </LocalizationProvider>
// </Grid>
