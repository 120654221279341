import { createSlice } from '@reduxjs/toolkit';

export const LoaderSlice = createSlice({
  name: 'loader',
  initialState: {
    loader: false,
  },
  reducers: {
      showLoader: (state) => {
        state.loader = true;
    },
    hideLoader: (state) => {
      state.loader = false;
    },
  },
});

export const { showLoader, hideLoader } = LoaderSlice.actions;

// Selectors
export const selectLoader = (state) => state.loader.loader;

export default LoaderSlice.reducer;
