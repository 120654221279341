import React,{useEffect} from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import CryptoJS from 'crypto-js';
import { useDispatch } from 'react-redux';
import { addType } from '../../features/user/calendarTypeSlice';
import { InfoWrapper } from '../../utils/InfoWrapper';
// const {redirectUI} = InfoWrapper();
const redirectUI = process.env.REACT_APP_REDIRECTURI;
const msalConfig = {
  auth: {
    clientId: '8407a354-3e9b-4e65-b78f-ce640700a037', // Replace with your Azure AD application's client ID
    // clientSecret: '22cc3deb-d1a0-495e-a321-5be03e74b25e',
    authority: 'https://login.microsoftonline.com/8b1a58ec-0bf4-473d-bc6d-2d65cf4162c5', // Replace with your Azure AD tenant ID
    redirectUri: redirectUI, // Replace with your redirect URI
    // code_verifier:'Test',
    // code_challenge : 'Test',
    // code_challenge_method:'Plain',
    // code_challenge_method:'S256',
  },
};

    const msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();

const MsOrganizationAccountSync = ({ calendarType,clientId, redirectUri, codeChallenge,setIsAuthenticated, setAccessToken,buttonText }) => {
  const dispatch = useDispatch();
 
    const handleMicrosoftLogin = async () => {
        try {
          // Construct the authorization URL dynamically
            const authorizationUrl = 'https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize' +
                `?response_type=code` +
                `&client_id=${encodeURIComponent(clientId)}` +
                `&scope=Calendars.ReadWrite%20offline_access` +
                `&redirect_uri=${encodeURIComponent(redirectUri)}` +
                `&code_challenge=${encodeURIComponent(codeChallenge)}` +
                `&code_challenge_method=plain`;
    
                 window.location.href = authorizationUrl;

            } catch (error) {
            console.error('Login error:', error);
            }
        };

  useEffect(() => {
    dispatch(
      addType({
        type: calendarType,
        
      })
    );
    // const handleRedirect = async () => {
    //   try {
    //     const account = await msalInstance.handleRedirectPromise();
    //     if (account) {
    //       // Extract the authorization code from the URL
    //       const urlParams = new URLSearchParams(window.location.search);
    //       const code = urlParams.get('code');
    //       if (code) {
    //         // Exchange the authorization code for an access token using a backend service
    //         // You need to implement this logic in your backend
    //         const tokenResponse = await exchangeAuthorizationCodeForToken(code);
    //         console.log('===================',tokenResponse)
    //         if (tokenResponse) {
    //           setAccessToken(tokenResponse.accessToken);
    //           setIsAuthenticated(true);
    //           // Now you can fetch calendar events using the obtained access token
    //           fetchCalendarEvents(tokenResponse.accessToken);
    //         }
    //       }
    //     }
    //   } catch (error) {
    //     console.error('Redirect error:', error);
    //   }
    // };

    // handleRedirect();
  }, []);

  const exchangeAuthorizationCodeForToken = async (code) => {
    // You need to implement this function in your backend
    // It should make a POST request to the token endpoint with the authorization code
    // and client credentials (client ID and client secret) to obtain an access token
    // and optionally a refresh token
  };

  const fetchCalendarEvents = async (token) => {
    // Fetch calendar events using the obtained access token
    // This function remains the same as in your original code
  };

  return (
    <div>
      <button style={{ width: 150 }} onClick={handleMicrosoftLogin}>{buttonText}</button>
    </div>
  );
};

export default MsOrganizationAccountSync;
