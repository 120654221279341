import React, { useState, useEffect } from "react";
import {
  TextField,
  IconButton,
  Stack,
  Chip,
  Avatar,
  Typography,
  Grid,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PersonIcon from "@mui/icons-material/Person";
import StyledTextField from "../UIComponents/StyledTextField";
import ErrorComponent from "../Messages/ErrorComponent";

export default function InviteMembers({
  members,
  setMembers,
  showMemberSuccess,
  setShowMemberSuccess,
}) {
  const [inputValue, setInputValue] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [userExists, setUserExists] = useState(false);
  const [successMessages, setSuccessMessage] = useState([
    "Invitation Sent Succesfully!",
  ]);

  // Email validation logic
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Check if user already exists
  const checkIfUserExists = async (email) => {
    // Simulate an API call
    const existingUsers = ["user1@example.com", "user2@example.com"];
    return existingUsers.includes(email);
  };

  useEffect(() => {
    if (validateEmail(inputValue)) {
      setIsValidEmail(true);
      checkIfUserExists(inputValue).then((exists) => {
        setUserExists(exists);
      });
    } else {
      setIsValidEmail(false);
      setUserExists(false);
    }
  }, [inputValue]);

  const handleAddMember = () => {
    if (!userExists && isValidEmail) {
      setMembers([...members, inputValue]);
      setInputValue(""); // Clear input after adding
    } else {
      alert("User already exists or invalid email.");
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={11} md={11}>
          <StyledTextField
            fullWidth
            label="Email"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            required
            variant="outlined"
          />
        </Grid>
        <Grid item sm={1} md={1}>
          <IconButton
            color="primary"
            onClick={handleAddMember}
            disabled={!isValidEmail || userExists}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>

      {userExists && (
        <Typography color="error">This user already exists.</Typography>
      )}
      {showMemberSuccess && (
        <ErrorComponent
          errorMessages={successMessages}
          setErrorMessage={setSuccessMessage}
          show={showMemberSuccess}
          setShow={setShowMemberSuccess}
          isSuccess={true}
          mt={2}
        />
      )}
      <Stack direction="row" spacing={1} flexWrap="wrap" mt={2}>
        {members &&
          members.map((member, index) => (
            <Chip
              key={index}
              label={member}
              onDelete={() => setMembers(members.filter((m) => m !== member))}
              avatar={
                <Avatar>
                  <PersonIcon />
                </Avatar>
              }
            />
          ))}
      </Stack>
    </>
  );
}
