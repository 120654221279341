// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   Container,
//   Grid,
//   InputAdornment,
//   IconButton,
//   FormControl,
//   Divider,
//   Chip,
// } from "@mui/material";
// import { Visibility, VisibilityOff } from "@mui/icons-material";
// import StyledTextField from "../components/UIComponents/StyledTextField";
// import GoogleAuthButton from "../components/UIComponents/GoogleAuthButton";
// import { useNavigate, useSearchParams } from "react-router-dom";
// import withLoader from "../components/Loader/withLoader";
// import { useDispatch } from "react-redux";
// import  { AuthService } from "../services/LoginService";
// import ErrorComponent from "../components/Messages/ErrorComponent";
// import BtnIcons from "../components/UIComponents/BtnIcons";
// import MUISubHeader from "../components/UIComponents/MUISubHeader";

// const Register = () => {
//   const [searchParams] = useSearchParams();
//   const [showError, setShowError] = useState(false);
//   const [errorMessage, setErrorMessage] = useState([""]);
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   useEffect(() => {
//     const validateUrl = async () => {
//       let res = await withLoader(dispatch, () =>
//         AuthService.isInviteUrlExpired(searchParams.get("token"))
//       );
//       if (res.Error) {
//         setShowError(true);
//         setErrorMessage([res.Error]);
//       }
//     };
//     validateUrl();
//   }, [dispatch, searchParams]);

//   const [formValues, setFormValues] = useState({
//     firstName: "",
//     lastName: "",
//     email: "",
//     newpassword: "",
//     confirmnewpassword: "",
//   });

//   // State for error messages
//   const [errors, setErrors] = useState({
//     firstName: false,
//     lastName: false,
//     email: false,
//     newpassword: false,
//     confirmnewpassword: false,
//   });
//   const [errorsMsg, setErrorsMsg] = useState({
//     firstName: "",
//     lastName: "",
//     email: "",
//     newpassword: "",
//     confirmnewpassword: "",
//   });

//   const [showNewPassword, setShowNewPassword] = useState(false);
//   const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);

//   const validateEmail = (email) => {
//     const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return regex.test(email);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;

//     // Update form values
//     setFormValues({
//       ...formValues,
//       [name]: value,
//     });

//     // Clear error state when user types
//     setErrors((prevErrors) => ({
//       ...prevErrors,
//       [name]: false,
//     }));
//     setErrorsMsg((prevMsg) => ({
//       ...prevMsg,
//       [name]: "",
//     }));
//   };

//   async function handleSubmit() {
//     setLoading(true);
//     let valid = true;

//     // Validate First Name
//     if (formValues.firstName === "") {
//       setErrors((prevErrors) => ({ ...prevErrors, firstName: true }));
//       setErrorsMsg((prevMsg) => ({ ...prevMsg, firstName: "Required!" }));
//       valid = false;
//     }

//     // Validate Last Name
//     if (formValues.lastName === "") {
//       setErrors((prevErrors) => ({ ...prevErrors, lastName: true }));
//       setErrorsMsg((prevMsg) => ({ ...prevMsg, lastName: "Required!" }));
//       valid = false;
//     }

//     // Validate Email
//     if (formValues.email === "") {
//       setErrors((prevErrors) => ({ ...prevErrors, email: true }));
//       setErrorsMsg((prevMsg) => ({ ...prevMsg, email: "Required!" }));
//       valid = false;
//     } else if (!validateEmail(formValues.email)) {
//       setErrors((prevErrors) => ({ ...prevErrors, email: true }));
//       setErrorsMsg((prevMsg) => ({
//         ...prevMsg,
//         email: "Invalid email format!",
//       }));
//       valid = false;
//     }

//     // Validate Password
//     if (formValues.newpassword === "") {
//       setErrors((prevErrors) => ({ ...prevErrors, newpassword: true }));
//       setErrorsMsg((prevMsg) => ({ ...prevMsg, newpassword: "Required!" }));
//       valid = false;
//     }

//     // Validate Confirm Password
//     if (formValues.confirmnewpassword === "") {
//       setErrors((prevErrors) => ({ ...prevErrors, confirmnewpassword: true }));
//       setErrorsMsg((prevMsg) => ({
//         ...prevMsg,
//         confirmnewpassword: "Required!",
//       }));
//       valid = false;
//     } else if (formValues.newpassword !== formValues.confirmnewpassword) {
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         newpassword: true,
//         confirmnewpassword: true,
//       }));
//       setErrorsMsg((prevMsg) => ({
//         ...prevMsg,
//         confirmnewpassword: "Passwords do not match!",
//       }));
//       valid = false;
//     }
//     if (!valid) {
//       setLoading(false);
//     }
//     if (valid) {
//       // Proceed with form submission logic
//       let token = searchParams.get("token") ? searchParams.get("token") : "";
//       console.log(token, formValues);
//       const res = await withLoader(dispatch, () =>
//         AuthService.CreateUser(formValues, token)
//       );
//       if (res.Error) {
//         setErrorMessage([res.Error]);
//         setShowError(true);
//         setLoading(false);

//       } else if (res.Login) {
//         setLoading(false);
//         navigate("/login");
//       }
//     }
//   }

//   return (
//     <>
//       <Container maxWidth="sm">
//         <Box
//           sx={{
//             marginTop: 4,
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//           }}
//         >
//           <Grid
//             display={"flex"}
//             flexDirection={"column"}
//             alignItems={"center"}
//             mb={2}
//           >
//             <Grid item>
//               <MUISubHeader text="Register" />
//             </Grid>

//             <Grid item mt={2}>
//               {showError && (
//                 <ErrorComponent
//                   errorMessages={errorMessage}
//                   setErrorMessage={setErrorMessage}
//                   show={showError}
//                   setShow={setShowError}
//                 />
//               )}
//             </Grid>
//           </Grid>

//           <FormControl>
//             <Grid container spacing={2}>
//               <Grid item xs={12} sm={6}>
//                 <StyledTextField
//                   required
//                   fullWidth
//                   id="firstName"
//                   name="firstName"
//                   label="First Name"
//                   value={formValues.firstName}
//                   onChange={handleChange}
//                   error={errors.firstName}
//                   helperText={errorsMsg.firstName}
//                 />
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <StyledTextField
//                   required
//                   fullWidth
//                   id="lastName"
//                   name="lastName"
//                   label="Last Name"
//                   value={formValues.lastName}
//                   onChange={handleChange}
//                   error={errors.lastName}
//                   helperText={errorsMsg.lastName}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <StyledTextField
//                   required
//                   fullWidth
//                   id="email"
//                   name="email"
//                   label="Email"
//                   value={formValues.email}
//                   onChange={handleChange}
//                   error={errors.email}
//                   helperText={errorsMsg.email}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <StyledTextField
//                   required
//                   fullWidth
//                   id="newpassword"
//                   name="newpassword"
//                   label="Password"
//                   type={showNewPassword ? "text" : "password"}
//                   value={formValues.newpassword}
//                   onChange={handleChange}
//                   error={errors.newpassword}
//                   helperText={errorsMsg.newpassword}
//                   InputProps={{
//                     endAdornment: (
//                       <InputAdornment position="end">
//                         <IconButton
//                           onClick={handleClickShowNewPassword}
//                           edge="end"
//                         >
//                           {showNewPassword ? <VisibilityOff /> : <Visibility />}
//                         </IconButton>
//                       </InputAdornment>
//                     ),
//                   }}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <StyledTextField
//                   required
//                   fullWidth
//                   id="confirmnewpassword"
//                   name="confirmnewpassword"
//                   label="Confirm Password"
//                   type={showNewPassword ? "text" : "password"}
//                   value={formValues.confirmnewpassword}
//                   onChange={handleChange}
//                   error={errors.confirmnewpassword}
//                   helperText={errorsMsg.confirmnewpassword}
//                 />
//               </Grid>
//             </Grid>
//             {/* <Button
//               fullWidth
//               variant="contained"
//               onClick={handleSubmit}
//               sx={{ mt: 3, mb: 2 }}
//             >
//               Register
//             </Button> */}
//             <Grid mt={3}>
//               <BtnIcons
//                 variant="contained"
//                 onClick={handleSubmit}
//                 btnLabel="Register"
//                 loading={loading}
//                 setLoading={setLoading}
//                 isIconButton={true}
//               />
//             </Grid>
//           </FormControl>
//         </Box>
//         <Divider>
//           <Chip label="or" />
//         </Divider>
//       </Container>
//       <Container maxWidth="sm">
//         <Grid
//           sm={4}
//           md={2}
//           sx={{
//             marginTop: 4,
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//           }}
//         >
//           <GoogleAuthButton
//             isSignIn={false}
//             crypto={searchParams.get("token")}
//           />
//         </Grid>
//       </Container>
//     </>
//   );
// };

// export default Register;import React, { useState } from "react";
import {
    Box,
    Container,
    Typography,
    InputAdornment,
    IconButton,
    Grid,
  } from "@mui/material";
  import { Visibility, VisibilityOff } from "@mui/icons-material";
  import StyledTextField from "../components/UIComponents/StyledTextField";
  import GoogleAuthButton from "../components/UIComponents/GoogleAuthButton";
  import BtnIcons from "../components/UIComponents/BtnIcons";
  import loginBgImg from "../Icons/loginBackground.jpg";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import withLoader from "../components/Loader/withLoader";
import { AuthService } from "../services/LoginService";
import { selectLoader } from "../features/user/LoaderSlice";
import AppLoader from "../components/Loader/AppLoader";
import ErrorComponent from "../components/Messages/ErrorComponent";
  
  const Register = () => {
    const [searchParams] = useSearchParams();
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState([""]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
  
    const [formValues, setFormValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        newpassword: "",
        confirmnewpassword: "",
      });
    
      // State for error messages
      const [errors, setErrors] = useState({
        firstName: false,
        lastName: false,
        email: false,
        newpassword: false,
        confirmnewpassword: false,
      });
      const [errorsMsg, setErrorsMsg] = useState({
        firstName: "",
        lastName: "",
        email: "",
        newpassword: "",
        confirmnewpassword: "",
      });
    
    const [showNewPassword, setShowNewPassword] = useState(false);
  
    const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  
    const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormValues((prev) => ({ ...prev, [name]: value }));
      setErrors((prev) => ({ ...prev, [name]: false }));
    };
  
    async function handleSubmit() {
        setLoading(true);
        let valid = true;
    
        // Validate First Name
        if (formValues.firstName === "") {
          setErrors((prevErrors) => ({ ...prevErrors, firstName: true }));
          setErrorsMsg((prevMsg) => ({ ...prevMsg, firstName: "Required!" }));
          valid = false;
        }
    
        // Validate Last Name
        if (formValues.lastName === "") {
          setErrors((prevErrors) => ({ ...prevErrors, lastName: true }));
          setErrorsMsg((prevMsg) => ({ ...prevMsg, lastName: "Required!" }));
          valid = false;
        }
    
        // Validate Email
        if (formValues.email === "") {
          setErrors((prevErrors) => ({ ...prevErrors, email: true }));
          setErrorsMsg((prevMsg) => ({ ...prevMsg, email: "Required!" }));
          valid = false;
        } else if (!validateEmail(formValues.email)) {
          setErrors((prevErrors) => ({ ...prevErrors, email: true }));
          setErrorsMsg((prevMsg) => ({
            ...prevMsg,
            email: "Invalid email format!",
          }));
          valid = false;
        }
    
        // Validate Password
        if (formValues.newpassword === "") {
          setErrors((prevErrors) => ({ ...prevErrors, newpassword: true }));
          setErrorsMsg((prevMsg) => ({ ...prevMsg, newpassword: "Required!" }));
          valid = false;
        }
    
        // Validate Confirm Password
        if (formValues.confirmnewpassword === "") {
          setErrors((prevErrors) => ({ ...prevErrors, confirmnewpassword: true }));
          setErrorsMsg((prevMsg) => ({
            ...prevMsg,
            confirmnewpassword: "Required!",
          }));
          valid = false;
        } else if (formValues.newpassword !== formValues.confirmnewpassword) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            newpassword: true,
            confirmnewpassword: true,
          }));
          setErrorsMsg((prevMsg) => ({
            ...prevMsg,
            confirmnewpassword: "Passwords do not match!",
          }));
          valid = false;
        }
        if (!valid) {
          setLoading(false);
        }
        if (valid) {
          // Proceed with form submission logic
          let token = searchParams.get("token") ? searchParams.get("token") : "";
          console.log(token, formValues);
          const res = await withLoader(dispatch, () =>
            AuthService.CreateUser(formValues, token)
          );
          if (res.Error) {
            setErrorMessage([res.Error]);
            setShowError(true);
            setLoading(false);
    
          } else if (res.Login) {
            setLoading(false);
            navigate("/login");
          }
        }
      }
      const loader = useSelector(selectLoader);
    return (
        <>
        <AppLoader show={loader}/>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          height: "100vh",
          overflow: "hidden",
          backgroundColor: "#f5f5f5",
        }}
      >
        {/* Left Side: Image */}
        <Box
          sx={{
            flex: 1,
            backgroundImage: `url(${loginBgImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: { xs: "none", md: "block" },
          }}
        />
  
        {/* Right Side: Form */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: { xs: 3, md: 6 },
            backgroundColor: "white",
          }}
        >
              
          <Container
            maxWidth="sm"
            sx={{
              boxShadow: 3,
              borderRadius: 2,
              backgroundColor: "white",
              padding: 4,
            }}
          >
            
            <ErrorComponent 
            errorMessages={errorMessage} 
            show={showError}
            setShow={setShowError}
            />

            {/* <Typography component="h1" variant="h5" mb={2}>
              Register
            </Typography> */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  required
                  fullWidth
                  name="firstName"
                  label="First Name"
                  value={formValues.firstName}
                  onChange={handleChange}
                  error={Boolean(errors.firstName)}
                  helperText={errors.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  required
                  fullWidth
                  name="lastName"
                  label="Last Name"
                  value={formValues.lastName}
                  onChange={handleChange}
                  error={Boolean(errors.lastName)}
                  helperText={errors.lastName}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  required
                  fullWidth
                  name="email"
                  label="Email"
                  value={formValues.email}
                  onChange={handleChange}
                  error={Boolean(errors.email)}
                  helperText={errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  required
                  fullWidth
                  name="newpassword"
                  label="Password"
                  type={showNewPassword ? "text" : "password"}
                  value={formValues.newpassword}
                  onChange={handleChange}
                  error={Boolean(errors.newpassword)}
                  helperText={errors.newpassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickShowNewPassword}>
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  required
                  fullWidth
                  name="confirmnewpassword"
                  label="Confirm Password"
                  type={showNewPassword ? "text" : "password"}
                  value={formValues.confirmnewpassword}
                  onChange={handleChange}
                  error={Boolean(errors.confirmnewpassword)}
                  helperText={errors.confirmnewpassword}
                />
              </Grid>
            </Grid>
            <Box>
                <BtnIcons
                variant="contained"
                fullWidth
                sx={{ mt: 3 }}
                onClick={handleSubmit}
                btnLabel="Register"
                />
            </Box>
            <Box sx={{ mt: 2 }}>
              <GoogleAuthButton isSignIn={false} />
            </Box>
          </Container>
        </Box>
      </Box>
      </>
    );
  };
  
  export default Register;
  
