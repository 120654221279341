
import { InfoWrapper } from "../utils/InfoWrapper";
const baseUrl = process.env.REACT_APP_BASEURL;
// const {baseUrl} = InfoWrapper();
export const GoogleCalendarService = {
  saveCalendar: async (data, user) => {
    try {
      const apiUrl = `${baseUrl}api/Calendar`;
      console.log("Inside fetch Events", data, user);
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: JSON.stringify({
          Code: data.code,
          User: user.uid,
        }),
      });
      return response;
    } catch (error) {
      console.error("Error fetching events from backend:", error);
      throw error;
    }
  },

  fetchEvents: async (Uid, start, end) => {
    try {
      const apiUrl = `${baseUrl}api/calendarEvents`;
      console.log("Inside fetch Events", Uid, start, end);
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: JSON.stringify({
          Uid: Uid,
          StartDate: start,
          EndDate: end,
        }),
      });
      const responseObject = await response.json();
      const res = responseObject["Info"];
      console.log(
        "=========res============",
        res,
        "=======------------======="
      );
      return res;
    } catch (error) {
      console.error("Error fetching events from backend:", error);
      throw error;
    }
  },
};

export const MSCalendarService = {
  SaveMsCalendar: async (uid, code, type) => {
    try {
      const apiUrl = `${baseUrl}api/syncmicrosoft`;
      console.log("Inside SaveMsCalendar", type.toString());
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: JSON.stringify({
          Uid: uid,
          Code: code,
          CalendarType: type.toString(),
        }),
      });

      return await response.json();
    } catch (error) {
      console.error("Error fetching events from backend:", error);
      throw error;
    }
  },
};

export const ICloudCalendarService = () => {};

export const CalendarDataService = {
  fetchAllCalendar: async (user) => {
    try {
      const endpoint = `${baseUrl}/api/Calendar/getcalendars?uid=${user.uid}`;
      console.log(
        "----      Inside CalendarDataService fetchAllCalendar  ----",
        endpoint
      );
      const response = await fetch(endpoint, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
          UserName: "",
          AccessToken: "",
          IsAuthenticated: "true",
          TokensId: "0",
        },
   
      });
      return response;
    } catch (error) {
      console.error("Error fetching Calendars from Server:", error);
      throw error;
    }
  },
  GetSyncedCalendars: async (id) => {
    try {
      const apiUrl = `${baseUrl}SyncedCalendars`;
      console.log("--- Inside CalendarDataService GetSyncedCalendars ----", id);
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: JSON.stringify({
          Id: id,
        }),
      });

      let resObject = await response.json();
      let res = resObject["Info"];
      console.log(res);
      return res;
    } catch (error) {
      console.error("Error fetching Info:", error);
      throw error;
    }
  },
  RemoveSyncedCalendar: async (uid, id) => {
    try {
      const apiUrl = `${baseUrl}RemoveCalendar`;
      console.log(
        "--- Inside CalendarDataService RemoveSyncedCalendar ----",
        uid,
        id
      );
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: JSON.stringify({
          Id: uid,
          CalId:id.toString()

        }),
      });

      let resObject = await response.json();
      let res = resObject["Info"];
      console.log(res);
      return res;
    } catch (error) {
      console.error("Error fetching Info:", error);
      throw error;
    }
  },
};

export default {
  GoogleCalendarService,
  MSCalendarService,
  ICloudCalendarService,
};
