import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  CssBaseline,
  Divider,
  Chip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StyledTextField from "../components/UIComponents/StyledTextField";
import GoogleAuthButton from "../components/UIComponents/GoogleAuthButton";
import loginBgImg from "../Icons/loginBackground.jpg";
import withLoader from "../components/Loader/withLoader";
import LoginService, { AuthService } from "../services/LoginService";
import AppLoader from "../components/Loader/AppLoader";
import { selectLoader } from "../features/user/LoaderSlice";
import ErrorComponent from "../components/Messages/ErrorComponent";
import { InfoWrapper } from "../utils/InfoWrapper";

function Login() {
  // let imageBackground = "https://img.freepik.com/free-vector/business-office-equipment_24908-59507.jpg?t=st=1733501601~exp=1733505201~hmac=64ea55f0d9de75534e911be013c2dce9f2bb90f86275a2d5d77f7ded17bfdde3&w=740";
  // let imageBackground = "https://img.freepik.com/free-photo/cyber-monday-sale-november-calendar-copy-space_23-2148670016.jpg?t=st=1733501007~exp=1733504607~hmac=1e31459df601d154fd8d94d34d6e84d671e0b125fabe72feae2afe9272c43950&w=826";
  // let imageBackground = "https://img.freepik.com/free-vector/time-alarm-clock-messages-service-calendar_24908-59456.jpg?t=st=1733501883~exp=1733505483~hmac=4688859900172059a0779c82a06f82bc8a00914deb47bb323c78c5d94c830bfc&w=740";
  // let imageBackground = "https://img.freepik.com/premium-vector/business-clock-alarm-time-tool-work-efficiency_24908-1287.jpg?w=740";
  let imageBackground = loginBgImg;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const {user} = InfoWrapper();

  //error handling
  //Enhanced Loader
  //Register
  const [error, setError] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Add your login logic here
    let res = await withLoader(dispatch, () =>
      AuthService.Login(email, password, dispatch)
    );
    
    if (!res || res.Error) {
      setError(true);
    }
    
    if (res) {
      let res = await withLoader(dispatch, () =>
        AuthService.GetUserDetails(user.uid, dispatch)
      );
    }
  };
  const loader = useSelector(selectLoader);
  return (
    <>
      <AppLoader show={loader} />
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          overflow: "hidden", // Prevent scrolling
        }}
      >
        {/* Left Side: Image */}
        <Box
          sx={{
            flex: 1,
            display: { xs: "none", sm: "block" },
            backgroundImage: `url(${imageBackground})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100vh", // Force the image to take up full height
          }}
        />
        {/* Right Side: Login Form */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 4,
            backgroundColor: "#fff",
            boxShadow: "4px 0px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "450px", // Adjusted max width for a cleaner look
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "40px", // Added more padding for spacing
              borderRadius: "12px",
              boxShadow: "0px 6px 18px rgba(0, 0, 0, 0.1)", // Slightly stronger shadow for depth
              backgroundColor: "#ffffff", // White background for contrast
            }}
          >
            <ErrorComponent
              errorMessages={"Invalid UserName or Password."}
              show={error}
              setShow={setError}
            />
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ width: "100%" }}
            >
              <Box mb={3}>
                <StyledTextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoFocus
                  sx={{
                    marginBottom: "20px",
                    backgroundColor: "#f5f5f5", // Light background for the text field
                    borderRadius: "8px",
                  }}
                />
              </Box>

              <StyledTextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{
                  marginBottom: "20px",
                  backgroundColor: "#f5f5f5", // Consistent background color
                  borderRadius: "8px",
                }}
              />
              <Box mt={3}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    backgroundColor: "#1976d2",
                    "&:hover": { backgroundColor: "#1565c0" },
                    marginBottom: "20px",
                    padding: "12px", // Slightly increased padding for better button appearance
                    fontWeight: 600,
                    borderRadius: "8px", // Rounded corners for the button
                  }}
                >
                  Log In
                </Button>
              </Box>

              <Typography
                variant="body2"
                align="center"
                sx={{ marginBottom: "15px" }}
              >
                Not a Member?{" "}
                <span
                  style={{
                    color: "#1976d2",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  onClick={() => navigate("/Register")}
                >
                  Register
                </span>
              </Typography>

              <Box mt={2}>
                <Divider>
                  <Chip label="or" />
                </Divider>
              </Box>

              <Box mt={2} display={"flex"} justifyContent={"center"}>
                <GoogleAuthButton isSignIn={true} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Login;

// import React, { useState } from "react";
// import {
//   Button,
//   Box,
//   Typography,
//   Container,
//   CssBaseline,
//   Divider,
// } from "@mui/material";
// import { useDispatch } from "react-redux";
// import withLoader from "../components/Loader/withLoader";
// import { AuthService } from "../services/LoginService";
// import StyledTextField from "../components/UIComponents/StyledTextField";
// import { useNavigate } from "react-router-dom";
// import GoogleAuthButton from "../components/UIComponents/GoogleAuthButton";
// import BgImage from "../Icons/BgImage.jpg";
// function Login() {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const dispatch = useDispatch();
//   const useLoader = useDispatch();
//   const navigate = useNavigate();

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     await withLoader(useLoader, () =>
//       AuthService.Login(email, password, dispatch)
//     );
//   };

//   return (
//     <Box
//       sx={{
//         width: "100vw",
//         height: "100vh",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         backgroundImage: `url("https://img.freepik.com/free-photo/cyber-monday-sale-calendar-clock_23-2148670017.jpg?t=st=1733496797~exp=1733500397~hmac=63d6c499bf09a6cc2877881fa706d42d4d533c40c90e9b128cffaf1ba5bab37f&w=996")`,
//         backgroundSize: "cover",
//         backgroundPosition: "center",
//         backgroundRepeat: "no-repeat",
//         position: "relative",
//         "&::before": {
//           content: '""',
//           position: "absolute",
//           top: 0,
//           left: 0,
//           width: "100%",
//           height: "100%",
//           backgroundColor: "rgba(0, 0, 0, 0.6)",
//           zIndex: 0,
//         },
//       }}
//     >
//       <CssBaseline />
//       <Container
//         component="main"
//         maxWidth="sm"
//         sx={{
//           position: "relative",
//           zIndex: 1,
//           backgroundColor: "#ffffff",
//           borderRadius: 4,
//           padding: 6,
//           boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.2)",
//           animation: "fadeIn 1s ease-out",
//         }}
//       >
//         <Typography
//           component="h1"
//           variant="h4"
//           align="center"
//           sx={{
//             fontWeight: "bold",
//             color: "#333",
//             mb: 4,
//             textTransform: "uppercase",
//             letterSpacing: "2px",
//           }}
//         >
//           Welcome
//         </Typography>
//         <Box
//           component="form"
//           onSubmit={handleSubmit}
//           noValidate
//           sx={{
//             mt: 1,
//             display: "flex",
//             flexDirection: "column",
//             gap: 3,
//           }}
//         >
//           <StyledTextField
//             required
//             fullWidth
//             id="email"
//             label="Email Address"
//             name="email"
//             autoComplete="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             autoFocus
//           />
//           <StyledTextField
//             required
//             fullWidth
//             name="password"
//             label="Password"
//             type="password"
//             id="password"
//             autoComplete="current-password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//           />
//           <Button
//             type="submit"
//             fullWidth
//             variant="contained"
//             sx={{
//               mt: 3,
//               mb: 2,
//               backgroundColor: "#ff6f61",
//               textTransform: "none",
//               fontWeight: "bold",
//               fontSize: "1rem",
//               color: "#fff",
//               "&:hover": {
//                 backgroundColor: "#e65c55",
//               },
//             }}
//           >
//             Log In
//           </Button>
//           <Typography
//             variant="body2"
//             align="center"
//             sx={{
//               mt: 2,
//               color: "#ccc",
//               "& span": {
//                 color: "#ff6f61",
//                 fontWeight: "bold",
//                 cursor: "pointer",
//               },
//             }}
//           >
//             Not a Member?{" "}
//             <span onClick={() => navigate("/Register")}>Register</span>
//           </Typography>
//           <Box mt={3}>
//             <Divider sx={{ backgroundColor: "#ccc" }} />
//           </Box>
//           <Box mt={3} display={"flex"} justifyContent={"center"}>
//             <GoogleAuthButton isSignIn={true} />
//           </Box>
//         </Box>
//       </Container>
//     </Box>
//   );
// }

// export default Login;
//************************************************** */
//************************************************** */
//************************************************** */
// // import React,{useState} from 'react'
// // import '../styles/Login.css'
// // // import {SignIn} from '../services/LoginService'
// // import { AuthService } from '../services/LoginService';
// // import { useDispatch } from 'react-redux';
// // import withLoader from '../components/Loader/withLoader';
// // function Login() {
// //   const [email ,setEmail] = useState("");
// //   const [password ,setPassword] = useState("");
// //   const [fullName ,setFullName] = useState("");
// //   const [profilePicUrl ,setProfilePicUrl] = useState("");
// //   const dispatch = useDispatch();
// //   const useLoader = useDispatch();

// //   const handleSubmit = async (event) => {
// //     console.log('Inside handleSubmit Login: <params>',email,password);

// //     event.preventDefault(); // Prevent default form submission
// //     await withLoader(useLoader,()=> AuthService.Login(email,password,dispatch));
// //   };

// //   return (
// //     <div className='Login'>
// //     <img src='' />
// //     <form onSubmit={handleSubmit}>
// //         {/* <input placeholder='Full Name(Required)' value={fullName} onChange={e => setFullName(e.target.value)} type='text'/>
// //         <input placeholder='Profile Pic Url(Optional)' value={profilePicUrl}  onChange={e => setProfilePicUrl(e.target.value)}  type='text'/> */}
// //         <input placeholder='Email' name='email' value={email} onChange={e => setEmail(e.target.value)} type='text'/>
// //         <input placeholder='Password' name='password' value={password} onChange={e => setPassword(e.target.value)} type='password'/>
// //         <button type='submit' >Log In</button>
// //     </form>
// //     <p><span className='login_register' onClick={()=>console.log('Register')}>Not a Member? Register</span></p>
// //     </div>
// //   )
// // }

// // export default Login

// import React, { useState } from "react";
// import {
//   TextField,
//   Button,
//   Box,
//   Typography,
//   Container,
//   Avatar,
//   CssBaseline,
//   Divider,
// } from "@mui/material";
// import { useDispatch } from "react-redux";
// import withLoader from "../components/Loader/withLoader";
// import { AuthService } from "../services/LoginService";
// import StyledTextField from "../components/UIComponents/StyledTextField";
// import { useNavigate } from "react-router-dom";
// import GoogleAuthButton from "../components/UIComponents/GoogleAuthButton";

// function Login() {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const dispatch = useDispatch();
//   const useLoader = useDispatch();
//   const navigate = useNavigate();
//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     await withLoader(useLoader, () =>
//       AuthService.Login(email, password, dispatch)
//     );
//   };

//   return (
//     <Container component="main" maxWidth="xs">
//       <CssBaseline />
//       <Box
//         sx={{
//           marginTop: 8,
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//         }}
//       >
//         {/* <Avatar src="" sx={{ m: 1, width: 56, height: 56 }} /> */}
//         <Typography component="h1" variant="h5">
//           Log In
//         </Typography>
//         <Box
//           component="form"
//           onSubmit={handleSubmit}
//           noValidate
//           sx={{ mt: 1 }}
//           gap={2}
//         >
//           <StyledTextField
//             // margin="normal"
//             required
//             fullWidth
//             id="email"
//             label="Email Address"
//             name="email"
//             autoComplete="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             autoFocus
//           />
//           <StyledTextField
//             // margin="normal"
//             required
//             fullWidth
//             name="password"
//             label="Password"
//             type="password"
//             id="password"
//             autoComplete="current-password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//           />
//           <Button
//             type="submit"
//             fullWidth
//             variant="contained"
//             sx={{ mt: 3, mb: 2 }}
//           >
//             Log In
//           </Button>
//           <Typography variant="body2" align="center">
//             Not a Member?{" "}
//             <span
//               style={{ color: "#1976d2", cursor: "pointer" }}
//               onClick={() => navigate("/Register")}
//             >
//               Register
//             </span>
//           </Typography>
//           <Box mt={2}>
//             <Divider />
//           </Box>
//           <Box mt={2} display={"flex"} justifyContent={"center"}>
//             <GoogleAuthButton
//               isSignIn={true}
//               // crypto={searchParams.get("token")}
//             />
//           </Box>
//         </Box>
//       </Box>
//     </Container>
//   );
// }

// export default Login;
