import {
  Box,
  Checkbox,
  Drawer,
  Grid,
  Tabs,
  Tab,
  useMediaQuery,
  useTheme,
  Divider,
  Switch,
  IconButton,
  Tooltip,
  Alert,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PreviewCalendar from "../components/Events/PreviewCalendar";
import ButtonDropDown from "../components/UIComponents/ButtonDropDown";
import BtnCancel from "../components/UIComponents/BtnCancel";
import MUISubHeader from "../components/UIComponents/MUISubHeader";
import TextOnly from "../components/UIComponents/TextOnly";
import CustomRadioButton from "../components/UIComponents/CustomRadioButton";
import ClickableRow from "../components/UIComponents/ClickableRow";
import SelectInput from "../components/UIComponents/SelectInput";
import InputText from "../components/UIComponents/InputText";
import GoBack from "../components/UIComponents/GoBack";
import { EventService } from "../services/EventService";
import { InfoWrapper } from "../utils/InfoWrapper";
import { useNavigate,  useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WebIcon from "@mui/icons-material/Web";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import EventIcon from "@mui/icons-material/Event";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";
import { Settings } from "@mui/icons-material";
import TextAreaWithUndoRedo from "../components/UIComponents/TextAreaWithUndoRedo";
import MultiDatePicker from "../components/UIComponents/MultiDatePicker";
import EventBookingOptions from "../components/Events/EventBookingOptions";

const EventDetails = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [tabValue, setTabValue] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error");
  const [host, setHost] = useState();
  const [eventPartipants, setEventPartipants] = useState([]);
  const [addGuest, setAddGuest] = useState(false);
  const [drawerSettings, setDrawerSettings] = useState(null);

  const [alertsDetails, setAlertsDetails] = useState(null);
  let { id } = useParams();
  const { user } = InfoWrapper();

  const toggleDrawer = (anchor) => {
    setDrawerOpen(drawerOpen === anchor ? "" : anchor);
  };

  useEffect(() => {
    const fetchData = async () => {
      const req = {
        EventId: id,
        Type: drawerOpen,
        Id: user.uid,
      };

      let res = await EventService.GetEventInfo(req);
      // console.log("============ Alerts API ===============", res?.Info?.[0]);
      if (res.Info) {
        switch (drawerOpen) {
          case "Participant":
            // Call the Participant API
            setHost(res.Info.filter((x) => x.Role === "Host"));
            setEventPartipants(
              res.Info.filter((x) => x.Role === "Participant")
            );
            setAddGuest(res.Info[0]?.AddGuests);
            break;
          case "Scheduling":
            // Call the Scheduling API
            // console.log("============ Scheduling API ===============");
            setDrawerSettings(res.Info[0]);
            break;
          case "Booking":
            // Call the Booking API
            // console.log("============ Booking API ===============");
            break;
          case "Alerts":
            setAlertsDetails(
              res.Info && res.Info.length > 0 ? res.Info[0] : null
            );
            break;
          default:
            // Handle cases where drawerOpen is not one of the expected values
            // console.log(
            //   "No matching API call for the current drawerOpen value"
            // );
            break;
        }
      }
      
    };

    fetchData();
  }, [drawerOpen]);

  
  const navigateTo = useNavigate();
  const SidePanelHeader = () => (
    <Box
      display="flex"
      justifyContent="space-between"
      padding={2}
      sx={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", color: "#ffffff" }}
    >
      <Tooltip onClick={() => navigateTo("/")} title="Back">
        <Box>
          <ArrowBackIcon color="action" />
        </Box>
      </Tooltip>
      <Box display="flex" gap={2}>
        <Tooltip onClick={console.log("djfk")} title="Settings">
          <Settings color="action" />
        </Tooltip>
        <Tooltip onClick={console.log("djfk")} title="Copy Link">
          <ContentCopyIcon color="primary" />
        </Tooltip>
        <Tooltip onClick={console.log("djfk")} title="Share">
          <ShareIcon color="primary" />
        </Tooltip>
      </Box>
    </Box>
  );

  const ShowTabs = () => (
    <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)}>
      <Tab label="Edit" />
      <Tab label="Preview" />
    </Tabs>
  );
  const [isDateRangeRowExpanded, setIsDateRangeRowExpanded] = useState(false);
  const [isNotifyIntervalRowExpanded, setIsNotifyIntervalRowExpanded] =
    useState(false);
  const [isTimeZoneRowExpanded, setIsTimeZoneRowExpanded] = useState(false);


  function handleSelectedDateRange(dates) {
    console.log("dates ==========================>", dates);
    setDrawerSettings((prevSettings) => {
      const updatedSettings = { ...prevSettings };

      updatedSettings.inDateRangeStartDate = dates?.[0];
      updatedSettings.inDateRangeEndDate = dates?.[1];

      return updatedSettings;
    });
  }

  const handleDateRangeRadButton = (selection) => {
    setDrawerSettings((prevSettings) => {
      const updatedSettings = { ...prevSettings };
      // Update the state based on the selection
      switch (selection) {
        case "future":
          updatedSettings.inFutureRange = true;
          updatedSettings.inDateRange = false;
          updatedSettings.Indefinite = false;
          break;
        case "daterange":
          updatedSettings.inFutureRange = false;
          updatedSettings.inDateRange = true;
          updatedSettings.Indefinite = false;
          break;
        case "indefinite":
          updatedSettings.inFutureRange = false;
          updatedSettings.inDateRange = false;
          updatedSettings.Indefinite = true;
          break;
        default:
          break;
      }

      // Return the updated settings
      return updatedSettings;
    });
  };
  const handleTimeZoneRadioButton = (selection) => {
    // setTimeZoneSelectedVal(value); // Update selected value
    setDrawerSettings((prevSettings) => {
      // Make a copy of the previous settings to ensure immutability
      const updatedSettings = { ...prevSettings };

      // Update the state based on the selection
      switch (selection) {
        case "auto":
          updatedSettings.isTimeZoneInvitee = true;
          updatedSettings.isTimeZoneLocked = false;

          break;
        case "locked":
          updatedSettings.isTimeZoneInvitee = false;
          updatedSettings.isTimeZoneLocked = true;
          break;

        default:
          break;
      }

      // Return the updated settings
      return updatedSettings;
    });
  };

  const notifyIntervalOptions = [
    { value: "Hour", label: "Hour" },
    { value: "Min", label: "Min" },
    { value: "Days", label: "Days" },
  ];
  const availabilityIntervalOptions = [
    { value: "Hour", label: "Hour" },
    { value: "Min", label: "Min" },
  ];

  // const [selectedNotifyInterval, setSelectedNotifyInterval] = useState("Days");
  const handleNotifyInterval = (event) => {
    setDrawerSettings((prev) => {
      const updatedSettings = { ...prev };

      // Check if `event` is a number or a regular event with `event.target.value`
      const isDirectValue = typeof event === "number" || !event.target;
      const value = isDirectValue ? event : event.target.value;

      // Update minimumNotice based on the value
      if (!(value === "Min" || value === "Hour" || value === "Days")) {
        // Update the amount part if it's not a recognized time unit
        updatedSettings.minimumNotice =
          value + "-" + (updatedSettings.minimumNotice.split("-")[1] || "");
      } else {
        // Update the unit part if it is a recognized time unit
        updatedSettings.minimumNotice =
          (updatedSettings.minimumNotice.split("-")[0] || "") + "-" + value;
      }

      return updatedSettings; // Return the updated object to update the state
    });
  };

  const handleAvailabilitySlotsInterval = (event) => {
    setDrawerSettings((prev) => {
      const updatedSettings = { ...prev };

      // Check if `event` is a number or a regular event with `event.target.value`
      const isDirectValue = typeof event === "number" || !event.target;
      const value = isDirectValue ? event : event.target.value;

      // Update minimumNotice based on the value
      if (!(value === "Min" || value === "Hour" || value === "Days")) {
        // Update the amount part if it's not a recognized time unit
        updatedSettings.AvailabilityTimeSlotsInterval =
          value +
          "-" +
          (updatedSettings.AvailabilityTimeSlotsInterval.split("-")[1] || "");
      } else {
        // Update the unit part if it is a recognized time unit
        updatedSettings.AvailabilityTimeSlotsInterval =
          (updatedSettings.AvailabilityTimeSlotsInterval.split("-")[0] || "") +
          "-" +
          value;
      }

      return updatedSettings; // Return the updated object to update the state
    });
  };

  const availabiltyIntervaloptions = [
    { id: 1, value: "30", name: "30 min" },
    { id: 2, value: "60", name: "60 min" },
    { id: 3, value: "90", name: "90 min" },
  ];
  
  const [isEmailFollowupRowExpanded, setIsEmailFollowupRowExpanded] =
    useState(false);

  

  const GoBackHeader = ({ header, navigate }) => (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box ml={1} paddingTop={"0.1rem"} sm={3}>
        <GoBack iconFont="small" onClick={() => NavigateBack(navigate)} />
      </Box>
      <Box paddingTop={1}>
        <MUISubHeader text={header} />
        {showError && <Alert severity="error">{errorMsg}</Alert>}
      </Box>
    </Box>
  );

  const NavigateBack = (val) => {
    //set all states to default if not saved
    setDrawerOpen("");
    switch (val) {
      case "scheduling":
        setDrawerOpen("");
        break;
      case "booking":
        setDrawerOpen("");
        break;
      case "alerts":
        setDrawerOpen("");
        break;
      case "participants":
        setDrawerOpen("");
        break;
        default:break;
    }
  };

  const saveDrawer = async () => {
    let data = {};
    if (drawerOpen === "Participant") {
      data = {
        EventId: id,
        Type: drawerOpen,
        Id: user.uid,
        AddGuest: addGuest,
      };
    }
    let save = await EventService.SaveEventInfo(data);
    //wait for save confirmation
    if (save?.Info) {
      setShowError(false);
      setErrorMsg("Error");
      toggleDrawer(drawerOpen);
    } else {
      setShowError(true);
      setErrorMsg("Unable to Save. Retry Again.");
    }
  };
  const FooterButtons = ({ btnCloseText, btnSaveText }) => (
    <Box
      sx={{ padding: "0.5rem" }}
      display="flex"
      justifyContent="flex-end"
      gap={2}
      height={40}
    >
      <BtnCancel onClick={() => toggleDrawer(drawerOpen)} />
      <ButtonDropDown
        text="Save & Close"
        buttonWidth="88px"
        padding="10px"
        buttonHeight="14px"
        onClick={saveDrawer}
      />
    </Box>
  );
  const EventParticipants = () => (
    <Box display="flex" flexDirection="column" height="100vh">
      <Box flexShrink={0}>
        <GoBackHeader header={"Participants"} navigate={"participants"} />
        <Divider sx={{ mr: 2, ml: 2, mt: 3, mb: 2 }} />
      </Box>

      <Box flexGrow={1} overflow="auto" ml={1}>
        <Box ml={2}>
          <MUISubHeader text="Host" textSize={18} />
          {host && host.map((x, i) => <TextOnly key={i} text={x.FullName} />)}
        </Box>

        <Box mt={2} ml={2}>
          <MUISubHeader text="Invitees" textSize={18} />
          {eventPartipants && eventPartipants.length === 0 ? (
            <TextOnly text={"No Participants"} />
          ) : (eventPartipants &&
            eventPartipants.map((x, i) => (
              <TextOnly key={i} text={x.FullName} />
            ))
          )}
        </Box>

        <Box display="flex" mt={2}>
          <Checkbox checked={addGuest} onClick={() => setAddGuest(!addGuest)} />
          <Box mt={1}>
            <TextOnly text="Invitee can add Guests to this Event." />
          </Box>
        </Box>
      </Box>

      <Box flexShrink={0}>
        <FooterButtons />
      </Box>
    </Box>
  );

  const EventScheduling = () => (
    <Box display="flex" flexDirection="column" height="100vh">
      <Box flexShrink={0}>
        <GoBackHeader header={"Scheduling Setting"} navigate={"scheduling"} />
        <Divider sx={{ mr: 2, ml: 2, mt: 3, mb: 2 }} />
      </Box>
      <Box flexGrow={1} overflow="auto" ml={1}>
        <Box flexGrow={1} overflow="auto" ml={1}>
          <ClickableRow
            text="Date Range"
            currentVal={"Not Needed"}
            content={
              <>
                <Box ml={1}>
                  <Box mb={1}>
                    <TextOnly
                      text="Can Be Scheduled in Following Range"
                      fontWeight={400}
                    />
                  </Box>

                  <CustomRadioButton
                    text="In Future Days"
                    checked={drawerSettings?.inFutureRange}
                    onChange={() => handleDateRangeRadButton("future")}
                  />
                  <Box display={"flex"} flexDirection={"column"}>
                    <CustomRadioButton
                      text={"Within a date range"}
                      checked={drawerSettings?.inDateRange}
                      onChange={() => handleDateRangeRadButton("daterange")}
                    />
                    {drawerSettings?.inDateRange && (
                      <Box ml={1} mb={2}>
                        <MultiDatePicker
                          selectedDates={
                            drawerSettings?.inDateRangeStartDate
                              ? [
                                  drawerSettings?.inDateRangeStartDate,
                                  drawerSettings?.inDateRangeEndDate,
                                ]
                              : []
                          }
                          // setSelectedDates={setSelectedDates}
                          handleSelectedDateRange={handleSelectedDateRange}
                        />
                      </Box>
                    )}
                  </Box>
                  <Box>
                    <CustomRadioButton
                      text={"Any Time in Future"}
                      checked={drawerSettings?.Indefinite}
                      onChange={() => handleDateRangeRadButton("indefinite")}
                    />
                  </Box>
                </Box>
              </>
            }
            expanded={isDateRangeRowExpanded}
            onToggle={() => setIsDateRangeRowExpanded((prev) => !prev)}
          />
        </Box>
        <Box mt={1}>
          {/* <ClickableRow
            text="Notify Before Event Start"
            currentVal={selectedDuration ? selectedDuration : "30 min"}
            content={EventNotification()}
          /> */}
          <ClickableRow
            text="Notify Before Event Start"
            currentVal={drawerSettings?.minimumNotice}
            content={
              <>
                <Box display="flex" alignItems="center" ml={3}>
                  <Box width={80}>
                    <InputText
                      isNumberOnly={true}
                      centerText={true}
                      value={drawerSettings?.minimumNotice?.split("-")[0]}
                      onChange={handleNotifyInterval}
                    />
                  </Box>
                  <Box width={"50%"} ml={2}>
                    <SelectInput
                      options={notifyIntervalOptions}
                      label="Duration"
                      value={
                        drawerSettings?.minimumNotice
                          ? drawerSettings.minimumNotice.split("-")[1]
                          : "Hour"
                      }
                      onChange={handleNotifyInterval}
                    />
                  </Box>
                </Box>
              </>
            }
            expanded={isNotifyIntervalRowExpanded}
            onToggle={() => setIsNotifyIntervalRowExpanded((prev) => !prev)}
          />
        </Box>
        <Box mt={1}>
          <ClickableRow
            text="Time Zone Display"
            currentVal={"Local"}
            content={
              <>
                <Box ml={3}>
                  <CustomRadioButton
                    text="Automatically Detect Time & display it to Invitees time Zone"
                    checked={drawerSettings?.isTimeZoneInvitee}
                    onChange={() => handleTimeZoneRadioButton("auto")}
                  />
                  <CustomRadioButton
                    text={"Lock time Zone"}
                    checked={drawerSettings?.isTimeZoneLocked}
                    onChange={() => handleTimeZoneRadioButton("locked")}
                  />
                </Box>
              </>
            }
            expanded={isTimeZoneRowExpanded}
            onToggle={() => setIsTimeZoneRowExpanded((prev) => !prev)}
          />
        </Box>

        <Box mt={1}>
          <ClickableRow
            text="Availability Interval"
            currentVal={drawerSettings?.AvailabilityTimeSlotsInterval}
            content={
              <>
                <Box display="flex" alignItems="center" ml={3}>
                  <Box width={80}>
                    <InputText
                      label="Time"
                      isNumberOnly={true}
                      centerText={true}
                      value={
                        drawerSettings?.AvailabilityTimeSlotsInterval?.split(
                          "-"
                        )[0]
                      }
                      onChange={handleAvailabilitySlotsInterval}
                    />
                  </Box>
                  <Box width={"50%"} ml={2}>
                    <SelectInput
                      options={availabilityIntervalOptions}
                      label="Duration"
                      value={
                        drawerSettings?.AvailabilityTimeSlotsInterval
                          ? drawerSettings.AvailabilityTimeSlotsInterval.split(
                              "-"
                            )[1]
                          : "Hour"
                      }
                      onChange={handleAvailabilitySlotsInterval}
                    />
                  </Box>
                </Box>
              </>
            }
            expanded={isNotifyIntervalRowExpanded}
            onToggle={() => setIsNotifyIntervalRowExpanded((prev) => !prev)}
          />

        </Box>
      </Box>
      <Box flexShrink={0}>
        <FooterButtons />
      </Box>
    </Box>
  );


  // const [questionTypes, setQuestionTypes] = useState([
  //   { id: 1, name: "Text", value: "Text" }, //Add words limit by default
  //   { id: 2, name: "Radio", value: "Radio" },
  //   { id: 3, name: "CheckBoxes", value: "CheckBoxes" },
  //   { id: 4, name: "DropDown", value: "DropDown" },
  //   { id: 5, name: "PhoneNumber", value: "PhoneNumber" },
  // ]);



  const EventAlerts = () => (
    <Box display="flex" flexDirection="column" height="100vh">
      <Box flexShrink={0}>
        <GoBackHeader header={"Notifications & Alerts"} navigate={"alerts"} />
        <Divider sx={{ mr: 2, ml: 2, mt: 3, mb: 2 }} />
      </Box>

      <Box flexGrow={1} overflow="auto" ml={2} mr={2}>
        <Box>
          <ClickableRow
            text={"CalendarInvitation"}
            customIcons={
              <IconButton onClick={console.log("Edit Clicked Calendar Nav")}>
                <EditIcon />
              </IconButton>
            }
            content={
              <>
                <Box ml={3}>
                  <TextOnly
                    text="Can Be Scheduled in Following Range"
                    fontWeight={400}
                  />
                  <CustomRadioButton text={"In Future Days"} mt={1} />
                  <CustomRadioButton text={"Within a date range"} mt={1} />
                  <CustomRadioButton text={"Any Time in Future"} />
                </Box>
              </>
            }
          />
        </Box>
        <Box mt={2}>
          <ClickableRow
            text={"Email Reminder"}
            customIcons={
              <Box display={"flex"}>
                <Switch checked={alertsDetails?.EmailReminder} />
                <IconButton
                  onClick={console.log("Edit Clicked Email Reminder")}
                >
                  <EditIcon />
                </IconButton>
              </Box>
            }
            content={
              <>
                <Box ml={3}>
                  <TextOnly
                    text="Can Be Scheduled in Following Range"
                    fontWeight={400}
                  />
                  <CustomRadioButton text={"In Future Days"} mt={1} />
                  <CustomRadioButton text={"Within a date range"} mt={1} />
                  <CustomRadioButton text={"Any Time in Future"} />
                </Box>
              </>
            }
          />
        </Box>

        <Box mt={2}>
          <ClickableRow
            text={"Text Reminder"}
            customIcons={
              <Box display={"flex"}>
                <Switch checked={alertsDetails?.TextReminder} />
                <IconButton onClick={console.log("Edit Clicked Text Reminder")}>
                  <EditIcon />
                </IconButton>
              </Box>
            }
            content={
              <>
                <Box ml={3}>
                  <TextOnly
                    text="Can Be Scheduled in Following Range"
                    fontWeight={400}
                  />
                  <CustomRadioButton text={"In Future Days"} mt={1} />
                  <CustomRadioButton text={"Within a date range"} mt={1} />
                  <CustomRadioButton text={"Any Time in Future"} />
                </Box>
              </>
            }
          />
        </Box>

        <Box mt={2}>
          <ClickableRow
            text={"Email Followup"}
            editIcon={true}
            customIcons={
              <Box display={"flex"}>
                <Switch
                  checked={alertsDetails?.EmailFollowup}
                  onClick={() => {
                    setAlertsDetails((prevState) => ({
                      ...prevState,
                      EmailFollowup: !prevState.EmailFollowup, // Toggle the EmailFollowup value
                    }));
                  }}
                />
              </Box>
            }
            content={
              <>
                <Box width={"100%"}>
                  <TextAreaWithUndoRedo />
                </Box>
              </>
            }
            expanded={isEmailFollowupRowExpanded}
            onToggle={() => setIsEmailFollowupRowExpanded((prev) => !prev)}
          />
        </Box>
      </Box>

      <Box flexShrink={0}>
        <FooterButtons />
      </Box>
    </Box>
  );

  const drawerContent = {
    Participant: <EventParticipants />,
    Scheduling: <EventScheduling />,
    Booking: <EventBookingOptions />,
    Alerts: <EventAlerts />,
  };

  return (
    <>
      {isSmallScreen ? (
        <>
          <SidePanelHeader />
          <ShowTabs />
          {tabValue === 0 ? <EventParticipants /> : <PreviewCalendar />}
        </>
      ) : (
        <Grid container>
          <Grid item sm={12} md={4}>
            <SidePanelHeader />
            <Box
              onClick={() => toggleDrawer("Participant")}
              display={"flex"}
              mt={2}
              ml={2}
            >
              <Box display={"flex"} flexDirection={"column"}>
                <EventIcon />
              </Box>
              <Box display={"flex"} flexDirection={"column"} ml={2}>
                <TextOnly color="#000000" text={"Event Details"} />
                <TextOnly text={"Duration"} />
                <TextOnly text={"Location"} />
              </Box>
              <Box ml={"auto"} mt={"auto"} mb={"auto"}>
                <ArrowForwardIosIcon />
              </Box>
            </Box>

            <Divider sx={{ mr: 2, ml: 2, mt: 3, mb: 2 }} />
            <Box
              onClick={() => toggleDrawer("Participant")}
              display={"flex"}
              mt={2}
              ml={2}
            >
              <Box display={"flex"} flexDirection={"column"}>
                <PeopleAltIcon />
              </Box>
              <Box display={"flex"} flexDirection={"column"} ml={2}>
                <TextOnly text={"Participants"} />
                <TextOnly text={"Name"} />
                <TextOnly text={"Event Type"} />
              </Box>
              <Box ml={"auto"} mt={"auto"} mb={"auto"}>
                <ArrowForwardIosIcon />
              </Box>
            </Box>

            <Divider sx={{ mr: 2, ml: 2, mt: 3, mb: 2 }} />

            <Box
              onClick={() => toggleDrawer("Scheduling")}
              display={"flex"}
              mt={2}
              ml={2}
            >
              <Box display={"flex"} flexDirection={"column"}>
                <CalendarMonthIcon />
              </Box>
              <Box display={"flex"} flexDirection={"column"} ml={2}>
                <TextOnly text={"Event Scheduling"} />
                <TextOnly text={"Option Selected"} />
                <TextOnly text={"Availability"} />
              </Box>
              <Box ml={"auto"} mt={"auto"} mb={"auto"}>
                <ArrowForwardIosIcon />
              </Box>
            </Box>

            <Divider sx={{ mr: 2, ml: 2, mt: 3, mb: 2 }} />

            <Box
              onClick={() => toggleDrawer("Booking")}
              display={"flex"}
              mt={2}
              ml={2}
            >
              <Box display={"flex"} flexDirection={"column"}>
                <WebIcon />
              </Box>
              <Box display={"flex"} flexDirection={"column"} ml={2}>
                <TextOnly text={"Booking Options"} />
                <TextOnly text={"Questions "} />
                <TextOnly text={"Confirmation Page"} />
              </Box>
              <Box ml={"auto"} mt={"auto"} mb={"auto"}>
                <ArrowForwardIosIcon />
              </Box>
            </Box>

            <Divider sx={{ mr: 2, ml: 2, mt: 3, mb: 2 }} />

            <Box
              onClick={() => toggleDrawer("Alerts")}
              display={"flex"}
              mt={2}
              ml={2}
            >
              <Box display={"flex"} flexDirection={"column"}>
                <NotificationsActiveIcon />
              </Box>
              <Box display={"flex"} flexDirection={"column"} ml={2}>
                <TextOnly text={" Notifications & Alerts"} />
                <TextOnly text={"Selected Reminders "} />
                <TextOnly text={"Confirmation Page"} />
              </Box>
              <Box ml={"auto"} mt={"auto"} mb={"auto"}>
                <ArrowForwardIosIcon />
              </Box>
            </Box>

            <Drawer
              anchor="left"
              open={Boolean(drawerOpen)}
              variant="persistent"
              sx={{
                "& .MuiDrawer-paper": {
                  width: isSmallScreen ? "100vw" : "35vw",
                  backgroundColor: "#ffffff",
                },
              }}
            >
              {drawerContent[drawerOpen]}
            </Drawer>
          </Grid>
          <Grid item sm={12} md={8}>
            <PreviewCalendar />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default EventDetails;
