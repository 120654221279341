import { StayPrimaryPortraitTwoTone } from "@mui/icons-material";
import React from "react";
const baseUrl = process.env.REACT_APP_BASEURL;

export const AvailabilityService = {
  Get: async (id, setWorkingDays) => {
    try {
      const apiUrl = `${baseUrl}Availability`;
      console.log("Inside AvailabilityService: <params>", id);

      const data = JSON.stringify({
        Id: id,
      });
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: data,
      });
      const responseObject = await response.json();
      const res = responseObject["Info"];
      // const leaves = responseObject["leavesData"];

      console.log("Info:", res);
      setWorkingDays(res);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  Save: async (id, workingDays) => {
    try {
      const apiUrl = `${baseUrl}SaveAvailability`;
      console.log("Inside SaveAvailabilityService: <params>", id, workingDays);

      const data = JSON.stringify({
        Id: id,
        DaySchedules: workingDays,
      });
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: data,
      });
      const responseObject = await response.json();
      const res = responseObject["Info"];
      console.log("Info:", res);
      return res;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
};
export const UnAvailabilityService = {
  Get: async (id, setLeaveData) => {
    try {
      const apiUrl = `${baseUrl}UnAvailability`;
      console.log("Inside UnAvailabilityService: <params>", id);

      const data = JSON.stringify({
        LoginsId: id,
      });
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: data,
      });
      const responseObject = await response.json();
      const res = responseObject["Info"];
      console.log("Info:", res);
      setLeaveData(res);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  SaveWorkOffTime: async (id, date, start, end) => {
    try {
      const apiUrl = `${baseUrl}SaveWorkOff`;
      console.log("Inside SaveWorkOffTime: <params>", id, date, start, end);
      const data = JSON.stringify({
        Id: id,
        Date: date,
        StartTime: start,
        EndTime: end,
      });
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: data,
      });
      const responseObject = await response.json();
      const res = responseObject["Info"];
      console.log("Info:", res);
      return res;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  SaveLeaves: async (id, SaveWorkOffTime) => {
    try {
      const apiUrl = `${baseUrl}SaveLeaves`;
      const data = JSON.stringify({
        Id: id,
        Days:SaveWorkOffTime
      });
      console.log("Inside SaveLeaves: <params>", id, data);
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: data,
      });
      const responseObject = await response.json();
      const res = responseObject["Info"];
      console.log("Info:", res);
      return res;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
};
export default { AvailabilityService, UnAvailabilityService };
