import React from "react";
import { TextField, useTheme } from "@mui/material";

const InputText = ({
  label,
  value,
  onChange,
  editable = true,
  isNumberOnly=false,
  centerText=false,
  placeholder,
  fullWidth = false,
  required = false,
  variant = "outlined",
  height = "42px",
  labelFontWeight = 500, // Default font weight for the label
  textFontWeight = 500, // Default font weight for the input text
  labelColor = "#808080", // Default label color (grey)
  textColor = "#808080", // Default input text color (grey)
  ...props
}) => {

  return (
    <TextField
      label={label}
      value={value}
      contentEditable={editable}
      onChange={(e) => {
        if (isNumberOnly) {
          // Allow only numbers
          const numericValue = e.target.value.replace(/[^0-9]/g, "");
          onChange(numericValue);
        } else {
          onChange(e.target.value);
        }
      }}
      placeholder={placeholder}
      fullWidth={fullWidth}
      required={required}
      variant={variant}
      size="small"
      sx={{
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            // Default border color
            borderColor: "#d3d3d3",
          },
          "&:hover fieldset": {
            // Keep the border color the same on hover
            borderColor: "#d3d3d3",
          },
          "&.Mui-focused fieldset": {
            // Change the border color to match the URL color in typography when focused
            borderColor: "#0069FF", // Replace this with your URL color if different
            
          },"& input:focus":{
            boxShadow:"none",
          }
        },
      }}
      {...props}
      InputLabelProps={{
        sx: {
          color: labelColor,
          fontWeight: labelFontWeight,
        },
      }}
      InputProps={{
        sx: {
          height: height,
          color: textColor,
          fontWeight: textFontWeight,
          backgroundColor: "#ffffff", // Set background color inside the text field to white
          textAlign: "center", // Center or left align text based on the prop
        },
        inputProps: {
          inputMode: isNumberOnly ? "numeric" : "text", // Enable number keyboard for mobile devices
        },
      }}
    />
  );
};

export default InputText;

// <TextField
//       label={label}
//       value={value}
//       contentEditable={editable}
//       onChange={onChange}
//       placeholder={placeholder}
//       fullWidth={fullWidth}
//       required={required}
//       variant={variant}
//       size="small"
//       sx={{"&hover"}}
//       {...props}
//       InputLabelProps={{
//         sx: {
//           color: labelColor,
//           fontWeight: labelFontWeight,
//         },
//       }}
//       InputProps={{
//         sx: {
//           height: height,
//           color: textColor,
//           fontWeight: textFontWeight,
//         },
//       }}
//     />
