const baseUrl = process.env.REACT_APP_BASEURL;

export const PaymentService = {
    PaymentForPlan: async (data) => {
        try {
          const apiUrl = `${baseUrl}SubscribePlan`;
          console.log("Inside PaymentService => PaymentForPlan", apiUrl);
    
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
              AppName: "DbLocal",
              "Content-Type": "application/json",
            },
            body:data
          });
    
          return response.json();
        } catch (error) {
          console.error("Error PaymentForPlan:", error);
          throw error;
        }
      }

}
export default {PaymentService}