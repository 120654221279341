import React, { useState } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import zoomLogo from "../../Icons/icon_Zoom.png";
import inPersonLogo from "../../Icons/icon_inperson.png";
import phoneLogo from "../../Icons/icon_Phone.png";
import GoogleMeet from "../../Icons/icon_GoogleMeet.png";
import MicrosoftTeams from "../../Icons/icon_Teams.png";
import Webex from "../../Icons/icon_webex.png";

import TextOnly from "../UIComponents/TextOnly";

const MeetingOptions = ({ handlephoneMOpen, handleLocation,handleinpersonMOpen }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
const handleChange = (val,name) => {
  if (val.includes("phone")) {
    handlephoneMOpen();
  } else if (val.includes("inperson")) {
    handleinpersonMOpen();
  } else {
    handleLocation(val, name);
  }
}
  const options = ["Google Meet", "Microsoft Teams", "Webex"];

  return (
    <Grid item sm={12} container justifyContent="left" alignItems="center">
      {[
        {
          logo: zoomLogo,
          text: "Zoom",
          value: "zoom",
        },
        {
          logo: GoogleMeet,
          text: "Google Meet",
          value: "google",
        },
        {
          logo: MicrosoftTeams,
          text: "Teams",
          value: "teams",
        },
        {
          logo: Webex,
          text: "Webex",
          value: "webex",
        },
        {
          logo: phoneLogo,
          text: "Phone Call",
          value: "phone",
        },
        {
          logo: inPersonLogo,
          text: "In-Person",
          value: "inperson",
        },
      ].map((item, index) => (
        <Grid item key={index} marginRight={3}>
          <Card
            elevation={0}
            sx={{
              backgroundColor: theme.palette.common.white,
              // border:"0.1rem solid #3f51b5",
              borderRadius: "8px",
              // boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.8s ease",
              width: "90px",
              height: "80px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              // margin: 1,
              "&:hover": {
                transform: "scale(1.03)",
                // boxShadow: "0 6px 30px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <CardActionArea
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: "transparent", // Ensure no hover background
                },
                "&.Mui-focusVisible": {
                  backgroundColor: "transparent", // Remove focus background color
                },
                
              }}
              disableRipple
              disableTouchRipple
              onClick={() => handleChange(item.value,item.text)}
            >
              <img
                src={item.logo}
                style={{ height: "35px", width: "35px" }}
                alt={item.text}
              />
              <CardContent sx={{ padding: 0, textAlign: "center" }}>
                <TextOnly text={item.text} fontSize={13} />
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
      {/* <Grid item>
        <Card
          elevation={8}
          sx={{
            backgroundColor: theme.palette.common.white,
            borderRadius: "8px",
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
            transition: "transform 0.3s ease",
            width: "90px",
            height: "90px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            "&:hover": {
              transform: "scale(1.03)",
              boxShadow: "0 6px 30px rgba(0, 0, 0, 0.15)",
            },
          }}
          onClick={handleMenuOpen}
        >
          <CardActionArea
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "transparent", // Ensure no hover background
              },
              "&.Mui-focusVisible": {
                backgroundColor: "transparent", // Remove focus background color
              },
            }}
            disableRipple
            disableTouchRipple
          >
            <ArrowDropDownIcon sx={{ fontSize: 40 }} />
            <CardContent sx={{ padding: 0, textAlign: "center" }}>
              <TextOnly text="More" fontSize={15} />
            </CardContent>
          </CardActionArea>
        </Card>
        <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
          {options.map((option, index) => (
            <MenuItem key={index} onClick={handleMenuClose}>
              {option}
            </MenuItem>
          ))}
        </Menu>
      </Grid> */}
    </Grid>
  );
};

export default MeetingOptions;
