import React from "react";
import { Typography, Container, Button, Grid } from "@mui/material";
import { styled } from '@mui/system';

const HeaderContainer = styled(Container)(({ theme }) => ({
  height: "8vh",
  display: "flex",
  alignItems: "center",
  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
  fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
  fontSize: '0.8rem',
  minWidth: '100%',
  marginTop: 0,
  padding: "0 1rem",
  [theme.breakpoints.down('sm')]: {
    padding: "1rem 1rem",
  },
}));

const MuiHeader = ({ onSave, text, fontWeight = 600, showSaveButton = false, cusButton }) => {
  return (
    <HeaderContainer>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={12} sm={6} >
          <Typography
            variant="h5"
            component="h2"
            sx={{
              fontWeight: fontWeight,
              color: "#808090",
              textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
              marginBottom: { xs: "0.5rem", sm: "0" },
            }}
          >
            {text}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={3} sx={{ display: 'flex', justifyContent: { xs: 'flex-start', sm: 'flex-end' } }}>
          {cusButton || (showSaveButton && (
            <Button
              variant="contained"
              color="secondary"
              onClick={onSave}
              sx={{
                borderRadius: "20px",
                fontSize: "14px",
                fontWeight: 600,
                padding: "4px 16px",
              }}
            >
              Save
            </Button>
          ))}
        </Grid>
      </Grid>
    </HeaderContainer>
  );
};

export default MuiHeader;

// import React from "react";
// import { Typography, Container, Button, Box } from "@mui/material";
// import { styled } from '@mui/system';

// const HeaderContainer = styled(Container)({
//   height: "8vh",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "space-between",
//   // background: "linear-gradient(90deg, #6dd5fa, #2980b9)", // Lighter shades of blue
//   boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)", // Clean shadow
//   fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
//   // transition: "transform 0.2s, box-shadow 0.2s", // Smooth transition for hover effects
//   // '&:hover': {
//   //   transform: "scale(1.02)", // Slightly scale up on hover
//   //   boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)", // Slightly stronger shadow on hover
//   // },
//   fontSize: '0.8rem',
//   minWidth: '100%',
//   marginTop: 0,
//   padding: "0 1rem", // Add some padding to left and right
// });

// const MuiHeader = ({ onSave,text, fontWeight = 600,bgColor,showSaveButton = false,cusButton }) => {
//   return (
//     <HeaderContainer>
//       <Typography
//         variant="h5"
//         component="h2"
//         sx={{
//           fontWeight: fontWeight,
//           color: "#808090", // White text color for contrast
//           textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)", // Subtle text shadow
//         }}
//       >
//         {text}
//       </Typography>
//       {cusButton || (showSaveButton && (
//         <Button
//           variant="contained"
//           color="secondary"
//           onClick={onSave}
//           sx={{
//             borderRadius: "20px",
//             fontSize: "14px",
//             fontWeight: 600,
//             padding: "4px 16px",
//           }}
//         >
//           Save
//         </Button>
//       ))}
//     </HeaderContainer>
//   );
// };

// export default MuiHeader;
