

 import React, { useEffect } from "react";
 import { PublicClientApplication } from "@azure/msal-browser";
 import CryptoJS from "crypto-js";
 import { useDispatch } from "react-redux";
 import { addType } from "../../features/user/calendarTypeSlice";
 import { Box } from "@mui/material";
 import ButtonDropDown from "../UIComponents/ButtonDropDown";
 import CalendarService from "../../services/CalendarService";
 import { InfoWrapper } from "../../utils/InfoWrapper";
 import withLoader from "../Loader/withLoader";
 import { useNavigate } from "react-router-dom";
//  const {redirectUI} = InfoWrapper();
const redirectUI = process.env.REACT_APP_REDIRECTURI;
 const msalConfig = {
   auth: {
     clientId: "79edece2-16fd-44fd-9f72-40f601d7b1a8",
     authority:
       "https://login.microsoftonline.com/ae64d763-4d46-42b8-bc8f-386a62c62cd6",
     redirectUri: redirectUI,
   },
 };
 
 const msalInstance = new PublicClientApplication(msalConfig);
 await msalInstance.initialize();
 
 const MSCalendar = ({
   calendarType,
   clientId,
   redirectUri,
   codeChallenge,
   setIsAuthenticated,
   setAccessToken,
   buttonText,
 }) => {
   const dispatch = useDispatch();
   const { user } = InfoWrapper();
   const navigate = useNavigate();
   clientId = "79edece2-16fd-44fd-9f72-40f601d7b1a8";
   redirectUri = `${redirectUI}Integrations/Outlook/Connect`;
   let codeVerifier = "cdabcdccabcdabcdccabcdabcdccabcdabcdcc";
   calendarType = 1;
   function generateCodeChallenge() {
     const sha256Hash = CryptoJS.SHA256(codeVerifier);
     const base64EncodedHash = CryptoJS.enc.Base64.stringify(sha256Hash);
     const urlSafeCodeChallenge = base64EncodedHash
       .replace(/\+/g, "-")
       .replace(/\//g, "_")
       .replace(/=/g, "");
 
     return urlSafeCodeChallenge;
   }
   const handleMicrosoftLogin = async () => {
     try {
       const authorizationUrl =
         "https://login.microsoftonline.com/common/oauth2/v2.0/authorize" +
         `?response_type=code` +
         `&client_id=${encodeURIComponent(clientId)}` +
         `&scope=Calendars.ReadWrite%20OnlineMeetings.ReadWrite%20User.Read%20offline_access` +
         `&redirect_uri=${encodeURIComponent(redirectUri)}` +
         `&code_challenge=${encodeURIComponent(
           "abcdabcdccabcdabcdccabcdabcdccabcdabcdccabcdabcdcc"
         )}` +
         `&code_challenge_method=plain`;
 
       window.location.href = authorizationUrl;
     } catch (error) {
       console.error("Login error:", error);
     }
   };
 
   useEffect(() => {
     const handleRedirect = async () => {
       try {
         const urlParams = new URLSearchParams(window.location.search);
         const code = urlParams.get("code");
         if (code) {
           let res = await withLoader(dispatch, () =>
             CalendarService.MSCalendarService.SaveMsCalendar(user.uid, code, 1)
           );
           if (res.Info) {
             navigate("/Integrations/Outlook/Connect");
           }
         }
       } catch (error) {
         console.error("Redirect error:", error);
       }
     };
 
     handleRedirect();
   }, []);
 
   const exchangeAuthorizationCodeForToken = async (code) => {};
 
   const fetchCalendarEvents = async (token) => {};
 
   return (
     <Box>
       <ButtonDropDown onClick={handleMicrosoftLogin} text="Connect Microsoft" />
     </Box>
   );
 };
 
 export default MSCalendar;
 


