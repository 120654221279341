import {  createSlice } from '@reduxjs/toolkit';

export const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState : {
    user:null
  },
  reducers: {
    AdduserDetails: (state,action) => {
        debugger;
      state.userDetails = action.payload;
    },
    
  },
 
});

export const { AdduserDetails } = userDetailsSlice.actions;

//Slicers
export const selectUserDetails= (state) => state.userDetails;
export default userDetailsSlice.reducer;
