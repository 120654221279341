import { useSelector } from "react-redux";
import { selectUser } from "../features/user/userSlice";
import { useTheme } from "@mui/material";
import { selectUserDetails } from "../features/user/userDetailsSlice";

export const InfoWrapper = () => {
  const user = useSelector(selectUser);
  const userDt = useSelector(selectUserDetails);
  const theme = useTheme();
  let textColor = theme.palette.common.grey;
  let borderColor = "#B0B0B0";
  let baseUrl = process.env.REACT_APP_BASEURL;
  let redirectUI = process.env.REACT_APP_REDIRECTURI;
  console.log("Redux State:", user);
 console.log("Theme Context:", theme);
  return { user,userDt, textColor, borderColor,baseUrl,redirectUI };
};
