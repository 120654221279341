import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import ButtonDropDown from "../UIComponents/ButtonDropDown";
import SyncIcon from "@mui/icons-material/Sync";
import ChecklistIcon from "@mui/icons-material/Checklist";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import StoreIcon from "@mui/icons-material/Store";
import LinkIcon from "@mui/icons-material/Link";

const ConnectTeams = () => {
  function onClick() {
    console.log("=========Zoom===========");
  }
  return (
    <>
      <Box
        sx={{
          padding: "3rem",
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
          backgroundColor: "#ffffff",
          marginBottom: "2rem",
          overflowY: "auto",
          margin: 5,
        }}
      >
        <Grid container spacing={3}>
          {/* Header Section */}
          <Grid item xs={12}>
            <Typography variant="h6" color="primary" gutterBottom>
              <SyncIcon
                sx={{
                  fontSize: "1.5rem",
                  verticalAlign: "middle",
                  marginRight: "0.5rem",
                }}
              />
              Connect Teams
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Create Microsoft Teams Meetings when an event is scheduled.
            </Typography>
          </Grid>

          {/* Requirements Section */}
          <Grid item xs={12} mt={2}>
            <Typography variant="h6" color="primary" gutterBottom>
              <ChecklistIcon
                sx={{
                  fontSize: "1.5rem",
                  verticalAlign: "middle",
                  marginRight: "0.5rem",
                }}
              />
              Requirements
            </Typography>
            <Typography variant="body1" color="textSecondary">
              <AccountCircleIcon
                sx={{
                  fontSize: "1.2rem",
                  verticalAlign: "middle",
                  marginRight: "0.3rem",
                }}
              />
              Zoom Account
            </Typography>
            <Typography variant="body1" color="textSecondary">
              <StoreIcon
                sx={{
                  fontSize: "1.2rem",
                  verticalAlign: "middle",
                  marginRight: "0.3rem",
                }}
              />
              Pre-approve Synclead in the Teams Marketplace
            </Typography>
          </Grid>

          {/* Connect Button */}
          <Grid item xs={12} mt={2}>
            <ButtonDropDown
              text="Connect Zoom"
              onClick={onClick}
              startIcon={<LinkIcon />}
              sx={{
                backgroundColor: "#1976d2",
                color: "#ffffff",
                fontSize: "1rem",
                padding: "0.75rem 1.5rem",
                borderRadius: "4px",
                "&:hover": {
                  backgroundColor: "#1565c0",
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ConnectTeams;
