import React, { useState, useEffect } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Checkbox,
  IconButton,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import StyledTextField from "../UIComponents/StyledTextField";

const UserListWithSearch = ({ participants, setParticipants }) => {
  const [users, setUsers] = useState(participants);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(participants);
  const [selectedUsers, setSelectedUsers] = useState([]);

  // Filter users based on the search term
  useEffect(() => {
    setFilteredUsers(
      users.filter((user) =>
        `${user.firstName} ${user.lastName}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, users]);

  // Handle checkbox toggle for selecting users
  const handleToggle = (userId) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(userId)
        ? prevSelected.filter((id) => id !== userId)
        : [...prevSelected, userId]
    );
  };

  // Handle delete for selected users
  const handleDeleteSelected = () => {
    const updatedUsers = users.filter(
      (user) => !selectedUsers.includes(user.id)
    );
    setUsers(updatedUsers);
    setParticipants(updatedUsers);
    setSelectedUsers([]); // Clear the selected users
  };

  // Helper function to determine avatar content or fallback to a letter
  const getAvatarContent = (avatar, firstName) => {
    if (avatar && avatar.length > 1) {
      return avatar;
    }
    return firstName.charAt(0).toUpperCase();
  };

  return (
    <Box>
      {/* Search Field */}

      {/* Delete Icon */}
      <Box display={"flex"} justifyContent={"flex-end"}>
        <StyledTextField
          fullWidth
          label="Search Users"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Grid item >
          <IconButton
            color={selectedUsers.length > 0 ? "secondary" : "default"}
            onClick={handleDeleteSelected}
            disabled={selectedUsers.length === 0}
            sx={{ mt: 1,ml:1 }}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Box>
      {/* List of Users */}
      <Box
        sx={{
          maxHeight: "200px",
          overflowY: "auto",
          backgroundColor: "#ffffff",
          mt:1
        }}
      >
        <List>
          {filteredUsers.map((user) => (
            <ListItem
              key={user.id}
              secondaryAction={
                <Checkbox
                  edge="end"
                  onChange={() => handleToggle(user.id)}
                  checked={selectedUsers.includes(user.id)}
                />
              }
            >
              <ListItemAvatar>
                <Avatar
                  src={user.avatar?.length > 1 ? user.avatar : null}
                  sx={{ backgroundColor: user.bgcolor }}
                >
                  {!(user.avatar?.length > 1) && user.letter}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${user.firstName} ${user.lastName}`}
                sx={{ color: "#808080" }}
              />
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Optional message when no users are found */}
      {filteredUsers.length === 0 && (
        <Typography variant="body2" color="textSecondary" mt={2}>
          No users found
        </Typography>
      )}
    </Box>
  );
};

export default UserListWithSearch;
