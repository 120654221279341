const baseUrl = process.env.REACT_APP_BASEURL;

export const TeamsService = {
  CreateTeam: async (data) => {
    try {
      const apiUrl = `${baseUrl}CreateTeam`;

      // Create a FormData object and append all the necessary fields
      const formData = new FormData();
      formData.append("SpaceId", data.spacetype);
      formData.append("LoginsId", data.user.uid);
      formData.append("TeamName", data.teamName);
      formData.append("TeamUrl", data.teamUrl);
      formData.append("Avatar", data.uploadedfile);
      formData.append("Members", JSON.stringify(data.teamMembers));

      console.log(
        "Inside TeamsService => CreateTeam",
        JSON.stringify(data.teamMembers)
      );

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          AppName: "DbLocal",
        },
        body: formData,
      });

      return await response.json();
    } catch (error) {
      console.error("Error Creating Team:", error);
      throw error;
    }
  },
  InviteTeamUsers: async (user, data, spacetype) => {
    try {
      const apiUrl = `${baseUrl}InviteTeamUser`;

      let name = user.firstName + " " + user.lastName;
      const apiData = JSON.stringify({
        LoginsId: user.uid,
        SpaceId: spacetype,
        EmailTo: data,
        SenderName: name,
      });

      console.log(
        "Inside TeamsService => CreateUser",
        "spacetype",
        spacetype,
        apiData
      );

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: apiData,
      });

      return await response.json();
    } catch (error) {
      console.error("Error Creating Team:", error);
      throw error;
    }
  },
  GetTeams: async (id) => {
    try {
      const apiUrl = `${baseUrl}GetTeams`;
      const data = JSON.stringify({
        SpaceId: id,
      });

      console.log("Inside TeamsService => GetTeams", apiUrl, data);

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: data,
      });

      return response.json();
    } catch (error) {
      console.error("Error GetTeams Team:", error);
      throw error;
    }
  },

  getTeamsbyTeamId: async (req) => {
    try {
      const apiUrl = `${baseUrl}GetTeamsInfobyId`;
      const data = JSON.stringify({
        SpaceId: req.spaceId,
        TeamId: req.TeamId,
      });

      console.log("Inside TeamsService => getTeamsbyTeamId", apiUrl, req);

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: data,
      });

      return response.json();
    } catch (error) {
      console.error("Error GetTeams Team:", error);
      throw error;
    }
  },

  getTeamUsersbyName: async (text, spaceId) => {
    try {
      const apiUrl = `${baseUrl}GetTeamUsersbyName`;
      const data = JSON.stringify({
        Text: text,
        SpaceId: spaceId,
      });

      console.log("Inside TeamsService => getTeamUsersbyName", apiUrl, data);

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: data,
      });
      const result = await response.json();
      // let test= result.Info.map(user => `${user.FirstName} ${user.LastName}`);
      return result.Info;
    } catch (error) {
      console.error("Error getTeamUsersbyName:", error);
      throw error;
    }
  },
};

export default { TeamsService };
