import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  useTheme,
} from "@mui/material";
import {
  Dashboard as DashboardIcon,
  TableChart as TableChartIcon,
  CalendarToday as CalendarTodayIcon,
  Chat as ChatIcon,
  BarChart as BarChartIcon,
  Warning as WarningIcon,
  Settings as SettingsIcon,
  ExitToApp as ExitToAppIcon,
  Menu as MenuIcon,
  EditCalendar as EditCalendarIcon,
} from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { styled } from "@mui/system";

const drawerWidth = 210;

const CustomDrawer = styled(Drawer)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

// const CustomNavLink = styled(NavLink)(({ theme }) => ({
//   color: theme.palette.text.primary,
//   textDecoration: "none",
//   "&.active": {
//     background: "linear-gradient(90deg, #6dd5fa, #2980b9)",
//     color: theme.palette.common.white,
//     "& .MuiListItemIcon-root": {
//       color: theme.palette.common.white
//     },
//     "& .MuiListItemText-root": {
//       color: theme.palette.common.white
//     }
//   },
//   "&:hover": {
//     background: "rgba(0, 0, 0, 0.1)"
//   },
//   "&.active:hover": {
//     background: "linear-gradient(90deg, #4facfe, #00f2fe)"
//   }
// }));
const CustomNavLink = styled(NavLink)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: "none",
  "&.active": {
    background: "linear-gradient(90deg, #6dd5fa, #2980b9)",
    BorderBottom: 6,

    color: theme.palette.common.white,
    transition: "background 1.5s ease",
    "& .MuiListItemIcon-root": {
      color: theme.palette.common.white,
      BorderBottom: "4px dotted blue",
    },
    "& .MuiListItemText-root": {
      color: theme.palette.common.white,
    },
    "& .MuiButtonBase-root": {
      background: "linear-gradient(90deg, #21618c, #5499c7)",
      color: theme.palette.common.white,
      transition: "background 1.8s ease",
    },
  },
  "&:hover": {
    "& .MuiButtonBase-root": {
      background: "linear-gradient(90deg, #21618c, #5499c7)",
      color: theme.palette.common.white,
      transition: "background 1.8s ease",
    },
    "& .MuiListItemIcon-root": {
      color: theme.palette.common.white,
    },
    "& .MuiListItemText-root": {
      color: theme.palette.common.white,
    },
  },
  "& .MuiListItemText-root": {
    color: "#dff5f7",
  },
  "& .MuiListItemIcon-root": {
    color: "#dff5f7",
  },
}));

const SidePanel = () => {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <List>
        <CustomNavLink to="/" exact activeClassName="active">
          <ListItem button>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
        </CustomNavLink>
        {/* <CustomNavLink to="/leads" activeClassName="active">
          <ListItem button>
            <ListItemIcon>
              <TableChartIcon />
            </ListItemIcon>
            <ListItemText primary="Leads" />
          </ListItem>
        </CustomNavLink> */}
        <CustomNavLink to="/calendar" activeClassName="active">
          <ListItem button>
            <ListItemIcon>
              <CalendarTodayIcon />
            </ListItemIcon>
            <ListItemText primary="Calendar" />
          </ListItem>
        </CustomNavLink>
        {/* <CustomNavLink to="/conversations" activeClassName="active">
          <ListItem button>
            <ListItemIcon>
              <ChatIcon />
            </ListItemIcon>
            <ListItemText primary="Conversations" />
          </ListItem>
        </CustomNavLink> */}
        <CustomNavLink to="/analytics" activeClassName="active">
          <ListItem button>
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="Analytics" />
          </ListItem>
        </CustomNavLink>
        <CustomNavLink to="/availability" activeClassName="active">
          <ListItem button>
            <ListItemIcon>
              <WarningIcon />
            </ListItemIcon>
            <ListItemText primary="Availability" />
          </ListItem>
        </CustomNavLink>
        <CustomNavLink to="/Integrations" activeClassName="active">
          <ListItem button>
            <ListItemIcon>
              <EditCalendarIcon />
            </ListItemIcon>
            <ListItemText primary="App Integrations" />
          </ListItem>
        </CustomNavLink>
        <CustomNavLink to="/Settings/UserDetails" activeClassName="active">
          <ListItem button>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Details" />
          </ListItem>
        </CustomNavLink>

        <CustomNavLink to="/logout" activeClassName="active">
          <ListItem button>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="LogOut" />
          </ListItem>
        </CustomNavLink>
      </List>
    </div>
  );

  return (
    <div style={{ display: "flex" }}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        style={{ display: mobileOpen ? "none" : "block" }}
      >
        <MenuIcon />
      </IconButton>
      <CustomDrawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            background: "linear-gradient(90deg, #1a237e, #3949ab)",
            // background: "#9c27b0",
          },
        }}
      >
        {drawer}
      </CustomDrawer>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          [`& .MuiDrawer-paper`]: {
            boxSizing: "border-box",
            width: drawerWidth,
            background: "linear-gradient(90deg, #1a237e, #3949ab)",
          },
        }}
      >
        {drawer}
      </Drawer>
    </div>
  );
};

export default SidePanel;

// import React, { useState, useEffect } from "react";
// import { Layout, Menu } from "antd";
// import {
//   AppstoreOutlined,
//   TableOutlined,
//   CalendarOutlined,
//   UserOutlined,
//   LineChartOutlined,
//   ExclamationCircleOutlined,
//   SettingOutlined,
//   LogoutOutlined,
// } from "@ant-design/icons";
// import { NavLink } from "react-router-dom";
// import "../../styles/SidePanel.css";
// import { styled } from "@mui/material";

// const { Sider } = Layout;
// const { SubMenu } = Menu;

// const SidePanel = () => {
//   const [collapsed, setCollapsed] = useState(false);

//   const toggleCollapsed = () => {
//     setCollapsed(!collapsed);
//   };

//   const handleResize = () => {
//     setCollapsed(window.innerWidth <= 500);
//   };

//   useEffect(() => {
//     window.addEventListener("resize", handleResize);
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const items = [
//     {
//       key: "1",
//       label: "DashBoard",
//       icon: <AppstoreOutlined />,
//       to: "/",
//     },
//     {
//       key: "2",
//       label: "Leads",
//       icon: <TableOutlined />,
//       to: "/leads",
//     },
//     {
//       key: "3",
//       label: "Calendar",
//       icon: <CalendarOutlined />,
//       to: "/calendar",
//     },
//     {
//       key: "4",
//       label: "Conversations",
//       icon: <UserOutlined />,
//       to: "/conversations",
//     },
//     {
//       key: "5",
//       label: "Analytics",
//       icon: <LineChartOutlined />,
//       to: "/analytics",
//     },
//     {
//       key: "6",
//       label: "Availability",
//       icon: <ExclamationCircleOutlined />,
//       to: "/availability",
//     },
//     {
//       key: "7",
//       label: "Settings",
//       icon: <SettingOutlined />,
//       children: [
//         {
//           key: "s1",
//           label: "Company Details",
//           to: "/settings/companydetails",
//         },
//         {
//           key: "s2",
//           label: "Sync Calendar",
//           to: "/settings/synccalendar",
//         },
//       ],
//     },
//     {
//       key: "8",
//       label: "LogOut",
//       icon: <LogoutOutlined />,
//       to: "/logout",
//     },
//   ];

//   return (
//     <Layout style={{ minHeight: "100vh" }} >
//       <Sider
//         style={{ background: "linear-gradient(90deg, #1a237e, #3949ab)" }}
//         collapsible
//         collapsed={collapsed}
//         onCollapse={toggleCollapsed}
//       >
//         <div className="logo" />
//         <Menu
//           defaultSelectedKeys={["1"]}
//           defaultOpenKeys={["7"]}
//           mode="inline"
//           color="red"
//           style={{
//             background: "linear-gradient(90deg, #1a237e, #3949ab)",
//             color: "#F9F6EE",
//             textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
//             fontWeight: 500,

//           }}
//         >
//           {items.map((item) =>
//             item.children ? (
//               <SubMenu
//                 key={item.key}
//                 icon={item.icon}
//                 title={item.label}
//                 style={{
//                   color: "#F9F6EE",
//                   textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
//                   fontWeight: 500,
//                 }}
//               >
//                 {item.children.map((subItem) => (
//                   <Menu.Item
//                     key={subItem.key}
//                     style={{
//                       color: "#F9F6EE",
//                       textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
//                       fontWeight: 500,
//                     }}
//                   >
//                     <NavLink to={subItem.to} activeClassName="activeClicked">
//                       {subItem.label}
//                     </NavLink>
//                   </Menu.Item>
//                 ))}
//               </SubMenu>
//             ) : (
//               <Menu.Item
//                 key={item.key}
//                 icon={item.icon}
//                 onClick={() => console.log(`Clicked ${item.label}`)}
//                 style={{
//                   padding: "12px 24px",
//                   transition: "all 0.3s",
//                   color: "#F9F6EE",
//                   textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
//                   fontWeight: 500,
//                 }}
//               >
//                 <NavLink
//                   to={item.to}
//                   activeClassName="activeClicked"
//                   style={{
//                     color: "#F9F6EE",
//                     textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
//                     fontWeight: 500,
//                   }}
//                 >
//                   {item.label}
//                 </NavLink>
//               </Menu.Item>
//             )
//           )}
//         </Menu>
//       </Sider>
//     </Layout>
//   );
// };

// export default SidePanel;

// import React, { useState, useEffect } from 'react';
// import { Layout, Menu } from 'antd';
// import {
//   AppstoreOutlined,
//   TableOutlined,
//   CalendarOutlined,
//   UserOutlined,
//   LineChartOutlined,
//   ExclamationCircleOutlined,
//   SettingOutlined,
//   LogoutOutlined,
// } from '@ant-design/icons';
// import { NavLink } from 'react-router-dom';
// import '../../styles/SidePanel.css';

// const { Sider } = Layout;
// const { SubMenu } = Menu;

// const SidePanel = () => {
//   const [collapsed, setCollapsed] = useState(false);

//   const toggleCollapsed = () => {
//     setCollapsed(!collapsed);
//   };

//   const handleResize = () => {
//     setCollapsed(window.innerWidth <= 500);
//   };

//   useEffect(() => {
//     window.addEventListener('resize', handleResize);
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);

//   const items = [
//     {
//       key: '1',
//       label: 'DashBoard',
//       icon: <AppstoreOutlined />,
//       to: '/',
//     },
//     {
//       key: '2',
//       label: 'Leads',
//       icon: <TableOutlined />,
//       to: '/leads',
//     },
//     {
//       key: '3',
//       label: 'Calendar',
//       icon: <CalendarOutlined />,
//       to: '/calendar',
//     },
//     {
//       key: '4',
//       label: 'Conversations',
//       icon: <UserOutlined />,
//       to: '/conversations',
//     },
//     {
//       key: '5',
//       label: 'Analytics',
//       icon: <LineChartOutlined />,
//       to: '/analytics',
//     },
//     {
//       key: '6',
//       label: 'Availability',
//       icon: <ExclamationCircleOutlined />,
//       to: '/availability',
//     },
//     {
//       key: '7',
//       label: 'Settings',
//       icon: <SettingOutlined />,
//       children: [
//         {
//           key: 's1',
//           label: 'Company Details',
//           to: '/settings/companydetails',
//         },
//         {
//           key: 's2',
//           label: 'Sync Calendar',
//           to: '/settings/synccalendar',
//         },
//       ],
//     },
//     {
//       key: '8',
//       label: 'LogOut',
//       icon: <LogoutOutlined />,
//       to: '/logout',
//     },
//   ];

//   return (
//     <Layout style={{ minHeight: '100vh' }}>
//       <Sider theme='light' collapsible collapsed={collapsed} onCollapse={toggleCollapsed}>
//         <div className="logo" />
//         <Menu
//           defaultSelectedKeys={['1']}
//           defaultOpenKeys={['7']}
//           mode="vertical"
//           theme="light"

//         >
//           {items.map((item) =>
//             item.children ? (
//               <SubMenu key={item.key} icon={item.icon} title={item.label}>
//                 {item.children.map((subItem) => (
//                   <Menu.Item key={subItem.key}>
//                     <NavLink to={subItem.to} activeclassname="activeClicked">
//                       {subItem.label}
//                     </NavLink>
//                   </Menu.Item>
//                 ))}
//               </SubMenu>
//             ) : (
//               <Menu.Item key={item.key} icon={item.icon}>
//                 <NavLink to={item.to} activeclassname="activeClicked">
//                   {item.label}
//                 </NavLink>
//               </Menu.Item>
//             )
//           )}
//         </Menu>
//       </Sider>
//     </Layout>
//   );
// };

// export default SidePanel;

//Side Panel DropDown Non Simplified
// {
//   key: '7',
//   label: 'Settings',
//   icon: <SettingOutlined />,
//   children: [
//     {
//       key: 's1',
//       label: 'Company Details',
//       to: '/settings/companydetails',
//     },
//     {
//       key: 's2',
//       label: 'Phone Number',
//       to: '/settings/phonenumber',
//     },
//     {
//       key: 's3',
//       label: 'Change Password',
//       to: '/settings/changepassword',
//     },
//     {
//       key: 's4',
//       label: 'Sync Calendar',
//       to: '/settings/synccalendar',
//     },
//   ],
// },
