import React, { useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Pagination,
} from "@mui/material";
import dayjs from "dayjs";
import MUISubHeader from "../UIComponents/MUISubHeader";

const UnavailabilityInfo = ({ leaveData }) => {
  const [page, setPage] = useState(1);
  const itemsPerPage = 7;
  const totalPages = Math.ceil(leaveData.length / itemsPerPage);

  const handleChange = (event, value) => {
    setPage(value);
  };
  if (leaveData.length > 0) {
  }
  const displayedItems = leaveData.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Paper elevation={3} sx={{ padding: 2 }}>
        <MUISubHeader text="Time Off Schedule" textSize={16} />
        <List>
          {displayedItems.map((leave, index) => (
            <ListItem key={index} sx={{ borderBottom: "1px solid #ddd" }}>
              {leave.TYPE_FLAG === 1 && (
                <ListItemText
                  primary={`${leave.leaveDate_Start}   -   ${leave.leaveDate_End}`}
                  secondary={leave.Reason}
                />
              )}
              {leave.TYPE_FLAG === 2 && (
                <ListItemText
                  primary={`${leave.leaveDate_Hourly} : ${leave.Start_Hours} - ${leave.End_Hours} `}
                  secondary={leave.Reason}
                />
              )}
              {/* <ListItemText
                  primary={`${leave.start.format('YYYY-MM-DD HH:mm')} - ${leave.end.format('HH:mm')}`}
                  secondary={leave.reason}
                /> */}
            </ListItem>
          ))}
        </List>
        <Grid container justifyContent="center" sx={{ marginTop: 2 }}>
          <Pagination count={totalPages} page={page} onChange={handleChange} />
        </Grid>
      </Paper>
    </Grid>
  );
};

export default UnavailabilityInfo;
