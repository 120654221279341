import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Box, Button, Typography } from "@mui/material";
import MUISubHeader from "./MUISubHeader";
import withLoader from "../Loader/withLoader";
import { useDispatch } from "react-redux";
import { PaymentService } from "../../services/PaymentService";
import { InfoWrapper } from "../../utils/InfoWrapper";

// 1. Load Stripe with your public API key
const stripePromise = loadStripe(
  "pk_test_51PuifhEp2euDEkistyGV1FJKnEPjDcqERJFyEKlHQgGhvREgZ05mWNabFoShjBKMb3WDAaRYCzSeDLi46yaiv85a00rPqshqZF"
);

const CheckoutForm = ({  plan,billingCycle, numberOfSeats, }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const { user } = InfoWrapper();
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return; // Stripe.js hasn't loaded yet.
    }

    const cardElement = elements.getElement(CardElement);

    // 2. Use stripe.createPaymentMethod() to create a PaymentMethod and obtain its ID
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.error("Payment Method Error:", error);
      // Handle the error appropriately in your UI
    } else {
      console.log("PaymentMethodId:", paymentMethod.id); // Send this ID to your backend for processing
      // Call your API with the PaymentMethodId, e.g.,
      // await fetch('/your-api-endpoint', {
      //   method: 'POST',
      //   body: JSON.stringify({ paymentMethodId: paymentMethod.id }),
      // });
      const data = JSON.stringify({
        LoginsId: user.uid,
        PlanType: plan,
        PaymentMethodId: paymentMethod.id,
        Email:user.email,
        Cycle:billingCycle,
        Seats:numberOfSeats

      });
      let res = await withLoader(dispatch, () =>
        PaymentService.PaymentForPlan(data)
      );
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ width: "100%", maxWidth: "400px", margin: "auto" }}
    >
      <MUISubHeader textSize={18} text="Payment Details" />
      <Box
        sx={{
          border: "1px solid #ccc",
          padding: "15px",
          borderRadius: "4px",
          marginBottom: "20px",
        }}
      >
        <CardElement
          options={{
            style: {
              base: {
                fontSize: "16px",
                color: "#424770",
                fontFamily: "Roboto, sans-serif",
              },
            },
          }}
        />
      </Box>
      <Button
        fullWidth
        type="submit"
        variant="contained"
        color="primary"
        disabled={!stripe}
      >
        Pay
      </Button>
    </Box>
  );
};

// 3. Wrap your CheckoutForm component with the Elements provider
const PaymentForm = ({ billingCycle, numberOfSeats, plan }) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm plan={plan} billingCycle={billingCycle} numberOfSeats={numberOfSeats}/>
    </Elements>
  );
};

export default PaymentForm;
