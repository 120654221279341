import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
import PersonAdd from "@mui/icons-material/PersonAdd";
import { Button } from "@mui/material";
export const BtnIcons = ({
  btnLabel = "Button",
  color = "secondary",
  disabled = false,
  startIcon,
  endIcon,
  variant = "outlined",
  onClick,
  loading = false,
  setLoading,
  isIconButton = false,
  size="medium"
}) => {
  function handleClick() {
    setLoading(true);
    onClick();
  }

  function handleBtnClick() {
    onClick();
  }

  return (
    <div>
         {isIconButton && (
        <LoadingButton
          onClick={handleClick}
          endIcon={endIcon}
          startIcon={startIcon}
          loading={loading}
          loadingPosition="end"
          variant={variant}
          disabled={disabled}
          size={size}
          sx={{ margin: 1,  textTransform: "none" }}
        >
          {btnLabel}
        </LoadingButton>
      )}
      {!isIconButton && (
        <Button
          onClick={handleBtnClick}
          variant={variant}
          disabled={disabled}
          color={color}
          size={size}
          sx={{ margin: 1,  textTransform: "none"}}
        >
          {btnLabel}
        </Button>
      )}
    </div>
  );
};
export default BtnIcons;
