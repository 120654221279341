import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { logout } from '../features/user/userSlice';
import { useNavigate } from 'react-router-dom';


const Logout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
      dispatch(logout());
      navigate('/'); // Redirect to login page after logout
    }, [dispatch, navigate]);
  
    return null; 
    // auth.signOut();
    // useEffect(() =>{
    // },[])
  // return (
  //   <Login/>
  // )
}

export default Logout
