import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { Table, Input, Button, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { InfoWrapper } from "../../utils/InfoWrapper";

const Users = ({ spaceId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [filters, setFilters] = useState({});
  const [sorter, setSorter] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const { baseUrl } = InfoWrapper();

  // API call for fetching data based on pagination, filters, and sorter
  const fetchData = async (pagination, filters, sorter) => {
    setLoading(true);
    try {
      const response = await fetch(`${baseUrl}GetSpaceUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          SpaceId: spaceId,
          page: pagination.current,
          pageSize: pagination.pageSize,
          UserType: filters,
          sorter,
        }),
      });
      const result = await response.json();
      // Assuming the API returns paginated data in this structure
      const data = result.Info ? result.Info : [];
      const total = result.Info ? result.Info.Length : 0;
      setData(data);
      setPagination((prev) => ({
        ...prev,
        total, // total records from API
      }));
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Only call API when pagination, filters, or sorter change, preventing unnecessary re-renders
  useEffect(() => {
    console.log(`spaceId: ${spaceId}`);
    fetchData(pagination, filters, sorter);
  }, [pagination.current, pagination.pageSize, filters, sorter]);

  // Handle table changes (pagination, filtering, sorting)
  const handleTableChange = (newPagination, newFilters, newSorter) => {
    // Only update pagination if it has changed
    if (
      newPagination.current !== pagination.current ||
      newPagination.pageSize !== pagination.pageSize
    ) {
      setPagination({
        ...pagination,
        current: newPagination.current,
        pageSize: newPagination.pageSize,
      });
    }
    setFilters({
      Role: newFilters.Role ? newFilters.Role[0] : false,
      IsActive: newFilters.IsActive ? newFilters.IsActive[0] : false,
      isAccepted: newFilters.isAccepted ? newFilters.isAccepted[0] : false,
    });

    setSorter({
      order: newSorter.order,
      field: newSorter.field,
    });
    // Only update filters if they have changed
    // if (JSON.stringify(newFilters) !== JSON.stringify(filters)) {
    //   setFilters(newFilters);
    // }

    // // Only update sorter if it has changed
    // if (
    //   newSorter.field !== sorter.field ||
    //   newSorter.order !== sorter.order
    // ) {
    //   setSorter({
    //     order: newSorter.order,
    //     field: newSorter.field,
    //   });
    // }
  };

  // Column search functionality
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <span style={{ backgroundColor: "#ffc069", padding: 0 }}>{text}</span>
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "FirstName",
      key: "FirstName",
      ...getColumnSearchProps("FirstName"),
    },
    {
      title: "Last Name",
      dataIndex: "LastName",
      key: "LastName",
      ...getColumnSearchProps("LastName"),
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
      ...getColumnSearchProps("Email"),
    },
    {
      title: "Role",
      dataIndex: "Role",
      key: "Role",
      filters: [
        { text: "User", value: "User" },
        { text: "Admin", value: "Admin" },
      ],
      onFilter: (value, record) => record.Role.includes(value),
    },
    {
      title: "Created On",
      dataIndex: "CreatedOn",
      key: "CreatedOn",
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: "Is Active",
      dataIndex: "IsActive",
      key: "IsActive",
      filters: [
        { text: "Active", value: true },
        { text: "Inactive", value: false },
      ],
      onFilter: (value, record) => record.IsActive === value,
      render: (isActive) => <span>{isActive ? "Active" : "Inactive"}</span>,
    },
    {
      title: "Is Accepted",
      dataIndex: "isAccepted",
      key: "isAccepted",
      filters: [
        { text: "Accepted", value: true },
        { text: "Not Accepted", value: false },
      ],
      onFilter: (value, record) => record.isAccepted === value,
      // onFilter: (value, record) => console.log(`Log: ${value} - ${record}`),
      render: (isAccepted) => (
        <span>{isAccepted ? "Accepted" : "Not Accepted"}</span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => console.log("Delete user", record.LOGINS_ID)}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={pagination}
      loading={loading}
      onChange={handleTableChange} // Handle pagination, filters, and sorting
      rowKey="LOGINS_ID"
      style={{ backgroundColor: "#fff", borderRadius: 10 }}
      headerStyle={{ backgroundColor: "#1976d2", color: "#fff" }}
    />
  );
};

export default Users;

// import React, { useState } from "react";
// import IconButton from "@mui/material/IconButton";
// import DeleteIcon from "@mui/icons-material/Delete";
// import { Table, Input, Button, Avatar, Space, Dropdown, Menu } from "antd";
// import {
//   SearchOutlined,
//   DeleteOutlined,
//   FilterOutlined,
// } from "@ant-design/icons";
// import "../../styles//TableAntd.css";
// // const dummyUsers = [
// //   {
// //     id: 1,
// //     userDetails: {
// //       name: "Moiz Farooq",
// //       avatar: "https://via.placeholder.com/40",
// //     },
// //     email: "moizfarook@gmail.com",
// //     Role: "User",
// //     Status: "Accepted",
// //     CreatedOn: "2024-09-04",
// //   },
// // ];

// const dummyUsers = [
//   {
//     LOGINS_ID: "113067366402112563539",
//     FirstName: "A",
//     LastName: "M",
//     Email: "moizfarook@gmail.com",
//     Role: "",
//     CreatedOn: "09-11-2024 03:00:51 AM",
//     IsActive: true,
//     InviteId: 13,
//     isAccepted: true
//   },
// ];

// const Users = () => {
//   const [searchText, setSearchText] = useState("");
//   const [searchedColumn, setSearchedColumn] = useState("");

//   const getColumnSearchProps = (dataIndex) => ({
//     filterDropdown: ({
//       setSelectedKeys,
//       selectedKeys,
//       confirm,
//       clearFilters,
//     }) => (
//       <div style={{ padding: 8 }}>
//         <Input
//           placeholder={`Search ${dataIndex}`}
//           value={selectedKeys[0]}
//           onChange={(e) =>
//             setSelectedKeys(e.target.value ? [e.target.value] : [])
//           }
//           onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
//           style={{ marginBottom: 8, display: "block" }}
//         />
//         <Space>
//           <Button
//             type="primary"
//             onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
//             icon={<SearchOutlined />}
//             size="small"
//             style={{ width: 90 }}
//           >
//             Search
//           </Button>
//           <Button
//             onClick={() => handleReset(clearFilters)}
//             size="small"
//             style={{ width: 90 }}
//           >
//             Reset
//           </Button>
//         </Space>
//       </div>
//     ),
//     filterIcon: (filtered) => (
//       <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
//     ),
//     onFilter: (value, record) =>
//       record[dataIndex]
//         ? record[dataIndex]
//             .toString()
//             .toLowerCase()
//             .includes(value.toLowerCase())
//         : "",
//     render: (text) =>
//       searchedColumn === dataIndex ? (
//         <span style={{ backgroundColor: "#ffc069", padding: 0 }}>{text}</span>
//       ) : (
//         text
//       ),
//   });

//   const handleSearch = (selectedKeys, confirm, dataIndex) => {
//     confirm();
//     setSearchText(selectedKeys[0]);
//     setSearchedColumn(dataIndex);
//   };

//   const handleReset = (clearFilters) => {
//     clearFilters();
//     setSearchText("");
//   };

//   const columns = [
//     {
//       title: "Email",
//       dataIndex: "Email",
//       key: "Email",
//       ...getColumnSearchProps("Email"),
//       render: (text) => <span>{text}</span>,
//     },
//     {
//       title: "First Name",
//       dataIndex: "FirstName",
//       key: "FirstName",
//       ...getColumnSearchProps("FirstName"),
//       render: (text) => <span>{text}</span>,
//     },
//     {
//       title: "Last Name",
//       dataIndex: "LastName",
//       key: "LastName",
//       ...getColumnSearchProps("LastName"),
//       render: (text) => <span>{text}</span>,
//     },

//     {
//       title: "Role",
//       dataIndex: "Role",
//       key: "Role",
//       filters: [
//         { text: "User", value: "User" },
//         { text: "Admin", value: "Admin" },
//       ],
//       onFilter: (value, record) => record.Role.includes(value),
//       render: (text) => <span>{text || "No Role"}</span>, // Handle empty roles
//     },
//     {
//       title: "Created On",
//       dataIndex: "CreatedOn",
//       key: "CreatedOn",
//       render: (text) => new Date(text).toLocaleDateString(),
//     },
//     {
//       title: "Is Active",
//       dataIndex: "IsActive",
//       key: "IsActive",
//       filters: [
//         { text: "Active", value: true },
//         { text: "Inactive", value: false },
//       ],
//       onFilter: (value, record) => record.IsActive === value,
//       render: (isActive) => <span>{isActive ? "Active" : "Inactive"}</span>,
//     },
//     {
//       title: "Invitation Status",
//       dataIndex: "isAccepted",
//       key: "isAccepted",
//       filters: [
//         { text: "Accepted", value: true },
//         { text: "Not Accepted", value: false },
//       ],
//       onFilter: (value, record) => record.isAccepted === value,
//       render: (isAccepted) => (
//         <span>{isAccepted ? "Accepted" : "Not Accepted"}</span>
//       ),
//     },
//     {
//       title: "Action",
//       key: "action",
//       render: (text, record) => (
//         <IconButton
//           aria-label="delete"
//           size="small"
//           onClick={() => console.log("Delete user", record.LOGINS_ID)}
//         >
//           <DeleteIcon fontSize="small" />
//         </IconButton>
//       ),
//     },
//   ];

//   return <Table columns={columns} dataSource={dummyUsers} rowKey="id" />;
// };

// export default Users;
