import React, { useState, useEffect } from "react";
import { Tabs, Tab, MenuItem, Select, Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

const ResponsiveTabs = ({
  tabs,
  selectedTab,
  setSelectedTab,
  handleTabChange,
}) => {
  useEffect(() => {
    console.log("============= Tabs ================", tabs);
  });

  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  const handleDropdownChange = (event) => {
    setSelectedTab(event.target.value);
  };

  return (
    <Box>
      {/* Render Dropdown for small screens */}
      {isSmallScreen ? (
        <Select value={selectedTab} onChange={handleDropdownChange} fullWidth>
          {tabs &&
            tabs.map((tab, index) => (
              <MenuItem key={index} value={tab.id}>
                {tab.TabName}
              </MenuItem>
            ))}
        </Select>
      ) : (
        // Render Tabs for larger screens
        <Tabs
          orientation="vertical" // Change to "horizontal" for horizontal tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="Responsive Tabs"
          sx={{ padding: 1 }}
        >
          {tabs &&
            tabs.map((tab, index) => (
              <Tab
                key={index}
                label={tab.TabName}
                sx={{
                  fontSize: 10, // Smaller font size
                  fontWeight: 600, // Bold text
                  minHeight: 32, // Reduce tab height
                  padding: "4px 8px", // Reduce padding
                  textTransform: "none", // Prevent text from being capitalized
                  whiteSpace: "nowrap", // Prevent wrapping of text
                  "&:hover": {
                    color: "#1976d2", // Change color on hover
                    backgroundColor: "white"
                  },
                }}
              />
            ))}
        </Tabs>
      )}

    </Box>
  );
};

export default ResponsiveTabs;
