import React from "react";
import { Box, colors, Grid, Typography } from "@mui/material";
export const MuiText = ({
  icon: Icon,
  text,
  textColor = "#808080",
  pt = "6px",
  fontSize = "1rem",
  iconColor = "#5862ff",
  mt=2,
  ml=3
}) => {
  return (
    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
      <Box display="flex" alignItems="center" sx={{ mt: mt, ml: ml }}>
        {Icon ? (
          <Icon sx={{ mr: 1, fontSize: fontSize, color: iconColor }} />
        ) : null}
        <Typography
          variant="h6"
          component="h2"
          gutterBottom
          sx={{
            pt: pt,
            fontSize: { fontSize },
            fontFamily:
              'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
            letterSpacing: "0.0075em",
            color: textColor ? textColor : "inherit",
          }}
        >
          {text}
        </Typography>
      </Box>
    </Grid>
  );
};

export default MuiText;
