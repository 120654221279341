// import { configureStore } from '@reduxjs/toolkit';
// import counterReducer from '../features/counter/counterSlice';
// import userReducer from '../features/user/userSlice'

// export const store = configureStore({
//   reducer: {
//     counter: counterReducer,
//     user:userReducer
//   },
// });

import { configureStore,combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from '../features/user/userSlice';
import userDetailsReducer from '../features/user/userDetailsSlice';
import loaderReducer, { loader } from '../features/user/LoaderSlice';
import calendarTypeReducer from '../features/user/calendarTypeSlice';


// Combine reducers
const rootReducer = combineReducers({
  user: userReducer,
  calendarType: calendarTypeReducer,
  loader:loaderReducer,
  userDetails:userDetailsReducer
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

export { store, persistor };

// const persistConfig = {
//   key: 'root',
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, userReducer,calendarTypeReducer);

// const store = configureStore({
//   reducer: {
//     user: persistedReducer,
//   },
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: false,
//     }),
// });

// const persistor = persistStore(store);

// export { store, persistor };
