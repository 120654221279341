// const {baseUrl} = InfoWrapper();
const baseUrl = process.env.REACT_APP_BASEURL;
const IntegrationService = {
  Get: async (uid) => {
    try {
      
      const apiUrl = `${baseUrl}GetIntegrations`;
      console.log("Inside IntegrationService Get", apiUrl);

      const data = JSON.stringify({
        Id: uid,
      });

      console.log("JsonObject", data);

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: data,
      });
      const res = await response.json();

      console.log("res:", res);

      return res;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  IsUserAppConnected: async (uid,  appName = "", appId = 0) => {
    try {
      // const apiUrl = `${baseUrl}GetUserConnectedApp?Id=${uid}&AppId=${appId}&AppName=${encodeURIComponent(appName)}`;
      let apiUrl = `${baseUrl}IsUserAppConnected?Id=${uid}`;
      apiUrl += `&AppName=${appName}`;
      apiUrl += `&AppId=${appId}`;
      
      console.log("Inside IntegrationService GetUserConnectedApp", apiUrl);

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        }
      });
      
      const res = await response.json();
      return res;
      
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  GetUserConnectedAppDetails: async (uid,  appName = "", appId = 0) => {
    try {
      // const apiUrl = `${baseUrl}GetUserConnectedApp?Id=${uid}&AppId=${appId}&AppName=${encodeURIComponent(appName)}`;
      let apiUrl = `${baseUrl}GetConnectedAppDetails`;
      console.log("Inside IntegrationService GetUserConnectedApp", apiUrl);
      const data = JSON.stringify({
        Uid: uid,
        AppName: appName,
        AppId: appId
      });

      console.log("JsonObject", data);

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: data,
      });
      const res = await response.json();

      console.log("res:", res);

      return res;
      
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  ConnectAppInfo: async (app) => {
    try {
      
      const apiUrl = `${baseUrl}ConnectAppInfo`;
      console.log("Inside IntegrationService ConnectAppInfo", apiUrl);

      const data = JSON.stringify({
        App: app,
      });

      console.log("JsonObject", data);

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: data,
      });
      const res = await response.json();

      console.log("res:", res);

      return res;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  ConnectGoogleAcc: async (token,uid) => {
    try {
      const apiUrl = `${baseUrl}api/calendar`;
      console.log("Inside IntegrationService ConnectGoogleAcc", apiUrl);

      const data = JSON.stringify({
        Code: token,
        User: uid
      });

      console.log("JsonObject", data);

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: data,
      });
      const res = await response.json();

      console.log("res:", res);

      return res;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  ConnectZoomAcc: async (token,uid) => {
    try {
      
      const apiUrl = `${baseUrl}ConnectZoom`;
      console.log("Inside IntegrationService ConnectZoomAcc", apiUrl);

      const data = JSON.stringify({
        Code: token,
        Logins_Id: uid
      });

      console.log("JsonObject", data);

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: data,
      });
      const res = await response.json();

      console.log("res:", res);

      return res;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  DeleteIntegratedApps: async (uid,Id,AppName) => {
    try {
      
      const apiUrl = `${baseUrl}DeleteIntegratedApps`;
      console.log("Inside IntegrationService DeleteIntegratedApps", apiUrl);

      const data = JSON.stringify({
        Id:Id,
        Uid:uid,
        AppName:AppName
      });

      console.log("JsonObject", data);

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: data,
      });
      const res = await response.json();

      console.log("res:", res);

      return res;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
};

export default IntegrationService;
