import React from 'react'
import '../styles/SignUp.css'
const SignUp = () => {
  return (
    <div className='SignUp'>
        <h1>Signup</h1>
      {/* //Img
      //form
      //Firstname,lastname, email,password,photourl */}
    <img src='' />
    <form>
        <input placeholder='Full Name(Required)' type='text'/>
        <input placeholder='Last' type='text'/>
        <input placeholder='FirstName' type='text'/>
        <input placeholder='FirstName' type='text'/>
    </form>
    </div>
  )
}

export default SignUp
