import React from "react";
import ArrowBackIosNewTwoToneIcon from "@mui/icons-material/ArrowBackIosNewTwoTone";
import TextOnly from "./TextOnly";
import { IconButton } from "@mui/material";

const GoBack = ({ text,iconFont="medium",iconColor = "secondary", onClick }) => {
  return (
    <>
      <IconButton aria-label="delete" size="small" onClick={onClick}>
        <ArrowBackIosNewTwoToneIcon fontSize={iconFont}/>
      </IconButton>
      {text && <TextOnly text={text} />}
    </>
  );
};

export default GoBack;
