import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  type: null,
};

const calendarTypeSlice = createSlice({
  name: 'calendarType',
  initialState,
  reducers: {
    addType: (state, action) => {
      console.log('------------ CalendarType Slice Invoke --------', action.payload);
      state.type = action.payload;
    },
    removeType: (state) => {
      state.type = null;
    },
  },
});

export const { addType, removeType } = calendarTypeSlice.actions;

// Selectors
export const selectCalendarType = (state) => state.calendarType.type;

export default calendarTypeSlice.reducer;
