
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { store, persistor } from "./app/store.js";
import { createRoot } from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { GoogleOAuthProvider } from "@react-oauth/google";

const container = document.getElementById("root");
const root = createRoot(container);
// let persistor = persistStore(store);
const theme = createTheme({
  palette: {
    text: {
      primary: "#000000",
      secondary: "#808080", // Add this if it's missing
    },
    background: {
      default: "#ffffff",
      paper: "#f5f5f5", // Add this if it's missing
    },
    common: {
      fontSize: 16,
      white: "#ffffff",
      grey: "#808080",
      errorColor:"#ed4337",
      borderColor:"#3f51b5",
      sidepanelBg: "#9c27b0",
      font: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    },
    font: {
      customFont:
        'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
      customFontSize: "1.5rem",
      customFontTextWeight: "500",
      subHeader: "0.87rem",
      lightText: "0.77rem",
      Header: "1rem",
      appTextColor: "#808080",
      letterSpacing: "0.0075em",
    },
  },
});

root.render(
  <GoogleOAuthProvider clientId="350494577451-5aagp02pd0ontk4t97kn3nt4up87tgec.apps.googleusercontent.com">
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
          ,
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </GoogleOAuthProvider>
);
