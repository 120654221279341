import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const SelectInput = ({
  label,
  value,
  onChange,
  options,
  fullWidth = false,
  size = "small",
  variant = 'outlined',
  height = '40px',
  labelFontWeight = 500,  // Default font weight for the label
  textFontWeight = 400,   // Default font weight for the selected text
  labelColor = "#808080", // Default label color (grey)
  textColor = "#808080",  // Default selected text color (grey)
  ...props
}) => {
  return (
    <FormControl fullWidth={fullWidth} variant={variant} size={size} {...props}>
      <InputLabel
        sx={{
          color: labelColor,
          fontWeight: labelFontWeight,
         
        }}
        
      >
        {label}
      </InputLabel>
      <Select
        value={value}
        onChange={onChange}
        label={label}
        sx={{
          color: textColor,
          fontWeight: textFontWeight,
          height: height,
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
