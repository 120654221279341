const baseUrl = process.env.REACT_APP_BASEURL;

export const UserDetailsService = {
  saveSettings: async (data) => {
    try {
      const apiUrl = `${baseUrl}api/SaveDetails`;

      // Create a FormData object and append all the necessary fields
      const formData = new FormData();
      formData.append('LoginsId', data.uid);
      formData.append('FirstName', data.firstName);
      formData.append('LastName', data.lastName);
      formData.append('UserName', data.userName);
      formData.append('PhoneNumber', data.phoneNumber);
      formData.append('Email', data.email);
      formData.append('OldPassword', data.password);
      formData.append('NewPassword', data.newpassword);
      formData.append('OTP', ""); // Add OTP if needed
      formData.append('CompanyName', data.companyname);
      formData.append('Profession', data.profession);
      formData.append('File', data.file); // Assuming `data.file` is a File object
      formData.append('Description', data.description);

      console.log("Inside saveSettings Events", apiUrl, formData);

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          AppName: "DbLocal",
        },
        body: formData,
      });

      return response;
    } catch (error) {
      console.error("Error Saving Details:", error);
      throw error;
    }
  },
};

export default { UserDetailsService };


// import React from "react";
// const baseUrl = process.env.REACT_APP_BASEURL;

// export const UserDetailsService = {
//   saveSettings: async (data) => {
//     try {
//       const apiUrl = `${baseUrl}api/SaveDetails`;

//       console.log(
//         "Inside saveSettings Events",
//         apiUrl,
//         JSON.stringify({
//           data,
//         })
//       );
//       const response = await fetch(apiUrl, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           AppName: "DbLocal",
//         },
//         body: JSON.stringify({
//           LoginsId: data.uid,
//           CompanyName: data.companyname,
//           Profession: data.profession,
//           File: data.filePath,
//           Description: data.description,
//           FirstName: data.firstName,
//           LastName: data.lastName,
//           PhoneNumber: data.phoneNumber,
//           Email: data.email,
//           OldPassword: data.password,
//           NewPassword: data.newpassword,
//           OTP: "",
//         }),
//       });
//       return response;
//     } catch (error) {
//       console.error("Error Saving Details:", error);
//       throw error;
//     }
//   },
// };

// export default { UserDetailsService };
