import React, { useState } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/user/userSlice";
import LoginService, { AuthService } from "../../services/LoginService";
import { useNavigate } from "react-router-dom";
import withLoader from "../Loader/withLoader";
import { Button, useTheme } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google"; // Google icon from MUI
import { InfoWrapper } from "../../utils/InfoWrapper";
import GoogleButton from "react-google-button";
import { login } from "../../features/user/userSlice";

const GoogleAuthButton = ({ isSignIn = true, crypto, navigateTo = "/" }) => {
  const { textColor, borderColor } = InfoWrapper();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const googleClientId =
    "350494577451-5aagp02pd0ontk4t97kn3nt4up87tgec.apps.googleusercontent.com";
  const [token, setToken] = useState(null);
  const user = useSelector(selectUser);
  let btnLabel = isSignIn ? "Sign in With Google" : "Sign Up With Google";
  //On Succesfully recieving token from Google
  //Pass the token to  to backend api for saving it
  const handleLoginSuccess = async (response) => {
    console.log("----------- Response Inside: -------------", response);
    setToken(response);
    //  Send the authorization code to your backend API
    const res = await withLoader(dispatch, () =>
      AuthService.GoogleSignIn(response, crypto)
    );
    if (res.Success) {
      console.log(
        "==============================================================="
      );

      console.log(res.Success);
      dispatch(
        login({
          email: res.Success.email,
          uid: res.Success.uid,
          accessToken: "",
          isLocked: 0,
          timerExpire: null,
          firstName: res.Success.firstName,
          lastName: res.Success.lastName,
          userName: res.Success.email.split("@")[0],
        })
      );
      navigate(navigateTo);
    } else {
      console.log(
        "=====================================Error =========================="
      );
      //Show Error
    }
   
  };

  const handleLoginError = (error) => {
    console.error("Google Login failed:", error);
  };
const {baseUrl} = InfoWrapper();
  const GoogleLoginButton = () => {
    const login = useGoogleLogin({
      onSuccess: handleLoginSuccess,
      onError: handleLoginError,
      flow: "auth-code",
      scope: "https://www.googleapis.com/auth/calendar",
      redirect_uri: baseUrl
    });

    return (
      <GoogleButton title={btnLabel} onClick={() => login()} />
    );
  };

  return (
    <GoogleOAuthProvider
      clientId={googleClientId}
      scopes={[
        "openid",
        "profile",
        "email",
        "https://www.googleapis.com/auth/calendar",
      ]}
    >
      <GoogleLoginButton />
    </GoogleOAuthProvider>
  );
};

export default GoogleAuthButton;
