import React from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useTheme } from "@mui/material";

const CustomSelect = ({
  height = 38,
  width = 160,
  fontWeight = 500,
  fontSize = 15,
  label = "Label",
  handleDropdownChange,
  selectedValue, // Single selected value
  options, // Array of options with { id, name, value } format
  showIcon = false,
  fullWidth = false,
  error = false,
  textColor="#B0B0B0"//"#555"
}) => {
  const theme = useTheme();

  const handleChange = (event) => {
    const selectedId = event.target.value;
    const selectedOption = options.find((option) => option.id === selectedId);
    handleDropdownChange(selectedOption); // Pass the full option object
  };

  return (
    <FormControl
      variant="outlined"
      fullWidth={fullWidth}
      sx={{
        width: width,
        height: height,
        marginBottom: "16px",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: error ? "red" : theme.palette.grey[400], // default border color

          },
          "&:hover fieldset": {
            borderColor:  error ? "red" : "#1976d2", // border color on hover
          },
        },
      }}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        sx={{
          width: width,
          height: height,
          color: textColor, // Text color
          fontWeight: fontWeight,
          fontSize: fontSize,
          fontFamily: theme.typography.fontFamily,
          backgroundColor: "white", // White background color for the select box
          "&:hover": {
            backgroundColor: theme.palette.grey[100], // Slightly grey on hover
          },
          "& .MuiSelect-select": {
            padding: "8px 12px",
          },
          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: error ? "red" : "#1976d2", // border color when focused
          },
        }}
        label={label}
        value={selectedValue} // Use selectedValue directly
        onChange={handleChange} // Use the custom handleChange function
        MenuProps={{
          PaperProps: {
            sx: {
              backgroundColor: "white", // White background for dropdown
              color:"#808080",
              "& .MuiMenuItem-root": {
                borderBottom: `1px solid ${theme.palette.grey[300]}`, // Light divider after each option
              },
              "& .MuiMenuItem-root:last-child": {
                borderBottom: "none", // Remove the divider for the last item
              },
            },
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={option.id}
            sx={{
              "&:hover": {
                backgroundColor: theme.palette.common.grey, // Slightly grey on hover
              },
              transition: "background-color 0.3s ease", // Smooth hover transition
              display: "flex", // Make sure the content is flexible
              alignItems: "center", // Align the content vertically
            }}
          >
            {showIcon && option.icon && (
              <img
                src={option.icon}
                alt={option.name}
                style={{ width: 20, height: 20, marginRight: 8 }} // Adjust the size and margin
              />
            )}
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
// {options.map((option, index) => (
//   <MenuItem
//     key={index}
//     value={option.id}
//     sx={{
//       "&:hover": {
//         backgroundColor: theme.palette.common.grey, // Slightly grey on hover
//       },
//       transition: "background-color 0.3s ease", // Smooth hover transition
//     }}
//   >
//     {showIcon && option.icon}{option.name}
//   </MenuItem>
// ))}
// import React from "react";
// import { Select, MenuItem, FormControl, InputLabel, Divider } from "@mui/material";
// import { useTheme } from "@mui/material";

// const CustomSelect = ({
//   height = 38,
//   width = 160,
//   fontWeight = 500,
//   fontSize = 15,
//   label = "Label",
//   handleDropdownChange,
//   selectedValue,  // Single selected value
//   options,        // Array of options with { id,name,value } format
// }) => {
//   const theme = useTheme();

//   return (
//     <FormControl
//       variant="outlined"
//       sx={{
//         width: width,
//         height: height,
//         marginBottom: "16px",
//         "& .MuiOutlinedInput-root": {
//           "& fieldset": {
//             borderColor: theme.palette.grey[400], // default border color
//           },
//           "&:hover fieldset": {
//             borderColor: "#9c27b0", // border color on hover
//           },
//         },
//       }}
//     >
//       <InputLabel>{label}</InputLabel>
//       <Select
//         sx={{
//           width: width,
//           height: height,
//           color: "#555", // Text color
//           fontWeight: fontWeight,
//           fontSize: fontSize,
//           fontFamily: theme.typography.fontFamily,
//           backgroundColor: "white", // White background color for the select box
//           "&:hover": {
//             backgroundColor: theme.palette.grey[100], // Slightly grey on hover
//           },
//           "& .MuiSelect-select": {
//             padding: "8px 12px",
//           },
//           "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//             borderColor: "#9c27b0", // border color when focused
//           },
//         }}
//         label={label}
//         value={selectedValue}  // Use selectedValue directly
//         onChange={(event) => handleDropdownChange(event)}
//         MenuProps={{
//           PaperProps: {
//             sx: {
//               backgroundColor: "white", // White background for dropdown
//               "& .MuiMenuItem-root": {
//                 borderBottom: `1px solid ${theme.palette.grey[300]}`, // Light divider after each option
//               },
//               "& .MuiMenuItem-root:last-child": {
//                 borderBottom: "none", // Remove the divider for the last item
//               },
//             },
//           },
//         }}
//       >
//         {options.map((option, index) => (
//           <MenuItem
//             key={index}
//             value={option.id}
//             name={option.value}
//             sx={{
//               "&:hover": {
//                 backgroundColor: theme.palette.common.grey, // Slightly grey on hover
//               },
//               transition: "background-color 0.3s ease", // Smooth hover transition
//             }}
//           >
//             {option.name}
//           </MenuItem>
//         ))}
//       </Select>
//     </FormControl>
//   );
// };

// export default CustomSelect;
