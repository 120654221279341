import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import MuiText from "./MuiText";
import { Box, Grid } from "@mui/material";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function InputFileUpload({ mt = 2, setUploadedFile }) {
  const [fileName, setFileName] = React.useState("");
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setUploadedFile(file);
    }
  };
  // const truncateFilename = (filename, maxLength) => {
  //   if (filename.length <= maxLength) return filename;
  //   const extension = filename.split(".").pop();

  //   return `${filename.substring(
  //     0,
  //     maxLength - extension.length - 3
  //   )}...${extension}`;
  // };
  const truncateFilename = (filename, maxLength=20) => {
    if (filename.length <= maxLength) return filename;
    const extension = filename.split(".").pop();
    const nameWithoutExtension = filename.substring(0, filename.lastIndexOf('.'));
    const charsToShow = maxLength - extension.length - 3; // 3 for the ellipsis

    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);

    return `${nameWithoutExtension.substring(0, frontChars)}...${nameWithoutExtension.substring(nameWithoutExtension.length - backChars)}.${extension}`;
};

  return (
    <Box>
      <Grid container>
        <Grid xs={12} md={4} item>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
            sx={{ textTransform: "none", mt: mt, fontSize: 12 }}
          >
            Upload
            <VisuallyHiddenInput
              type="file"
              onChange={handleFileChange}
              multiple
            />
          </Button>
        </Grid>
        <Grid xs={12} md={8} item>
          {fileName && <MuiText text={truncateFilename(fileName)} />}
        </Grid>
      </Grid>
    </Box>
  );
}
