
import { Card, CardContent, Grid, CardHeader } from "@mui/material";
import withLoader from "../components/Loader/withLoader";
import { useDispatch } from "react-redux";
import { PlansService } from "../services/PlansService";
import { useTheme } from "@emotion/react";
import MuiText from "../components/UIComponents/MuiText";
import MUISubHeader from "../components/UIComponents/MUISubHeader";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const UpgradePlan = () => {
  const dispatch = useDispatch();
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let res = await withLoader(dispatch, () => PlansService.getPlanDetails());
      setFeatures(res["features"]);
    };
    fetchData();
  }, [dispatch]);
  const navigate = useNavigate();

const handleClick = (val) => {
  switch(val){
    case "Free Plan" :
      navigate("/Subscribe",{ state: { plan: val } })
      break;
      case "Standard Plan" :
      navigate("/Subscribe",{ state: { plan: val } })
      break;
      case "Team Plan" :
      navigate("/Subscribe",{ state: { plan: val } })
      break;
      case "Enterprise Plan" :
      navigate("/Subscribe",{ state: { plan: val } })
      break;
      default:break;
  }
}
  const renderFeatureCard = (title, features) => (
    <Card
      onClick={() => handleClick(title)}
      sx={{
        backgroundColor: "white",
        border: "0.1rem #808080", // Blue border to make it stand out
        borderRadius: "12px", // Rounded corners
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)", // More pronounced shadow
        transition: "transform 0.3s ease, box-shadow 0.3s ease", // Animation on hover
        marginBottom: "5px",
        cursor: "pointer", // Make the card clickable
        "&:hover": {
          transform: "scale(1.01)", // Slightly enlarge on hover
          boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)", // Stronger shadow on hover
        },
      }}
    >
      <CardHeader
        sx={{
          backgroundColor: "#3f51b5", // Blue background
          color: "#ffffff", // White text
          padding: "16px",
        }}
        title={<MUISubHeader text={title} textSize={15} textColor={"#ffffff"} />}
      />
      <CardContent>
        <ul style={{ color: "#808080", paddingLeft: "20px" }}>
          {features.map((feature, index) => (
            <li key={index} style={{ marginBottom: "1px" }}>
              <MuiText text={feature.Description} />
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );

  const freeFeatures = features.filter(
    (f) => f.isFree 
  );
  const standardFeatures = features.filter(
    (f) => !f.isFree && f.isStandard 
  );
  const teamFeatures = features.filter(
    (f) => !f.isFree && !f.isStandard && f.isTeam 
  );
  const enterpriseFeatures = features.filter(
    (f) => !f.isTeam && f.isEnterprise
  );

  return (
    <div>
      <Grid container spacing={2} sx={{ padding: "10px" }}>
        <Grid item xs={12} sm={6} md={3}>
          {renderFeatureCard("Free Plan", freeFeatures)}
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          {renderFeatureCard("Standard Plan", standardFeatures)}
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          {renderFeatureCard("Team Plan", teamFeatures)}
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          {renderFeatureCard("Enterprise Plan", enterpriseFeatures)}
        </Grid>
      </Grid>
    </div>
  );
};

export default UpgradePlan;
