const baseUrl = process.env.REACT_APP_BASEURL;

export const PlansService = {
  getPlanDetails: async (id) => {
    try {
      const apiUrl = `${baseUrl}PlanDetails`;
    //   const data = JSON.stringify({
    //     LoginsId:id
    //   })

      console.log("Inside PlansService => PlanDetails", apiUrl);

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          AppName: "DbLocal",
        },
      });

      return response.json();
    } catch (error) {
      console.error("Error getTeamUsersInfo Team:", error);
      throw error;
    }
  },
};

export default { PlansService };
