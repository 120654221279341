
// import { showLoader, hideLoader } from '../../features/user/LoaderSlice';
// export const withLoader = async (dispatch, asyncAction) => {
//     dispatch(showLoader());
//   try {
//     const result = await asyncAction();
//     return result;
//   } finally {
//     dispatch(hideLoader());
//   }
// };

// export default withLoader;

import { showLoader, hideLoader } from '../../features/user/LoaderSlice';

export const withLoader = async (dispatch, asyncAction) => {
  dispatch(showLoader());

  // Set a timeout to hide the loader after 10 seconds
  const timeoutId = setTimeout(() => {
    dispatch(hideLoader());
  }, 10000);

  try {
    const result = await asyncAction();
    clearTimeout(timeoutId); // Clear the timeout if the action completes within 10 seconds
    return result;
  } finally {
    dispatch(hideLoader());
    clearTimeout(timeoutId); // Ensure timeout is cleared after action completes
  }
};

export default withLoader;
