import React, { useEffect, useState } from "react";
import { Box, Grid, useTheme } from "@mui/material";
import MuiHeader from "../components/UIComponents/MuiHeader";
import GoogleIcon from "@mui/icons-material/Google";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
import ExchangeIcon from "@mui/icons-material/Business";
import OutlookIcon from "@mui/icons-material/Email";
import "../styles/Dashboard.css";
import { useLocation, useNavigate } from "react-router-dom";
import EventTypeCard from "../components/Events/EventTypeCard";
import { InfoWrapper } from "../utils/InfoWrapper";

const Events = () => {
  const location = useLocation();
  const {userDt} = InfoWrapper();
  const navigate = useNavigate();
  const theme = useTheme();
  const { spaceId,spaceType } = location.state || "";
  const handleClick = (val) => {
    switch (val) {
      case "p2p":
        navigate("/CreateEvent", {
          state: { spaceId: spaceId, EventType: "1",spaceType:userDt.UserType },
        });
        break;
      case "group":
        navigate("/CreateEvent", {
          state: { spaceId: spaceId, EventType: "3" },
        });
        break;
      case "joint":
        navigate("/CreateEvent", {
          state: { spaceId: spaceId, EventType: "2" },
        });
        break;
      case "round":
        navigate("/CreateEvent", {
          state: { spaceId: spaceId, EventType: "4" },
        });
        break;
      default:
        console.log("NA");
    }
  };
  useEffect(() => {
    console.log("================ spaceId ==================", spaceId);
  });
  return (
    <>
      <MuiHeader text={"Events"} />

      <Box sx={{ flexGrow: 1, padding: 2, marginTop: 5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <EventTypeCard
              icon={GoogleIcon}
              title="P2P"
              description="Person 2 Person"
              meetingType="Arrange a Person to Person Meeting"
              color="#4285F4"
              onClick={() => handleClick("p2p")}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <EventTypeCard
              icon={MicrosoftIcon}
              title="Group"
              description="One Host - Multiple Partipants"
              meetingType="Suitable for Webinars, Online Classes"
              color="#00A4EF"
              onClick={() => handleClick("group")}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <EventTypeCard
              icon={ExchangeIcon}
              title="Joint"
              description="Multiple Host - One Invitee"
              meetingType="Suitable For Interviews"
              color="#0078D4"
              onClick={() => handleClick("joint")}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <EventTypeCard
              icon={OutlookIcon}
              title="RoundRobin"
              description="Rotating host with one invitee"
              meetingType=" Perfect for rotating team members to connect with new sales leads."
              color="#0078D4"
              onClick={() => handleClick("round")}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Events;
