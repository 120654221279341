// import React from "react";
// import {
//   Card,
//   CardActionArea,
//   CardContent,
//   CardHeader,
//   CardActions,
//   IconButton,
//   Typography,
//   Button,
//   Menu,
//   MenuItem,
//   useTheme,
//   Box,
// } from "@mui/material";
// import SettingsIcon from "@mui/icons-material/Settings";
// import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// import ShareIcon from "@mui/icons-material/Share";
// import MUISubheader from "../UIComponents/MUISubHeader";

// const EventCard = ({
//   title,
//   description,
//   meetingType,
//   onClick,
//   copyEventUrl,
// }) => {
//   const theme = useTheme();
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const open = Boolean(anchorEl);

//   const handleMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//     event.stopPropagation(); // Prevents card click event
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };

//   return (
//     <Card
//       elevation={10}
//       sx={{
//         backgroundColor: theme.palette.common.white,
//         borderRadius: "8px",
//         boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
//         position: "relative",
//         paddingBottom: "4px",
//         "&:hover::after": {
//           content: '""',
//           position: "absolute",
//           left: "10%",
//           bottom: 0,
//           width: "80%",
//           height: "2px",
//           backgroundColor: theme.palette.primary.main,
//           transition: "width 0.4s ease-in-out",
//         },
//       }}
//     >
//       <CardActionArea
//         onClick={onClick}
//         sx={{
//           "&:hover": {
//             backgroundColor: "transparent",
//           },
//         }}
//       >
//         <CardHeader
//           title={<MUISubheader text={title} textSize={15} fontWeight={700} />}
//           action={
//             <IconButton onClick={handleMenuOpen} sx={{ fontSize: 15 }}>
//               <SettingsIcon />
//             </IconButton>
//           }
//           sx={{
//             paddingBottom: 0,
//             fontFamily: theme.palette.common.font,
//             backgroundColor: theme.palette.common.white,
//           }}
//         />
//         <CardContent sx={{ padding: "16px 24px", textAlign: "left" }} >
//           <Typography
//             variant="body2"
//             color="textSecondary"
//             sx={{ marginBottom: 1 }}
//           >
//             {description}
//           </Typography>
//           <Typography variant="body2" color="textSecondary">
//             {meetingType}
//           </Typography>
//         </CardContent>
//         <CardActions
//           sx={{ justifyContent: "space-between", padding: "16px 24px" }}
//         >
//           <Box display={"flex"} flexDirection={"row"}>
//             <Box onClick={() => copyEventUrl()}>
//               <ContentCopyIcon />
//             </Box>
//             <Box onClick={copyEventUrl} ml={2}>
//               <ShareIcon />
//             </Box>
//           </Box>
//           <Button
//             variant="outlined"
//             color="secondary"
//             sx={{
//               borderRadius: "20px",
//               fontSize: "12px",
//               fontWeight: 600,
//               padding: "4px 16px",
//             }}
//           >
//             Activate
//           </Button>
//         </CardActions>
//       </CardActionArea>
//       <Menu
//         anchorEl={anchorEl}
//         open={open}
//         onClose={handleMenuClose}
//         sx={{ color: theme.palette.text.primary }}
//       >
//         <MenuItem onClick={handleMenuClose}>Settings</MenuItem>
//         <MenuItem onClick={handleMenuClose}>More Options</MenuItem>
//       </Menu>
//     </Card>
//   );
// };

// export default EventCard;
import React from "react";
import {
  IconButton,
  Typography,
  Button,
  Menu,
  MenuItem,
  useTheme,
  Box,
  Checkbox,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";
import MUISubheader from "../UIComponents/MUISubHeader";

const EventCard = ({
  title,
  description,
  meetingType,
  onClick,
  copyEventUrl,
  isActive,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation(); // Prevents box click event
  };

  const handleMenuClose = (e) => {
    setAnchorEl(null);
    e.stopPropagation(); // Prevents box click event
  };

  return (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: isActive
          ? theme.palette.action.selected
          : theme.palette.common.white,
        borderRadius: "7px",
        boxShadow: "0 6px 15px rgba(0, 0, 0, 0.12)",
        position: "relative",
        padding: "15px",
        cursor: "pointer",
        transition: "all 0.3s ease",
        maxWidth: "400px",
        minWidth: "300px",
        height: "220px",
        // margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflow: "hidden",
        "&:hover": {
          boxShadow: "0 8px 30px rgba(0, 0, 0, 0.18)",
          transform: "translateY(-2px)",
        },
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        onClick={(e) => e.stopPropagation()}
      >
        <Box>
          <Checkbox sx={{ marginRight: 2 }} />
        </Box>

        <Box
          onClick={handleMenuOpen}
          sx={{ fontSize: 18, color: theme.palette.grey[600] }}
        >
          <SettingsIcon />
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: "8px",
          textAlign: "left",
          flexGrow: 1,
          overflowY: "auto",
          paddingRight: "8px",
        }}
      >
        <MUISubheader
          text={title}
          textSize={18}
          fontWeight={800}
          sx={{ color: theme.palette.primary.main }}
        />
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            marginBottom: 1,
            lineHeight: 1.6,
            color: theme.palette.grey[700],
          }}
        >
          {description}
        </Typography>
        <Typography variant="body2" sx={{ color: theme.palette.grey[600] }}>
          {meetingType}
        </Typography>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginTop={4}
        onClick={(e) => e.stopPropagation()}
      >
        <Box display="flex" flexDirection="row" gap={2}>
          <IconButton
            onClick={isActive ? undefined : copyEventUrl}
            sx={{
              color: isActive
                ? theme.palette.grey[400]
                : theme.palette.primary.main,
              cursor: isActive ? "not-allowed" : "pointer",
            }}
          >
            <ContentCopyIcon />
          </IconButton>
        </Box>
        <Button
          variant="contained"
          color="primary"
          sx={{
            borderRadius: "20px",
            fontSize: "14px",
            fontWeight: 700,
            padding: "8px 24px",
            textTransform: "none",
            boxShadow: "0 3px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          Activate
        </Button>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        sx={{ color: theme.palette.text.primary }}
      >
        <MenuItem onClick={handleMenuClose}>Settings</MenuItem>
        <MenuItem onClick={handleMenuClose}>More Options</MenuItem>
      </Menu>
    </Box>
  );
};

export default EventCard;
