import React from "react";
const baseUrl = process.env.REACT_APP_BASEURL;

export const EventService = {
  Save: async (event) => {
    try {
      
      const apiUrl = `${baseUrl}CreateEvent`;
      console.log("Inside EventService: <params>");

      const data = JSON.stringify({
        ...event,
      });
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: data,
      });
      const result = await response.json();
      // const res = responseObject["Info"];
      // const leaves = responseObject["leavesData"];

      console.log("result:", result);
      return result;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  GetAllInfo: async (id) => {
    try {
      const apiUrl = `${baseUrl}Events?Id=${id}`;
      console.log("Inside EventService => GetAllInfo:", apiUrl, id);
      const response = await fetch(apiUrl);

      const responseObject = await response.json();
      // const res = responseObject["Info"];

      // console.log("Info:", res);
      // return JSON.parse(res);
      return responseObject;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  GetEvents: async (id, type) => {
    try {
      const apiUrl = `${baseUrl}Events?Id=${id}&Type=${type}`;
      console.log("Inside EventService => GetEvents", apiUrl, id, type);
      const response = await fetch(apiUrl);

      const responseObject = await response.json();
      // const res = responseObject["Info"];

      // console.log("Info:", res);
      // return JSON.parse(res);
      return responseObject;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
  GetEventInfo: async (event) => {
    try {
      const apiUrl = `${baseUrl}GetEventInfo`;
      console.log("Inside EventService GetEventInfo:");

      const data = JSON.stringify({
        ...event,
      });
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: data,
      });
      const result = await response.json();
      // const res = responseObject["Info"];
      // const leaves = responseObject["leavesData"];

      console.log("result:", result);
      return result;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },SaveEventInfo: async (event) => {
    try {
      const apiUrl = `${baseUrl}SaveEventInfo`;
      console.log("Inside EventService SaveEventInfo:");

      const data = JSON.stringify({
        ...event,
      });
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: data,
      });
      const result = await response.json();
      console.log("result:", result);
      return result;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },GetTimeSlots: async (req) => {
    try {
      const apiUrl = `${baseUrl}CheckAvailability`;
      console.log("Inside EventService GetTimeSlots:");

      const data = JSON.stringify({
        ...req,
      });
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AppName: "DbLocal",
        },
        body: data,
      });
      const result = await response.json();
      console.log("result:", result);
      return result;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },
};
