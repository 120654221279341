import React, { useEffect, useState } from "react";
import { Card, Button, Box, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import Zoom from "@mui/material/Zoom";
import MUISubHeader from "../components/UIComponents/MUISubHeader";
import MuiText from "../components/UIComponents/MuiText";
import { useLocation } from "react-router-dom";
import withLoader from "../components/Loader/withLoader";
import { useDispatch } from "react-redux";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import VideocamIcon from "@mui/icons-material/Videocam";
import PublicIcon from "@mui/icons-material/Public";
import "../styles/Scrollbar.css";
import { EventService } from "../services/EventService";

const BookEvent = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [slotsVisible, setSlotsVisible] = useState(false);
  const [bookedSlot, setBookedSlot] = useState(null);
  const [username, setUsername] = useState();
  const [eventName, setEventName] = useState();
  const [timeSlots, setTimeSlots] = useState();
  const [timeSlotForDay, setTimeSlotsForDay] = useState();
  const [meetingLocation, setMeetingLocation] = useState();
  const location = useLocation();

  useEffect(() => {
    const pathSegments = location.pathname.split("/").filter(Boolean);
    setUsername(pathSegments[0]);
    setEventName(pathSegments[1]);

    const fetchData = async () => {
      let data = {
        UserName: pathSegments[0],
        EventName: pathSegments[1],
      };
      let res = await EventService.GetTimeSlots(data);
      setTimeSlots(res);
    };
    fetchData();
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSlotsVisible(true);
    setBookedSlot(null);
    const filteredTimeSlots = timeSlots.filter((timeSlot) => {
      const timeSlotDate = timeSlot.split("T")[0];
      return timeSlotDate === date.format("YYYY-MM-DD");
    });

    setTimeSlotsForDay(filteredTimeSlots);
  };

  const handleSlotClick = (slot) => {
    setBookedSlot(slot);
  };

  const handleBookClick = (slot) => {
    console.log(`Slot ${slot} booked!`);
  };

  const renderTimeSlots = () => {
    return timeSlotForDay.map((slot, index) => (
      <Zoom
        in={slotsVisible}
        style={{ transitionDelay: `${index * 100}ms` }}
        key={index}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            width: "250px",
            mt: 1,
          }}
        >
          {bookedSlot === slot ? (
            <>
              {/* Time Button (Occupying half width when slot is selected) */}
              <Button
                variant="outlined"
                onClick={() => handleSlotClick(slot)}
                sx={{
                  width: "50%",
                  backgroundColor: "#ffffff",
                  color: "#1976d2",
                }}
              >
                {`${slot.split("T")[1].split("+")[0]}`}
              </Button>

              {/* Book Button (Occupying half width when slot is selected) */}
              <Button
                variant="contained"
                onClick={() => handleBookClick(slot)}
                sx={{
                  width: "50%",
                  backgroundColor: "#1976d2",
                  color: "#ffffff",
                  "&:hover": {
                    backgroundColor: "#388e3c",
                  },
                }}
              >
                Book
              </Button>
            </>
          ) : (
            // Single Time Button (Full width when slot is not selected)
            <Button
              variant="outlined"
              onClick={() => handleSlotClick(slot)}
              sx={{
                width: "100%",
                backgroundColor: "#ffffff",
                color: "#1976d2",
              }}
            >
              {`${slot.split("T")[1].split("+")[0]}`}
            </Button>
          )}
        </Box>
      </Zoom>
    ));
  };

  return (
    <Box
      sx={{
        padding: "20px",
        maxWidth: "95vw",
        // margin: "auto",
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
      }}
    >
      <Card
        sx={{
          padding: "20px",
          boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
          borderRadius: "10px",
          backgroundColor: "#ffffff",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          minHeight: "85vh",
        }}
      >
        <Box
          sx={{
            flex: 0.6,
            minWidth: "150px",
            textAlign: { xs: "center", md: "left" },
            // mt: { xs: 5, md: 5 },
          }}
        >
          <MUISubHeader text={username} />
          <MUISubHeader text={eventName} />
          <MuiText
            icon={AccessTimeIcon}
            text={"60 Minutes"}
            textColor="#808080"
            mt={3}
            iconColor="#808080"
            fontSize="16px"
          />
          <MuiText
            icon={PublicIcon}
            text={"Time Zone: To Be Added"}
            textColor="#808080"
            mt={1}
            iconColor="#808080"
            fontSize="16px"
          />
        </Box>

        <Box sx={{ flex: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              value={selectedDate}
              onChange={handleDateChange}
              disablePast
              renderInput={(params) => <TextField {...params} fullWidth />}
              slotProps={{
                actionBar: { actions: [] },
                textField: { fullWidth: true },
              }}
              sx={{
                backgroundColor: "#ffffff",
                borderRadius: "12px",
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Softer shadow for elegance
                padding: "16px",
                "& .MuiPickersDay-root": {
                  fontWeight: "bold",
                  "&.Mui-selected": {
                    backgroundColor:
                      "linear-gradient(135deg, #1976d2 30%, #42a5f5 90%)", // Gradient for selected date
                    color: "#ffffff",
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)", // Slight shadow for selection
                    transform: "scale(1.05)", // Subtle scaling effect on selection
                    transition:
                      "transform 0.2s ease-in-out, background-color 0.2s", // Smooth transition
                  },
                  "&:not(.Mui-selected)": {
                    borderRadius: "50%", // Circle unselected days for consistency
                    color: "#424242", // Darker color for dates
                    transition: "background-color 0.2s ease",
                  },
                  "&:hover": {
                    backgroundColor: "rgba(25, 118, 210, 0.1)", // Soft blue hover effect
                    transform: "scale(1.03)", // Slight hover scale for interactivity
                    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.15)", // Shadow on hover
                  },
                },
                "& .css-gnwvcc-MuiTypography-root": {
                  display: "none", // Hides unnecessary typography if desired
                },
                "& .MuiCalendarPicker-root": {
                  backgroundColor: "#f9f9f9", // Light background for the entire picker
                  borderRadius: "8px",
                },
                "& .MuiPickersArrowSwitcher-root": {
                  color: "#1976d2",
                  fontWeight: "600",
                },
                "& .MuiTypography-caption": {
                  color: "#1976d2", // Light blue for month/year text
                  fontWeight: "bold",
                },
              }}
            />
          </LocalizationProvider>
        </Box>

        {selectedDate && (
          <Box sx={{ flex: 0.7, minWidth: "250px", mt: 3 }}>
            <MuiText
              text={`${format(selectedDate.toDate(), "MMMM d, yyyy")}`}
              sx={{ marginBottom: "10px", color: "#1976d2" }}
            />
            <div className="scrollbar" id="style-1">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  maxHeight: "300px",
                  overflowY: "auto",
                  paddingRight: "10px",
                }}
              >
                {renderTimeSlots()}
              </Box>
            </div>
          </Box>
        )}
      </Card>
    </Box>
  );
};

export default BookEvent;

// import React, { useEffect, useState } from "react";
// import { Card, Typography, Button, Box, TextField } from "@mui/material";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
// import { format } from "date-fns";
// import Zoom from "@mui/material/Zoom";
// import MUISubHeader from "../components/UIComponents/MUISubHeader";
// import MuiText from "../components/UIComponents/MuiText";
// import { useLocation } from "react-router-dom";
// import withLoader from "../components/Loader/withLoader";
// import { useDispatch } from "react-redux";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import VideocamIcon from "@mui/icons-material/Videocam";
// import "../styles/Scrollbar.css";
// import { EventService } from "../services/EventService";

// const BookEvent = () => {
//   const [selectedDate, setSelectedDate] = useState(null);
//   const [slotsVisible, setSlotsVisible] = useState(false);
//   const [bookedSlot, setBookedSlot] = useState(null);
//   const [username, setUsername] = useState();
//   const [eventName, setEventName] = useState();
//   const [timeSlots, setTimeSlots] = useState();
//   const [timeSlotForDay, setTimeSlotsForDay] = useState();
//   const [meetingLocation, setMeetingLocation] = useState();
//   const location = useLocation();

//   useEffect(() => {
//     const pathSegments = location.pathname.split("/").filter(Boolean);
//     setUsername(pathSegments[0]);
//     setEventName(pathSegments[1]);

//     const fetchData = async () => {
//       let data = {
//         UserName: pathSegments[0],
//         EventName: pathSegments[1],
//       };
//       let res = await EventService.GetTimeSlots(data);
//       setTimeSlots(res);
//       console.log("=============================");
//       console.log("=============================");
//       console.log(res);
//     };
//     fetchData();
//   }, []);

//   const dispatch = useDispatch();

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//     setSlotsVisible(true);
//     setBookedSlot(null);
//     const filteredTimeSlots = timeSlots.filter((timeSlot) => {
//       // Extract the date part from the timestamp (up to the 'T')
//       const timeSlotDate = timeSlot.split("T")[0];
//       return timeSlotDate === date.format("YYYY-MM-DD");
//     });

//     setTimeSlotsForDay(filteredTimeSlots);
//   };

//   const handleSlotClick = (slot) => {
//     setBookedSlot(slot);
//   };

//   const handleBookClick = (slot) => {
//     console.log(`Slot ${slot} booked!`);
//     // Add your booking logic here
//   };

//   const renderTimeSlots = () => {
//     const dummySlots = [
//       "10:00 AM",
//       "11:30 AM",
//       "1:00 PM",
//       "3:30 PM",
//       "11:30 AM",
//       "1:00 PM",
//       "3:30 PM",
//     ];
//     return timeSlotForDay.map((slot, index) => (
//       <Zoom
//         in={slotsVisible}
//         style={{ transitionDelay: `${index * 100}ms` }}
//         key={index}
//       >
//         <Box
//           sx={{ display: "flex", alignItems: "center", gap: 1, width: "250px",mt:1 }}
//         >
//           {bookedSlot === slot ? (
//             <>
//               {/* Time Button (Occupying half width when slot is selected) */}
//               <Button
//                 variant="outlined"
//                 onClick={() => handleSlotClick(slot)}
//                 sx={{
//                   width: "50%",
//                   backgroundColor: "#ffffff",
//                   color: "#1976d2",
//                 }}
//               >
//                 {`${slot.split("T")[1].split("+")[0]}`}
//               </Button>

//               {/* Book Button (Occupying half width when slot is selected) */}
//               <Button
//                 variant="contained"
//                 onClick={() => handleBookClick(slot)}
//                 sx={{
//                   width: "50%",
//                   backgroundColor: "#1976d2",
//                   color: "#ffffff",
//                   "&:hover": {
//                     backgroundColor: "#388e3c",
//                   },
//                 }}
//               >
//                 Book
//               </Button>
//             </>
//           ) : (
//             // Single Time Button (Full width when slot is not selected)
//             <Button
//               variant="outlined"
//               onClick={() => handleSlotClick(slot)}
//               sx={{
//                 width: "100%",
//                 backgroundColor: "#ffffff",
//                 color: "#1976d2",
//               }}
//             >
//               {`${slot.split("T")[1].split("+")[0]}`}
//             </Button>
//           )}
//         </Box>
//       </Zoom>
//     ));

//     // return timeSlotForDay.map((slot, index) => (
//     //   <Zoom
//     //     in={slotsVisible}
//     //     style={{ transitionDelay: `${index * 100}ms` }}
//     //     key={index}
//     //   >
//     //     <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
//     //       <Button
//     //         variant="outlined"
//     //         onClick={() => handleSlotClick(slot)}
//     //         sx={{
//     //           margin: "10px 0",
//     //           width: bookedSlot ? "100px" : "250px",
//     //           backgroundColor: "#ffffff",
//     //           color: "#1976d2",
//     //         }}
//     //       >
//     //         {`${slot.split("T")[1].split("+")[0]}`}
//     //       </Button>
//     //       {bookedSlot === slot && (
//     //         <Button
//     //           variant="contained"
//     //           onClick={() => handleBookClick(slot)}
//     //           sx={{
//     //             backgroundColor: "#1976d2", // Green for the book button
//     //             color: "#ffffff",
//     //             "&:hover": {
//     //               backgroundColor: "#388e3c", // Darker green on hover
//     //             },
//     //           }}
//     //         >
//     //           Book
//     //         </Button>
//     //       )}
//     //     </Box>
//     //   </Zoom>
//     // ));
//   };

//   return (
//     <Box
//       sx={{
//         padding: "20px",
//         maxWidth: "95vw",
//         margin: "auto",
//         backgroundColor: "#f5f5f5",
//         minHeight: "100vh",
//       }}
//     >
//       <Card
//         sx={{
//           padding: "20px",
//           boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
//           borderRadius: "10px",
//           backgroundColor: "#ffffff",
//           display: "flex",
//           minHeight: "85vh",
//           flexDirection: { xs: "column", md: "row" },
//         }}
//       >
//         {/* Left Section: Event Info */}
//         <Box sx={{ flex: 1, minWidth: "400px", mt: 10 }}>
//           {/* <MUISubHeader
//             text={"Event Information"}
//             textColor={"#1976d2"}
//             fontWeight={500}
//             textSize={22}
//           /> */}
//           <Box sx={{ marginBottom: "10px" }}>
//             <MUISubHeader text={username} />
//           </Box>
//           <Box sx={{ marginBottom: "10px" }}>
//             <MUISubHeader text={eventName} />
//           </Box>
//           <Box sx={{ marginBottom: "2px" }}>
//             <MuiText
//               icon={AccessTimeIcon}
//               text={"60 Minutes"}
//               textColor="#808080"
//               mt={0}
//               ml={0}
//               iconColor="#808080"
//             />
//           </Box>
//           <Box sx={{ marginBottom: "10px" }}>
//             <MuiText
//               icon={VideocamIcon}
//               text={
//                 meetingLocation
//                   ? meetingLocation
//                   : "Web conferencing details provided upon confirmation."
//               }
//               textColor="#808080"
//               mt={0}
//               ml={0}
//               iconColor="#808080"
//             />
//           </Box>
//         </Box>

//         {/* Middle Section: Calendar */}
//         <Box sx={{ flex: 1, justifyContent: "flex-start" }}>
//           <LocalizationProvider dateAdapter={AdapterDayjs}>
//             <StaticDatePicker
//               value={selectedDate}
//               onChange={handleDateChange}
//               disablePast
//               renderInput={(params) => <TextField {...params} fullWidth />}
// slotProps={{
//   actionBar: { actions: [] },
//   toolbar: { hidden: true }, // Hide the top toolbar
//   calendarHeader: { hidden: true }, // Hide the calendar header
//   textField: { fullWidth: true },
// }}
//               sx={{
//                 backgroundColor: "#ffffff",
//                 width: "400px", // Adjust width to make the calendar appear larger
//                 height: "500px", // Adjust height to make the calendar appear larger
//                 transform: "scale(1.2)", // Scale the entire calendar to make it bigger
//                 transformOrigin: "top left", // Ensure scaling starts from the top left
//                 "& .css-gnwvcc-MuiTypography-root": {
//                   color: "#1976d2",
//                   fontWeight: 500,
//                   fontSize: 22,
//                   fontFamily:
//                     'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
//                 },
//                 "& .css-3jvy96-MuiTypography-root-MuiDatePickerToolbar-title": {
//                   color: "#808080",
//                   fontWeight: 500,
//                   fontSize: 22,
//                 },
//                 "& .MuiPickersCalendarHeader-label": { color: "#3788d8" },
//                 "& .MuiPickersDay-root": {
//                   "&.Mui-selected": {
//                     backgroundColor: "#1976d2",
//                     fontWeight: 500,
//                   },
//                 },
//                 // "& .MuiPickersDay-root": {
//                 //   fontSize: "1.1rem", // Increases font size of the dates
//                 //   padding: "10px", // Increases the size of the date cells
//                 // },
//               }}
//             />
//           </LocalizationProvider>
//         </Box>

//         {/* Right Section: Time Slots */}
//         {selectedDate && (
//           <Box sx={{ flex: 1, minWidth: "250px",height:"70vh" }} mr={5}>
//             <MuiText
//               text={`${format(selectedDate.toDate(), "MMMM d, yyyy")}`}
//               sx={{ marginBottom: "10px", color: "#1976d2" }}
//             />
//             <div className="scrollbar" id="style-1">
//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   alignItems: "center",
//                   maxHeight: "300px", // Set a fixed height for the scrollable area
//                   overflowY: "auto", // Enable vertical scrolling
//                   paddingRight: "10px", // Optional: Add padding to avoid scrollbar overlap
//                   width: "100%", // Adjust width if needed

//                   // // Custom scrollbar styles
//                   // "&::-webkit-scrollbar": {
//                   //   width: "8px", // Width of the scrollbar
//                   //   background: "transparent", // No background for the scrollbar track
//                   // },
//                   // "&::-webkit-scrollbar-thumb": {
//                   //   backgroundColor: "#808080", // Cool gray color for the scrollbar thumb
//                   //   borderRadius: "4px", // Slightly rounded edges for the thumb
//                   // },
//                   // "&::-webkit-scrollbar-thumb:hover": {
//                   //   backgroundColor: "#606060", // Darker shade when hovering over the thumb
//                   // },
//                   // scrollbarWidth: "thin", // For Firefox
//                   // scrollbarColor: "#808080 transparent", // Thumb color and track color for Firefox
//                 }}
//               >
//                 {renderTimeSlots()}
//               </Box>
//             </div>
//           </Box>
//         )}
//       </Card>
//     </Box>
//   );
// };

// export default BookEvent;
