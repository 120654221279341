import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Modal,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Button,
  TextField,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import InputText from "../components/UIComponents/InputText";
import MUISubHeader from "../components/UIComponents/MUISubHeader";
import TextOnly from "../components/UIComponents/TextOnly";
import MeetingOptions from "../components/CreateEvent/MeetingOptions";
import { MuiPhone } from "../components/UIComponents/MuiPhone";
import { EventService } from "../services/EventService";
import { InfoWrapper } from "../utils/InfoWrapper";
import withLoader from "../components/Loader/withLoader";
import { useDispatch } from "react-redux";
import StyledTextField from "../components/UIComponents/StyledTextField";
import CustomSelect from "../components/UIComponents/CustomSelect";
import IntegrationService from "../services/IntegrationService";
import EventPreview from "../components/Events/EventPreview";
import ButtonDropDown from "../components/UIComponents/ButtonDropDown";
import BtnCancel from "../components/UIComponents/BtnCancel";
import { useLocation, useNavigate } from "react-router-dom";
import MemberManager from "../components/UIComponents/MemberManager";
import zoomLogo from "../Icons/icon_Zoom.png";
import inPersonLogo from "../Icons/icon_inperson.png";
import phoneLogo from "../Icons/icon_Phone.png";
import GoogleMeetLogo from "../Icons/icon_GoogleMeet.png";
import MsteamsLogo from "../Icons/icon_Teams.png";
import WebexLogo from "../Icons/icon_webex.png";
import MuiText from "../components/UIComponents/MuiText";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

const CreateEvent = () => {
  const { user } = InfoWrapper();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [selectedDuration, setSelectedDuration] = useState("");
  const [eventDurationId, setEventDurationId] = useState(1);
  const [roundRobinLocation, setRoundRobinLocation] = useState(1);
  const [eventName, setEventName] = useState("");
  const [eventUrl, setEventUrl] = useState("");
  const [phoneModal, setPhoneModal] = useState(false);
  const [inPersonModal, setInPersonModal] = useState(false);
  const [physicalLocation, setPhysicalLocation] = useState("");
  const [phone, setPhone] = useState("");
  const [ownerWillCall, setOwnerWillCall] = useState(true);
  const [location, setLocation] = useState("");
  const [showlocationError, setShowLocationError] = useState(false);
  const [showlocationErrorMsg, setShowLocationErrorMsg] = useState(false);
  const [roundRobinHosts, setRoundRobinHosts] = useState([]);
  const [roundRobinLocations, setRoundRobinLocations] = useState([]);
  const [roundRobinErrors, setRoundRobinErrors] = useState({});

  const navigate = useNavigate();
  const loc = useLocation();
  const handlePhone = (val) => setPhone(val);
  const handlephoneMOpen = () => {
    setPhoneModal(true);
  };
  const handlephoneClose = () => {
    setPhoneModal(false);
    setOwnerWillCall(true);
  };
  const handleinpersonMOpen = () => {
    setInPersonModal(true);
  };
  const handleinpersonSave = () => {
    setLocation("In-Person Meeting");
    setInPersonModal(false);
  };
  const handleinpersonMClose = () => {
    setInPersonModal(false);
    setPhysicalLocation("");
  };
  const handleLocation = async (val, name) => {
    //check if it is a valid location that is attached
    let res = await IntegrationService.IsUserAppConnected(user.uid, name);
    if (!res.Info) {
      setShowLocationError(true);
      setShowLocationErrorMsg(`${val} is not Connected`);
      ConnectApp(val);
    } else {
      setShowLocationError(false);
      setShowLocationErrorMsg("");
    }
    setLocation(name);
  };
  const CreateEvent = async () => {
    const data = {
      Id: user.uid,
      Name: eventName,
      EventType: "0", //Need to Pass P2p or other
      TimeLimit: selectedDuration,
      Location: location,
      Rgb: "",
    };
    let res = await withLoader(dispatch, () => EventService.Save(data));
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const options = [
    { id: 1, value: "30", name: "30 min" },
    { id: 2, value: "60", name: "60 min" },
    { id: 3, value: "90", name: "90 min" },
  ];

  const meetingLocationOpt = [
    { id: 1, value: "zoom", name: "Zoom", icon: zoomLogo },
    { id: 2, value: "google", name: "Google Meet", icon: GoogleMeetLogo },
    { id: 3, value: "team", name: "Microsoft Teams", icon: MsteamsLogo },
    { id: 4, value: "webex", name: "WebEx", icon: WebexLogo },
    { id: 5, value: "phone", name: "Phone Call", icon: phoneLogo },
    { id: 6, value: "inperson", name: "In-Person", icon: inPersonLogo },
  ];

  const handleSelectChange = (event) => {
    setSelectedDuration(event.value);
    setEventDurationId(event.id);
  };
  const [maxParticipant,setMaxParticipant] = useState();

 function handleMaxParticipants(e){
  const isDirectValue = typeof e === "number" || !e.target;
      const value = isDirectValue ? e : e .target.value;
      setMaxParticipant(value);

 }

  const handleRoundRObinMeetingLoc = async (hostId, selectedValue) => {
    let res = await IntegrationService.IsUserAppConnected(
      hostId,
      selectedValue.value
    );
    console.log(
      "=======Response handleRoundRObinMeetingLoc ===========",
      res.Info
    );

    if (!res.Info) {
      setRoundRobinErrors((prevErrors) => ({
        ...prevErrors,
        [hostId]: { isLocIntegrated: !res.Info, Name: selectedValue.name }, // Set error to true if res.Info is false
      }));
    } else {
      // setShowLocationError(false);
      // setShowLocationErrorMsg("");
    }

    setRoundRobinLocations((prevSelections) => ({
      ...prevSelections,
      [hostId]: selectedValue, // Update selection for the specific host
    }));
    console.log("selectedValue", selectedValue);
    console.log("roundRobinLocations", roundRobinLocations);
    console.log("===================>>>>> roundRobinErrors", roundRobinErrors);
    console.log(
      "SelectedRoundRObin Metting Location",
      roundRobinLocations[hostId]
    );
  };

  const handleEventName = (e) => {
    setEventName(e.target.value);
  };
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "1px solid #808080",
    borderRadius: 3,
    boxShadow: 24,
    p: 4,
  };
  const [urlConnectApp, setUrlConnectApp] = useState("");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  function ConnectApp(val) {
    console.log(`ConnectApp =>${val}`);
    switch (val.toLowerCase()) {
      case "zoom":
        setUrlConnectApp("/Integrations/Zoom/Connect");
      case "teams":
        setUrlConnectApp("/Integrations/Teams/Connect");
      case "google":
        setUrlConnectApp("/Integrations/Google/Connect");
    }
  }
  const [eventNameError, setEventNameError] = useState(false);
  const [roundRobinError, setRRError] = useState(false);

  const CreateNewEvent = async () => {
    //create a new side anel that will slide and display upon this panel
    if (!eventName) {
      setEventNameError(true);
      return;
    }

    if (newEventType === "4" && !roundRobinHosts.length) {
      setRRError(true);
      return;
    }
    if (newEventType === "3" && !maxParticipant) {
      return;
    }
    setEventNameError(false);
    setRRError(false);

    const data = {
      Id: user.uid,
      WorkSpaceId: userSpaceId,
      Name: eventName,
      EventType: newEventType, //Need to Pass P2p or other
      TimeLimit: selectedDuration,
      Location: location,
      Rgb: "",
      // RoundRobinHosts: roundRobinLocations,
      ...(newEventType === "4" && { Hosts: roundRobinLocations }),
      ...(newEventType === "3" && { 
        Hosts:roundRobinLocations,
        MaxParticipants:parseInt(maxParticipant)
      }),

    };

    
    console.log("===============NewEvent Data ================", data);
    let res = await withLoader(dispatch, () => EventService.Save(data));
    if (res.Info) {
      let newEventId = res.Info.Id;
      navigate(`/EventDetails/${newEventId}`);
    } else {
      //Handle Error
    }
  };
  const [userSpaceId, setUserSpaceId] = useState(0);
  const [userSpaceType, setUserSpaceType] = useState(0);
  const [newEventType, setNewEventType] = useState();
  useEffect(() => {
    const { spaceId, EventType,spaceType } = loc.state || "";
    console.log(
      `=================Inside Create Event ==============spceid: ${spaceId} EventType: ${EventType}`
    );
    debugger;
    setUserSpaceType(spaceType);
    setUserSpaceId(spaceId);
    setNewEventType(EventType);
  }, []);
  return (
    <>
      <Grid container display={"flex"} flexDirection={"row"}>
        <Grid
          item
          sm={5}
          md={3.5}
          height={"100vh"}
          display="flex"
          flexDirection="column"
        >
          <Box
            sx={{
              padding: "0.8rem",
              borderRight: "1px solid #e0e0e0",
              backgroundColor: "#ffffff",
              marginBottom: "2rem",
              overflowY: "auto",
              flexGrow: 1, // This allows the Box to take the available space
              maxWidth: "100%",
            }}
          >
            <MUISubHeader text="Event Details" />
            <Grid display="flex" flexDirection={"column"} gap={1}>
              <Grid item>
                <StyledTextField
                  label="Name"
                  placeholder="Event Name"
                  value={eventName}
                  setValue={setEventName}
                  fullWidth={true}
                  error={eventNameError}
                  required
                />
              </Grid>

              <Grid item mt={2}>
                <CustomSelect
                  label="Duration"
                  handleDropdownChange={handleSelectChange}
                  selectedValue={eventDurationId}
                  options={options}
                  width={"100%"}
                  height={50}
                  fullWidth={true}
                />
              </Grid>
              {newEventType === "3" && (
                <Box>
                  <InputText
                      isNumberOnly={true}
                      centerText={true}
                      value={maxParticipant}
                      onChange={handleMaxParticipants}
                    />
                </Box>
              )}
              {(newEventType === "4" || newEventType === "3") && (
                <Box>
                  <MemberManager
                    members={roundRobinHosts}
                    setMembers={setRoundRobinHosts}
                    roundRobinLocations={roundRobinLocations}
                    setRoundRobinLocation={setRoundRobinLocation}
                    spaceId={userSpaceId} //space type
                    label="Add Hosts"
                    header="Round Robin Hosts"
                    error={true}
                    handleRoundRObinMeetingLoc={handleRoundRObinMeetingLoc}
                  />
                  {roundRobinError && (
                    <TextOnly text={`At Least 2 hosts Required.`} color={"#B0B0B0"}/>
                  )}
                </Box>
              )}

              {/* 
                    Select show based on map of roundrobin Hosts
                    Save every Selected Host Location in an object {hostId,MeetingLocation}
                    Updated the UI fo rround robin host seletion Add tooltip on each member added capsule,
                    light grey color,Avatar working 

                    */}
              {roundRobinHosts.map((x) => (
                <Box mt={2} key={x.Id}>
                  <CustomSelect
                    label={`Location For ${x.FirstName} ${x.LastName}`}
                    handleDropdownChange={(selectedValue) =>
                      handleRoundRObinMeetingLoc(x.Id, selectedValue)
                    }
                    selectedValue={roundRobinLocations[x.Id]?.id || 1} // Show selected value for each host
                    options={meetingLocationOpt}
                    width={"100%"}
                    height={50}
                    fullWidth={true}
                    showIcon={true}
                    error={roundRobinErrors[x.Id]?.isLocIntegrated || false}
                    textColor="#B0B0B0"
                  />
                  {roundRobinErrors[x.Id] && (
                    <TextOnly
                      text={`${roundRobinErrors[x.Id].Name} is not Integrated`}
                    />
                  )}
                </Box>
              ))}

              {!location && !(newEventType === "4" || newEventType === "3") && (
                <>
                  <MUISubHeader text="Location" textSize={17} />
                  <MeetingOptions
                    handlephoneMOpen={handlephoneMOpen}
                    handleLocation={handleLocation}
                    handleinpersonMOpen={handleinpersonMOpen}
                    locationSeleted={location}
                  />
                </>
              )}
              {location && (
                <Grid sm={12} md={9}>
                  <TextField
                    value={location}
                    id="standard-search"
                    label="Location"
                    type="search"
                    fullWidth
                    onChange={(e) => setLocation(e.target.value)}
                    onTouchCancel={() => setShowLocationError("")}
                  />
                  {showlocationError && (
                    <>
                      <TextOnly
                        text={showlocationErrorMsg}
                        color={theme.palette.common.errorColor}
                        fontWeight={400}
                      />
                      <Typography
                        variant="body1"
                        component="a"
                        href={urlConnectApp}
                        target="_blank"
                        sx={{
                          cursor: "pointer",
                          textDecoration: "none",
                          fontFamily: theme.palette.common.font,
                          fontWeight: 500,
                          fontSize: 14,
                          letterSpacing: 0.2,
                          color: "rgb(0,105,255)",
                        }}
                      >
                        {"Connect"}
                      </Typography>
                    </>
                  )}
                </Grid>
              )}
            </Grid>
          </Box>

          <Box sx={{ padding: "0.5rem" }} mr={3}>
            <Grid
              container
              display={"flex"}
              flexDirection={"row"}
              spacing={1}
              justifyContent="flex-end"
              mb={3}
            >
              <Grid item mr={3}>
                <BtnCancel onClick={() => navigate("/")} />
              </Grid>
              <Grid item>
                <ButtonDropDown
                  text={"Continue"}
                  onClick={CreateNewEvent}
                  buttonWidth="80px"
                  buttonHeight="2vh"
                  padding="10px"
                  fullWidth // Optional: use full width for the button
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item sm={7} md={8.5}>
          <EventPreview
            eventName={eventName ? eventName : "Event Name"}
            location={location ? location : "Add Location to be displlyed here"}
            duration={options.filter((x) => x.id === eventDurationId)[0].value}
            username={`${user.userName}`}
          />
        </Grid>
      </Grid>

      {phoneModal && (
        <Modal
          open={phoneModal}
          onClose={handlephoneClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="admin"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  onClick={() => setOwnerWillCall(true)}
                  value="admin"
                  control={<Radio />}
                  label="I will call my Invitee"
                />
                <FormControlLabel
                  onClick={() => setOwnerWillCall(false)}
                  value="invitee"
                  control={<Radio />}
                  label="Invitee Should Call me"
                />
              </RadioGroup>
            </FormControl>
            {!ownerWillCall && (
              <Box mt={2}>
                <MuiPhone onChange={handlePhone} value={phone} />
              </Box>
            )}
            <Grid
              container
              spacing={1}
              justifyContent="flex-end"
              sx={{ marginTop: 2 }}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handlephoneClose}
                  sx={{ padding: "8px 16px", textTransform: "none" }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={handlephoneClose}
                  fullWidth
                  sx={{ padding: "8px 16px", textTransform: "none" }}
                >
                  Save & Close
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      )}

      {inPersonModal && (
        <Modal
          open={inPersonModal}
          onClose={handleinpersonMClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <MUISubHeader text="Enter Location:" textSize={17} />
            <InputText
              value={physicalLocation}
              onChange={(e) => setPhysicalLocation(e.target.value)}
              editable={false}
              required={true}
              fullWidth
            />
            <Grid
              container
              spacing={1}
              justifyContent="flex-end"
              sx={{ marginTop: 2 }}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleinpersonMClose}
                  sx={{ padding: "8px 16px", textTransform: "none" }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={handleinpersonSave}
                  sx={{ padding: "8px 16px", textTransform: "none" }}
                  fullWidth
                >
                  Save & Close
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default CreateEvent;

{
  /* <Grid item sm={12} md={9}>
                  <Grid mb={2}>
                    <MUISubHeader text={`/${eventUrl}`} textSize={12} />
                  </Grid>
                  <StyledTextField
                    label="Url"
                    placeholder="Event Url"
                    value={eventUrl}
                    setValue={setEventUrl}
                    fullWidth
                  />
                </Grid> */
}
